import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
} from '@material-ui/core';
import {
  Skeleton
} from '@material-ui/lab';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import IssueReportPanel from './IssueReportPanel';

import localization from '../../utils/localizations';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  marginVertical: {
    marginTop: 20,
    marginBottom: 20,
  },
  breadcrumbs: {
    margin: 10,
  },
  returnButtonAbs: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  aboutContainer: {
    padding: 20,
  },
  mainTitle: {
    marginBottom: 20,
  },
}));


function AboutPanel(props: {}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  

  const { path, url } = useRouteMatch();
  const backUrl = `/`;

  useEffect(() => {
    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    },300);

  }, []);
  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={6} className={classes.aboutContainer}>
            <Typography variant="h2" className={classes.mainTitle}>Sea Fox Boats</Typography>

            <Typography variant="body1" className={classes.marginVertical}>
              <Link href="http://www.seafoxboats.com/" target="_blank">http://www.seafoxboats.com/</Link>
            </Typography>

            <Typography variant="body1" className={classes.marginVertical}>
            2550 Highway 52<br/>
            Moncks Corner, SC 29461<br/>
            </Typography>
            <Typography>
            Telephone: <Link href="tel:843-761-6090">843-761-6090</Link><br/>
            Fax: <Link href="fax:843-761-6139">843-761-6139</Link><br/>
            </Typography>

            <IssueReportPanel />
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>

        <IconButton
          color="inherit"
          onClick={() => {
            setOpen(false);
            setTimeout(() => {
              setNavigateBack(true);
            }, 300);
          }}
          className={classes.returnButtonAbs}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Drawer>
      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}

export default AboutPanel;