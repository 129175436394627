import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
} from '@material-ui/core';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import {
  Boat
} from '../../types';
import localization from '../../utils/localizations';


const useStyles = makeStyles({
  root: {
    width: 360,
  },
  media: {
    height: 142,
  },
  boatDescriptionText: {
    whiteSpace: "pre-line",
  },
  cardActionArea: {
    justifyContent: "space-between",
  }
});

export default function BoatCard(props: {
  boat: Boat;
  actionLabel: string;
  isLoading?: boolean;
  onClicked: (boat: Boat) => void;
  className?: string;
}) {
  const classes = useStyles();

  return (
    <Card className={`${classes.root} ${props.className}`}>
      <CardActionArea onClick={() => props.onClicked(props.boat)}>
        <CardMedia
          className={classes.media}
          image={props.boat.thumbnail}
          title={props.boat.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.boat.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" className={classes.boatDescriptionText}>
            {props.boat.description ? props.boat.description : ''}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActionArea}>
        <div>
        <Button size="small" color="primary" onClick={() => props.onClicked(props.boat)}>
          Order
        </Button>
        <Button size="small" color="primary" onClick={() => {
          if (props.boat.product_url) window.open(props.boat.product_url, '_blank');
        }}>
          Learn More
        </Button>
        </div>
        {props.isLoading && <CircularProgress size={20}/>}
      </CardActions>
    </Card>
  );
}