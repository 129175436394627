import React, { useEffect } from 'react';

import {
  Alert,
  AlertTitle
} from '@material-ui/lab';

import { ToastMessage } from '../../types';


function isSimilar(toast1: ToastMessage, toast2: ToastMessage) {
  if ((toast1.title === toast2.title) && (toast1.message === toast2.message) && (toast1.type === toast2.type)) {
    return true;
  }
  return false;
}

export function Toasts(props: {
  toasts: ToastMessage[];
  onSetToasts: (toasts: ToastMessage[]) => void;
}) {

  const [toastTimeoutHandle, setToastTimeoutHandle] = React.useState<any|undefined>(undefined);

  const dismissToast = (removedToast: ToastMessage, toasts: ToastMessage[]) => {
    const newToasts = toasts.filter(t => {
      if (isSimilar(removedToast, t)) {
        return false;
      }
      return true;
    });

    console.log('dismiss', removedToast, newToasts)
    console.log(toasts)

    props.onSetToasts(newToasts);
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '18px',
        right: '20px',
        cursor: 'pointer',
        zIndex: 999,
      }}
    >
      {props.toasts.map((toast, i) => {
        return (
          <Toast
            key={`toast-${i}-${toast.title}`}
            toast={toast}
            toasts={props.toasts}
            onDismiss={(toast, toasts) => dismissToast(toast, toasts)}
          />
        )
    })}
    </div>
  );
}

export function Toast(props: {
  toast: ToastMessage;
  toasts: ToastMessage[];
  onDismiss: (toast: ToastMessage, toasts: ToastMessage[]) => void;
}) {

  useEffect(() => {
    let handle = setTimeout(() => {
      props.onDismiss(props.toast, props.toasts);
    }, 2500);
    return () => clearTimeout(handle);
  }, [props.toast, props.toasts]);

  const toast = props.toast;
  return (
    <Alert
      severity={toast.type}
      onClick={() => {
        props.onDismiss(toast, props.toasts);
      }}
      style={{
        marginBottom: 18,
        maxWidth: 400,
      }}
    >
      <AlertTitle>{toast.title}</AlertTitle>
      {toast.message}
    </Alert>
  )
}
