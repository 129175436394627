
export function isSamePhoneNumber(phone1: string, phone2: string) {
    if (!phone1) return false;
    if (!phone2) return false;
    let phone1Normalized = phone1.trim().split('-').join('').split(' ').join('').split('.').join('').split(',').join('');
    let phone2Normalized = phone2.trim().split('-').join('').split(' ').join('').split('.').join('').split(',').join('');
    if (!phone1Normalized) return false;
    if (!phone2Normalized) return false;
    if (phone1Normalized === phone2Normalized) return true;

    return false;
}

export function isSameEmail(email1: string, email2: string) {
    if (!email1) return false;
    if (!email2) return false;
    let email1Normalized = email1.toLowerCase().trim();
    let email2Normalized = email2.toLocaleLowerCase().trim();
    if (!email1Normalized) return false;
    if (!email2Normalized) return false;
    if (email1Normalized === email2Normalized) return true;

    return false;
}


export function isSameAddress(address1: string, address2: string) {
    if (!address1) return false;
    if (!address2) return false;
    let address1Normalized = address1.toLowerCase().trim().split('-').join('').split(' ').join('').split('.').join('').split(',').join('');;
    let address2Normalized = address2.toLocaleLowerCase().trim().split('-').join('').split(' ').join('').split('.').join('').split(',').join('');;
    if (!address1Normalized) return false;
    if (!address2Normalized) return false;
    if (address1Normalized === address2Normalized) return true;

    return false;
}

export function isSimilarAddress(address1: string, address2: string) {
    if (!address1) return false;
    if (!address2) return false;
    let address1Normalized = address1.toLowerCase().trim().split('-').join('').split(' ').join('').split('.').join('').split(',').join('');;
    let address2Normalized = address2.toLocaleLowerCase().trim().split('-').join('').split(' ').join('').split('.').join('').split(',').join('');;
    if (!address1Normalized) return false;
    if (!address2Normalized) return false;
    if (address1Normalized === address2Normalized) return true;
    
    let distance = levenshteinDistance(address1Normalized, address2Normalized)
    let score = distance / address1Normalized.length;
    if (score <= 0.15) return true;

    return false;
}

export function levenshteinDistance(str1: string, str2: string) {
    const len1 = str1.length;
    const len2 = str2.length;
  
    let matrix = Array(len1 + 1);
    for (let i = 0; i <= len1; i++) {
      matrix[i] = Array(len2 + 1);
    }
  
    for (let i = 0; i <= len1; i++) {
      matrix[i][0] = i;
    }
  
    for (let j = 0; j <= len2; j++) {
      matrix[0][j] = j;
    }
  
    for (let i = 1; i <= len1; i++) {
      for (let j = 1; j <= len2; j++) {
        if (str1[i - 1] === str2[j - 1]) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j] + 1,
            matrix[i][j - 1] + 1,
            matrix[i - 1][j - 1] + 1
          );
        }
      }
    }
  
    return matrix[len1][len2];
  }