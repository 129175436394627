import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Link
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import localization from '../../utils/localizations';

import * as Sentry from '@sentry/browser';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: 20,
    marginTop: 20,
  }
}));
  

function IssueReportPanel() {
  const classes = useStyles();
  return (

    <Alert
      severity="info"
      className={classes.alert}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={() => {
            if (appConfig.sentryDSN) {
              let now = moment().format('YYYY-MM-DD h:mm:ss a');
              let err = new Error(`New Error Report - ${now}`);
              const eventId = Sentry.captureException(err);
              const user: any = {}
              if ((window as any)._cache?.session?.student) {
                user.email = (window as any)._cache.session.student.email;
                user.name = (window as any)._cache.session.student.full_name;
              }
              Sentry.showReportDialog({ eventId: eventId, user: user });
            }
            else {
              window.open(appConfig.reportUrl);
            }
          }}
        >
          Report Issue
        </Button>
      }
    >
      Developed by <Link href="http://groverwebdesign.com/" target="_blank">Grover Web Design</Link>.
    </Alert>

  );
}

export default IssueReportPanel;