import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";



import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Tab,
  Tabs
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton
} from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import localization from '../utils/localizations';
import {
  Header
} from '../views/panels/Header';

import {
  SessionContext
} from '../data/Session';

import {
  BaseAPI,
} from '../data/BaseAPI';

import { useProfile } from '../data/Profile';

import {
  User,
} from '../types';

import UserCollection from '../views/collections/UserCollection';
import UserDetail from '../views/singles/UserDetail';
import UserForm from '../views/forms/UserForm';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    position: 'relative',
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    marginBottom: 10,
    padding: 10
  },
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function LoadingView() {
  return (
    <div style={{position: 'relative', padding: 10}}>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
    </div>
  );
}


export default function UsersPage() {
  const classes = useStyles();
  const { path, url } = useRouteMatch();

  const [showEditUserForm, setShowEditUserForm] = useState(false);
  const [editUser, setEditUser] = useState<User|null>(null);
  const [editUserCounter, setEditUserCounter] = useState(0);

  return (
    <SessionContext.Consumer>
      {({session}) => (
        <>
          <Header
            session={session}
            title="Users"
            breadcrumbs={[
              {
                title: "Users",
                link: `${appConfig.homepage}users/`
              }
            ]}
          />
          <div className={classes.root}>
            <Paper className={classes.toolbar}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  setEditUser(null);
                  setShowEditUserForm(true);
                }}
              >New User</Button>
            </Paper>

            <UserCollection key={`claim-collections-${editUserCounter}`} />

            <Switch>
                <Route exact path={`${path}:userId/`}>
                  <UserDetailPanel
                    onUpdated={(user) => {
                      setEditUserCounter(editUserCounter+1);
                    }}
                    onDeleted={(user) => {
                      setEditUserCounter(editUserCounter+1);
                    }}
                  />
                </Route>
            </Switch>

            <Dialog
              open={showEditUserForm}
              onClose={() => {
                setShowEditUserForm(false);
                setEditUser(null);
              }}
              fullScreen
              TransitionComponent={Transition}
            >
              <>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Typography variant="h6" className={classes.dialogTitle}>
                    {editUser ? `Edit ${editUser.first_name} ${editUser.last_name}` : 'Create New User'}
                  </Typography>
                  <IconButton edge="start" color="inherit" onClick={() => {
                    setShowEditUserForm(false);
                    setEditUser(null);
                  }} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <UserForm
                  user={editUser}
                  onSave={(user) => {
                    setShowEditUserForm(false);
                    setEditUser(null);
                    setEditUserCounter(editUserCounter+1);
                  }}
                  onSaveRedirect={(user) => `/users/${user.id}/`}
                  onCancel={() => {
                    setShowEditUserForm(false);
                    setEditUser(null);
                  }}
                />
              </DialogContent>
              </>
            </Dialog>

          </div>
        </>
      )}
    </SessionContext.Consumer>
  );
}


function UserDetailPanel(props: {
  onUpdated: (user: User) => void;
  onDeleted: (user: User) => void;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [user, setUser] = useState(undefined as User|undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [backUrl, setBackUrl] = useState('/users/');

  const { path, url } = useRouteMatch();
  const { userId } = useParams() as any;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('back')) setBackUrl(urlParams.get('back') as string);
  }, []);

  const loadUser = async (id: string) => {
    if (!id) return;
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`all-users-paginated/${id}/`);
      if ((data as any).id) {
        setUser(data as User);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadUser(userId);

    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    }, 300);

  }, [userId]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
        aria-labelledby="selected-user-title"
        aria-describedby="selected-user-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.dialogTitle}>
              {user?.first_name} {user?.last_name} {user?.email}
            </Typography>
            <Typography style={{marginRight: 20}}>
              {user?.role}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setNavigateBack(true);
              }, 300);
            }} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{backgroundColor: '#f5f5f5'}}>
          {!!user &&
            <UserDetail
              user={user}
              onUpdated={user => {
                setUser(user);
                props.onUpdated(user);
              }}
              onDeleted={user => {
                props.onDeleted(user);
                setNavigateBack(true);
              }}
            />}
          {!user &&
            <div>
              <LoadingView />
            </div>
          }
        </DialogContent>
        </>
      </Dialog>

      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}

