import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Chip,
  Avatar,
  FormHelperText,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import Alert from '@material-ui/lab/Alert';

import Dropzone from 'react-dropzone';

import {
  BaseAPI,
} from '../../data/BaseAPI';

import localization from '../../utils/localizations';
import fileSize from '../../utils/fileSize';


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    
  },
  uploadContainer: {
    marginBottom: 20,
    minHeight: 150,
    height: 'calc(100vh - 300px)',
    display: 'flex',
    flexDirection: 'column',
  },
  dropzone: {
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    marginBottom: 10,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  marginBottom: {
    marginBottom: 16,
  },
  fileChip: {
    marginBottom: 16,
    marginRight: 10,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

function FileChip(props: {id: string, onDelete: (id: string) => void}) {
  const classes = useStyles();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [fileData, setFileData] = useState({} as {id?: string; file_name?: string});
  const loadFileData = async (id: string) => {
    const api = new BaseAPI();
    const result = await api.get(`files/${id}/`);
    setFileData(result);
  };

  useEffect(() => {
    loadFileData(props.id);
  }, [props.id])
  return (
    <>
      <Chip
        avatar={<FileIcon />}
        label={fileData.file_name ? fileData.file_name : 'loading...'}
        className={classes.fileChip}
        onDelete={() => {
          setShowDeleteDialog(true);
        }}
      />
      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        aria-labelledby="alert-file-delete-dialog-title"
        aria-describedby="alert-file-delete-dialog-description"
      >
        <DialogTitle id="alert-file-delete-dialog-title">{localization.removeFilePrompt}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-file-delete-dialog-description">
            {localization.doYouWantToRemoveThisFile}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            autoFocus
            onClick={() => {
              setShowDeleteDialog(false);
              props.onDelete(props.id);
            }}
          >
            {localization.remove}
          </Button>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">
            {localization.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function FileUploadField(props: {
  accept?: string;
  multiple?: boolean;
  values?: string[]|string|undefined;
  helpText?: string;
  error?: string;
  onChanged?: (values: unknown) => void;
  title: string;
  maxSize?: number;
  description?: string;
  fileTitle?: string;
  fileKind?: string;
  fileStatus?: string;
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState([] as string[]);
  const [selectedFiles, setSelectedFiles] = React.useState([] as File[]);
  const [isUploading, setIsUploading] = React.useState(false);

  const onChanged = (values: string[]) => {
    if (!props.onChanged) return;

    if (props.multiple) {
      props.onChanged(values);
    }
    else {
      if (values.length > 0) props.onChanged(values[0]);
      else props.onChanged(undefined);
    }
  }

  const uploadFile = async (f: File) => {
    const api = new BaseAPI();
    const data = new FormData();
    if (props.fileTitle) data.append('title', props.fileTitle);
    if (props.fileKind) data.append('kind', props.fileKind);
    if (props.fileStatus) data.append('status', props.fileStatus);
    data.append('file', f);

    const [result, response, error] = await api.post(data, 'files/');
    return result.id;
  };

  const doUpload = async () => {
    if (selectedFiles.length == 0) return;

    setIsUploading(true);
    const uploads: Promise<string>[] = selectedFiles.map(uploadFile);
    const uploadResults = await Promise.all(uploads);
    setIsUploading(false);

    setSelectedFiles([]);
    onChanged(uploadResults);
  };

  let fileValues: string[] = [];
  if (typeof props.values == 'string') {
    if (props.values == '') fileValues = [];
    else fileValues = [props.values as string];
  }
  else if (typeof props.values == undefined || typeof props.values == null) fileValues = [];
  else fileValues = props.values as string[];

  return (
    <div className={classes.mainContainer}>
      {fileValues &&
      <div>
        {fileValues.map((f, i) => (
          <FileChip
            key={`file-${f}-${i}`}
            id={f}
            onDelete={(f) => {
              if (!fileValues) return;

              const newFiles: string[] = [];
              fileValues.forEach((ef) => {
                if (f != ef) newFiles.push(ef);
              });
              onChanged(newFiles);
            }}
          />
        ))}
      </div>
      }
      <Button
        variant="contained"
        size="small"
        startIcon={<CloudUploadIcon />}
        onClick={() => setOpen(true)}
      >Browse...</Button>
      {props.helpText && <FormHelperText>{props.helpText}</FormHelperText>}
      {props.error && <FormHelperText error={true}>{props.error}</FormHelperText>}
      <Dialog
        open={open}
        onClose={() => {
          if (!isUploading) setOpen(false);
        }}
        aria-labelledby="file-upload"
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitle id="file-upload">{props.title}</DialogTitle>
        <DialogContent>
          {props.description &&
            <DialogContentText>
              {props.description}
            </DialogContentText>
          }
          <Dropzone
            disabled={isUploading}
            accept={props.accept}
            onDrop={(acceptedFiles: File[]) => {
              const errors: string[] = [];

              let newSelectedFiles: File[] = [];
              if (props.multiple) {
                newSelectedFiles = Object.assign([], selectedFiles);
              }

              acceptedFiles.forEach((f, i) => {
                if (!props.multiple && i > 0) return;
                if (props.maxSize && f.size > props.maxSize) {
                  errors.push(`${f.name} (${fileSize(f.size)}) is too large!`);
                  return;
                }

                newSelectedFiles.push(f);
              });

              setErrors(errors);
              setSelectedFiles(newSelectedFiles);
            }}
          >
            {({getRootProps, getInputProps}) => (
              <section className={classes.uploadContainer}>
                <div className={classes.dropzone} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <CloudUploadIcon fontSize="large" />
                  <Typography className={classes.textAlignCenter}>Choose a file or drag here</Typography>
                  {props.maxSize &&
                  <Typography className={classes.textAlignCenter}>Max.  size: {fileSize(props.maxSize)}</Typography>
                  }
                </div>
                <div>
                  <div className={classes.textAlignRight}>
                  {selectedFiles.map((selectedFile, i) => {
                    return (
                        <Chip
                          key={`selected-files-${i}`}
                          avatar={<FileIcon />}
                          label={selectedFile.name ? selectedFile.name : ''}
                          className={classes.fileChip}
                          disabled={isUploading}
                          onDelete={() => {
                            const newSelectedFiles: File[] = [];
                            selectedFiles.forEach((f) => {
                              if (f != selectedFile) newSelectedFiles.push(f);
                            });
                            setSelectedFiles(newSelectedFiles);
                          }}
                        />
                    );
                  })}
                  </div>
                </div>
                <div>
                  {errors.map((error, i) => <Alert key={`error-${i}`} severity="error">{error}</Alert>)}
                </div>
              </section>
            )}
          </Dropzone>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={(selectedFiles.length == 0) || isUploading}
            onClick={(e) => {
              e.preventDefault();
              doUpload();
              setOpen(false);
            }}
          >
            {localization.save}
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
          >
            {localization.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FileUploadField;