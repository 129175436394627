import React, {
  useState,
  useEffect
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DownloadIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import FilePicker from '../forms/fields/FilePicker';
import {
  PartPicture
} from '../../types';
import { APIError, BaseAPI } from '../../data/BaseAPI';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

interface SelectedFile {
  file: File;
  key: string;
}

export default function PartPictures(props: {
  title?: string;
  pictures: PartPicture[];
  readOnly?: boolean;
  baseUrl?: string;
  onAdded?: (picture: PartPicture) => void;
  onRemoved?: (picture: PartPicture) => void;
  style?: React.CSSProperties | undefined;
}) {
  const classes = useStyles();
  const [files, setFiles] = useState<SelectedFile[]>([]);
  const [deletedPictures, setDeletedPictures] = useState<string[]>([]);
  const [uploadedPictures, setUploadedPictures] = useState<PartPicture[]>([]);

  const baseUrl = props.baseUrl ? props.baseUrl : 'boats/editor/part-pictures/';

  const deletePartPicture = async (picture: PartPicture) => {
    const newDeletedAttachments = Object.assign([], deletedPictures);
    newDeletedAttachments.push(picture.id);
    setDeletedPictures(newDeletedAttachments);
    
    if (props.onRemoved) props.onRemoved(picture);

    if (!picture.boat && !picture.part && !picture.variant) {
      const api = new BaseAPI();
      try {
        api.delete(`${baseUrl}${picture.id}/`);
      } catch (error) {
        console.error(error);
      }
    }
  }

  if (props.readOnly && props.pictures.length === 0) return (<></>);

  const filteredPictures = props.pictures.filter(picture => !deletedPictures.find(deletedId => picture.id === deletedId));

  return (
    <Paper className={classes.root} style={props.style}>
      <List
        dense
        component="nav"
        aria-label={props.title}
        subheader={props.title ? <ListSubheader>{props.title}</ListSubheader> : <></>} 
      >
        {filteredPictures.map((picture, i) => (
          <ListItem key={`picture-download-${i}`} button component="a" href={`${picture.picture_url}`} target="_blank">
            <img src={picture.thumbnail_url} alt="" width={60} height={60} style={{marginRight: 6, border: 'solid 1px #efefef'}} />
            <ListItemText primary={picture.title} secondary={picture.size_human} />
            {!props.readOnly &&
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  if (window.confirm(`Delete ${picture.title}?`)) {
                    deletePartPicture(picture);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>}
            {props.readOnly &&
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="download" href={`${picture.picture_url}`} target="_blank">
                <DownloadIcon />
              </IconButton>
            </ListItemSecondaryAction>}
          </ListItem>
        ))}
        {(filteredPictures.length === 0) && (
          <ListItem>
            <ListItemText primary="No file found" />
          </ListItem>
        )}
        {files.map((f) => (
          <PartPictureUploadItem
            key={`file-upload-${f.key}`}
            baseUrl={baseUrl}
            file={f.file}
            onCanceled={(file) => {
              console.log('canceled', file)
              setFiles(files.filter(f => f.file !== file));
            }}
            onCompleted={(picture, file) => {
              setFiles(files.filter(f => f.file !== file));

              const newUploadedAttachments = Object.assign([], uploadedPictures);
              newUploadedAttachments.push(picture);
              setUploadedPictures(newUploadedAttachments);

              if (props.onAdded) props.onAdded(picture);
            }}
          />
        ))}
      </List>
      {!props.readOnly &&
      <>
      <Divider />
      <List component="nav" aria-label="operations">
        <FilePicker
          label={(
            <ListItem button>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Add a new file..." />
            </ListItem>
          )}
          accept="image/png, image/gif, image/jpeg, image/webp"
          multiple
          onChange={(selectedFiles) => {
            const newFiles: SelectedFile[] = Object.assign([], files);
            selectedFiles.forEach(file => {
              newFiles.push({
                key: moment().format(),
                file: file
              })
            })
            setFiles(newFiles);
          }}
        />
      </List>
      </>}
    </Paper>
  );
}

function PartPictureUploadItem(props: {
  baseUrl: string;
  file: File;
  onCompleted: (picture: PartPicture, file: File) => void;
  onCanceled?: (file: File) => void;
}) {

  const [selectedFileXHR, setSelectedFileXHR] = useState<XMLHttpRequest|undefined>(undefined);
  const [selectedFileProgress, setSelectedFileProgress] = useState({loaded: 0, total: 0});
  const [error, setError] = useState<APIError|undefined>(undefined);

  const uploadFile = async (f: File) => {
    setError(undefined);
    const api = new BaseAPI();
    const data = new FormData();
    data.append('picture', f);
    data.append('title', f.name);

    try {
      const [result, request] = await api.upload(
        data,
        `${props.baseUrl}upload/`,
        (xhr: XMLHttpRequest) => {
          setSelectedFileXHR(xhr);
        },
        (loaded: number, total: number) => {
          setSelectedFileProgress({
            loaded: loaded,
            total: total,
          })
        });
      return result as PartPicture; 
    } catch (error) {
      console.error(error);
      setError(error as APIError);
    }
    return undefined;
  };

  const createAttachment = async (f: File) => {
    const picture = await uploadFile(f);
    if (picture) props.onCompleted(picture, f);
  }

  useEffect(() => {
    createAttachment(props.file);
  }, []);

  let progressText = '0%';
  let total = 0;
  if (selectedFileProgress.total) {
    total = (selectedFileProgress.loaded / selectedFileProgress.total) * 100;
    progressText = `${total.toFixed(0)}%`;
  }

  if (error) {
    progressText = `Error: ${error.message}`;
  }

  return (
    <ListItem>
      {!error &&
      <ListItemIcon>
        {(total === 0) &&
          <CircularProgress />}
        {(total > 0) &&
        <CircularProgress variant="determinate" value={total} />}
      </ListItemIcon>}
      {!!error &&
      <ListItemIcon>
        <ErrorOutlineIcon htmlColor="red" />
      </ListItemIcon>
      }
      <ListItemText primary={`Uploading ${props.file.name}...`} secondary={progressText} />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="cancel"
          onClick={() => {
            if (selectedFileXHR) selectedFileXHR.abort();
            if (props.onCanceled) props.onCanceled(props.file);
          }}
        >
          <CloseIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}