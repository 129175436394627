import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";


import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Tab,
  Tabs
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton
} from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import localization from '../utils/localizations';
import {
  Header
} from '../views/panels/Header';

import {
  SessionContext
} from '../data/Session';

import {
  BaseAPI,
} from '../data/BaseAPI';

import { useProfile } from '../data/Profile';

import BoatEditorCollection from '../views/collections/BoatEditorCollection';
import BoatEditorDetail from '../views/singles/BoatEditorDetail';
import BoatEditorForm from '../views/forms/BoatEditorForm';

import {
  BoatEditor,
  Part,
} from '../types';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    position: 'relative',
  },
  pageContainer: {
    padding: 10,
  },
  marginBottom: {
    marginBottom: 10
  },
  logoContainer: {
    textAlign: 'center',
    marginTop: 50,
    marginBottom: 50,
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    marginBottom: 10,
    padding: 10
  },
  tabNavigation: {
    marginBottom: 12,
  }
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function BoatDetailPanel(props: {
  onUpdated: (boat: BoatEditor) => void;
  onDeleted: (boat: BoatEditor) => void;
  backUrl: string;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [boat, setBoat] = useState(undefined as BoatEditor|undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { path, url } = useRouteMatch();
  const { boatId } = useParams() as any;
  const backUrl = props.backUrl;

  const loadBoat = async (id: number) => {
    if (!id) return;
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`boats/editor/${id}/`);
      if ((data as any).id) {
        setBoat(data as BoatEditor);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadBoat(boatId);

    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    }, 300);

  }, [boatId]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
        aria-labelledby="selected-order-title"
        aria-describedby="selected-order-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.dialogTitle}>
              {boat?.name}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setNavigateBack(true);
              }, 300);
            }} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{backgroundColor: '#f5f5f5'}}>
            {!!boat &&
            <BoatEditorDetail
              boat={boat}
              onUpdated={boat => {
                setBoat(boat);
                props.onUpdated(boat);
                loadBoat(boat.id);

                setOpen(false);
                setTimeout(() => {
                  setNavigateBack(true);
                }, 300);
              }}
              onDeleted={boat => {
                props.onDeleted(boat);
                setNavigateBack(true);
              }}
            />}
          {!boat &&
            <div>
              <LoadingView />
            </div>
          }
        </DialogContent>
        </>
      </Dialog>

      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}


function LoadingView() {
  return (
    <div style={{position: 'relative', padding: 10}}>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
    </div>
  );
}


export default function BoatsPage() {
  const classes = useStyles();
  const { path, url } = useRouteMatch();
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();

  const [showEditBoatForm, setShowEditBoatForm] = useState(false);
  const [editBoat, setEditBoat] = useState<BoatEditor|null>(null);
  const [editBoatCounter, setEditBoatCounter] = useState(0);

  if (profile.role !== 'admin') return (<></>);

  return (
    <SessionContext.Consumer>
      {({session}) => (
        <>
          <Header
            session={session}
            title="Boats"
            breadcrumbs={[
              {
                title: "Boats",
                link: `${appConfig.homepage}boats/`
              }
            ]}
          />
          <div className={classes.root}>
            <Paper className={classes.toolbar}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  setEditBoat(null);
                  setShowEditBoatForm(true);
                }}
              >New Boat</Button>
            </Paper>

            <Switch>
              <Route exact path={`${path}:boatId/`}>
                <BoatDetailPanel
                  backUrl={`${path}`}
                  onUpdated={(boat) => {
                    setEditBoatCounter(editBoatCounter+1);
                  }}
                  onDeleted={(boat) => {
                    setEditBoatCounter(editBoatCounter+1);
                  }}
                />
              </Route>
            </Switch>

            <BoatEditorCollection
              key={`active-boats-collections-${editBoatCounter}`}
              baseUrl={`${path}`}
              active={true}
            />

            <Dialog
              open={showEditBoatForm}
              onClose={() => {
                setShowEditBoatForm(false);
                setEditBoat(null);
              }}
              fullScreen
              TransitionComponent={Transition}
            >
              <>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Typography variant="h6" className={classes.dialogTitle}>
                    {editBoat ? `Edit Boat` : 'Create New Boat'}
                  </Typography>
                  <IconButton edge="start" color="inherit" onClick={() => {
                    setShowEditBoatForm(false);
                    setEditBoat(null);
                  }} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <BoatEditorForm
                  boat={editBoat}
                  onSave={(boat) => {
                    setShowEditBoatForm(false);
                    setEditBoat(null);
                    setEditBoatCounter(editBoatCounter+1);
                  }}
                  onCancel={() => {
                    setShowEditBoatForm(false);
                    setEditBoat(null);
                  }}
                />
              </DialogContent>
              </>
            </Dialog>
          </div>
        </>
      )}
    </SessionContext.Consumer>
  );
}
