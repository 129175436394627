
import {
  Boat,
  Part,
  PartGroup,
  GroupedPart,
  Dealer,
  PartVariant,
  PartRule,
  DealerDiscount,
} from '../../../../types';

import {
  OrderLine,
  PartOrderLine,
  DiscountItem,
  FormValues
} from '../OrderTypes';


export const isPartRequired = (part: Part, boat: Boat) => {
  let required = false;

  boat.required_parts.forEach((requiredPart) => {
    if (requiredPart.id === part.id) required = true;
  })

  return required;
}

export const getVariants = (part: Part, boat: Boat, orderLines: OrderLine[]) => {
  return part.variants.filter(variant => {
    let boatFound = false;
    variant.boats.forEach((b) => {
      if (b.id == boat.id) boatFound = true;
    })
    if (variant.boats.length > 0) {
      if (boatFound) {
        if (variant.parts.length > 0) {
          let found = false;
          variant.parts.forEach((p) => {
            orderLines.forEach((orderLine) => {
              if (orderLine.id == p.id) {
                found = true;
              }
            });
          });
          if (!found) return false;
        }
      }
      else {
        return false;
      }
    }
    return true;
  });
};


export function removeOrderLine(removedOrderLine: OrderLine, prevOrderLines: OrderLine[], part: Part, groupedPart: GroupedPart, boat: Boat) {
  const orderLines = prevOrderLines.slice();

  const removedParts: OrderLine[] = [];
  orderLines.forEach((orderLine, i) => {
    if (orderLine.id === removedOrderLine.id) {
      removedParts.push(orderLine);
    }
  });

  part.must_includes.forEach(part => {
    orderLines.forEach((orderLine, i) => {
      if (orderLine.id === part.id) {
        removedParts.push(orderLine);
      }
    });
  });

  orderLines.forEach((orderLine, i) => {
    boat.available_parts.forEach(pp => {
      if (orderLine.id === pp.id) {
        pp.must_includes.forEach(mp => {
          if (mp.id === part.id) {
            if (pp.part_type == 'boat') {
              console.log('removal skipped because target is a boat', pp, part)
            }
            else {
              removedParts.push(orderLine);
              console.log('removing', pp, part)
            }
          }
        });
        pp.unselectable_unless.forEach(mp => {
          if (mp.id === part.id) {
            if (pp.part_type == 'boat') {
              console.log('removal skipped because target is a boat', pp, part)
            }
            else {
              removedParts.push(orderLine);
              console.log('removing', pp, part)
            }
          }
        });
      }
    });
  });

  removedParts.forEach(orderLine => {
    orderLines.splice(orderLines.indexOf(orderLine), 1);
  });

  // iterate over added parts that also exists in boat.available_parts
  // make sure their must included and must excluded is satisfied
  // starting with engine, then the rest

  let addedParts: OrderLine[] = [];
  let engineParts: OrderLine[] = [];
  let otherParts: OrderLine[] = [];

  orderLines.forEach((orderLine, i) => {
    if (orderLine.type === 'engine') {
      engineParts.push(orderLine);
    }
    else {
      if (boat.available_parts.find(pp => pp.id === orderLine.id)) {
        addedParts.push(orderLine);
      }
    }
  });

  let primaryParts: OrderLine[] = [...engineParts, ...addedParts];

  primaryParts.forEach((orderLine, i) => {
    let part = boat.available_parts.find(pp => pp.id === orderLine.id);
    if (!part) return;
    part.must_includes.forEach(part => {
      if (part.id === removedOrderLine.id) return;
      let partQuantity = 1;
      let partPrice = part.price;
      part.must_includes_options.forEach(option => {
        if (option.part === part.id) {
          console.log(option, part);
          partQuantity = option.minimum_quantity;
          if (option.price !== 'None') {
            partPrice = option.price;
          }
        }
      });
      otherParts.push({
        id: part.id,
        part_number: part.part_number,
        description: part.description,
        type: part.part_type,
        quantity: partQuantity,
        variant: part.variants.length > 0 ? part.variants[0] : undefined,
        note: '',
        price: partPrice,
      });
    });
  });

  primaryParts.forEach((orderLine, i) => {
    let part = boat.available_parts.find(pp => pp.id === orderLine.id);
    if (!part) return;
    part.must_excludes.forEach(part => {
      if (part.id === removedOrderLine.id) return;
      if (otherParts.find(op => op.id === part.id)) {
        otherParts.splice(otherParts.findIndex(op => op.id === part.id), 1);
      }
    });
  });

  otherParts.forEach((orderLine, i) => {
    if (orderLines.find(ol => ol.id === orderLine.id)) return;
    orderLines.push(orderLine);
  });

  return orderLines;
}


export function addOrderLine(orderLine: OrderLine, prevOrderLines: OrderLine[], part: Part, groupedPart: GroupedPart, boat: Boat) {
  
  let orderLines = prevOrderLines.slice().filter(o => o.id !== orderLine.id);
    if (groupedPart.group?.group_behavior === 'exclusive') {
      // exclusive parts: remove other parts from the same category
      prevOrderLines.forEach(ol => {
        groupedPart.parts.forEach(p => {
          if (ol.id === p.id) {
            orderLines = removeOrderLine(ol, orderLines, p, groupedPart, boat);
          }
        });
      });
    }
    else {
      if (part.override_group_behavior === 'exclusive') {
        prevOrderLines.forEach(ol => {
          groupedPart.parts.forEach(p => {
            if ((ol.id === p.id) && p.override_group_behavior === 'exclusive') {
              orderLines = removeOrderLine(ol, orderLines, p, groupedPart, boat);
            }
          });
        });
      }
    }

    const newItems: OrderLine[] = [];

    part.must_includes.forEach(extraPart => {
      let extraPartQuantity = 1;
      let extraPartPrice = extraPart.price;
      part.must_includes_options.forEach(option => {
        if (option.part === extraPart.id) {
          console.log(option, extraPart);
          extraPartQuantity = option.minimum_quantity;
          if (option.price !== 'None') {
            extraPartPrice = option.price;
          }
        }
      });
      if (orderLines.filter(o => o.id === extraPart.id).length == 0) {
        newItems.push({
          id: extraPart.id,
          part_number: extraPart.part_number,
          description: extraPart.description,
          type: extraPart.part_type,
          quantity: extraPartQuantity,
          variant: extraPart.variants.length > 0 ? extraPart.variants[0] : undefined,
          note: '',
          price: extraPartPrice,
        });
      }
    });
    newItems.push(orderLine);

    const shouldBeOnFront = part.part_type === 'boat';

    newItems.forEach(orderLine => {
      if (shouldBeOnFront) {
        orderLines.unshift(orderLine);
      }
      else {
        orderLines.push(orderLine);
      }
    });

    part.must_excludes.forEach(part => {
      orderLines.forEach((orderLine, i) => {
        if (orderLine.id === part.id) {
          orderLines = removeOrderLine(orderLine, orderLines, part, groupedPart, boat);
        }
      });
    });
    orderLines.forEach((orderLine, i) => {
      boat.available_parts.forEach(p => {
        if (p.id === orderLine.id) {
          p.must_excludes.forEach(pp => {
            if (part.id === pp.id) {
              orderLines = removeOrderLine(orderLine, orderLines, p, groupedPart, boat);
            }
            orderLines.forEach((oo) => {
              if (oo.id === pp.id) {
                orderLines = removeOrderLine(oo, orderLines, pp, groupedPart, boat);
              }
            });
          });
        }
      })
    });
  
    return orderLines;
}


export function toggleOrderLine(orderLine: OrderLine, orderLines: OrderLine[], part: Part, groupedPart: GroupedPart, boat: Boat) {
  if (orderLines.filter(ol => ol.id === orderLine.id).length > 0) {
    if (isPartRequired(part, boat)) return orderLines;
    // return removeOrderLine(orderLine, orderLines, part, groupedPart, boat);

    // if variant update, do not remove and re-add. instead, just update existing entry
    let prevOrderLine = orderLines.filter(ol => ol.id === orderLine.id)[0];
    if (orderLine.variant?.id && (orderLine.variant.id !== prevOrderLine.variant?.id)) {
      return orderLines.map(ol => {
        if (ol.id === orderLine.id) return orderLine;
        return ol;
      })
    }
    else {
      return removeOrderLine(orderLine, orderLines, part, groupedPart, boat);
    }
  }
  
  let newOrderLines = addOrderLine(orderLine, orderLines, part, groupedPart, boat);
  if (orderLines.filter(ol => ol.id === orderLine.id).length === 0) {
    newOrderLines = addOrderLine(orderLine, newOrderLines, part, groupedPart, boat);
  }
  return newOrderLines;
}


export function orderLinesDiff(newOrderLines: OrderLine[], oldOrderLines: OrderLine[], boat: Boat) {
  const results = {
    added: [] as OrderLine[],
    removed: [] as OrderLine[]
  }

  const newOrderLineIds = newOrderLines.map(orderLine => orderLine.id)
  const oldOrderLineIds = oldOrderLines.map(orderLine => orderLine.id)
  const availablePartIds = boat.available_parts.map(part => part.id)
  const hiddenIds = boat.hidden_parts.map(part => part.id)

  results.added = newOrderLines
    .filter(orderLine => !oldOrderLineIds.includes(orderLine.id))
    .filter(orderLine => !hiddenIds.includes(orderLine.id))
    .filter(orderLine => availablePartIds.includes(orderLine.id));
  results.removed = oldOrderLines
    .filter(orderLine => !newOrderLineIds.includes(orderLine.id))
    .filter(orderLine => !hiddenIds.includes(orderLine.id))
    .filter(orderLine => availablePartIds.includes(orderLine.id));

  return results;
}


export function replaceOrderLine(orderLine: OrderLine, orderLines: OrderLine[], part: Part, groupedPart: GroupedPart, boat: Boat) {
  if (orderLines.filter(ol => ol.id === orderLine.id).length > 0) {
    const newOrderLines = orderLines.filter(oo => oo.id != orderLine.id);
    newOrderLines.push(orderLine);
    return newOrderLines;
  }
  
  return addOrderLine(orderLine, orderLines, part, groupedPart, boat);
}

export function getPartFromOrderLine(orderLine: OrderLine, boat: Boat) {
  let part: Part|undefined = undefined;

  const parts = boat.available_parts.filter(p => orderLine.id === p.id);
  if (parts.length > 0) part = parts[0];

  return part;
}


export const canSelectPart = (part: Part, variant: PartVariant|null, orderLines: OrderLine[], boat: Boat) : {selectable: boolean; errorMessage: string} => {

  let result = {
    selectable: true,
    errorMessage: ""
  }

  // engine selection must be prioritized
  const engines = boat.available_parts
                      .filter(part => orderLines.map(orderLine => orderLine.id).includes(part.id))
                      .filter(part => part.active)
                      .filter(part => !part.admin_only)
                      .filter(part => part.groups.map(group => group.part_type).includes('engine'))
  engines.forEach(engine => {
    engine.must_excludes
      .filter(p => p.id === part.id)
      .forEach(p => {
        result = {
          selectable: false,
          errorMessage: `${p.description} is not compatible with ${engine.description}. Please select another engine if you want to select this option.`
        }
      })
  })

  part.must_excludes
    .filter(part => orderLines.map(orderLine => orderLine.id).includes(part.id))
    .filter(part => part.active)
    .filter(part => !part.admin_only)
    .filter(part => part.groups.map(group => group.part_type).includes('engine'))
    .forEach(engine => {
      result = {
        selectable: false,
        errorMessage: `${part.description} is not compatible with ${engine.description}. Please select another engine if you want to select this option.`
      }
    })
  return result;
}
