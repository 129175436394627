import React, {
  useState,
  useEffect
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Paper,
  CircularProgress,
  Typography,
  Collapse,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DownloadIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EditIcon from '@material-ui/icons/Edit';

import CustomerCommentForm from '../forms/CustomerCommentForm';

import {
  CustomerComment
} from '../../types';
import { APIError, BaseAPI } from '../../data/BaseAPI';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

function CustomerCommentItem(props: {
  comment: CustomerComment;
  readOnly?: boolean;
  onSave?: (comment: CustomerComment) => void;
  onDelete?: () => void;
}) {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);

  if (edit) {
    return (
      <>
        <CustomerCommentForm
          key={`comment-form-${props.comment.id}`}
          comment={props.comment}
          onSave={(comment) => {
            setEdit(false);
            if (props.onSave) props.onSave(comment);
          }}
          onCancel={() => setEdit(false)}
          onDelete={props.onDelete}
        />
        <Divider />
      </>
    );
  }

  const attachments = props.comment.attachments.map((attachment) => (
    <Chip
      key={`attachment-${attachment.id}`}
      icon={<DownloadIcon />}
      variant="outlined"
      label={`${attachment.name} (${attachment.size_human})`}
      onClick={() => window.open(`${attachment.url}download/`, '_blank')}
      style={{marginRight: 6, marginTop: 6}}
    />
  ));

  return (
    <ListItem alignItems="flex-start">
      {!!props.comment.created_by &&
      <ListItemAvatar>
        <Avatar alt={props.comment.created_by?.first_name} src={props.comment.created_by?.avatar} />
      </ListItemAvatar>}
      <ListItemText
        primary={
          <>
            <span>{props.comment.created_by?.first_name} {props.comment.created_by?.last_name} — </span>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textSecondary"
            >
              {moment(props.comment.created_at).format('MMMM Do YYYY hh:mm:ss')}
            </Typography>
            {(!props.readOnly && !props.comment.show_to_dealer) &&
            <Chip size="small" variant="outlined" style={{marginLeft: 12}} label="hidden" />
            }
          </>}
        secondary={
          <>
            <div style={{whiteSpace: 'pre-line'}}>{props.comment.comments}</div>
            <div>{attachments}</div>
          </>
        }
      />
      {!props.readOnly &&
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="edit" onClick={() => setEdit(true)}>
          <EditIcon />
        </IconButton>
      </ListItemSecondaryAction>}
    </ListItem>
  );
}

export default function CustomerComments(props: {
  title?: string;
  customerId: string;
  readOnly?: boolean;
  baseUrl?: string;
  onAdded?: (attachment: CustomerComment) => void;
  onRemoved?: (attachment: CustomerComment) => void;
  style?: React.CSSProperties | undefined;
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [showNewCommentForm, setShowNewCommentForm] = useState(false);
  const [newComment, setNewComment] = useState<CustomerComment|undefined>(undefined);
  const [comments, setComments] = useState<CustomerComment[]>([]);

  const loadComments = async (customerId: string) => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    let url = 'warranty-customer-comments/';

    let kwargs: any = {
      customer: customerId,
    };
    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data instanceof Array) {
        setComments(data as CustomerComment[]);

        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadComments(props.customerId);
  }, [props.customerId]);

  const title = props.title ? props.title : 'Comments';

  if (props.readOnly && comments.length === 0) return <></>;

  return (
    <Paper className={classes.root} style={props.style}>
      <List
        dense
        component="nav"
        aria-label={title}
        subheader={title ? <ListSubheader>{title}</ListSubheader> : <></>} 
      >
        {comments.map((comment) => {
          return (
            <CustomerCommentItem
              key={`comment-${comment.id}`}
              comment={comment}
              readOnly={props.readOnly}
              onSave={(comment) => {
                loadComments(props.customerId);
              }}
              onDelete={() => {
                loadComments(props.customerId);
              }}
            />
          );
        })}
        {(!isLoading && (comments.length === 0)) &&
        <ListItem>
          <ListItemText primary="No comments found" />
        </ListItem>}
      </List>
      {!props.readOnly && <Divider />}
      {!props.readOnly && 
      <Collapse in={showNewCommentForm}>
        <CustomerCommentForm
          key={`comment-form-${newComment?.local_id}`}
          comment={newComment}
          onSave={(comment) => {
            setShowNewCommentForm(false);
            loadComments(props.customerId);
          }}
          onCancel={() => setShowNewCommentForm(false)}
        />
      </Collapse>}
      {!props.readOnly && 
      <Collapse in={!showNewCommentForm}>
        <List component="nav" aria-label="operations">
          <ListItem button>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Add a new comment..." onClick={() => {
              setNewComment({
                      local_id: moment().format(),
                      id: "",
                      customer_id: props.customerId,
                      comments: "",
                      show_to_dealer: false,
                      attachments: [],
                      created_at: moment().format(),
                      updated_at: moment().format(),
                    })
              setShowNewCommentForm(true);
            }} />
          </ListItem>
        </List>
      </Collapse>}
    </Paper>
  );
}
