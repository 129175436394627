import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  LinearProgress,
  ListSubheader,
  ButtonGroup,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  CircularProgress,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Toolbar,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton,
  Alert
} from '@material-ui/lab';
import PreviousIcon from '@material-ui/icons/ArrowBack';
import NextIcon from '@material-ui/icons/ArrowForward';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import { useProfile } from '../../data/Profile';
import {
  Dealer,
  DealerLocation
} from '../../types';
import SortButton from './components/SortButton';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  container: {
    paddingBottom: 10,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  tableNav: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paginationControl: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


interface FindDealerFormValues {
  ordering?: string|undefined;
  active?: string|undefined;
  search: string;
}

export default function DealerLocatorCollection(props: {className?: string}) {
  const classes = useStyles();

  const [filter, setFilter] = useState<FindDealerFormValues|undefined>({search: '', active: '1'});
  const [pageSize, setPageSize] = useState(100);
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [isLoading, setIsLoading] = useState(false);
  const [sortKey, setSortKey] = useState<string|undefined>(undefined);
  const [dealerLocations, setDealerLocations] = useState([] as DealerLocation[]);
  const [dealerLocationsMeta, setDealerLocationsMeta] = useState({
    count: 0,
    page_size: 10,
    num_pages: 0,
    page: 0,
    next: 0
  });

  const { path, url } = useRouteMatch();
  const backUrl = `${url}../`;

  const loadDealerLocations = async (page?: number, filter?: FindDealerFormValues) => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    let url = `admin-dealer-locations/`;
    let kwargs: any = {
      page_size: pageSize,
    };
    if (page) kwargs.page = page;
    if (filter?.search) kwargs.search = filter.search;
    if (filter?.active) kwargs.active = filter.active;
    if (filter?.ordering) kwargs.ordering = filter.ordering;
    else if (sortKey) kwargs.ordering = sortKey;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        if (page && (page > dealerLocationsMeta.page)) {
          const results = data.results as DealerLocation[];
          setDealerLocations(results);
        }
        else {
          setDealerLocations(data.results as DealerLocation[]);
        }

        setIsLoading(false);
        setDealerLocationsMeta({
          count: data.count,
          page_size: data.page_size,
          num_pages: data.num_pages,
          page: data.page,
          next: data.next
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const onSubmit = async (values: FindDealerFormValues) => {
    setIsLoading(true);
    setFilter({
      search: values.search,
    })
    const api = new BaseAPI();
    await loadDealerLocations(undefined, values);
    setIsLoading(false);
  }

  const onValidate = (values: FindDealerFormValues) => {
    const errors: any = {}

    return errors;
  }

  useEffect(() => {
    loadDealerLocations(undefined, filter);
  }, [])

  useEffect(() => {
    loadDealerLocations(undefined, Object.assign({ordering: sortKey}, filter));
  }, [sortKey, pageSize]);

  let paginations = Array.from({length: dealerLocationsMeta.num_pages}, (_, i) => i + 1);
  if (paginations.length > 10) {
    const starts = paginations.slice(0, 3);
    const ends = paginations.slice(paginations.length - 4, paginations.length - 1);
    let middles: number[] = [];
    if (starts.length && ends.length && dealerLocationsMeta.page > 1) {
      if (!starts.includes(dealerLocationsMeta.page - 1) && !ends.includes(dealerLocationsMeta.page - 1)) middles.push(dealerLocationsMeta.page - 1);
      if (!starts.includes(dealerLocationsMeta.page) && !ends.includes(dealerLocationsMeta.page)) middles.push(dealerLocationsMeta.page);
      if (!starts.includes(dealerLocationsMeta.page + 1) && !ends.includes(dealerLocationsMeta.page + 1)) middles.push(dealerLocationsMeta.page + 1);
    }
    const newPaginations = [...starts, ...middles, ...ends];
    paginations = newPaginations;
  }
  return (
    <div className={classes.root}>
      <Paper style={{paddingTop: 12,}}>
        <Form
          onSubmit={onSubmit}
          validate={onValidate}
          initialValues={{
            search: '',
            active: '1'
          }}
          render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
            <form onSubmit={handleSubmit} className={classes.root} style={{margin: 12, paddingBottom: 12}}>
              <Grid container>
                <Grid container spacing={3}>
                  <Field name="search">
                    {props => (
                      <Grid item xs={12}>
                        <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                          <TextField
                            id={props.input.name}
                            label={'Search'}
                            placeholder="Enter keywords to search..."
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                            helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={9}>
                    <Field name="active">
                      {props => (
                          <FormControl component="fieldset" error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                            <RadioGroup
                              id={props.input.name}
                              aria-label="Listing"
                              name={props.input.name}
                              value={props.input.value}
                              onChange={(event) => {
                                props.input.onChange(event);
                              }}
                              style={{flexDirection: 'row'}}
                            >
                              <FormControlLabel value="" control={<Radio />} label="All" />
                              <FormControlLabel value="1" control={<Radio />} label="Active" />
                              <FormControlLabel value="0" control={<Radio />} label="Inactive" />
                            </RadioGroup>
                          </FormControl>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3} style={{textAlign: 'right'}}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="small"
                      disabled={isLoading}
                    >Find</Button>
                    <Button
                      variant="contained"
                      disabled={isLoading}
                      style={{marginLeft: 12}}
                      size="small"
                      onClick={() => {
                        setFilter(undefined);
                        loadDealerLocations(undefined, undefined);
                        form.change('search', '')
                      }}
                    >Clear</Button>
                    {isLoading && <CircularProgress size={20} style={{marginLeft: 10}} />}
                  </Grid>
                </Grid>

                {(!pristine && (Object.keys(errors as any).length > 0)) &&
                <Grid item xs={12}>
                  <div style={{marginTop: 10, marginBottom: 10}}>
                    <Alert severity="error">Please make sure all required fields are correctly filled!</Alert>
                  </div>
                </Grid>}
              </Grid>

            </form>
          )}
        />
      </Paper>
      <TableContainer component={Paper} className={classes.container}>
        <Table size="small">
          <TableHead>
            {isLoading && 
            <TableRow>
              <TableCell colSpan={4} style={{padding: 0}}>
                <LinearProgress id="users-list-loading" style={{width: '100%'}} />
              </TableCell>
            </TableRow>}
            <TableRow>
              <TableCell>
                <SortButton
                  sortKey='title'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                >Title</SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey='address_line_1'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >Address</SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey='city'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >City</SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey='state'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >State</SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey='zip'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >Zip</SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey='country'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >Country</SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey='active'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >Active</SortButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dealerLocations.map((dealer, i) => {
              return (
                <DealerLocationRow key={`user-item-${dealer.id}`} dealerLocation={dealer} />
              );
            })}
            {(dealerLocations.length === 0) &&
              <TableRow>
                <TableCell colSpan={4}>{isLoading ? 'Loading...' : 'No data yet.'}</TableCell>
              </TableRow>
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={1}>
                {isLoading && <CircularProgress size={20} />}
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={8}
                count={dealerLocationsMeta.count}
                rowsPerPage={dealerLocationsMeta.page_size}
                page={dealerLocationsMeta.page-1}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={(event: any, newPage: number) => {
                  if (!isLoading) loadDealerLocations(newPage + 1, filter);
                }}
                onChangeRowsPerPage={(event: any) => {
                  if (!isLoading) setPageSize(parseInt(event.target.value, 10));
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

function DealerLocationRow(props: {
  dealerLocation: DealerLocation;
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null|HTMLButtonElement>(null);
  const [showCreateClaimForm, setShowCreateClaimForm] = useState(false);
  const [redirect, setRedirect] = useState('');
  const dealerLocation = props.dealerLocation;
  return (
    <TableRow>
      <TableCell>
        <Button component={RouterLink} to={`${appConfig.homepage}dealers/locator/${dealerLocation.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>{dealerLocation.title}</Button>
      </TableCell>
      <TableCell align="right">
        <Button component={RouterLink} to={`${appConfig.homepage}dealers/locator/${dealerLocation.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>{dealerLocation.address_line_1} {dealerLocation.address_line_2}</Button>
      </TableCell>
      <TableCell align="right">
        <Button component={RouterLink} to={`${appConfig.homepage}dealers/locator/${dealerLocation.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>{dealerLocation.city}</Button>
      </TableCell>
      <TableCell align="right">
        <Button component={RouterLink} to={`${appConfig.homepage}dealers/locator/${dealerLocation.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>{dealerLocation.state}</Button>
      </TableCell>
      <TableCell align="right">
        <Button component={RouterLink} to={`${appConfig.homepage}dealers/locator/${dealerLocation.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>{dealerLocation.zip}</Button>
      </TableCell>
      <TableCell align="right">
        <Button component={RouterLink} to={`${appConfig.homepage}dealers/locator/${dealerLocation.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>{dealerLocation.country}</Button>
      </TableCell>
      <TableCell align="right">
        <Button component={RouterLink} to={`${appConfig.homepage}dealers/locator/${dealerLocation.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>{dealerLocation.active ? <CheckIcon color="primary" /> : <CloseIcon color="secondary" />}</Button>
      </TableCell>
      
    </TableRow>
  );
}


function TablePaginationActions(props: {
  count: number;
  onChangePage?: (event: any, newPage: number) => void;
  page: number;
  rowsPerPage: number;
}) {
  const classes = useStyles();
  const { count, page, rowsPerPage } = props;

  const onChangePage = (event: any, newPage: number) => {
    if (props.onChangePage) props.onChangePage(event, newPage);
  }

  let numPages = Math.ceil(props.count / props.rowsPerPage);
  let paginations = Array.from({length: numPages}, (_, i) => i + 1);
  if (paginations.length > 10) {
    let page = props.page + 1;
    const starts = paginations.slice(0, 3);
    const ends = paginations.slice(paginations.length - 3, paginations.length );
    let middles: number[] = [];
    if (starts.length && ends.length && page > 1) {
      if (!starts.includes(page - 1) && !ends.includes(page - 1)) middles.push(page - 1);
      if (!starts.includes(page) && !ends.includes(page)) middles.push(page);
      if (((page + 1) < numPages) && !starts.includes(page + 1) && !ends.includes(page + 1)) middles.push(page + 1);
    }
    const newPaginations = [...starts, ...middles, ...ends];
    paginations = newPaginations;
  }

  return (
    <div className={classes.paginationControl}>
      <IconButton
        onClick={(event) => {
          onChangePage(event, 0);
        }}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={(event) => {
          onChangePage(event, page - 1);
        }}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {paginations.map(i => (
        <Button
          key={`pagination-button-${i}`}
          disabled={(i - 1) === (props.page)}
          onClick={() => {
            onChangePage(undefined, i - 1);
          }}
        >{i}</Button>
      ))}
      <IconButton
        onClick={(event) => {
          onChangePage(event, page + 1);
        }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={(event) => {
          onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}
