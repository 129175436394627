import React, {
  useState,
  useEffect,
  createContext,
} from 'react';
import * as Sentry from '@sentry/browser';

import {
  BaseAPI,
  APIError,
} from './BaseAPI';
import {
  Session
} from '../types';

import {
  getSession,
  setSession,
} from './SessionUtils';

class SessionAPI extends BaseAPI {
  path = 'session/'
}

let sessionAPI: SessionAPI;
function getAPI() {
  if (!sessionAPI) sessionAPI = new SessionAPI();
  return sessionAPI;
}

function useSession(path=''): [Session, boolean, (() => Promise<void>), ((data: any) => Promise<(boolean | Session)[] | (boolean | undefined)[]>)] {
  const [session, setCurrentSession] = useState(getSession());
  const [loading, setLoading] = useState(true);

  const loadSession = async () => {
    if (!(window as any)._cache) {
      (window as any)._cache = {};
    }

    if ((window as any)._cache.session) {
      setCurrentSession(setSession((window as any)._cache.session));
    }

    setLoading(true);
    const sessionAPI = getAPI();

    try {
      const s = (await sessionAPI.get(path)) as Session;

      setCurrentSession(setSession(s));

      (window as any)._cache.session = s;
      (window as any).csrftoken = s.csrftoken;
      if (s.user) {
        Sentry.configureScope(function(scope) {
          if (s.user) {
            console.log('setting up user data', s.user)
            scope.setUser({
              id: s.user.id,
              email: s.user.email,
              first_name: s.user.first_name,
              last_name: s.user.last_name,
            });
          }
        });
      }
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  }

  const updateSession = async (data: any) => {
    let success = false;
    setLoading(true);
    const sessionAPI = getAPI();
    try {
      const [result, response, error] = await sessionAPI.post(data, path);
      if (result && result.success) {
        const data = await sessionAPI.get(path);
        if ((data as any).success) {
          const s = data as Session;
          setCurrentSession(setSession(s));
          success = true;
          setLoading(false);
          return [success, s];
        }
      }
    } catch (error) {
      console.error(error);
    }
    
    
    setLoading(false);
    return [false, undefined];
  };

  useEffect(() => {
    loadSession();
  }, []);

  return [
    session,
    loading,
    loadSession,
    updateSession,
  ];
}


const SessionContext = createContext({
  session: undefined as Session|undefined,
});


export {
  getAPI,
  useSession,
  SessionContext,
};
