import LocalizedStrings from 'react-localization';

let localization = new LocalizedStrings({
  "en-us": {
    about: "About",
    accepted: "Accepted",
    active: "Active",
    admins: "Admins",
    adminsList: "Admins List",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    appLoginTitle: "Dealer Portal",
    appSubTitle: "Online Ordering System",
    appTitle: "Sea Fox Boats",
    back: "Back",
    backToHome: "Back to home",
    boats: "Boats",
    calendar: "Calendar",
    cancel: "Cancel",
    canceled: "Canceled",
    changePassword: "Change Password",
    city: "City",
    close: "Close",
    closed: "Closed",
    comment: "Comment",
    comments: "Comments",
    completed: "Completed",
    countdown: "Countdown",
    createNewOrder: "New Order",
    currentPassword: "Current Password",
    customerName: "Customer Name",
    customerNumber: "Customer Number",
    date: "Date",
    days: "days",
    daysLater: "days later",
    dealers: "Dealers",
    doYouWantToRemoveThisFile: "Do you want to remove this file?",
    documentUploadHistory: "Document Upload History",
    documents: "Documents",
    downloads: "Downloads",
    editProfile: "Edit Profile",
    email: "Email",
    file: "File",
    files: "Files",
    fillIfExist: "Fill if exist",
    forgotPassword: "Forgot Password?",
    fullName: "Full Name",
    hideOlderHistory: "Hide Older History",
    home: "Home",
    importantInformation: "Important Information",
    invitation: "Invitation",
    issueReportPanelMessage: "This application is currently under active development. Please report any issue or bug directly to the development team.",
    lastUpdated: "Last Updated",
    links: "Links",
    loading: "Loading",
    location: "Location",
    login2faMessage: "For your account security, please enter a one-time verification code. The verification code has been sent to your email address.",
    login: "Login",
    month: "Month",
    newPassword: "New Password",
    newPasswordRepeat: "New Password (repeat)",
    note: "Note",
    numberShort: "No.",
    other: "Other",
    remove: "Remove",
    removeEntryPrompt: "Remove this entry?",
    removeFilePrompt: "Remove This File?",
    required: "This field is required.",
    save: "Save",
    schedule: "Schedule",
    scheduled: "Sceduled",
    state: "State",
    status: "Status",
    submit: "Submit",
    submitted: "Submitted",
    submittedOrders: "Submitted Orders",
    test: "Language Test",
    title: "Title",
    type: "Type",
    users: "Users",
    userManagement: "User Management",
    verificationCode: "Verification Code",
    warranty: "Warranty",
    zip: "ZIP",
  }
});

export default localization;
