import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";



import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Tab,
  Tabs
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton
} from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import localization from '../utils/localizations';
import {
  Header
} from '../views/panels/Header';

import {
  SessionContext
} from '../data/Session';

import {
  BaseAPI,
} from '../data/BaseAPI';

import { useProfile } from '../data/Profile';

import {
  Dealer,
  DealerLocation,
} from '../types';

import DealerCollection from '../views/collections/DealerCollection';
import DealerDetail from '../views/singles/DealerDetail';
import DealerForm from '../views/forms/DealerForm';
import DealerLocationDetail from '../views/singles/DealerLocationDetail';
import DealerLocationForm from '../views/forms/DealerLocationForm';
import DealerLocatorCollection from '../views/collections/DealerLocatorCollection';
import DealerTerritoryExplorer from '../views/reports/DealerTerritoryExplorer';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    position: 'relative',
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    marginBottom: 10,
    padding: 10
  },
  tabNavigation: {
    marginBottom: 12,
  }
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function LoadingView() {
  return (
    <div style={{position: 'relative', padding: 10}}>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
    </div>
  );
}


export default function DealersPage() {
  const classes = useStyles();
  const { path, url } = useRouteMatch();
  const matchDealers = useRouteMatch(`${appConfig.homepage}dealers/list/`);
  const matchDealerLocator = useRouteMatch(`${appConfig.homepage}dealers/locator/`);
  const matchDealerTerritory = useRouteMatch(`${appConfig.homepage}dealers/territory/`);
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();

  const [showEditDealerForm, setShowEditDealerForm] = useState(false);
  const [editDealer, setEditDealer] = useState<Dealer|null>(null);
  const [editDealerCounter, setEditDealerCounter] = useState(0);

  const [showEditDealerLocationForm, setShowEditDealerLocationForm] = useState(false);
  const [editDealerLocation, setEditDealerLocation] = useState<DealerLocation|null>(null);
  const [editDealerLocationCounter, setEditDealerLocationCounter] = useState(0);

  let currentTabPosition = 0;
  if (matchDealers) currentTabPosition = 0;
  if (matchDealerLocator && (profile.role === 'admin')) currentTabPosition = 1;
  if (matchDealerTerritory && (profile.role === 'admin')) currentTabPosition = 2;

  return (
    <SessionContext.Consumer>
      {({session}) => (
        <>
          <Header
            session={session}
            title="Dealers"
            breadcrumbs={[
              {
                title: "Dealers",
                link: `${appConfig.homepage}dealers/list/`
              }
            ]}
          />
          <div className={classes.root}>
            <AppBar position="static" color="default" className={classes.tabNavigation}>
              <Tabs value={currentTabPosition} aria-label="">
                <Tab label="Dealers" to={`${appConfig.homepage}dealers/list/`} component={RouterLink} />
                {(profile.role === 'admin') &&
                <Tab label="Dealer Locator" to={`${appConfig.homepage}dealers/locator/`} component={RouterLink} />}
                {(profile.role === 'admin') &&
                <Tab label="Dealer Territory" to={`${appConfig.homepage}dealers/territory/`} component={RouterLink} />}
              </Tabs>
            </AppBar>

            <Switch>
              <Route path={`${path}list/`}>
                <Paper className={classes.toolbar}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setEditDealer(null);
                      setShowEditDealerForm(true);
                    }}
                  >New Dealer</Button>
                </Paper>

                <DealerCollection key={`dealer-collections-${editDealerCounter}`} />

                <Route exact path={`${path}list/:dealerId/`}>
                  <DealerDetailPanel
                    onUpdated={(dealer) => {
                      setEditDealerCounter(editDealerCounter+1);
                    }}
                    onDeleted={(dealer) => {
                      setEditDealerCounter(editDealerCounter+1);
                    }}
                  />
                </Route>
              </Route>
              <Route path={`${path}locator/`}>
                <Paper className={classes.toolbar}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setEditDealerLocation(null);
                      setShowEditDealerLocationForm(true);
                    }}
                  >New Dealer Location</Button>
                </Paper>

                <DealerLocatorCollection key={`dealer-locator-collections-${editDealerLocationCounter}`} />

                <Route exact path={`${path}locator/:dealerId/`}>
                  <DealerLocationDetailPanel
                    onUpdated={(dealerLocation) => {
                      setEditDealerLocationCounter(editDealerLocationCounter+1);
                    }}
                    onDeleted={(dealerLocation) => {
                      setEditDealerLocationCounter(editDealerLocationCounter+1);
                    }}
                  />
                </Route>
              </Route>
              <Route path={`${path}territory/`}>
                <Paper className={classes.toolbar}>
                  <Typography variant="h5">Dealer Territory</Typography>
                </Paper>
                <DealerTerritoryExplorer />
              </Route>
            </Switch>

            <Dialog
              open={showEditDealerForm}
              onClose={() => {
                setShowEditDealerForm(false);
                setEditDealer(null);
              }}
              fullScreen
              TransitionComponent={Transition}
            >
              <>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Typography variant="h6" className={classes.dialogTitle}>
                    {editDealer ? `Edit ${editDealer.customer_name}` : 'Create New Dealer'}
                  </Typography>
                  <IconButton edge="start" color="inherit" onClick={() => {
                    setShowEditDealerForm(false);
                    setEditDealer(null);
                  }} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <DealerForm
                  dealer={editDealer}
                  onSave={(dealer) => {
                    setShowEditDealerForm(false);
                    setEditDealer(null);
                    setEditDealerCounter(editDealerCounter+1);
                  }}
                  onSaveRedirect={(dealer) => `/dealers/list/${dealer.id}/`}
                  onCancel={() => {
                    setShowEditDealerForm(false);
                    setEditDealer(null);
                  }}
                />
              </DialogContent>
              </>
            </Dialog>

            <Dialog
              open={showEditDealerLocationForm}
              onClose={() => {
                setShowEditDealerLocationForm(false);
                setEditDealerLocation(null);
              }}
              fullScreen
              TransitionComponent={Transition}
            >
              <>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Typography variant="h6" className={classes.dialogTitle}>
                    {editDealerLocation ? `Edit ${editDealerLocation.title}` : 'Create New Dealer Location'}
                  </Typography>
                  <IconButton edge="start" color="inherit" onClick={() => {
                    setShowEditDealerLocationForm(false);
                    setEditDealerLocation(null);
                  }} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <DealerLocationForm
                  dealerLocation={editDealerLocation}
                  onSave={(dealer) => {
                    setShowEditDealerLocationForm(false);
                    setEditDealerLocation(null);
                    setEditDealerLocationCounter(editDealerLocationCounter+1);
                  }}
                  onSaveRedirect={(dealer) => `/dealers/locator/${dealer.id}/`}
                  onCancel={() => {
                    setShowEditDealerLocationForm(false);
                    setEditDealerLocation(null);
                  }}
                />
              </DialogContent>
              </>
            </Dialog>

          </div>
        </>
      )}
    </SessionContext.Consumer>
  );
}


function DealerDetailPanel(props: {
  onUpdated: (dealer: Dealer) => void;
  onDeleted: (dealer: Dealer) => void;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [dealer, setDealer] = useState(undefined as Dealer|undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [backUrl, setBackUrl] = useState('/dealers/list/');

  const { path, url } = useRouteMatch();
  const { dealerId } = useParams() as any;
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('back')) setBackUrl(urlParams.get('back') as string);
  }, []);

  const loadDealer = async (id: string) => {
    if (!id) return;
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`all-dealers-paginated/${id}/`);
      if ((data as any).id) {
        setDealer(data as Dealer);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadDealer(dealerId);

    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    }, 300);

  }, [dealerId]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
        aria-labelledby="selected-dealer-title"
        aria-describedby="selected-dealer-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.dialogTitle}>
              {dealer?.customer_name}
            </Typography>
            <Typography style={{marginRight: 20}}>
              {dealer ? (dealer.active ? 'Active Dealer' : 'Inactive Dealer') : ''}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setNavigateBack(true);
              }, 300);
            }} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{backgroundColor: '#f5f5f5'}}>
          {!!dealer &&
            <DealerDetail
              dealer={dealer}
              onUpdated={dealer => {
                setDealer(dealer);
                props.onUpdated(dealer);
                loadDealer(dealer.id);
              }}
              onDeleted={dealer => {
                props.onDeleted(dealer);
                setNavigateBack(true);
              }}
            />}
          {!dealer &&
            <div>
              <LoadingView />
            </div>
          }
        </DialogContent>
        </>
      </Dialog>

      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}


function DealerLocationDetailPanel(props: {
  onUpdated: (dealerLocation: DealerLocation) => void;
  onDeleted: (dealerLocation: DealerLocation) => void;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [dealerLocation, setDealerLocation] = useState(undefined as DealerLocation|undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [backUrl, setBackUrl] = useState('/dealers/locator/');

  const { path, url } = useRouteMatch();
  const { dealerId } = useParams() as any;
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('back')) setBackUrl(urlParams.get('back') as string);
  }, []);

  const loadDealerLocation = async (id: string) => {
    if (!id) return;
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`admin-dealer-locations/${id}/`);
      if ((data as any).id) {
        setDealerLocation(data as DealerLocation);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadDealerLocation(dealerId);

    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    }, 300);

  }, [dealerId]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
        aria-labelledby="selected-dealer-location-title"
        aria-describedby="selected-dealer-location-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.dialogTitle}>
              {dealerLocation?.title}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setNavigateBack(true);
              }, 300);
            }} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{backgroundColor: '#f5f5f5'}}>
          {!!dealerLocation &&
            <DealerLocationDetail
              dealerLocation={dealerLocation}
              onUpdated={dealerLocation => {
                setDealerLocation(dealerLocation);
                props.onUpdated(dealerLocation);
                if (dealerLocation.id) loadDealerLocation(dealerLocation.id);
              }}
              onDeleted={dealerLocation => {
                props.onDeleted(dealerLocation);
                setNavigateBack(true);
              }}
            />}
          {!dealerLocation &&
            <div>
              <LoadingView />
            </div>
          }
        </DialogContent>
        </>
      </Dialog>

      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}

