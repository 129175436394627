import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Divider,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton
} from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import localization from '../../utils/localizations';
import {
  SessionContext
} from '../../data/Session';

import {
  BaseAPI,
} from '../../data/BaseAPI';

import {
  useProfile
} from '../../data/Profile';

import {
  JobDescription,
  LaborCategory
} from '../../types';

import { LaborCategoryForm } from '../forms/LaborCategoryForm';
import { JobDescriptionForm } from '../forms/JobDescriptionForm';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  filterForm: {
    marginBottom: 24,
    padding: 12,
  },
  reportContent: {
  },
  fieldContainer: {
  },
  table: {

  }
}));


function WarrantySettings(props: {}) {
  const classes = useStyles();

  const [selectedCategory, setSelectedCategory] = useState<LaborCategory|null>(null);
  const [showCategoryEditor, setShowCategoryEditor] = useState(false);
  const [categoryReloadCounter, setCategoryReloadCounter] = useState(0);
  const [selectedJobDescription, setSelectedJobDescription] = useState<JobDescription|null>(null);
  const [showJobDescriptionEditor, setShowJobDescriptionEditor] = useState(false);
  const [jobDescriptionReloadCounter, setJobDescriptionReloadCounter] = useState(0);

  return (
    <div>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper className="p-2">
              <LaborCategoriesList
                key={`labor-categories-list-${categoryReloadCounter}`}
                selectedId={selectedCategory ? selectedCategory.id : undefined}
                onSelected={(category) => {
                  setSelectedCategory(category);
                  setShowCategoryEditor(false);
                }}
                onEditSelected={(category) => {
                  setSelectedCategory(category);
                  setShowCategoryEditor(true);
                }}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedCategory(null);
                  setShowCategoryEditor(true);
                }}
              >
                Add New Category
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className="p-2">
              <JobDescriptionsList
                key={`job-descriptions-list-${jobDescriptionReloadCounter}`}
                selectedId={selectedJobDescription ? selectedJobDescription.id : undefined}
                jobCategoryId={selectedCategory ? selectedCategory.id : undefined}
                onSelected={(jobDescription) => {
                  setSelectedJobDescription(jobDescription);
                  setShowJobDescriptionEditor(false);
                }}
                onEditSelected={(jobDescription) => {
                  setSelectedJobDescription(jobDescription);
                  setShowJobDescriptionEditor(true);
                }}
              />

              {!!selectedCategory &&
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedJobDescription(null);
                  setShowJobDescriptionEditor(true);
                }}
              >
                Add New Job Description
              </Button>}
            </Paper>
          </Grid>
        </Grid>

        <Dialog
          open={showCategoryEditor}
          onClose={() => setShowCategoryEditor(false)}
          aria-labelledby="edit-labor-category"
        >
          <LaborCategoryForm
            category={selectedCategory}
            onCancel={() => setShowCategoryEditor(false)}
            onSave={(category) => {
              setSelectedCategory(category);
              setShowCategoryEditor(false);
              setCategoryReloadCounter(categoryReloadCounter+1);
            }}
          />
        </Dialog>

        <Dialog
          open={showJobDescriptionEditor}
          onClose={() => {
            setSelectedJobDescription(null);
            setShowJobDescriptionEditor(false);
          }}
          aria-labelledby="edit-labor-category"
        >
          {!!selectedCategory &&
          <JobDescriptionForm
            jobDescription={selectedJobDescription}
            category={selectedCategory}
            onCancel={() => {
              setSelectedJobDescription(null);
              setShowJobDescriptionEditor(false);
            }}
            onSave={(jobDescription) => {
              setSelectedJobDescription(jobDescription);
              setShowJobDescriptionEditor(false);
              setJobDescriptionReloadCounter(jobDescriptionReloadCounter+1);
            }}
          />}
        </Dialog>
      </div>
    </div>
  );
}

function LaborCategoriesList(props: {
  selectedId?: string;
  onSelected?: (category: LaborCategory|null) => void;
  onEditSelected?: (category: LaborCategory|null) => void;
}) {
  const [laborCategories, setLaborCategories] = useState([] as LaborCategory[]);
  const [isLoading, setIsLoading] = useState(false);

  const loadLaborCategories = async () => {
    const api = new BaseAPI();
    let url = `warranty-labor-categories/`;
    let kwargs: any = {};
    url = `${url}?${new URLSearchParams(kwargs).toString()}`;
    setIsLoading(true);
    try {
      const data: any = await api.get(url);
      if (data instanceof Array) {
        setLaborCategories(data as LaborCategory[]);
        (window as any)._cached['labor_categories'] = data;
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const deleteCategory = async (categoryId: string) => {
    const api = new BaseAPI();
    const [result, response, error] = await api.delete(`warranty-labor-categories/${categoryId}/`);
    loadLaborCategories();
  }

  useEffect(() => {
    loadLaborCategories();
  }, []);

  return (
    <List
      component="nav"
      aria-label="Labor Categories"
      subheader={<ListSubheader>Labor Categories</ListSubheader>}
    >
      {laborCategories.map((category, i) => (
        <ListItem
          key={`labor-category-item-${category.id}`}
          button
          selected={category.id === props.selectedId}
          onClick={(event) => {
            if (props.onSelected) props.onSelected(category)
          }}
        >
          <ListItemText primary={category.name} />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="edit"
              onClick={() => {
                if (props.onEditSelected) props.onEditSelected(category);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => {
                if (window.confirm(`Delete labor category "${category.name}"?`)) {
                  deleteCategory(category.id);
                  if (props.onSelected) props.onSelected(null);
                }

              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
      {!!isLoading &&
        <ListItem>
          <ListItemIcon>
            <CircularProgress />
          </ListItemIcon>
          <ListItemText primary="Loading..." />
        </ListItem>
      }
      {(!isLoading && laborCategories.length === 0) &&
        <ListItem>
          <ListItemText secondary="empty" />
        </ListItem>
      }
    </List>
  );
}


function JobDescriptionsList(props: {
  selectedId?: string;
  jobCategoryId?: string;
  onSelected?: (category: JobDescription|null) => void;
  onEditSelected?: (category: JobDescription|null) => void;
}) {
  const [jobDescriptions, setJobDescriptions] = useState([] as JobDescription[]);
  const [isLoading, setIsLoading] = useState(false);

  const loadJobDescriptions = async () => {
    const api = new BaseAPI();
    let url = `warranty-job-descriptions/`;
    let kwargs: any = {};
    url = `${url}?${new URLSearchParams(kwargs).toString()}`;
    setIsLoading(true)
    try {
      const data: any = await api.get(url);
      if (data instanceof Array) {
        setJobDescriptions(data as JobDescription[]);
        (window as any)._cached['job_descriptions'] = data;
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const deleteJobDescription = async (jobDescriptionId: string) => {
    const api = new BaseAPI();
    const [result, response, error] = await api.delete(`warranty-job-descriptions/${jobDescriptionId}/`);
    loadJobDescriptions();
  }

  useEffect(() => {
    loadJobDescriptions();
  }, []);

  const filteredJobDescriptions = jobDescriptions.filter((jd) => jd.category.id === props.jobCategoryId);

  return (
    <List
      component="nav"
      aria-label="Job Description"
      subheader={<ListSubheader>Job Description</ListSubheader>}
    >
      {filteredJobDescriptions.map((jd, i) => (
        <ListItem
          key={`job-description-item-${jd.id}`}
          button
          selected={jd.id === props.selectedId}
          onClick={(event) => {
            if (props.onEditSelected) props.onEditSelected(jd)
          }}
        >
          <ListItemText
            primary={jd.name}
            secondary={jd.flat_rate_code}
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="edit"
              onClick={() => {
                if (props.onEditSelected) props.onEditSelected(jd);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => {
                if (window.confirm(`Delete job description "${jd.name}"?`)) {
                  deleteJobDescription(jd.id);
                  if (props.onSelected) props.onSelected(null);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
      {!!isLoading &&
        <ListItem>
          <ListItemIcon>
            <CircularProgress />
          </ListItemIcon>
          <ListItemText primary="Loading..." />
        </ListItem>
      }
      {(!isLoading && !!props.jobCategoryId && filteredJobDescriptions.length === 0) &&
        <ListItem>
          <ListItemText secondary="empty" />
        </ListItem>
      }
      {(!isLoading && !props.jobCategoryId && filteredJobDescriptions.length === 0) &&
        <ListItem>
          <ListItemText secondary="No labor category selected!" />
        </ListItem>
      }
    </List>
  );
}

export {
  WarrantySettings,
}