import {
  useState,
  useEffect
} from 'react';

import {
  BaseAPI,
} from './BaseAPI';


class StatusAPI extends BaseAPI {
  path = 'status/'
}

let statusAPI: StatusAPI;
function getAPI() {
  if (!statusAPI) statusAPI = new StatusAPI();
  return statusAPI;
}

function useStatus(showGlobalLoading=true) {
  const [status, setStatus] = useState({} as any);
  const [loading, setLoading] = useState(true);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false); // TODO: use mobx global state

  const loadStatus = async () => {
    if (!(window as any)._cache) {
      (window as any)._cache = {};
    }

    if ((window as any)._cache.status) {
      setStatus((window as any)._cache.status);
    }

    setLoading(true);
    if (showGlobalLoading) setIsGlobalLoading(true);
    const statusAPI = getAPI();
    try {
      const data = await statusAPI.get(); 
      
      setStatus(data);
      setLoading(false);
      if (showGlobalLoading) setIsGlobalLoading(false);

      (window as any)._cache.status = data;

      if (data && (data as any).csrftoken) (window as any).csrftoken =  (data as any).csrftoken;     
    } catch (e) {
      console.error(e);
      setLoading(false);
      if (showGlobalLoading) setIsGlobalLoading(false);

      return;
    }
  }

  const updateStatus = async (data: any) => {
    let success = false;
    setLoading(true);
    const statusAPI = getAPI();
    const [result, response, error] = await statusAPI.post(data);
    if (result && result.success) {
      const status = await statusAPI.get();
      setStatus(status);
      success = true;
    }
    setLoading(false);
    return [success, result];
  };

  useEffect(() => {
    loadStatus();
  }, []);

  return [
    status,
    loading,
    loadStatus,
    updateStatus,
  ];
}

export {
  getAPI,
  useStatus,
};
