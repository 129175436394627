import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Grid,
  Paper,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Toolbar,
  IconButton,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox
} from '@material-ui/core';

import {
  TransitionProps
} from '@material-ui/core/transitions';

import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import {
  useProfile
} from '../../data/Profile';
import {
  User,
} from '../../types';

import localization from '../../utils/localizations';
import DealerUserCollection from '../collections/DealerUserCollection';
import UserForm from '../forms/UserForm';
import DateTimeView from './DateTimeView';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
  },
  partNumber: {
    marginRight: 10,
    minWidth: 100,
  },
  alignRight: {
    textAlign: 'right',
  },
  bolder: {
    fontWeight: 'bold'
  },
  noteContainer: {
    marginBottom: 20,
  },
  shippingAddressContainer: {
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function UserDetail(props: {
  user: User;
  onUpdated: (user: User) => void;
  onDeleted: (user: User) => void;
  className?: string;
}) {
  const classes = useStyles();
  const [showEditForm, setShowEditForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null|HTMLButtonElement>(null);
  const [showCreateClaimForm, setShowCreateClaimForm] = useState(false);
  const [redirect, setRedirect] = useState('');
  const user = props.user;

  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();

  const deleteUser = async (user: User) => {
    const api = new BaseAPI();
    try {
      const [result, request] = await api.delete(`all-users-paginated/${user.id}/`);
      props.onDeleted(user);
    } catch (error) {
      console.error(error);
    }
  }

  const sendInvite = async (user: User) => {
    const api = new BaseAPI();
    try {
      const [result, request] = await api.post({}, `all-users-paginated/${user.id}/send_invite/`);
      window.alert('Invite sent!');
    } catch (error) {
      console.error(error);
    }
  }

  let isAdmin = false;
  if (profile && (profile.role === 'admin')) {
    isAdmin = true;
  }

  let canEdit = false;
  if (profile && (profile.role === 'admin')) {
    canEdit = true;
  }
  let canDelete = canEdit;

  if (canEdit && showEditForm) {
    return (
      <div className={`${classes.root} ${props.className}`}>
        <UserForm
          user={props.user}
          onSave={(user) => {
            setShowEditForm(false);
            props.onUpdated(user);
          }}
          onCancel={() => {
            setShowEditForm(false);
          }}
        />
      </div>
    )
  }

  return (
    <div className={`${classes.root} ${props.className}`}>

      <Grid container spacing={3} component={Paper} style={{margin: 0, marginBottom: 12, width: '100%'}}>
        <Grid item xs={12}>
          <div className={classes.shippingAddressContainer}>
            <Typography variant="h6">
              User Information
              <Chip color={props.user.is_active ? 'primary' : 'secondary'} label={props.user.is_active ? "active" : "inactive"} size="small" className="mx-2" />
              <Chip label={props.user.role} size="small" />
            </Typography>
            <Typography variant="body2">
              Name: {user.first_name} {user.last_name} <br />
              Email: {user.email ? <a href={`mailto:${user.email}`}>{user.email}</a>: '-'} <br />
              Date Joined: {user.date_joined && <DateTimeView value={user.date_joined} dateOnly />} <br />
              Last Login: {user.last_login && <DateTimeView value={user.last_login} />} <br />
            </Typography>


            <div style={{marginTop: 20, display: 'flex', justifyContent: 'space-between'}}>
              {canEdit &&
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setShowEditForm(true)}
                  style={{marginRight: 10}}
                >Edit</Button>

                {canDelete &&
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    if (window.confirm('Remove this user?')) {
                      deleteUser(props.user);
                    }
                  }}
                  style={{marginRight: 10}}
                >Delete</Button>}
              </div>}

              {canEdit &&
              <div>
                <Button
                  variant="contained"
                  href={`/admin/seafox/user/${user.id}/password/`}
                  target="_blank"
                  size="small"
                  style={{marginRight: 10}}
                >Change Password</Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    if (window.confirm('Send invite email to this user?')) {
                      sendInvite(user);
                    }
                  }}
                  style={{marginRight: 10}}
                >Send Invite Mail</Button>
              </div>}
            </div>

          </div>
        </Grid>
      </Grid>

      <DealerUserCollection
        userId={user.id}
        backUrl={window.location.pathname}
        hideUser
      />

      {!!redirect && <Redirect to={redirect} />}
    </div>
  );
}