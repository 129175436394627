import React, {
  useState,
  useEffect
} from 'react';

import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  TreeView,
  TreeItem,
} from '@material-ui/lab';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
  BoatEditor,
  BoatEditorPartGroup,
  BoatEditorPart,
  BoatEditorPartVariant,
} from '../../../types';


const useStyles = makeStyles({
  root: {

  },
});

export default function PartPreview(props: {
  boat: BoatEditor;
  groups: BoatEditorPartGroup[];
  parts: BoatEditorPart[];
  linkedParts: BoatEditorPart[];
  partVariants: BoatEditorPartVariant[];
}) {
  const classes = useStyles();
  const topLevelGroupIds: string[] = [];
  const groupedParts: Record<string, BoatEditorPart[]> = {};
  const groupedChildren: Record<string, BoatEditorPartGroup[]> = {};
  const topLevelGroups: BoatEditorPartGroup[] = props.groups.filter(group => {
    let parts: BoatEditorPart[] = props.parts.filter((part) => part.groups.includes(group.id));
    if (group.id === 0) parts = props.parts.filter((part) => part.groups.length === 0);
    if (parts.length > 0) return true;
    return false;
  });
  
  topLevelGroups.forEach(group => {
    let groupkey = `group-${group.id}`;
    let parts: BoatEditorPart[] = props.parts.filter((part) => part.groups.includes(group.id));
    if (group.id === 0) parts = props.parts.filter((part) => part.groups.length === 0);
    groupedParts[groupkey] = parts;
    topLevelGroupIds.push(groupkey);

    let childGroups = group.children.filter(group => {
      let parts: BoatEditorPart[] = props.parts.filter((part) => part.groups.includes(group.id));
      if (parts.length > 0) return true;
      return false;
    });

    groupedChildren[groupkey] = childGroups;

    group.children.forEach(group => {
      let groupkey = `group-${group.id}`;
      let parts: BoatEditorPart[] = props.parts.filter((part) => part.groups.includes(group.id));
      if (group.id === 0) parts = props.parts.filter((part) => part.groups.length === 0);
      groupedParts[groupkey] = parts;
      if (parts.length > 0) topLevelGroupIds.push(groupkey);
    });
  });

  const renderPart = (part: BoatEditorPart) => {
    let partKey = `part-${part.id ? part.id : part.local_id}`;
    let label = (<span><span style={{color: '#888'}}>{part.part_number}</span> {part.description}</span>);
    let price = part.price ? formatNumber({prefix: 'US$'})(parseFloat(part.price as string)) : '';
    let priceMsrp = part.price_msrp ? formatNumber({prefix: 'US$'})(parseFloat(part.price_msrp as string)) : '';
    let priceMsrpDeal = part.price_msrp_deal ? formatNumber({prefix: 'US$'})(parseFloat(part.price_msrp_deal as string)) : '';
    return (
      <TreeItem key={partKey} nodeId={partKey} label={label}>
        {!!price && <TreeItem nodeId={`${partKey}-price`} label={<span><span style={{color: '#888'}}>Price:</span> {price}</span>} />}
        {!!priceMsrp && <TreeItem nodeId={`${partKey}-price-msrp`} label={<span><span style={{color: '#888'}}>MSRP Price:</span> {priceMsrp}</span>} />}
        {!!priceMsrpDeal && <TreeItem nodeId={`${partKey}-price-msrp-deal`} label={<span><span style={{color: '#888'}}>MSRP Deal Price:</span> {priceMsrpDeal}</span>} />}
        {!!part.csv_description && <TreeItem nodeId={`${partKey}-csv-description`} label={<span><span style={{color: '#888'}}>CSV Description:</span> {part.csv_description}</span>} />}
        {!!part.extra_information && <TreeItem nodeId={`${partKey}-extra-information`} label={<span><span style={{color: '#888'}}>Description:</span> {part.extra_information}</span>} />}
        {renderVariants(part)}
      </TreeItem>
    );
  }

  const renderVariants = (part: BoatEditorPart) => {
    let variants = props.partVariants
      .filter(partVariant => partVariant.part === part.id)
      .sort((a, b) => {
        let priceA = a.price ? a.price : '0';
        let priceB = b.price ? b.price : '0';
  
        let nameA = a.name ? a.name : '0';
        let nameB = b.name ? b.name : '0';
  
        let orderA = a.order ? a.order : '0';
        let orderB = b.order ? b.order : '0';
  
        if (part.variants_ordering === 'price_low_high') {
          if (priceA > priceB) return 1;
          if (priceB > priceA) return -1;
          return 0;
        }
  
        if (part.variants_ordering === 'price_high_low') {
          if (priceA > priceB) return -1;
          if (priceB > priceA) return 1;
          return 0;
        }
  
        if (part.variants_ordering === 'name_asc') {
          if (nameA > nameB) return 1;
          if (nameB > nameA) return -1;
          return 0;
        }
  
        if (part.variants_ordering === 'name_desc') {
          if (nameA > nameB) return -1;
          if (nameB > nameA) return 1;
          return 0;
        }
  
        if (part.variants_ordering === 'custom') {
          if (orderA > orderB) return 1;
          if (orderB > orderA) return -1;
          return 0;
        }
  
        if (priceA > priceB) return 1;
        if (priceB > priceA) return -1;
        return 0;
      });

    if (variants.length === 0) return (<></>);

    let partKey = `part-${part.id ? part.id : part.local_id}-variants`;
    return (
      <TreeItem key={partKey} nodeId={partKey} label={<b>{`Variants (${variants.length})`}</b>}>
        {variants.map(variant => {
          let partKey = `part-variant-${variant.id ? variant.id : variant.local_id}`;
          let label = (<span><span style={{color: '#888'}}>{variant.part_number}</span> {variant.name}</span>);
          let price = variant.price ? formatNumber({prefix: 'US$'})(parseFloat(variant.price as string)) : '';
          let priceMsrp = variant.price_msrp ? formatNumber({prefix: 'US$'})(parseFloat(variant.price_msrp as string)) : '';
          let priceMsrpDeal = variant.price_msrp_deal ? formatNumber({prefix: 'US$'})(parseFloat(variant.price_msrp_deal as string)) : '';
          return (
            <TreeItem key={partKey} nodeId={partKey} label={label}>
              {!!price && <TreeItem nodeId={`${partKey}-price`} label={<span><span style={{color: '#888'}}>Price:</span> {price}</span>} />}
              {!!priceMsrp && <TreeItem nodeId={`${partKey}-price-msrp`} label={<span><span style={{color: '#888'}}>MSRP Price:</span> {priceMsrp}</span>} />}
              {!!priceMsrpDeal && <TreeItem nodeId={`${partKey}-price-msrp-deal`} label={<span><span style={{color: '#888'}}>MSRP Deal Price:</span> {priceMsrpDeal}</span>} />}
              {!!part.csv_description && <TreeItem nodeId={`${partKey}-csv-description`} label={<span><span style={{color: '#888'}}>CSV Description:</span> {part.csv_description}</span>} />}
              {!!part.description && <TreeItem nodeId={`${partKey}-extra-information`} label={<span><span style={{color: '#888'}}>Description:</span> {part.description}</span>} />}
            </TreeItem>
          );
        })}
      </TreeItem>
    );
  }

  return (
    <div className={classes.root}>
      <TreeView
      className={classes.root}
      defaultExpanded={topLevelGroupIds}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {topLevelGroups.map(group => {
        let groupkey = `group-${group.id}`;
        let parentGroup = group;
        let parts = groupedParts[groupkey];
        return (
          <TreeItem key={groupkey} nodeId={groupkey} label={<b>{group.name}</b>}>
            {parts.map(part => renderPart(part))}
            {groupedChildren[groupkey].map(group => {
              let groupkey = `group-${group.id}`;
              let parts = groupedParts[groupkey];
              return (
                <TreeItem key={groupkey} nodeId={groupkey} label={<b>{group.name}</b>}>
                  {parts.map(part => renderPart(part))}
                </TreeItem>
              )})}
          </TreeItem>
        );
      })}
    </TreeView>
    </div>
  );
}