import React, {
  useState,
  useEffect
} from 'react';

import { Form, Field } from "react-final-form";

import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  MenuItem,
  ListSubheader,
} from '@material-ui/core';

import {
  APIError,
  BaseAPI,
} from '../../../data/BaseAPI';

import {
  Dealer,
} from '../../../types';

const useStyles = makeStyles({
  root: {
    
  },
});

export default function DealerSelect(props: {
  id: string;
  label: string;
  variant: "filled" | "outlined" | "standard" | undefined;
  name: string;
  value: string;
  allowEmpty?: boolean;
  emptyLabel?: string;
  onChange: (event: any) => void;
  error?: boolean | undefined
}) {
  const classes = useStyles();
  
  const [allDealers, setAllDealers] = useState(((window as any)._cached && (window as any)._cached['all_dealers'] ? (window as any)._cached['all_dealers']: []) as Dealer[]);

  const loadAllDealers = async () => {

    const api = new BaseAPI();
    try {
      const data = await api.get('all-dealers/');
      if (data instanceof Array) {
        setAllDealers(data as Dealer[]);

        if (!(window as any)._cached) (window as any)._cached = {} as any;
        if (!(window as any)._cached['all_dealers']) (window as any)._cached['all_dealers'] = data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(allDealers.length === 0) loadAllDealers();
  }, []);

  return (
    <Select
      id={props.id}
      label={props.label}
      variant={props.variant}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      error={props.error}
    >
      {!!props.allowEmpty && 
      <MenuItem value="">{props.emptyLabel ? props.emptyLabel : '----'}</MenuItem>}
      <ListSubheader>Active Dealers</ListSubheader>
      {allDealers.filter(dealer => dealer.active).map((dealer, i) => (
        <MenuItem key={`dealer-menu-item-${i}`} value={dealer.id}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
      ))}
      <ListSubheader>Inactive Dealers</ListSubheader>
      {allDealers.filter(dealer => !dealer.active).map((dealer, i) => (
        <MenuItem key={`dealer-menu-item-inactive-${i}`} value={dealer.id}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
      ))}
    </Select>
  );
}