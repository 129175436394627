import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
  LinearProgress,
} from '@material-ui/core';
import {
  Alert,
  Skeleton
} from '@material-ui/lab';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import InfoIcon from '@material-ui/icons/InfoRounded';
import PDFIcon from '@material-ui/icons/Description';

import BoatCard from '../singles/BoatCard';
import OrderBoatFormV2 from '../forms/order/OrderBoatFormV2';
import BoatStandardItems from '../singles/BoatStandardItems';
import AdminToolPricingDataImport from './AdminToolPricingDataImport';
import AdminToolBoatEditor from './AdminToolBoatEditor';
import PrintIframe from '../singles/PrintIframe';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import {
  Boat,
  DealerPermission,
} from '../../types';
import localization from '../../utils/localizations';
import processBoatsData from '../../utils/processBoatsData';
import { useProfile } from '../../data/Profile';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  marginVertical: {
    marginTop: 20,
    marginBottom: 20,
  },
  breadcrumbs: {
    margin: 10,
  },
  returnButtonAbs: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  boatsContainer: {

  },
  gridList: {
    
  },
  boatCard: {
    margin: 20,
  },
  boatTitle: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  orderInformationText: {
    padding: 20,
    whiteSpace: "pre-line",
  },
  adminToolsContainer: {
    margin: 20,
    marginTop: 40,
    marginBottom: 40,
  },
  adminToolBoatEditorContainer: {
    marginTop: 40,
    marginBottom: 40,
  },
  boatActionButtonsContainer: {
    margin: 20,
    '& button': {
      marginRight: 8,
      marginBottom: 8
    },
    '& a': {
      marginRight: 8,
      marginBottom: 8
    },
  }
}));

const appConfig = (window as any).APP_CONFIG;

const BOAT_LIST_VIEW_URL = `/order-form/`;

function BoatsPanel(props: {}) {
  const classes = useStyles();

  const { boatId } = useParams() as any;

  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [open, setOpen] = useState(false);
  const [showHiddenInfo, setShowHiddenInfo] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [navigateToUrl, setNavigateToUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [boatList, setBoatList] = useState(((window as any)._cached && (window as any)._cached['boats'] ? (window as any)._cached['boats']: []) as Boat[]);

  const { path, url } = useRouteMatch();
  const backUrl = `/`;

  const loadBoats = async () => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`thin-boats/?t=${(new Date()).getTime()}`);
      if (data instanceof Array) {
        // let boats = processBoatsData(data as Boat[]);
        let boats = data as Boat[];
        setBoatList(boats);
        if (!(window as any)._cached) (window as any)._cached = {} as any;
        if (!(window as any)._cached['boats']) (window as any)._cached['boats'] = boats;
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadBoats();
  }, [])

  useEffect(() => {
    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    },300);

  }, []);

  const canAccessBoatOrderingPortal = (permissions?: DealerPermission[], role?: string) => {
    let hasPermission = false;
    if (role === 'admin') hasPermission = true;
    if (permissions) {
      permissions.forEach(permission => {
        if (permission.admin || permission.order) hasPermission = true;
      });
    }
    return hasPermission;
  }

  if (!canAccessBoatOrderingPortal(profile.permissions, profile.role)) {
    return (<>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
      >
        <Typography variant="h2" style={{marginLeft: 14, marginTop: 14}}>Loading...</Typography>
        <div style={{height: 60}}></div>
      </Drawer>
      {navigateBack && <Redirect to={backUrl} />}
      {navigateToUrl && <Redirect to={navigateToUrl} />}
    </>);
  }
  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
      >
        {(isLoading && boatList.length == 0) && <LinearProgress />}
        <div className={classes.boatsContainer}>
          <Typography variant="h2" style={{marginLeft: 14, marginTop: 14}}>Available Boats</Typography>
          <GridList cellHeight={160} className={classes.gridList} cols={3}>
            {boatList.map((boat, i) => {
              return (
                <BoatCard
                  key={`boat-${boat.id}-${i}`}
                  className={classes.boatCard}
                  boat={boat}
                  actionLabel="Order"
                  onClicked={async (boat) => {
                    setNavigateToUrl(`${BOAT_LIST_VIEW_URL}${boat.id}/`)
                    setTimeout(() => {
                      setNavigateToUrl('');
                    }, 100);
                  }}
                />
              );
            })}
          </GridList>
        </div>

        <Route exact path={`${BOAT_LIST_VIEW_URL}:boatId/`}>
          <OrderFormPanel />
        </Route>

        <IconButton
          color="inherit"
          onClick={() => {
            setOpen(false);
            setTimeout(() => {
              setNavigateBack(true);
            }, 300);
          }}
          className={classes.returnButtonAbs}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Drawer>
      {navigateBack && <Redirect to={backUrl} />}
      {navigateToUrl && <Redirect to={navigateToUrl} />}
    </>
  );
}


function OrderFormPanel(props: {}) {
  const classes = useStyles();

  const { boatId } = useParams() as any;

  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [open, setOpen] = useState(false);
  const [showHiddenInfo, setShowHiddenInfo] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [navigateToUrl, setNavigateToUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBoat, setSelectedBoat] = useState(null as Boat|null);
  const [boatList, setBoatList] = useState(((window as any)._cached && (window as any)._cached['boats'] ? (window as any)._cached['boats']: []) as Boat[]);

  const { path, url } = useRouteMatch();
  const backUrl = BOAT_LIST_VIEW_URL;

  const loadBoat = async (boatId: number) => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`boats/${boatId}/`);
      setIsLoading(false);
      if (data) {
        let boats = processBoatsData([data as Boat]);
        let boat = boats[0];
        return boat
      }
    } catch (error) {
      console.error(error);
      setErrorMessage((error as Error).message);
    }

    setIsLoading(false);
  }

  const loadBoatFormById = async (boatIdStr: string) => {
    let boatId = parseInt(boatIdStr ? boatIdStr : '');
    if (boatIdStr && !isNaN(boatId)) {
      let b = await loadBoat(boatId);
      if (b) setSelectedBoat(b);
    }
  }

  useEffect(() => {
    loadBoatFormById(boatId);
  }, [boatId])

  useEffect(() => {
    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    },300);

  }, []);
  
  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
      >
        {(isLoading) && <LinearProgress />}
        {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {selectedBoat &&
          <Grid container>
            <Grid xs={12} sm={8}>
              <OrderBoatFormV2
                key={`boat-order-form-${selectedBoat ? selectedBoat.id : '-'}`}
                boat={selectedBoat}
                showHiddenInfo={showHiddenInfo}
                onCancel={() => {
                  setOpen(false);
                  setTimeout(() => {
                    setNavigateBack(true);
                  }, 300);
                }}
                onOrderCompleted={(orderId: number) => {
                  setOpen(false);
                  setTimeout(() => {
                    setNavigateToUrl(`${appConfig.homepage}orders/${orderId}/`);
                  }, 300);
                }}
                onOrderDraftCompleted={(orderDraftId: number) => {
                  setOpen(false);
                  setTimeout(() => {
                    setNavigateToUrl(`${appConfig.homepage}orders/quotes/${orderDraftId}/`);
                  }, 300);
                }}
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <img src={selectedBoat.thumbnail} style={{width: "100%", marginTop: 50}}/>
              <Paper>
                {!!selectedBoat.logo_full && <Typography variant="h5" className={classes.boatTitle}>{selectedBoat.name}</Typography>}
                {!!selectedBoat.order_information &&
                  <Typography variant="body2" className={classes.orderInformationText}>
                    {selectedBoat.order_information}
                  </Typography>
                }
                
                <div className={classes.boatActionButtonsContainer}>
                  <Button color="primary" onClick={() => {
                    if (selectedBoat.product_url) window.open(selectedBoat.product_url, '_blank');
                  }} startIcon={<InfoIcon />} variant="outlined">
                    Learn More
                  </Button>
                  {!!selectedBoat.specs_pdf_url &&
                    <Button color="primary" onClick={() => {
                      if (selectedBoat.specs_pdf_url) window.open(selectedBoat.specs_pdf_url, '_blank');
                    }} startIcon={<PDFIcon />} variant="outlined">
                      Specs PDF
                    </Button>}
                </div>

                <PrintIframe boat={selectedBoat} className={classes.boatActionButtonsContainer} />
                {!isLoading && !!selectedBoat &&
                <div style={{padding: 10}}>
                  <AdminToolBoatEditor
                    boat={selectedBoat}
                    className={classes.adminToolBoatEditorContainer}
                    onBoatUpdated={(boat) => setSelectedBoat(boat)}
                    onBoatsUpdated={(boats) => setBoatList(boats)}
                    showPartNumber={showHiddenInfo}
                    onShowPartNumber={(show) => setShowHiddenInfo(show)}
                  />
                </div>}
                <BoatStandardItems
                  boat={selectedBoat}
                />
              </Paper>
            </Grid>
          </Grid>
        }

        <IconButton
          color="inherit"
          onClick={() => {
            setOpen(false);
            setTimeout(() => {
              setNavigateBack(true);
            }, 300);
          }}
          className={classes.returnButtonAbs}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Drawer>

      {navigateBack && <Redirect to={backUrl} />}
      {navigateToUrl && <Redirect to={navigateToUrl} />}
    </>);
}

export default BoatsPanel;