import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import {
  Chip,
  Tooltip,
} from '@material-ui/core';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';

interface DateTimeViewProps {
  value: string;
  format?: string|undefined;
  tooltipFormat?: string|undefined;
  chip?: boolean;
  fromNow?: boolean;
  size?: "medium"|"small"|undefined;
  endValue?: string;
  rangeDateOnly?: boolean;
  dateOnly?: boolean;
  icon?: any;
  className?: string;
}

function DateTimeView(props: DateTimeViewProps) {

  const [shoudUpdate, setShouldUpdate] = useState(0);
  useEffect(() => {
    const periodicUpdate = () => {
      setShouldUpdate((new Date()).getTime());
    }
    const timer = setInterval(periodicUpdate, 60000);

    return () => {
      clearInterval(timer);
    }
  }, []);

  const d = moment(props.value);
  let defaultFormat = "Do MMMM YYYY, HH:mm:ss";
  if (props.dateOnly) defaultFormat = "Do MMMM YYYY";
  let formatted = props.fromNow ? d.fromNow() : d.format(props.format ? props.format : defaultFormat);

  if (props.endValue) {
    const format = props.format ? props.format : "Do MMMM YYYY";
    const startDate = moment(props.value).format(format);
    const startTime = moment(props.value).format("HH:mm");
    const endDate = moment(props.endValue).format(format);
    const endTime = moment(props.endValue).format("HH:mm");
    
    if (startDate == endDate) {
      if (props.rangeDateOnly) formatted = startDate;
      else formatted = `${startDate} ${startTime} - ${endTime}`;
    }
    else {
      if (props.rangeDateOnly) formatted = `${startDate} - ${endDate}`;
      else formatted = `${startDate} ${startTime} - ${endDate} ${endTime}`;
    }
  }


  let component: any;
  if (!props.chip) {
    component = (
      <span key={`datetimeview-${shoudUpdate}`} className={props.className}>
        {formatted}
      </span>
    );
  }
  else {
    let icon = props.icon ? props.icon : <AccessTimeOutlinedIcon />;
    if (!props.icon && props.endValue) icon = <DateRangeIcon />;

    component = (
      <Chip
        key={`datetimeview-${shoudUpdate}`}
        label={formatted}
        size={props.size}
        avatar={icon}
        className={props.className}
      />
    );
  }

  if (props.chip) {
    let tooltipValue = props.fromNow ? d.format(props.tooltipFormat ? props.tooltipFormat : "dddd, Do MMMM YYYY, HH:mm:ss") : d.fromNow();
    return (
      <Tooltip title={tooltipValue}>
        {component}
      </Tooltip>
    );
  }
  else {
    return component;
  }
}

export default DateTimeView;