import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Chip,
  withStyles,
} from '@material-ui/core';

import localization from '../../utils/localizations';


const useStyles = makeStyles((theme) => ({
  mainBody: {
    padding: 10,
  },
}));


const GreenChip = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#689f38',
  },
}))(Chip);

const RedChip = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#d32f2f',
  },
}))(Chip);

const YellowChip = withStyles((theme) => ({
  root: {
    color: '#000000',
    backgroundColor: '#fbc02d',
  },
}))(Chip);

const BlueChip = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#0288d1',
  },
}))(Chip);

const GreyChip = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#616161',
  },
}))(Chip);


function StatusChip(props: {status: string; size?: "medium" | "small" | undefined; className?: string;}) {
  const classes = useStyles();
  let ChipComponent: any = Chip;

  switch (props.status) {
    case 'accepted':
    case 'active':
    case 'completed':
    case 'open':
    case 'available':
      ChipComponent = GreenChip;
      break;
    case 'scheduled':
    case 'submitted':
      ChipComponent = BlueChip;
      break;
    case 'canceled':
    case 'rejected':
    case 'inactive':
    case 'unavailable':
      ChipComponent = RedChip;
      break;
    case 'closed':
      ChipComponent = GreyChip;
      break;

    default:
      ChipComponent = GreyChip;
      break;
  }

  return (
    <ChipComponent
      size={props.size}
      label={(localization as any)[props.status] ? (localization as any)[props.status].toLowerCase() : props.status}
      color="primary"
      className={props.className}
    />
  );
}

export default StatusChip;