

import bankersRounding from '../../../utils/bankersRounding';

import {
  BaseAPI,
  APIError,
} from '../../../data/BaseAPI';

import {
  Boat,
  Part,
  PartGroup,
  GroupedPart,
  Dealer,
  PartVariant,
  PartRule,
  DealerDiscount,
  BoatOrderDraftItem,
  PartRuleAdditionalRule,
} from '../../../types';

import {
  OrderLine,
  PartOrderLine,
  DiscountItem,
  FormValues
} from './OrderTypes';

export const computeTotal = (orderLines: OrderLine[], dealer: Dealer|undefined, dealerDiscounts: DealerDiscount[]): number => {
  let total = 0;
  let discount = 0;
  let boatOrderLine = orderLines.find(o => o.type === 'boat');
  orderLines.forEach(orderLine => {
    if (orderLine.type === 'boat' || orderLine.type === 'option') {
      total += parseFloat(orderLine.price) * orderLine.quantity;
    }
    else if (boatOrderLine && orderLine.type === 'discount_p') {
      discount += bankersRounding((parseFloat(orderLine.price) / 100) * parseFloat(boatOrderLine.price) * orderLine.quantity, 2);
    }
    else if (boatOrderLine && orderLine.type === 'discount_a') {
      let d = parseFloat(boatOrderLine.price) - parseFloat(orderLine.price);
      if (d < 0) discount += parseFloat(boatOrderLine.price) * orderLine.quantity;
      else discount += parseFloat(orderLine.price) * orderLine.quantity;
    }
  });

  if (dealer) {
    const applicableDiscounts = getApplicableDiscounts(orderLines, dealer, dealerDiscounts);
    applicableDiscounts.forEach(dd => discount += parseFloat(`${dd.total}`));
  }
  return total - discount;
}

export const computeTotalWithoutDiscounts = (orderLines: OrderLine[]) => {
  let total = 0;
  orderLines.forEach(orderLine => {
    const price = parseFloat(`${orderLine.price}`);
    if (price > 0) total += price * orderLine.quantity;
  });

  return total;
}

export const computeDiscount = (discount: DealerDiscount, amount: string|number) => {
  if (discount.discount_type == 'fixed') return parseFloat(`${discount.discount_amount}`);
  if (discount.discount_type == 'percentage') return bankersRounding(parseFloat(`${amount}`) * (parseFloat(`${discount.discount_amount}`) / 100.0))
  return 0
}

type DiscountedMap = {
  [key: string]: boolean;
};

export const getApplicableDiscounts = (orderLines: OrderLine[], dealer: Dealer|undefined, dealerDiscounts: DealerDiscount[]) => {
  const discounts = [] as DiscountItem[];
  if (!dealer) return discounts;

  const discountedMap: DiscountedMap = {}
  let previousTotalDiscount = 0;
  dealerDiscounts.forEach(dealerDiscount => {
    if (dealerDiscount.excluded_dealers.includes(dealer.id)) return;
    if (!dealerDiscount.applied_to_all_dealers) {
      if (dealerDiscount.dealer && (dealerDiscount.dealer === dealer.id)) {

      }
      else {
        return;
      }
    };

    if (dealerDiscount.discount_target === 'boat_only') {
      orderLines.forEach(orderLine => {
        if (orderLine.type === 'boat') {
          if (!dealerDiscount.stackable && discountedMap[orderLine.id]) {
            return;
          }

          let totalPrice = parseFloat(`${orderLine.price}`) * orderLine.quantity;
          discountedMap[orderLine.id] = true;
          let discount = computeDiscount(dealerDiscount, totalPrice);
          previousTotalDiscount += discount;
          discounts.push({
            name: dealerDiscount.discount_name,
            total: discount
          });
        }
      })
    }
    if (dealerDiscount.discount_target === 'total_amount') {
      let totalPrice = 0;
      orderLines.forEach(orderLine => {
        if (!dealerDiscount.stackable && discountedMap[orderLine.id]) {
          return;
        }

        let totalPriceItem = parseFloat(`${orderLine.price}`) * orderLine.quantity;
        if (totalPriceItem) {
          totalPrice += totalPriceItem;
          discountedMap[orderLine.id] = true;
        }
      });

      if (totalPrice) {
        let discount = computeDiscount(dealerDiscount, totalPrice);
        if (dealerDiscount.stackable) discount = computeDiscount(dealerDiscount, totalPrice - previousTotalDiscount);
        previousTotalDiscount += discount;
        discounts.push({
          name: dealerDiscount.discount_name,
          total: discount
        });
      }
    }
    if (dealerDiscount.discount_target === 'parts_only') {
      let totalPrice = 0;
      orderLines.forEach(orderLine => {
        if (!dealerDiscount.stackable && discountedMap[orderLine.id]) {
          return;
        }

        if (orderLine.type === 'option') {
          let excluded = false;

          if (!dealerDiscount.parts.includes(orderLine.id)) excluded = true;

          if ((dealerDiscount.parts.length == 0) && (dealerDiscount.part_groups.length > 0)) {
            let partGroupIncluded = false;
            // TODO: part group matching
          }

          if (dealerDiscount.excluded_parts.includes(orderLine.id)) excluded = true;
          if (dealerDiscount.excluded_parts.length > 0) {
            let partGroupIncluded = false;
            // TODO: part group exclusion matching
            if (partGroupIncluded) excluded = true
          }

          if (excluded) return;

          let totalPriceItem = parseFloat(`${orderLine.price}`) * orderLine.quantity;
          if (totalPriceItem) {
            totalPrice += totalPriceItem;
            discountedMap[orderLine.id] = true;
          }
        }
      });

      if (totalPrice) {
        let discount = computeDiscount(dealerDiscount, totalPrice);
        previousTotalDiscount += discount;
        discounts.push({
          name: dealerDiscount.discount_name,
          total: discount
        });
      }
    }
  });

  return discounts;
}

export const includeRequiredParts = (orderLines: OrderLine[], boat: Boat) => {
  boat.required_parts.forEach((requiredPart) => {
    let alreadyIncluded = false;
    orderLines.forEach((orderLine) => {
      if (orderLine.id === requiredPart.id) alreadyIncluded = true;
    });
    if (!alreadyIncluded) {
      let price = requiredPart.price;
      let requiredPartVariants = requiredPart.variants.filter(variant => {
        let included = false;
        variant.boats.forEach(b => {
          if (b.id === boat.id) included = true;
        });
        return included;
      });
      let selectedVariant = requiredPartVariants.length > 0 ? requiredPartVariants[0] : undefined;
      getVariants(requiredPart, boat, orderLines).forEach((variant) => {
        if (variant.recommended) {
          selectedVariant = variant;
          price = variant.price && variant.price !== 'None' ? variant.price : requiredPart.price;
        }
      });

      orderLines.push({
        id: requiredPart.id,
        part_number: requiredPart.part_number,
        description: requiredPart.description,
        type: requiredPart.part_type,
        quantity: 1,
        variant: selectedVariant,
        note: '',
        price: price,
      });

      requiredPart.must_includes.forEach(extraPart => {
        let extraPartQuantity = 1;
        let extraPartPrice = extraPart.price;
        requiredPart.must_includes_options.forEach(option => {
          if (option.part === extraPart.id) {
            console.log(option, extraPart);
            extraPartQuantity = option.minimum_quantity;
            if (option.price !== 'None') {
              extraPartPrice = option.price;
            }
          }
        });
        let alreadyIncluded = false;
        orderLines.forEach((orderLine) => {
          if (orderLine.id === extraPart.id) alreadyIncluded = true;
        });
        if (alreadyIncluded) return;
        let extraPartVariants = extraPart.variants.filter(variant => {
          let included = false;
          variant.boats.forEach(b => {
            if (b.id === boat.id) included = true;
          });
          return included;
        });
        orderLines.push({
          id: extraPart.id,
          part_number: extraPart.part_number,
          description: extraPart.description,
          type: extraPart.part_type,
          quantity: extraPartQuantity,
          variant: extraPartVariants.length > 0 ? extraPartVariants[0] : undefined,
          note: '',
          price: extraPartPrice,
        });
      });
    }
  });
  return orderLines;
};

export const getVariants = (part: Part, boat: Boat, orderLines: OrderLine[]) => {
  return part.variants.filter(variant => {
    let boatFound = false;
    variant.boats.forEach((b) => {
      if (b.id == boat.id) boatFound = true;
    })
    if (variant.boats.length > 0) {
      if (boatFound) {
        if (variant.parts.length > 0) {
          let found = false;
          variant.parts.forEach((p) => {
            orderLines.forEach((orderLine) => {
              if (orderLine.id == p.id) {
                found = true;
              }
            });
          });
          if (!found) return false;
        }
      }
      else {
        return false;
      }
    }
    return true;
  });
};

export const includeRecommendedParts = (orderLines: OrderLine[], boat: Boat) => {
  boat.recommended_parts.forEach((recommendedPart) => {
    let alreadyIncluded = false;
    orderLines.forEach((orderLine) => {
      if (orderLine.id === recommendedPart.id) alreadyIncluded = true;
    });
    if (!alreadyIncluded) {
      let price = recommendedPart.price;
      let selectedVariant = recommendedPart.variants.length > 0 ? recommendedPart.variants[0] : undefined;
      recommendedPart.variants.forEach((variant) => {
        if (variant.recommended) {
          selectedVariant = variant;
          price = variant.price && variant.price !== 'None' ? variant.price : recommendedPart.price;
        }
      });

      orderLines.push({
        id: recommendedPart.id,
        part_number: recommendedPart.part_number,
        description: recommendedPart.description,
        type: recommendedPart.part_type,
        quantity: 1,
        variant: selectedVariant,
        note: '',
        price: price,
      });

      recommendedPart.must_includes.forEach(extraPart => {
        let extraPartQuantity = 1;
        let extraPartPrice = extraPart.price;
        recommendedPart.must_includes_options.forEach(option => {
          if (option.part === extraPart.id) {
            extraPartQuantity = option.minimum_quantity;
            if (option.price !== 'None') {
              extraPartPrice = option.price;
            }
          }
        });
        let alreadyIncluded = false;
        orderLines.forEach((orderLine) => {
          if (orderLine.id === extraPart.id) alreadyIncluded = true;
        });
        if (alreadyIncluded) return;
        orderLines.push({
          id: extraPart.id,
          part_number: extraPart.part_number,
          description: extraPart.description,
          type: extraPart.part_type,
          quantity: extraPartQuantity,
          variant: extraPart.variants.length > 0 ? extraPart.variants[0] : undefined,
          note: '',
          price: extraPartPrice,
        });
      });
    }
  });
  return orderLines;
};

export const includeDraftParts = (orderLines: OrderLine[], boat: Boat, draftItems: BoatOrderDraftItem[]) => {
  draftItems.forEach((item) => {
    let alreadyIncluded = false;
    orderLines.forEach((orderLine) => {
      if (orderLine.id === item.part?.id) alreadyIncluded = true;
    });
    if (!alreadyIncluded) {
      let part = null as Part|null;
      boat.available_parts.forEach(p => {
        if (p.id === item.part?.id) part = p as Part;
        else {
          p.must_includes.forEach(pp => {
            if (pp.id === item.part?.id) part = pp as Part;
          });
        }
      });
      if (!part) return;

      let price = item.price;
      let selectedVariant = undefined;
      if (item.selected_variant && part) {
        part.variants.forEach((variant) => {
          if (variant.id === item.selected_variant?.id) {
            selectedVariant = variant;
          }
        })
      }

      orderLines.push({
        id: part.id,
        part_number: part.part_number,
        description: part.description,
        type: part.part_type,
        quantity: item.quantity,
        variant: selectedVariant,
        note: item.note ? item.note : '',
        price: item.price === undefined ? part.price : item.price,
      });
    }
  });
  return orderLines;
};

const isAdditionalRuleMatches = (partRule: PartRuleAdditionalRule, selectedParts: string[], selectedVariants: string[]) => {
  let isMatches = false;
  if (partRule.match_mode == 'and') {
    isMatches = (partRule.matches.length > 0) || (partRule.variant_matches.length > 0);
    partRule.matches.forEach(part => {
      if (!selectedParts.includes(part.id)) isMatches = false;
    });
    partRule.variant_matches.forEach(variant => {
      if (!selectedVariants.includes(variant.id)) isMatches = false;
    });
  }
  else if (partRule.match_mode == 'or') {
    isMatches = false;
    partRule.matches.forEach(part => {
      if (selectedParts.includes(part.id)) isMatches = true;
    });
    partRule.variant_matches.forEach(variant => {
      if (selectedVariants.includes(variant.id)) isMatches = true;
    });
  }

  return isMatches;
}

export const applyPartRulesToOrderLines = (orderLines: OrderLine[], partRules: PartRule[]) => {
  const selectedParts = orderLines.map(orderLine => orderLine.id);
  const selectedVariants = orderLines.filter(orderLines => orderLines.variant ? true : false).map(orderLine => orderLine.variant ? orderLine.variant.id : '');
  const matchedWithAnyRule: any = {};
  const removedOrderLines: OrderLine[] = [];

  orderLines.forEach(orderLine => {
    partRules.forEach(partRule => {
      if (partRule.target.id === orderLine.id) {
        let isMatches = (partRule.matches.length > 0) || (partRule.variant_matches.length > 0) || (partRule.additional_rules.length > 0);
        if (partRule.match_mode == 'and') {
          partRule.matches.forEach(part => {
            if (!selectedParts.includes(part.id)) isMatches = false;
          });
          partRule.variant_matches.forEach(variant => {
            if (!selectedVariants.includes(variant.id)) isMatches = false;
          });
          partRule.additional_rules.forEach(additionalRule => {
            if (!isAdditionalRuleMatches(additionalRule, selectedParts, selectedVariants)) {
              isMatches = false;
            }
          });
        }
        else if (partRule.match_mode == 'or') {
          isMatches = false;
          partRule.matches.forEach(part => {
            if (selectedParts.includes(part.id)) isMatches = true;
          });
          partRule.variant_matches.forEach(variant => {
            if (selectedVariants.includes(variant.id)) isMatches = true;
          });
          partRule.additional_rules.forEach(additionalRule => {
            if (isAdditionalRuleMatches(additionalRule, selectedParts, selectedVariants)) {
              isMatches = true;
            }
          });
        }
        if (isMatches) {
          console.log('> part rule match', partRule.name)
          matchedWithAnyRule[partRule.target.id] = true;
          if (partRule.target_field === 'quantity') {
            let defaultQuantity = partRule.target_value_parsed as number;
            if (orderLine.quantity != defaultQuantity) orderLine.quantity = defaultQuantity;
          }
          if (partRule.target_field === 'price') {
            let price = partRule.target_value_parsed as string;
            orderLine.price = price;
          }
          if (partRule.target_field === 'unselectable') {
            removedOrderLines.push(orderLine);
          }
          if (partRule.target_field === 'part_number') {
            let defaultPartNumber = partRule.target_value_parsed;
            if (orderLine.part_number != defaultPartNumber) orderLine.part_number = defaultPartNumber;
          }
        }
        else if (!matchedWithAnyRule[partRule.target.id]) {
          if (partRule.target_field === 'quantity') {
            let defaultQuantity = partRule.target.quantity;
            if (orderLine.variant && partRule.target.quantity != orderLine.variant.quantity) defaultQuantity = orderLine.variant.quantity;
            if (orderLine.quantity != defaultQuantity) orderLine.quantity = defaultQuantity;
          }
          if (partRule.target_field === 'price') {
            let price = partRule.target.price;
            orderLine.price = price;
          }
          if (partRule.target_field === 'part_number') {
            let defaultPartNumber = partRule.target.part_number;
            if (orderLine.part_number != defaultPartNumber) orderLine.part_number = defaultPartNumber;
          }
        }
      }
      else {

      }
    });
  });

  return orderLines.filter((o) => {
    let retained = true;
    removedOrderLines.forEach((oo) => {
      if (oo.id === o.id) {
        retained = false;
      }
    });
    return retained;
  });
};

export const submitOrder = async (values: FormValues, orderLines: OrderLine[], boat: Boat, submitterName: string, draftId?: string|null) => {
  const api = new BaseAPI();
  let success = false;
  let orderId: number|undefined = undefined;
  const [result, response, error] = await api.post({
    boat: boat.id,
    parts: orderLines,
    note: values.note,
    customer_number: values.customer_number,
    order_type: values.order_type,
    customer_name: values.order_type === 'stock' ? '' : values.customer_name,
    shipping_name: values.shipping_name,
    address_line_1: values.address_line_1,
    address_line_2: values.address_line_2,
    city: values.city,
    state: values.state,
    country: values.country,
    zip: values.zip,
    submitter_name: submitterName,
    draft_id: draftId,
  }, 'orders/submit/');
  success = result.success;
  orderId = result.id;
  console.log('result', result)

  return orderId;
};

export const submitOrderDraft = async (values: FormValues, orderLines: OrderLine[], boat: Boat, draftId?: string|null) => {
  const api = new BaseAPI();
  let success = false;
  let orderId: number|undefined = undefined;
  const [result, response, error] = await api.post({
    id: draftId,
    boat: boat.id,
    parts: orderLines,
    note: values.note,
    customer_number: values.customer_number,
    order_type: values.order_type,
    customer_name: values.order_type === 'stock' ? '' : values.customer_name,
  }, 'order-drafts/submit/');
  success = result.success;
  orderId = result.id;
  console.log('result', result)

  return orderId;
};
