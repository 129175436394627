import React, {
  useState,
  useEffect
} from 'react';


import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TableSortLabel,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
}));

export default function SortButton(props: {
  sortKey: string;
  currentSortKey?: string;
  onSort: (sortKey: string|undefined) => void;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  children?: any;
}) {
  const classes = useStyles();
  const [sortKey, setSortKey] = useState<string|undefined>(undefined);
  
  let direction: "asc" | "desc" | undefined = undefined;
  if (sortKey === props.sortKey) direction = 'asc';
  if (sortKey === `-${props.sortKey}`) direction = 'desc';

  useEffect(() => {
    if ((props.currentSortKey !== props.sortKey) && (props.currentSortKey !== `-${props.sortKey}`)) {
      setSortKey(undefined);
    }
  }, [props.currentSortKey]);

  return (
    <TableSortLabel
      className={`${classes.root} ${props.className}`}
      style={props.style}
      active={direction !== undefined}
      direction={direction}
      disabled={props.disabled}
      onClick={() => {
        let currentSortKey = sortKey;
        if (!currentSortKey) currentSortKey = props.sortKey;
        else if (currentSortKey === props.sortKey) currentSortKey = `-${props.sortKey}`;
        else if (currentSortKey === `-${props.sortKey}`) currentSortKey = undefined;
        setSortKey(currentSortKey);
        props.onSort(currentSortKey);
      }}
    >
      {props.children}
    </TableSortLabel>
  );

}