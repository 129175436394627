import React, {
  useState,
  useEffect
} from 'react';

import MaskedInput from 'react-text-mask';

import { makeStyles } from '@material-ui/core/styles';
import {
  Input,
  InputLabel,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    
  },
});

export default function HinField(props: {
  id: string;
  label: string;
  name: string;
  value?: string;
  variant?: string;
  helperText?: string;
  placeholder?: string;
  onChange: (event: any) => void;
  readOnly?: boolean;
  error?: boolean | undefined;
  autoFocus?: boolean | undefined;
  fullWidth?: boolean | undefined;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}) {
  const classes = useStyles();
  
  return (
    <TextField
      autoFocus={props.autoFocus}
      fullWidth={props.fullWidth}
      label={props.label}
      name={props.name}
      value={props.value}
      InputProps={{
        readOnly: props.readOnly,
      }}
      onChange={(event) => {
        let serial = event?.target.value;
        if (serial) {
          serial = serial.toUpperCase().trim().replace(/-/g, '').replace(/\ /g, '');
          event.target.value = serial;
        }
        props.onChange(event)
      }}
      error={props.error}
      id={`${props.id}_input`}
      variant="outlined"
      placeholder={props.placeholder}
      helperText={props.helperText}
      onClick={props.onClick}
    />
  );
}