import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  LinearProgress,
  ListSubheader,
  ButtonGroup,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  CircularProgress,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Toolbar,
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton,
  Alert
} from '@material-ui/lab';
import PreviousIcon from '@material-ui/icons/ArrowBack';
import NextIcon from '@material-ui/icons/ArrowForward';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import { useProfile } from '../../data/Profile';
import {
  User,
  Dealer,
  Order
} from '../../types';
import SortButton from './components/SortButton';

import BoatSelectId from '../forms/fields/BoatSelectId';
import CountrySelect from '../forms/fields/CountrySelect';
import StateSelect from '../forms/fields/StateSelect';


const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  container: {
    paddingBottom: 10,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  tableNav: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paginationControl: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


interface FindOrderFormValues {
  serial_number: string;
  start_date: string;
  end_date: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  submitter_name: string;
  dealer: string|undefined;
  boat: string|undefined;
  search: string;
}

export default function OrderCollection(props: {className?: string}) {
  const classes = useStyles();

  const [filter, setFilter] = useState<FindOrderFormValues|undefined>(undefined);
  const [pageSize, setPageSize] = useState(25);
  const [allDealers, setAllDealers] = useState(((window as any)._cached && (window as any)._cached['all_dealers'] ? (window as any)._cached['all_dealers']: []) as Dealer[]);
  const [selectedDealer, setSelectedDealer] = useState(null as Dealer|null|undefined);
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [isLoading, setIsLoading] = useState(false);
  const [sortKey, setSortKey] = useState<string|undefined>(undefined);
  const [orders, setOrders] = useState([] as Order[]);
  const [ordersMeta, setOrdersMeta] = useState({
    count: 0,
    page_size: 10,
    num_pages: 0,
    page: 0,
    next: 0
  });

  const { path, url } = useRouteMatch();
  const backUrl = `${url}../`;

  const loadOrders = async (page?: number, dealer?: Dealer|null|undefined, filter?: FindOrderFormValues) => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    let url = `thin-orders/`;
    let kwargs: any = {
      page_size: pageSize,
    };
    if (page) kwargs.page = page;
    if (dealer) kwargs.dealer = dealer.id;
    if (filter?.submitter_name) kwargs.submitter_name = filter.submitter_name;
    if (filter?.boat) kwargs.boat = filter.boat;
    if (filter?.serial_number) kwargs.serial_number = filter.serial_number;
    if (filter?.start_date) kwargs.start_date = filter.start_date;
    if (filter?.end_date) kwargs.end_date = filter.end_date;
    if (filter?.search) kwargs.search = filter.search;
    if (filter?.city) kwargs.city = filter.city;
    if (filter?.state) kwargs.state = filter.state;
    if (filter?.zip) kwargs.zip = filter.zip;
    if (filter?.country) kwargs.country = filter.country;
    if (sortKey) kwargs.ordering = sortKey;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        if (page && (page > ordersMeta.page)) {
          const results = data.results as Order[];
          setOrders(results);
        }
        else {
          setOrders(data.results as Order[]);
        }

        setIsLoading(false);
        setOrdersMeta({
          count: data.count,
          page_size: data.page_size,
          num_pages: data.num_pages,
          page: data.page,
          next: data.next
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadOrders();
  }, [])

  const loadAllDealers = async () => {

    const api = new BaseAPI();
    try {
      const data = await api.get('all-dealers/');
      if (data instanceof Array) {
        setAllDealers(data as Dealer[]);

        if (!(window as any)._cached) (window as any)._cached = {} as any;
        if (!(window as any)._cached['all_dealers']) (window as any)._cached['all_dealers'] = data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (values: FindOrderFormValues) => {
    setIsLoading(true);
    setFilter({
      dealer: values.dealer,
      boat: values.boat,
      submitter_name: values.submitter_name,
      serial_number: values.serial_number,
      start_date: values.start_date,
      end_date: values.end_date,
      search: values.search,
      city: values.city,
      state: values.state,
      zip: values.zip,
      country: values.country,
    });
    console.log('values', values)
    const api = new BaseAPI();
    await loadOrders(undefined, selectedDealer, values);
    setIsLoading(false);
  }

  const onValidate = (values: FindOrderFormValues) => {
    const errors: any = {}

    return errors;
  }

  useEffect(() => {
    loadOrders(undefined, selectedDealer, Object.assign({ordering: sortKey}, filter));
  }, [sortKey, pageSize]);

  useEffect(() => {
    if (allDealers.length === 0) loadAllDealers();
  }, []);

  let paginations = Array.from({length: ordersMeta.num_pages}, (_, i) => i + 1);
  if (paginations.length > 10) {
    const starts = paginations.slice(0, 3);
    const ends = paginations.slice(paginations.length - 4, paginations.length - 1);
    let middles: number[] = [];
    if (starts.length && ends.length && ordersMeta.page > 1) {
      if (!starts.includes(ordersMeta.page - 1) && !ends.includes(ordersMeta.page - 1)) middles.push(ordersMeta.page - 1);
      if (!starts.includes(ordersMeta.page) && !ends.includes(ordersMeta.page)) middles.push(ordersMeta.page);
      if (!starts.includes(ordersMeta.page + 1) && !ends.includes(ordersMeta.page + 1)) middles.push(ordersMeta.page + 1);
    }
    const newPaginations = [...starts, ...middles, ...ends];
    paginations = newPaginations;
  }
  return (
    <div className={classes.root}>
      <Paper style={{paddingTop: 12,}}>
        <Form
          onSubmit={onSubmit}
          validate={onValidate}
          initialValues={{
            dealer: undefined,
            boat: undefined,
            submitter_name: '',
            serial_number: '',
            start_date: '',
            end_date: '',
            search: '',
            city: '',
            state: '',
            zip: '',
            country: '',
          }}
          render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
            <form onSubmit={handleSubmit} className={classes.root} style={{margin: 12, paddingBottom: 12}}>
              <Grid container>
                <Grid container spacing={3}>
                  <Field name="search">
                    {props => (
                      <Grid item xs={12}>
                        <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                          <TextField
                            id={props.input.name}
                            label={'Search'}
                            placeholder="Enter keywords to search..."
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                            helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>
                </Grid>
                <Grid container spacing={3}>
                  <Field name="dealer">
                    {props => (
                      <Grid item xs={3}>
                        <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                          <InputLabel>Dealer</InputLabel>
                          <Select
                            id={props.input.name}
                            label={'Dealer'}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={(event) => {
                              props.input.onChange(event);
                              if (!event.target.value) {
                                setSelectedDealer(undefined);
                                return;
                              }

                              allDealers.filter((dealer) => {
                                if (dealer.id == event.target.value) {
                                  setSelectedDealer(dealer);
                                }
                              })
                            }}
                            error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          >
                            <ListSubheader>Active Dealers</ListSubheader>
                              <MenuItem value="">&mdash;</MenuItem>
                            {allDealers.filter(dealer => dealer.active).map((dealer, i) => (
                              <MenuItem key={`dealer-menu-item-${i}`} value={dealer.id}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
                            ))}
                            <ListSubheader>Inactive Dealers</ListSubheader>
                            {allDealers.filter(dealer => !dealer.active).map((dealer, i) => (
                              <MenuItem key={`dealer-menu-item-inactive-${i}`} value={dealer.id}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
                            ))}
                          </Select>
                          {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                            <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                          }
                        </FormControl>
                      </Grid>
                    )}
                  </Field>

                  <Field name="boat">
                    {props => (
                      <Grid item xs={3}>
                        <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                          <InputLabel>Boat Model</InputLabel>
                          <BoatSelectId
                            id={props.input.name}
                            label={'Boat Model'}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value}
                            allowEmpty
                            emptyLabel="All Boats"
                            onChange={(event) => {
                              props.input.onChange(event);
                            }}
                            error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          />
                          {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                            <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                          }
                        </FormControl>
                      </Grid>
                    )}
                  </Field>

                  <Field name="start_date">
                    {props => (
                      <Grid item xs={3}>
                        <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                          <TextField
                            id={props.input.name}
                            label={'Start Date'}
                            variant="outlined"
                            type="date"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                            helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>

                  <Field name="end_date">
                    {props => (
                      <Grid item xs={3}>
                        <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                          <TextField
                            id={props.input.name}
                            label={'End Date'}
                            variant="outlined"
                            type="date"
                            placeholder=""
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                            helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>
                </Grid>

                <Grid container spacing={3}>
                  <Field name="city">
                    {props => (
                      <Grid item xs={3}>
                        <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                          <TextField
                            id={props.input.name}
                            label={'City'}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value.substring(0, 150)}
                            onChange={props.input.onChange}
                            error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                            helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>

                  <Field name="country">
                    {props => (
                      <Grid item xs={3}>
                        <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                          <InputLabel>Country</InputLabel>
                          <CountrySelect
                            id={props.input.name}
                            label={'Country'}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value.substring(0, 150)}
                            onChange={props.input.onChange}
                            error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          />
                          {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                            <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                          }
                        </FormControl>
                      </Grid>
                    )}
                  </Field>

                  <Field name="state">
                    {props => (
                      <Grid item xs={3}>
                        {values.country === 'United States' &&
                        <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                          <InputLabel>State</InputLabel>
                          <StateSelect
                            id={props.input.name}
                            label={'State'}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value.substring(0, 150)}
                            onChange={props.input.onChange}
                            error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          />
                          {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                            <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                          }
                        </FormControl>}
                        {values.country !== 'United States' &&
                        <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                          <TextField
                            id={props.input.name}
                            label={'State'}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value.substring(0, 150)}
                            onChange={props.input.onChange}
                            error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                            helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                          />
                        </FormControl>
                        }
                      </Grid>
                    )}
                  </Field>

                  <Field name="zip">
                    {props => (
                      <Grid item xs={3}>
                        <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                          <TextField
                            id={props.input.name}
                            label={'Zip / Postal Code'}
                            variant="outlined"
                            name={props.input.name}
                            value={props.input.value.substring(0, 150)}
                            onChange={props.input.onChange}
                            error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                            helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Field>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="small"
                      disabled={isLoading}
                    >Find</Button>
                    <Button
                      variant="contained"
                      disabled={isLoading}
                      style={{marginLeft: 12}}
                      size="small"
                      onClick={() => {
                        setSelectedDealer(undefined);
                        setFilter(undefined);
                        loadOrders(undefined, undefined);

                        form.change('dealer', undefined);
                        form.change('boat', undefined);
                        form.change('submitter_name', '');
                        form.change('serial_number', '');
                        form.change('start_date', '');
                        form.change('end_date', '');
                        form.change('search', '');
                        form.change('city', '');
                        form.change('state', '');
                        form.change('zip', '');
                        form.change('country', '');
                      }}
                    >Clear</Button>
                    {isLoading && <CircularProgress size={20} style={{marginLeft: 10}} />}
                  </Grid>
                </Grid>

                {(!pristine && (Object.keys(errors as any).length > 0)) &&
                <Grid item xs={12}>
                  <div style={{marginTop: 10, marginBottom: 10}}>
                    <Alert severity="error">Please make sure all required fields are correctly filled!</Alert>
                  </div>
                </Grid>}
              </Grid>

            </form>
          )}
        />
      </Paper>
      <TableContainer component={Paper} className={classes.container}>
        <Table size="small">
          <TableHead>
            {isLoading && 
            <TableRow>
              <TableCell colSpan={6} style={{padding: 0}}>
                <LinearProgress id="users-list-loading" style={{width: '100%'}} />
              </TableCell>
            </TableRow>}
            <TableRow>
              <TableCell>
                <SortButton
                  sortKey='id'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                >Order #</SortButton>
              </TableCell>
              <TableCell align="right">
                Additional Notes
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey='boat__name'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >Boat</SortButton>
              </TableCell>
              <TableCell align="right">
                Engine
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey='dealer__customer_name'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >Dealer</SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey='created_at'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >Date Submitted</SortButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, i) => {
              return (
                <OrderRow key={`order-item-${order.id}`} order={order} />
              );
            })}
            {(orders.length === 0) &&
              <TableRow>
                <TableCell colSpan={6}>{isLoading ? 'Loading...' : 'No data yet.'}</TableCell>
              </TableRow>
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={1}>
                {isLoading && <CircularProgress size={20} />}
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={5}
                count={ordersMeta.count}
                rowsPerPage={ordersMeta.page_size}
                page={ordersMeta.page-1}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={(event: any, newPage: number) => {
                  if (!isLoading) loadOrders(newPage + 1, selectedDealer, filter);
                }}
                onChangeRowsPerPage={(event: any) => {
                  if (!isLoading) setPageSize(parseInt(event.target.value, 10));
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

function OrderRow(props: {
  order: Order;
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null|HTMLButtonElement>(null);
  const [showCreateClaimForm, setShowCreateClaimForm] = useState(false);
  const [redirect, setRedirect] = useState('');
  const order = props.order;
  let engine = '';
  order.items.forEach((item) => {
    if (item?.part?.part_type == 'boat')
    engine = `${item?.part?.description ? item?.part?.description : ''} ${engine}`
  });
  return (
    <TableRow>
      <TableCell>
        <Button component={RouterLink} to={`${appConfig.homepage}orders/${order.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>#{order.id}</Button>
      </TableCell>
      <TableCell align="right">
        <Button component={RouterLink} to={`${appConfig.homepage}orders/${order.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>{order.notes.length > 0 ? order.notes.length : ''}</Button>
      </TableCell>
      <TableCell align="right">
        <Button component={RouterLink} to={`${appConfig.homepage}orders/${order.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>{order.boat_name}</Button>
      </TableCell>
      <TableCell align="right">
        <Button component={RouterLink} to={`${appConfig.homepage}orders/${order.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>{engine}</Button>
      </TableCell>
      <TableCell align="right">
        <Button component={RouterLink} to={`${appConfig.homepage}orders/${order.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>{order.dealer_name}</Button>
      </TableCell>
      <TableCell align="right">
        <Button component={RouterLink} to={`${appConfig.homepage}orders/${order.id}/`} size="medium" style={{textTransform: 'none', padding: 0}}>{order.created_at ? moment(order.created_at).format('MMMM Do YYYY') : ''}</Button>
      </TableCell>
      
    </TableRow>
  );
}


function TablePaginationActions(props: {
  count: number;
  onChangePage?: (event: any, newPage: number) => void;
  page: number;
  rowsPerPage: number;
}) {
  const classes = useStyles();
  const { count, page, rowsPerPage } = props;

  const onChangePage = (event: any, newPage: number) => {
    if (props.onChangePage) props.onChangePage(event, newPage);
  }

  let numPages = Math.ceil(props.count / props.rowsPerPage);
  let paginations = Array.from({length: numPages}, (_, i) => i + 1);
  if (paginations.length > 8) {
    let page = props.page + 1;
    const starts = paginations.slice(0, 3);
    const ends = paginations.slice(paginations.length - 3, paginations.length );
    let middles: number[] = [];
    if (starts.length && ends.length && page > 1) {
      if (!starts.includes(page - 1) && !ends.includes(page - 1)) middles.push(page - 1);
      if (!starts.includes(page) && !ends.includes(page)) middles.push(page);
      if (((page + 1) < numPages) && !starts.includes(page + 1) && !ends.includes(page + 1)) middles.push(page + 1);
    }
    const newPaginations = [...starts, ...middles, ...ends];
    paginations = newPaginations;
  }

  return (
    <div className={classes.paginationControl}>
      <IconButton
        onClick={(event) => {
          onChangePage(event, 0);
        }}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={(event) => {
          onChangePage(event, page - 1);
        }}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {paginations.map(i => (
        <Button
          key={`pagination-button-${i}`}
          disabled={(i - 1) === (props.page)}
          onClick={() => {
            onChangePage(undefined, i - 1);
          }}
        >{i}</Button>
      ))}
      <IconButton
        onClick={(event) => {
          onChangePage(event, page + 1);
        }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={(event) => {
          onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}
