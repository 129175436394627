import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  Tooltip,
  Chip,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';

import {
  APIError,
  BaseAPI,
} from '../../../data/BaseAPI';

import {
  BoatEditor,
  BoatEditorPartGroup,
  BoatEditorPart,
  BoatEditorPartVariant,
  BoatEditorPartOption,
  Session,
  PartPicture,
} from '../../../types';

import PartEditorElement from './PartEditorElement';

const useStyles = makeStyles({
  root: {

  },
  groupContainer: {
    padding: 12,
    marginBottom: 24
  },
  fieldContainer: {
    marginBottom: 20,
    '& .MuiInputBase-root ': {
      backgroundColor: '#fff'
    }
  },
});

export default function GroupEditorElement(props: {
  group: BoatEditorPartGroup;
  parentGroup?: BoatEditorPartGroup;
  groups: BoatEditorPartGroup[];
  boat: BoatEditor;
  parts: BoatEditorPart[];
  linkedParts: BoatEditorPart[];
  partVariants: BoatEditorPartVariant[];
  disabled?: boolean;
  onPartUpdated: (part: BoatEditorPart) => void;
  onLinkedPartUpdated: (part: BoatEditorPart) => void;
  onPartVariantsUpdated: (part: BoatEditorPartVariant[]) => void;
  onPartRemoved: (part: BoatEditorPart) => void;
  onLinkedPartRemoved: (part: BoatEditorPart) => void;
  onPartVariantRemoved: (part: BoatEditorPartVariant) => void;
  onBoatUpdated: (part: BoatEditor) => void;
  onPartPictureRemoved: (picture: PartPicture) => void;
}) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [showAddPartNumberForm, setShowAddPartNumberForm] = useState(false);
  const [addPartNumber, setAddPartNumber] = useState('');
  const [addPartNumberType, setAddPartNumberType] = useState('option');
  const [addPartNumberError, setAddPartNumberError] = useState('');

  const [showAddPartForm, setShowAddPartForm] = useState(false);
  const [addPartError, setAddPartError] = useState('');
  const [addPart, setAddPart] = useState<BoatEditorPart|undefined>(undefined);
  const [addPartVariants, setAddPartVariants] = useState<BoatEditorPartVariant[]>([]);

  const group = props.group;
  const boat = props.boat;
  const partVariants = props.partVariants;

  const getPart = async (partNumber: string): Promise<BoatEditorPart|null|undefined> => {
    if (isLoading) return;
    if (parts.filter(p => p.part_number.toUpperCase() === partNumber).length > 0) {
      setAddPartNumberError(`This part number is already added to this boat.`);
      return;
    }

    const api = new BaseAPI();
    setIsLoading(true);
    let url = `boats/editor/parts/`;
    let kwargs: any = {
      page_size: 20,
      part_number: partNumber
    };

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        const results = data.results as BoatEditorPart[];
        setIsLoading(false);
        if (results.length > 0) {
          let part = results[0];
          loadAddPartVariants(part.id);
          return part;
        }
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
    let groupIds = [];
    if (group.id > 0) groupIds.push(group.id);
    return {
      "id": 0,
      "local_id": Date.now(),
      "part_number": partNumber,
      "fb_part_number": "",
      "description": "",
      "csv_description": "",
      "price": "",
      "price_msrp": "",
      "price_msrp_deal": "",
      "quantity": 1,
      "boats": [
          boat.id,
      ],
      "part_type": addPartNumberType,
      "must_includes": [],
      "must_includes_options": [],
      "can_includes": [],
      "must_excludes": [],
      "unselectable_unless": [],
      "hidden_parts": [],
      "groups": groupIds,
      "extra_information": "",
      "variants": [],
      "order": 0,
      "csv_order": null,
      "variants_ordering": "price_low_high",
      "override_group_behavior": "",
      "active": true,
      "admin_only": false,
      "pictures": [],
    }
  }

  const loadAddPartVariants = async (partId: number, page?: number) => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    let url = `boats/editor/part-variants/`;
    let kwargs: any = {
      page_size: 100,
      part: partId,
    };
    if (page) kwargs.page = page;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        if (page && (page > 0)) {
          const results = data.results as BoatEditorPartVariant[];
          setAddPartVariants(results);
        }
        else {
          setAddPartVariants(data.results as BoatEditorPartVariant[]);
        }

        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const onAddPart = async (partNumber: string) => {
    let part = await getPart(partNumber);
    if (part) {
      setAddPart(part);
      setShowAddPartNumberForm(false);
      setShowAddPartForm(true);
      console.log('part', part);
    }
  }


  const updateAddPartVariant = (newPartVariant: BoatEditorPartVariant) => {
    let added = false;
    let newPartVariants = addPartVariants.map(partVariant => {
      if (newPartVariant.local_id && (newPartVariant.local_id === partVariant.local_id)) {
        added = true;
        return newPartVariant;
      }
      else if (newPartVariant.id && (newPartVariant.id === partVariant.id)) {
        added = true;
        return newPartVariant;
      }
      return partVariant;
    });
    if (!added) {
      newPartVariants.push(newPartVariant);
    }
    setAddPartVariants(newPartVariants);
  }

  const updateAddPartVariants = (newPartVariants: BoatEditorPartVariant[]) => {
    let addedList: BoatEditorPartVariant[] = [];
    let newList = addPartVariants.map(partVariant => {
      let matchedPartVariants = newPartVariants.filter(newPartVariant => {
        if (newPartVariant.local_id && (newPartVariant.local_id === partVariant.local_id)) {
          return true;
        }
        else if (newPartVariant.id && (newPartVariant.id === partVariant.id)) {
          return true;
        }
        return false;
      });
      if (matchedPartVariants.length > 0) {
        addedList.push(matchedPartVariants[0]);
        return matchedPartVariants[0];
      }
      return partVariant;
    });

    newPartVariants.forEach(newPartVariant => {
      if (!addedList.includes(newPartVariant)) newList.push(newPartVariant);
    });
    setAddPartVariants(newList);
  }

  const removeAddPartVariant = (newPartVariant: BoatEditorPartVariant) => {
    let newPartVariants = addPartVariants.filter(partVariant => {
      if (newPartVariant.local_id && (newPartVariant.local_id === partVariant.local_id)) {
        return false;
      }
      else if (newPartVariant.id && (newPartVariant.id === partVariant.id)) {
        return false;
      }
      return true;
    }).map(partVariant => partVariant);
    setAddPartVariants(newPartVariants);
  }


  const parts = React.useMemo(() => props.parts
    .filter((part) => {
      if (group.id > 0)
        return part.groups.includes(group.id);
      return part.groups.length === 0;
    })
    .sort((a, b) => {
      let priceA = a.price ? a.price : '0';
      let priceB = b.price ? b.price : '0';

      let nameA = a.description ? a.description : '0';
      let nameB = b.description ? b.description : '0';

      let orderA = a.order ? a.order : '0';
      let orderB = b.order ? b.order : '0';

      if (group.items_ordering === 'price_low_high') {
        if (priceA > priceB) return 1;
        if (priceB > priceA) return -1;
        return 0;
      }

      if (group.items_ordering === 'price_high_low') {
        if (priceA > priceB) return -1;
        if (priceB > priceA) return 1;
        return 0;
      }

      if (group.items_ordering === 'name_asc') {
        if (nameA > nameB) return 1;
        if (nameB > nameA) return -1;
        return 0;
      }

      if (group.items_ordering === 'name_desc') {
        if (nameA > nameB) return -1;
        if (nameB > nameA) return 1;
        return 0;
      }

      if (group.items_ordering === 'custom') {
        if (orderA > orderB) return 1;
        if (orderB > orderA) return -1;
        return 0;
      }

      if (priceA > priceB) return 1;
      if (priceB > priceA) return -1;
      return 0;
    }), [props.parts, group.items_ordering]);
  

  return (
    <>
      <Paper className={classes.groupContainer}>
        <div style={{marginBottom: (parts.length > 0) ? 12 : 0,}}>
          <Typography variant="h6">
            {group.name}
            {(group.group_behavior === 'exclusive') &&
              <Tooltip title="Customers can only select one part in this group. When the customer select another part in the same group, the previously selected part will be unselected automatically.">
                <Chip
                  label="Exclusive"
                  clickable
                  size="small"
                  color="primary"
                  variant="outlined"
                  deleteIcon={<HelpOutlineIcon />}
                  onDelete={() => {

                  }}
                  style={{marginLeft: 10}}
                />
              </Tooltip>
            }
            {(group.required) &&
              <Tooltip title="Customers must select at least one part in this group.">
                <Chip
                  label="Required"
                  clickable
                  size="small"
                  color="primary"
                  variant="outlined"
                  deleteIcon={<HelpOutlineIcon />}
                  onDelete={() => {

                  }}
                  style={{marginLeft: 10}}
                />
              </Tooltip>
            }
            {props.parentGroup && <span style={{fontSize: 12, color: '#888', marginLeft: 12}}>subgroup of {props.parentGroup.name}</span>}
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<AddIcon />}
              style={{float: 'right'}}
              disabled={props.disabled}
              onClick={() => {
                setAddPartNumber('');
                setAddPartNumberType((group.part_type === 'engine') ? 'boat': 'option');
                setAddPartNumberError('');
                setShowAddPartNumberForm(true);
              }}
            >Add Part</Button>
          </Typography>
        </div>

        <div>
          {parts.map((part, i) => {
            return (
              <PartEditorElement
                key={`part-${group.id}-${part.id}-${i}`}
                part={part}
                group={group}
                groups={props.groups}
                parts={parts}
                linkedParts={props.linkedParts}
                partVariants={partVariants}
                boat={boat}
                disabled={props.disabled}
                isEngine={group.part_type === 'engine'}
                onPartUpdated={props.onPartUpdated}
                onLinkedPartUpdated={props.onLinkedPartUpdated}
                onPartVariantsUpdated={props.onPartVariantsUpdated}
                onPartRemoved={props.onPartRemoved}
                onLinkedPartRemoved={props.onLinkedPartRemoved}
                onPartVariantRemoved={props.onPartVariantRemoved}
                onBoatUpdated={props.onBoatUpdated}
                onPartPictureRemoved={props.onPartPictureRemoved}
              />
            );
          })}

          {(parts.length > 0) &&
          <div style={{height: 56, paddingTop: 24}}>
            <Button
              variant="contained"
              size="small"
              style={{float: 'right'}}
              onClick={() => {
                setAddPartNumber('');
                setAddPartNumberType((group.part_type === 'engine') ? 'boat': 'option');
                setAddPartNumberError('');
                setShowAddPartNumberForm(true);
              }}
              color="primary"
              startIcon={<AddIcon />}
            >Add Part</Button>
          </div>}
        </div>
      </Paper>

      {group.children.map(childGroup => {
        return (
          <GroupEditorElement
            key={`group-${childGroup.id}`}
            group={childGroup}
            groups={props.groups}
            parentGroup={group}
            boat={boat}
            parts={props.parts}
            linkedParts={props.linkedParts}
            partVariants={partVariants}
            disabled={props.disabled}
            onPartUpdated={props.onPartUpdated}
            onLinkedPartUpdated={props.onLinkedPartUpdated}
            onPartVariantsUpdated={props.onPartVariantsUpdated}
            onPartRemoved={props.onPartRemoved}
            onLinkedPartRemoved={props.onLinkedPartRemoved}
            onPartVariantRemoved={props.onPartVariantRemoved}
            onBoatUpdated={props.onBoatUpdated}
            onPartPictureRemoved={props.onPartPictureRemoved}
          />
        );
      })}

      <Dialog
        open={showAddPartNumberForm}
        maxWidth='xs'
        fullWidth
        onClose={() => {
          setShowAddPartNumberForm(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Add New Part</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onAddPart(addPartNumber);
            }}
          >
            <FormControl fullWidth error={!!addPartNumberError} className={classes.fieldContainer}>
              <TextField
                id="part_number"
                label={'Part Number'}
                variant="outlined"
                name="part_number"
                value={addPartNumber}
                disabled={isLoading}
                required
                autoFocus
                size="small"
                onChange={(e) => {
                  setAddPartNumber(e.target.value.trim().toUpperCase());
                }}
                error={!!addPartNumberError}
                helperText={addPartNumberError}
              />
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >Add Part</Button>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showAddPartForm}
        maxWidth='xl'
        fullWidth
        onClose={() => {
          setShowAddPartForm(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Add New Part</DialogTitle>
        <DialogContent>
          {addPart && <PartEditorElement
            part={addPart}
            group={group}
            groups={props.groups}
            parts={parts}
            linkedParts={props.linkedParts}
            partVariants={addPartVariants}
            boat={boat}
            disabled={props.disabled}
            isEngine={group.part_type === 'engine'}
            onPartUpdated={(part) => setAddPart(part)}
            onLinkedPartUpdated={props.onLinkedPartUpdated}
            onPartVariantsUpdated={(partVariants) => {
              updateAddPartVariants(partVariants);
            }}
            onPartRemoved={(part) => setShowAddPartForm(false)}
            onLinkedPartRemoved={props.onLinkedPartRemoved}
            onPartVariantRemoved={(partVariant) => {
              removeAddPartVariant(partVariant);
            }}
            onBoatUpdated={(boat) => {

            }}
            onPartPictureRemoved={(picture) => props.onPartPictureRemoved(picture)}
            expanded
          />}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={() => {
              if (addPart) {
                props.onPartUpdated(addPart);
                props.onPartVariantsUpdated(addPartVariants);
                setShowAddPartForm(false);
              }
            }}
          >Add Part</Button>
          <Button
            variant="contained"
            onClick={() => {
              setShowAddPartForm(false);
            }}
          >Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
