import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";


import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Divider,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListSubheader,
  CircularProgress,
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton
} from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SendIcon from '@material-ui/icons/Send';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import localization from '../../utils/localizations';
import {
  SessionContext
} from '../../data/Session';

import {
  BaseAPI,
} from '../../data/BaseAPI';

import {
  useProfile
} from '../../data/Profile';

import DealerSelect from '../forms/fields/DealerSelect';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  filterForm: {
    marginBottom: 24,
    padding: 12,
  },
  reportContent: {
  },
  fieldContainer: {
  },
  table: {

  }
}));

interface ReportRow {
  flat_rate_code: string;
  labor_category: string;
  job_description: string;
  quantity: number;
}

function FRCReport() {
  const classes = useStyles();
  const { path, url } = useRouteMatch();
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<ReportFilterFormValues>({
    start_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    dealer: undefined
  });
  const [reportItems, setReportItems] = useState<ReportRow[]>([]);
  
  const loadReport = async (filter: ReportFilterFormValues) => {
    if (isLoading) return;

    const api = new BaseAPI();
    api.apiEndpoint = '/warranty/api/v1/'
    setIsLoading(true);
    let url = 'reports/frc/';
    let kwargs: any = {};
    if (filter.dealer) kwargs.dealer = filter.dealer;
    if (filter.start_date) kwargs.start_date = filter.start_date;
    if (filter.end_date) kwargs.end_date = filter.end_date;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        setReportItems(data.results as ReportRow[]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };


  const downloadReport = async (filter: ReportFilterFormValues) => {
    let url = '/warranty/api/v1/reports/frc/';
    let kwargs: any = {
      format: 'csv'
    };
    if (filter.dealer) kwargs.dealer = filter.dealer;
    if (filter.start_date) kwargs.start_date = filter.start_date;
    if (filter.end_date) kwargs.end_date = filter.end_date;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;
    window.open(url, '_blank');
  }

  useEffect(() => {
    loadReport(filter);
  }, [filter.dealer, filter.start_date, filter.end_date]);

  if (profile.role !== 'admin') return (<div>Permission Denied</div>);

  return (
    <div className={classes.root}>
      <ReportFilterForm
        isLoading={isLoading}
        onSubmit={(values => setFilter(values))}
        onDownload={(values => {
          downloadReport(values);
        })}
      />

      <TableContainer component={Paper} className={classes.reportContent}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Flat Rate Code</TableCell>
              <TableCell>Job Description</TableCell>
              <TableCell>Labor Category</TableCell>
              <TableCell align="right">Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportItems.map((item, i) => (
            <TableRow key={`item-${i}`}>
              <TableCell component="th" scope="row">{item.flat_rate_code}</TableCell>
              <TableCell>{item.job_description}</TableCell>
              <TableCell>{item.labor_category}</TableCell>
              <TableCell align="right">{item.quantity}</TableCell>
            </TableRow>
            ))}
            {(reportItems.length === 0) && (
            <TableRow>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

interface ReportFilterFormValues {
  start_date: string;
  end_date: string;
  dealer?: string;
}

function ReportFilterForm(props: {
  isLoading: boolean;
  onSubmit: (values: ReportFilterFormValues) => void;
  onDownload: (values: ReportFilterFormValues) => void;
}) {
  const classes = useStyles();


  const onSubmit = async (values: ReportFilterFormValues) => {
    props.onSubmit(values);
  }

  const onValidate = (values: ReportFilterFormValues) => {
    const errors: any = {}

    if (!values.start_date) errors.start_date = 'Required';
    if (!values.end_date) errors.start_date = 'Required';

    return errors;
  }

  const isLoading = props.isLoading;
  return (
    <Paper className={classes.filterForm}>
      <Form
        onSubmit={onSubmit}
        validate={onValidate}
        initialValues={{
          start_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          dealer: undefined
        }}
        render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
          <form onSubmit={handleSubmit} className={classes.root}>
            <Grid container spacing={3}>
              <Field name="dealer">
                {props => (
                  <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Dealer</InputLabel>
                      <DealerSelect
                        id={props.input.name}
                        label={'Dealer'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        allowEmpty
                        emptyLabel="All Dealers"
                        onChange={(event) => {
                          props.input.onChange(event);
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      />
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="start_date">
                {props => (
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Start Date'}
                        variant="outlined"
                        type="date"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="end_date">
                {props => (
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'End Date'}
                        variant="outlined"
                        type="date"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{marginRight: 12}}
                  onClick={(e) => {
                    e.preventDefault();
                    form.change('start_date', moment().subtract(7, 'days').format('YYYY-MM-DD'));
                    form.change('end_date', moment().format('YYYY-MM-DD'));
                  }}
                >1 Week</Button>
                <Button
                  variant="outlined"
                  size="small"
                  style={{marginRight: 12}}
                  onClick={(e) => {
                    e.preventDefault();
                    form.change('start_date', moment().subtract(1, 'month').format('YYYY-MM-DD'));
                    form.change('end_date', moment().format('YYYY-MM-DD'));
                  }}
                >1 Month</Button>
                <Button
                  variant="outlined"
                  size="small"
                  style={{marginRight: 12}}
                  onClick={(e) => {
                    e.preventDefault();
                    form.change('start_date', moment().subtract(2, 'months').format('YYYY-MM-DD'));
                    form.change('end_date', moment().format('YYYY-MM-DD'));
                  }}
                >2 Months</Button>
                <Button
                  variant="outlined"
                  size="small"
                  style={{marginRight: 12}}
                  onClick={(e) => {
                    e.preventDefault();
                    form.change('start_date', moment().subtract(3, 'months').format('YYYY-MM-DD'));
                    form.change('end_date', moment().format('YYYY-MM-DD'));
                  }}
                >3 Months</Button>
                <Button
                  variant="outlined"
                  size="small"
                  style={{marginRight: 12}}
                  onClick={(e) => {
                    e.preventDefault();
                    form.change('start_date', moment().subtract(4, 'months').format('YYYY-MM-DD'));
                    form.change('end_date', moment().format('YYYY-MM-DD'));
                  }}
                >4 Months</Button>
                <Button
                  variant="outlined"
                  size="small"
                  style={{marginRight: 12}}
                  onClick={(e) => {
                    e.preventDefault();
                    form.change('start_date', moment().subtract(6, 'months').format('YYYY-MM-DD'));
                    form.change('end_date', moment().format('YYYY-MM-DD'));
                  }}
                >6 Months</Button>
                <Button
                  variant="outlined"
                  size="small"
                  style={{marginRight: 12}}
                  onClick={(e) => {
                    e.preventDefault();
                    form.change('start_date', moment().subtract(1, 'year').format('YYYY-MM-DD'));
                    form.change('end_date', moment().format('YYYY-MM-DD'));
                  }}
                >1 Year</Button>
              </Grid>
              <Grid item xs={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
                {isLoading && <CircularProgress size={20} />}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SendIcon />}
                  type="submit"
                  disabled={isLoading}
                  size="small"
                  style={{marginLeft: 12}} 
                >Apply</Button>
                <Button
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  disabled={isLoading}
                  size="small"
                  style={{marginLeft: 12}}
                  onClick={() => {
                    props.onDownload(values);
                  }}
                >
                  Download
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Paper>
  );
}

export {
  FRCReport,
}