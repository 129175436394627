import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Link,
  Paper,
  ListSubheader,
  Collapse,
} from '@material-ui/core';
import {
  Alert
} from '@material-ui/lab';

import {
  User,
  Boat,
} from '../../types';

import localization from '../../utils/localizations';
import PartsListForm from '../forms/PartsListForm';
import StandardItemsListForm from '../forms/StandardItemsListForm';


const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {
  },
  header: {
    paddingLeft: 8,
    paddingTop: 8,
    lineHeight: '24px'
  },
  adminButtons: {
    paddingTop: 8,
    paddingBottom: 8,

    '& button': {
      margin: 8,
    },

    '& a': {
      margin: 8,
    }
  }
}));
  

function AdminTools(props: {
  boat: Boat;
  className?: string;
  onBoatUpdated: (boat: Boat) => void;
  onBoatsUpdated: (boats: Boat[]) => void;
  showPartNumber: boolean;
  onShowPartNumber: (show: boolean) => void;
}) {
  const user: User|undefined = (window as any)?._cache?.session?.user;
  const classes = useStyles();
  const [showAdminButtons, setShowAdminButtons] = useState(true);
  const [showStandardListForm, setShowStandardListForm] = useState(false);
  const [showPartListForm, setShowPartListForm] = useState(false);

  if (user?.role !== 'admin') return (<></>);

  let removeFromRecommendedParts = props.boat.recommended_parts.filter((part) => part.admin_only);

  return (
    <Paper className={`${classes.root} ${props.className}`}>
      <ListSubheader className={classes.header}>Admin Only</ListSubheader>
      <Collapse in={showAdminButtons}>
        <div className={classes.adminButtons}>
          <Button
            variant="contained"
            href={`/boats/${props.boat.id}/`}
            target="_blank"
          >Edit Boat Data</Button>
          <Button
            variant="contained"
            onClick={() => {
              props.onShowPartNumber(!props.showPartNumber);
            }}
          >{props.showPartNumber ? 'Hide Part Numbers' : 'Display Part Numbers'}</Button>
        </div>
        
        {(removeFromRecommendedParts.length > 0) &&
        <Alert severity="error" style={{margin: 10}}>
          <span>Please remove the following parts from the recommended parts list: </span>
          <ul style={{listStyle: 'circle', paddingLeft: 16, paddingTop: 6}}>
          {removeFromRecommendedParts.map((part, i) => (
            <li key={`pr-${i}`}><span style={{fontWeight: 600}}>{part.part_number}</span> &mdash; {part.description}</li>
          ))}
          </ul>
        </Alert>}
        
      </Collapse>
      <Collapse in={showPartListForm}>
        <PartsListForm
          boat={props.boat}
          onClose={() => {
            setShowPartListForm(false);
            setShowAdminButtons(true);
          }}
          onBoatUpdated={(boat) => props.onBoatUpdated(boat)}
          onBoatsUpdated={(boats) => props.onBoatsUpdated(boats)}
        />
      </Collapse>
      <Collapse in={showStandardListForm}>
        <StandardItemsListForm
          boat={props.boat}
          onClose={() => {
            setShowStandardListForm(false);
            setShowAdminButtons(true);
          }}
          onBoatUpdated={(boat) => props.onBoatUpdated(boat)}
          onBoatsUpdated={(boats) => props.onBoatsUpdated(boats)}
        />
      </Collapse>
    </Paper>
  );
}

export default AdminTools;