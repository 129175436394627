import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  CircularProgress,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton
} from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import localization from '../utils/localizations';
import {
  Header
} from '../views/panels/Header';

import {
  SessionContext
} from '../data/Session';

import {
  BaseAPI,
} from '../data/BaseAPI';

import { useProfile } from '../data/Profile';

import {
  OrderDraft,
  User,
  DealerPermission
} from '../types';

import OrderList from '../views/collections/OrderList';
import DateTimeView from '../views/singles/DateTimeView';
import OrderDraftDetail from '../views/singles/OrderDraftDetail';
import OrderDraftCollection from '../views/collections/OrderDraftCollection';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    position: 'relative',
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    marginBottom: 10,
    padding: 10
  },
  marginVertical: {
    marginTop: 20,
    marginBottom: 20,
  },
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function LoadingView() {
  return (
    <div style={{position: 'relative', padding: 10}}>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
    </div>
  );
}


export default function OrderDraftsPage() {
  const classes = useStyles();
  
  const [open, setOpen] = useState(false);
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [navigateBack, setNavigateBack] = useState(false);

  const [showEditOrderForm, setShowEditOrderForm] = useState(false);
  const [editOrder, setEditOrder] = useState<OrderDraft|null>(null);
  const [editOrderCounter, setEditOrderCounter] = useState(0);


  const { path, url } = useRouteMatch();
  const backUrl = `/`;

  useEffect(() => {
    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    },300);

  }, []);

  const goBack = () => {
    setOpen(false);
    setTimeout(() => {
      setNavigateBack(true);
    }, 300);
  };

  const canAccessBoatOrderingPortal = (permissions?: DealerPermission[], role?: string) => {
    let hasPermission = false;
    if (role === 'admin') hasPermission = true;
    if (permissions) {
      permissions.forEach(permission => {
        if (permission.admin || permission.order) hasPermission = true;
      });
    }
    return hasPermission;
  }

  if (!canAccessBoatOrderingPortal(profile.permissions, profile.role)) {
    return (<>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={goBack}
      >
        <Typography variant="h2" style={{marginLeft: 14, marginTop: 14}}>Loading...</Typography>
        <div style={{height: 60}}></div>
      </Drawer>
      {navigateBack && <Redirect to={backUrl} />}
    </>);
  }

  return (
    <SessionContext.Consumer>
      {({session}) => (
        <>
          <Header
            session={session}
            title={profile.role === 'admin' ? 'All Quotes' : 'Your Quotes'}
            breadcrumbs={[
              {
                title: "Orders",
                link: `${appConfig.homepage}orders/`
              },
              {
                title: "Quotes",
                link: `${appConfig.homepage}orders/quotes/`
              }
            ]}
          />
          <div className={classes.root}>
            <Paper className={classes.toolbar}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  component={RouterLink}
                  to={`${appConfig.homepage}order-form/`}
                >New Order</Button>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`${appConfig.homepage}orders/`}
                  style={{marginLeft: 12}}
                >Orders</Button>
              </div>
            </Paper>

            <OrderDraftCollection key={`order-collections-${editOrderCounter}`} />
          </div>


          <Switch>
            <Route exact path={`${path}:orderId/`}>
              <OrderDetailPanel
                onUpdated={(order) => {
                  setEditOrderCounter(editOrderCounter+1);
                }}
                onDeleted={(order) => {
                  setEditOrderCounter(editOrderCounter+1);
                }}
              />
            </Route>
          </Switch>

        </>
      )}
    </SessionContext.Consumer>
  );
}


function OrderDetailPanel(props: {
  onUpdated: (order: OrderDraft) => void;
  onDeleted: (order: OrderDraft) => void;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [order, setOrder] = useState(undefined as OrderDraft|undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { path, url } = useRouteMatch();
  const { orderId } = useParams() as any;
  const backUrl = `/orders/quotes/`;

  const loadOrder = async (id: number) => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`order-drafts/${id}/`);
      if ((data as any).id) {
        setOrder(data as OrderDraft);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadOrder(orderId);

    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    }, 300);

  }, [orderId]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
        aria-labelledby="selected-order-title"
        aria-describedby="selected-order-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.dialogTitle}>
              Quote #{orderId}
            </Typography>
            {!!order && <Button
              component={RouterLink}
              to={`${appConfig.homepage}order-form/${order.boat_id}/?quote=${order.id}`}
              size="medium"
              style={{color: '#ffffff'}}
            >Edit</Button>}
            <IconButton edge="start" color="inherit" onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setNavigateBack(true);
              }, 300);
            }} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText id="selected-order-description">
            {!!order &&
              <OrderDraftDetail
                order={order}
                onChanged={(order) => {
                  loadOrder(orderId);
                  props.onUpdated(order);
                }}
                onDeleted={(order) => {
                  setOpen(false);
                  props.onDeleted(order);
                  setTimeout(() => {
                    setNavigateBack(true);
                  }, 300);
                }}
              />}
            {!order &&
              <div>
                <LoadingView />
              </div>
            }
          </DialogContentText>
        </DialogContent>
        </>
      </Dialog>

      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}
