import React, {
  useState,
  useEffect,
  useRef,
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';

import {
  APIError,
  BaseAPI,
} from '../../data/BaseAPI';

import {
  useProfile
} from '../../data/Profile';

import {
  User,
  Session,
  Order,
  OrderNote,
} from '../../types';

import localization from '../../utils/localizations';

import CountrySelect from './fields/CountrySelect';
import StateSelect from './fields/StateSelect';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles({
  root: {
    margin: 10,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  closeButton: {
    padding: 4,
  },
  downloadButton: {
    marginRight: 6,
  },
  lineItem: {
    minWidth: '90%',
    marginBottom: 12,
  },
  lineItemTitle: {
    fontSize: 14
  }
});


interface OrderNoteFormValues {
  content: string;
  admin_only: boolean;
}

export default function OrderNoteForm(props: {
  orderId: string;
  note: OrderNote|null|undefined;
  onSave: () => void;
  onCancel: () => void;
  onDelete?: () => void;
  className?: string;
  showAdminOnlyOption?: boolean;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  
  const deleteNote = async (orderId: string, noteId: string) => {
    if (!window.confirm('Delete this comment?')) return;
    const api = new BaseAPI();
    try {
      const [result, response, error] = await api.post({
        note: noteId,
      }, `orders/${orderId}/delete_note/`);
      if (result.success) {
        if (props.onDelete) props.onDelete();
      }
      else {
        return result;
      }
    }
    catch (e) {
      console.log(e);
      if ((e as APIError).errorData) {
        return (e as APIError).errorData;
      }
    }
  }

  const onSubmit = async (values: OrderNoteFormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();
    const orderId = props.orderId;
    let note = {
      content: values.content,
      admin_only: values.admin_only,
    }
    if (props.note) {
      note = Object.assign(props.note, {
        content: values.content,
        admin_only: values.admin_only,
      });
    }

    console.log(note);
    let url = `orders/${orderId}/add_note/`;
    if (props.note?.id) url = `orders/${orderId}/edit_note/`;
    try {
      const [result, response, error] = await api.post(note, url);
      console.log('result', result);
      if (result.success) {
        props.onSave();
      }
    } catch (e) {
      console.log(e);
      setShowErrorSnackBar(true);
      if ((e as APIError).errorData) {
        setIsSaving(false);
        return (e as APIError).errorData;
      }
    }

    setIsSaving(false);
  }

  const onValidate = (values: OrderNoteFormValues) => {
    const errors: any = {}

    if (!values.content) errors.content = "Required";
    
    return errors;
  }

  let note = props.note;
  let showAdminOnlyOption = props.showAdminOnlyOption;
  return (
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        content: note ? note.content : '',
        admin_only: note ? note.admin_only : false,
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid container spacing={1}>

            <Field name="content">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Content'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      multiline
                      rows={4}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            {showAdminOnlyOption &&
            <Field name="admin_only">
              {props => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={props.input.value} onChange={props.input.onChange} name={props.input.name} />}
                    label="only show this note to admins"
                  />
                </Grid>
              )}
            </Field>}

            <Grid item xs={12}>
              <Button 
                type="submit"
                disabled={isSaving}
                color="primary"
                variant="contained"
              >
                {props.note?.id ? "Update" : "Add"}
              </Button>
              {(!!props.note?.id && props.onDelete) &&
              <Button
                onClick={() => {
                  if (props.note?.id) deleteNote(props.orderId, props.note.id);
                }}
                color="secondary"
                variant="contained"
                style={{
                  marginLeft: 12,
                  marginRight: 12
                }}
              >
                Delete
              </Button>}
              <Button onClick={() => props.onCancel()} color="primary">
                Cancel
              </Button>
            </Grid>
          </Grid>
      </form>
      )}
    />
  );
}
