import React, {
  useState,
  useEffect,
  useRef,
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';

import ClaimAttachments from '../collections/ClaimAttachments';

import {
  APIError,
  BaseAPI,
} from '../../data/BaseAPI';

import {
  useProfile
} from '../../data/Profile';

import {
  User,
  Session,
  LaborCategory,
  JobDescription
} from '../../types';

import localization from '../../utils/localizations';

import CountrySelect from './fields/CountrySelect';
import StateSelect from './fields/StateSelect';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles({
  root: {
    margin: 10,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  closeButton: {
    padding: 4,
  },
  downloadButton: {
    marginRight: 6,
  },
  lineItem: {
    minWidth: '90%',
    marginBottom: 12,
  },
  lineItemTitle: {
    fontSize: 14
  }
});


interface JobDescriptionFormValues {
  name: string;
  flat_rate_code: string;
  labor_hours?: string;
}

function JobDescriptionForm(props: {
  jobDescription: JobDescription|null|undefined;
  category: LaborCategory;
  onSave: (jobDescription: JobDescription) => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);

  const onSubmit = async (values: JobDescription) => {
    const api = new BaseAPI();
    setIsSaving(true);
    setShowErrorSnackBar(false);
    if (props.jobDescription) {
      const jobDescription = Object.assign(values, {
        category: props.jobDescription.category.id,
        labor_hours: values.labor_hours ? values.labor_hours : null,
      });
      const [result, response, error] = await api.put(jobDescription, `warranty-job-descriptions-raw/${props.jobDescription.id}/`);
      props.onSave(result as JobDescription);
    }
    else {
      const jobDescription = Object.assign(values, {
        category: props.category.id,
      });
      const [result, response, error] = await api.post(jobDescription, 'warranty-job-descriptions-raw/');
      props.onSave(result as JobDescription);
    }

    setIsSaving(false);
  }

  const onValidate = (values: JobDescriptionFormValues) => {
    const errors: any = {}

    if (!values.name) errors.name = "Required";
    if (!values.flat_rate_code) errors.flat_rate_code = "Required";
    
    return errors;
  }

  let jobDescription = props.jobDescription;
  return (
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        name: jobDescription ? jobDescription.name : '',
        flat_rate_code: jobDescription ? jobDescription.flat_rate_code : '',
        labor_hours: jobDescription ? jobDescription.labor_hours : null,
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <DialogTitle id="form-dialog-title">Labor Category</DialogTitle>
          <DialogContent>
            <Grid container>

              <Field name="name">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Name'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="flat_rate_code">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Flat Rate Code'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        type="text"
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="labor_hours">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Labor Hours'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        type="number"
                        inputProps={{
                          step: "0.25",
                          min: "0",
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

            </Grid>
          </DialogContent>
          <DialogActions style={{justifyContent: 'space-between', padding: 24}}>
            <Button 
              type="submit"
              disabled={isSaving}
              color="primary"
              variant="contained"
            >
              {props.jobDescription?.id ? "Update" : "Add"}
            </Button>
            <Button onClick={() => props.onCancel()} color="primary">
              Cancel
            </Button>
          </DialogActions>
    
      </form>
      )}
    />
  );
}


export {
  JobDescriptionForm,
}