import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Dropzone from 'react-dropzone'

import {
  BaseAPI,
} from '../../data/BaseAPI';

import {
  Part,
  User,
  Boat,
} from '../../types';

import {
  useProfile
} from '../../data/Profile';

import localization from '../../utils/localizations';


const useStyles = makeStyles({
  root: {
    padding: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  formTitle: {
    marginBottom: 20,
  },
  textFieldContainer: {
    marginBottom: 20,
  },
  closeButton: {
    padding: 4,
  },
});

export default function PartsListForm(props: {
  className?: string;
  onClose: () => void;
  boat: Boat;
  onBoatUpdated: (boat: Boat) => void;
  onBoatsUpdated: (boats: Boat[]) => void;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [partNumbers, setPartNumbers] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setPartNumbers(props.boat.available_parts.map((part) => {
      return part.part_number;
    }).join("\n"));
  }, props.boat.available_parts)


  const loadBoats = async () => {
    const api = new BaseAPI();
    const data = await api.get('boats/');
    if (data instanceof Array) {
      if (!(window as any)._cached) (window as any)._cached = {} as any;
      if (!(window as any)._cached['boats']) (window as any)._cached['boats'] = data as Boat[];
    }
    return data;
  };

  const onClose = () => {

    props.onClose();
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    
    const api = new BaseAPI();
    setIsSaving(true);
    let success = false;
    let orderId: number|undefined = undefined;
    try {
      const [result, response, error] = await api.post({
        parts: partNumbers
      }, `boats/${props.boat.id}/update_parts/`);
      success = result.success;
      orderId = result.id;
      console.log('result', result);
      if (!success && result.message) {
        setErrorMessage(result.message);
      }
    } catch (e: any) {
      console.log(e);
      setErrorMessage(e.message);
    }

    if (success) {
      const data = await loadBoats();
      if (data instanceof Array) {
        const boats = data as Boat[];
        boats.forEach((boat) => {
          if (boat.id == props.boat.id) {
            setPartNumbers(boat.available_parts.map((part) => {
              return part.part_number;
            }).join("\n"));
            props.onBoatUpdated(boat);
            props.onBoatsUpdated(boats);
          }
        })
      }
      onClose();
    }
    setIsSaving(false);
  };

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.formTitle}>Update Parts List</Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth className={classes.textFieldContainer}>
            <TextField
              id={'part_numbers'}
              label={'Part Numbers'}
              variant="outlined"
              name={'part_numbers'}
              value={partNumbers}
              disabled={isSaving}
              onChange={(event: any) => {
                setPartNumbers(event.target.value);
              }}
              multiline
              rows={20}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSaving}
          >Update</Button>
          {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
        </Grid>
        <Grid item xs={6} className={classes.alignRight}>
          <Button
            variant="contained"
            onClick={onClose}
          >Cancel</Button>
        </Grid>
      </Grid>

      <Snackbar
        key={'error-snackbar'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={errorMessage !== ''}
        onClose={() => setErrorMessage('')}
        onExited={() => setErrorMessage('')}
        message={errorMessage}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.closeButton}
              onClick={() => setErrorMessage('')}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />

    </form>
  );
}