import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Chip,
  Tooltip,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import {
  BaseAPI,
} from '../../data/BaseAPI';

import {
  User
} from '../../types';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    paddingTop: 4,
    paddingBottom: 4,
    width: 140,
  },
  profileBackground: {
    width: '100%'
  },
  profileAvatarContainer: {
    position: 'relative',
    height: 140,
    width: 140,
    overflow: 'hidden',
  },
  avatarCenteringContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  avatar: {
    width: 140,
    height: 140,
    color: theme.palette.getContrastText(grey[300]),
    backgroundColor: grey[300],
  },
  avatarButtonContainer: {
    borderRadius: 100,
  },
  userFullName: {
    fontSize: '14px',
    textAlign: 'center',
    marginTop: 5,
  },
}));


function ProfilePanel(props: {profile: User}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.profileAvatarContainer}>
        <div className={classes.avatarCenteringContainer}>
          <Avatar variant="square" className={classes.avatar} src={props.profile.avatar} />
        </div>
      </div>
    </div>
  );
}

function ProfileChip(props: {profile?: User; id?: string; role?: string; size?: "medium" | "small" | undefined; className?: string;}) {
  const [profile, setProfile] = useState(props.profile);

  const loadProfileData = async (id: string, role: string) => {
    const api = new BaseAPI();
    const result = await api.get(`${role}/${id}/`);
    if (result && (result as any).id) setProfile(result as User);
  };

  useEffect(() => {
    if (props.id && props.role) loadProfileData(props.id, props.role);
  }, [props.id, props.role])

  if (profile) {
    return (
      <Tooltip
        title={<ProfilePanel profile={profile} />}
        arrow
      >
        <Chip
          avatar={<Avatar src={profile.avatar} />}
          label={`${profile.first_name} ${profile.last_name}`}
          className={props.className}
          size={props.size}
          variant="outlined"
        />
      </Tooltip>
    );
  }
  return (<></>)
}

export default ProfileChip;