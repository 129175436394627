import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'mobx-react-lite/batchingForReactDom';

import * as Sentry from '@sentry/browser';

const appConfig = (window as any).APP_CONFIG;

if (appConfig.sentryDSN && (appConfig.sentryDSN !== "__SENTRY_DSN__")) {
  Sentry.init({
    dsn: appConfig.sentryDSN,
    beforeSend: (event, hint) => {
      console.log('sentry before send', event)
      if (event.exception) {
        const user: any = {}
        if ((window as any)._cache?.session?.user) {
          user.email = (window as any)._cache.session.user.email;
          user.customer_name = (window as any)._cache.session.user.customer_name;
          user.customer_number = (window as any)._cache.session.user.customer_number;
        }
        Sentry.showReportDialog({ eventId: event.event_id, user: user });
      }
      return event;
    }
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
