import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Chip,
  Tooltip,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import {
  BaseAPI,
} from '../../data/BaseAPI';

import {
  Dealer
} from '../../types';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    paddingTop: 4,
    paddingBottom: 4,
    width: 140,
  },
  profileBackground: {
    width: '100%'
  },
  profileAvatarContainer: {
    position: 'relative',
    height: 140,
    width: 140,
    overflow: 'hidden',
  },
  avatarCenteringContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  avatar: {
    width: 140,
    height: 140,
    color: theme.palette.getContrastText(grey[300]),
    backgroundColor: grey[300],
  },
  avatarButtonContainer: {
    borderRadius: 100,
  },
  userFullName: {
    fontSize: '14px',
    textAlign: 'center',
    marginTop: 5,
  },
}));


function DealerChip(props: {
  dealer?: Dealer;
  id?: string;
  link?: boolean;
  size?: "medium" | "small" | undefined;
  className?: string;
}) {
  const [dealer, setDealer] = useState(props.dealer);

  const loadDealerData = async (id: string) => {
    const api = new BaseAPI();
    const result = await api.get(`all-dealers/${id}/`);
    if (result && (result as any).id) setDealer(result as Dealer);
  };

  useEffect(() => {
    if (props.id) loadDealerData(props.id);
  }, [props.id])

  if (dealer) {
    if (props.link) {
      return (
        <Chip
          label={`${dealer.customer_name} (${dealer.customer_number})`}
          className={props.className}
          size={props.size}
          variant="outlined"
          component={RouterLink}
          to={`${appConfig.homepage}dealers/list/${props.id}/`} 
        />
      );
    }

    return (
      <Chip
        label={`${dealer.customer_name} (${dealer.customer_number})`}
        className={props.className}
        size={props.size}
        variant="outlined"
      />
    );
  }
  return (<></>)
}

export default DealerChip;