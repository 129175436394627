import React, {
  useState,
  useEffect
} from 'react';


import {
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Divider,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import formatNumber from 'format-number';
import bankersRounding from '../../../../utils/bankersRounding';

import {
  BoatPartItem
} from './BoatPartItem';

import {
  getPartFromOrderLine,
  canSelectPart,
} from './utils';

import {
  Boat,
  Part,
  PartGroup,
  GroupedPart,
  Dealer,
  PartVariant,
  PartRule,
  DealerDiscount,
} from '../../../../types';

import {
  OrderLine,
  PartOrderLine,
  DiscountItem,
  FormValues
} from '../OrderTypes';


const useStyles = makeStyles({
  root: {

  },
  list: {
    padding: 0,
  }
});


export function BoatPartGroup(props: {
  groupedPart: GroupedPart;
  boat: Boat;
  dealer?: Dealer;
  orderLines: OrderLine[];
  setOrderLines: (orderLines: OrderLine[]) => void;
  showHiddenInfo?: boolean;
  hideGroupLabel?: boolean;
  oneLiner?: boolean;
  className?: string;
}) {
  const classes = useStyles(); 

  const isHiddenPart = (part: Part, boat: Boat, orderLines: OrderLine[]) => {
    const hiddenIds = boat.hidden_parts.map(part => part.id);
    let isHidden = false;
    if (hiddenIds.includes(part.id)) {
      isHidden = true;
      orderLines.forEach(orderLine => {
        if (orderLine.id === part.id) {
          isHidden = false;
          return;
        }
        let p = getPartFromOrderLine(orderLine, boat);
        if (p) {
          const canIncludeIds = p.can_includes.map(p => p.id);
          if (canIncludeIds.includes(part.id)) {
            isHidden = false;
            return;
          }
        }
      });

    }
    return isHidden;
  }
  
  const canWeSelectThisPartNoToast = (part: Part, variant: PartVariant|null) => {
    const result = canSelectPart(part, variant, props.orderLines, props.boat);

    if (result.selectable) return true;
    return false;
  }
  
  let defaultGroupName = 'Other';

  return (
    <div className={`${classes.root} ${props.className ? props.className : ''}`}>
      <List
        className={classes.list}
        subheader={!props.hideGroupLabel ?
          <ListSubheader component="div">{props.groupedPart.group?.name ? props.groupedPart.group?.name : defaultGroupName}</ListSubheader>
          : undefined
        }
      >
        {props.groupedPart.parts
          .filter((part) => !isHiddenPart(part, props.boat, props.orderLines))
          .filter((part) => canWeSelectThisPartNoToast(part, null))
          .map((part, i) => {
          return (
            <BoatPartItem
              key={`boat-part-item-${part.id}`}
              part={part}
              groupedPart={props.groupedPart}
              boat={props.boat}
              dealer={props.dealer}
              orderLines={props.orderLines}
              setOrderLines={props.setOrderLines}
              showHiddenInfo={props.showHiddenInfo}
              oneLiner={props.oneLiner}
            />
          );
        })}
      </List>

      {props.groupedPart?.children?.map((gp, i) => (
        <BoatPartGroup
          key={`gp-child-${gp.group ? gp.group.id : i}`}
          groupedPart={gp}
          boat={props.boat}
          dealer={props.dealer}
          orderLines={props.orderLines}
          setOrderLines={props.setOrderLines}
          showHiddenInfo={props.showHiddenInfo}
          oneLiner={props.oneLiner}
          hideGroupLabel
        />
      ))}
    </div>
  );
}
