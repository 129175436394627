import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Paper,
  Slide,
  Grid,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  DialogContent,
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton,
  Alert,
  TreeView,
  TreeItem
} from '@material-ui/lab';

import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import { useProfile } from '../../data/Profile';
import DealerLocationDetail from '../singles/DealerLocationDetail';
import {
  Dealer,
  DealerLocation
} from '../../types';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  container: {
    padding: 10
  },
  treeRoot: {

  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

interface DealerLocationExplorer {
  success: boolean;
  dealers: DealerLocation[];
  tree: DealerLocationExplorerCountry[];
}

interface DealerLocationExplorerCountry {
  name: string;
  code: string;
  dealers: DealerLocationExplorerDealerSummary[];
  total_dealers: number;
  child_locator_ids: string[];
  child_locator_ids_with_issue: string[];
  issue: boolean;
  states: DealerLocationExplorerState[];
}

interface DealerLocationExplorerDealerSummary {
  id: number;
  dealer_id: string;
  territory_id: number;
  title: string;
  active: boolean;
}

interface DealerLocationExplorerState {
  name: string;
  code: string;
  dealers: DealerLocationExplorerDealerSummary[];
  total_dealers: number;
  child_locator_ids: string[];
  child_locator_ids_with_issue: string[];
  issue: boolean;
  counties: DealerLocationExplorerCounty[];
}

interface DealerLocationExplorerCounty {
  name: string;
  dealers: DealerLocationExplorerDealerSummary[];
  total_dealers: number;
  child_locator_ids: string[];
  child_locator_ids_with_issue: string[];
  issue: boolean;
  zip_codes: DealerLocationExplorerZipCode[];
}

interface DealerLocationExplorerZipCode {
  name: string;
  zip_code: string;
  dealers: DealerLocationExplorerDealerSummary[];
  total_dealers: number;
  child_locator_ids: string[];
  child_locator_ids_with_issue: string[];
  issue: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DealerTerritoryExplorer(props: {className?: string}) {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<DealerLocationExplorer|undefined>(undefined);
  const [selectedDealers, setSelectedDealers] = useState<DealerLocation[]>([]);
  const [dealerIdsWithIssue, setDealerIdsWithIssue] = useState<string[]>([]);

  const loadReport = async () => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    let url = 'dealer-territory-explorer/';

    try {
      const data: any = await api.get(url);
      if (data.success) {
        let d = data as DealerLocationExplorer;
        setData(d);
        setIsLoading(false);
        (window as any)._cachedDealerLocationExplorer = d;
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const getDealers = (key: string, data: DealerLocationExplorer) => {
    let nodeMapData: any = {};
    if ((window as any).nodeMapData) nodeMapData = (window as any).nodeMapData as any;
    let locationIds = nodeMapData[key] as string[]|undefined;
    
    let dealers: DealerLocation[] = []
    if (locationIds) dealers = data.dealers.filter(d => d.id &&(locationIds as string[]).includes(d.id));

    return dealers.sort((a, b) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });
  }

  useEffect(() => {
    if ((window as any)._cachedDealerLocationExplorer) {
      setData((window as any)._cachedDealerLocationExplorer as DealerLocationExplorer);
    }
    loadReport();
  }, []);

  useEffect(() => {
    if (!data) return;

    let dealerIdsWithIssue: string[] = [];
    let nodeMapData: any = {};
    data.tree.forEach(country => {
      let key = `country-${country.code}`;
      country.child_locator_ids_with_issue.forEach((id) => dealerIdsWithIssue.push(id));
      if (country.child_locator_ids.length > 0) nodeMapData[key] = country.child_locator_ids
      country.states.forEach(state => {
        let key = `country-${country.code}-state-${state.code}`;
        state.child_locator_ids_with_issue.forEach((id) => dealerIdsWithIssue.push(id));
        if (state.child_locator_ids.length > 0) nodeMapData[key] = state.child_locator_ids
        state.counties.forEach(county => {
          let key = `country-${country.code}-state-${state.code}-county-${county.name}`;
          county.child_locator_ids_with_issue.forEach((id) => dealerIdsWithIssue.push(id));
          if (county.child_locator_ids.length > 0) nodeMapData[key] = county.child_locator_ids
          county.zip_codes.forEach(zipCode => {
            let key = `country-${country.code}-state-${state.code}-county-${county.name}-zip-${zipCode.zip_code}`;
            zipCode.child_locator_ids_with_issue.forEach((id) => dealerIdsWithIssue.push(id));
            if (zipCode.child_locator_ids.length > 0) nodeMapData[key] = zipCode.child_locator_ids
          });
        });
      });
    });
    (window as any).nodeMapData = nodeMapData;
    setDealerIdsWithIssue(dealerIdsWithIssue);
  }, [data]);

  if (isLoading && !data) {
    return (
    <div className={classes.root}>
      <Paper className={classes.container}>
        <div style={{position: 'relative', padding: 10}}>
          <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
          <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
          <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
          <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
          <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
          <div>Generating dealer territory report. This may take a few moment...</div>
        </div>
      </Paper>
    </div>
    )
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.container}>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div style={{maxHeight: 'calc(100vh - 270px)', overflowY: 'auto'}}>
              <TreeView
                className={classes.treeRoot}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeSelect={(e: any, value: any) => {
                  if (data) {
                    let dealers = getDealers(value, data);
                    setSelectedDealers(dealers);
                  }
                }}
              >
                {!!data && data.tree.map((country) => {
                  let key = `country-${country.code}`;
                  let endIcon = country.issue ? <ErrorOutlineIcon fontSize="small" color="secondary" /> : null;
                  let label = <span>{country.name} {endIcon}</span>;
                  let dealerLabel = (country.total_dealers > 1) ? 'dealers' : 'dealer';
                  if (country.total_dealers === 1) label = <span><span>{country.name}</span> <span style={{color: '#888', fontSize: 11}}>&mdash; {getDealers(key, data).map(dealer => dealer.title).join(', ')}</span>  {endIcon}</span>;
                  if (country.total_dealers > 1) label = <span><span>{country.name}</span> <span style={{color: '#888', fontSize: 11}}>&mdash; {country.total_dealers} {dealerLabel}</span>  {endIcon}</span>;
                  return (
                    <TreeItem nodeId={key} label={label}>
                      {country.states.map((state) => {
                        let key = `country-${country.code}-state-${state.code}`;
                        let endIcon = state.issue ? <ErrorOutlineIcon fontSize="small" color="secondary" /> : null;
                        let label = <span>{state.name} {endIcon}</span>;
                        let dealerLabel = (state.total_dealers > 1) ? 'dealers' : 'dealer';
                        if (state.total_dealers === 1) label = <span><span>{state.name}</span> <span style={{color: '#888', fontSize: 11}}>&mdash; {getDealers(key, data).map(dealer => dealer.title).join(', ')}</span> {endIcon}</span>;
                        if (state.total_dealers > 1) label = <span><span>{state.name}</span> <span style={{color: '#888', fontSize: 11}}>&mdash; {state.total_dealers} {dealerLabel}</span> {endIcon}</span>;
                        return (
                          <TreeItem nodeId={key} label={label}>
                            {state.counties.map((county) => {
                              let key = `country-${country.code}-state-${state.code}-county-${county.name}`;
                              let endIcon = county.issue ? <ErrorOutlineIcon fontSize="small" color="secondary" /> : null;
                              let label = <span>{county.name} {endIcon}</span>;
                              let dealerLabel = (county.total_dealers > 1) ? 'dealers' : 'dealer';
                              if (county.total_dealers === 1) label = <span><span>{county.name}</span> <span style={{color: '#888', fontSize: 11}}>&mdash; {getDealers(key, data).map(dealer => dealer.title).join(', ')}</span> {endIcon}</span>;
                              if (county.total_dealers > 1) label = <span><span>{county.name}</span> <span style={{color: '#888', fontSize: 11}}>&mdash; {county.total_dealers} {dealerLabel}</span> {endIcon}</span>;
                              return (
                                <TreeItem nodeId={key} label={label}>
                                  {county.zip_codes.map((zipCode) => {
                                    let key = `country-${country.code}-state-${state.code}-county-${county.name}-zip-${zipCode.zip_code}`;
                                    let endIcon = zipCode.issue ? <ErrorOutlineIcon fontSize="small" color="secondary" /> : null;
                                    let zipCodeLabel = zipCode.name ? zipCode.name : zipCode.zip_code;
                                    let label = <span>{zipCodeLabel} {endIcon}</span>;
                                    let dealerLabel = (zipCode.total_dealers > 1) ? 'dealers' : 'dealer';
                                    if (zipCode.total_dealers === 1) label = <span><span>{zipCodeLabel}</span> <span style={{color: '#888', fontSize: 11}}>&mdash; {getDealers(key, data).map(dealer => dealer.title).join(', ')}</span> {endIcon}</span>;
                                    if (zipCode.total_dealers > 1) label = <span><span>{zipCodeLabel}</span> <span style={{color: '#888', fontSize: 11}}>&mdash; {zipCode.total_dealers} {dealerLabel}</span> {endIcon}</span>;
                                    return (
                                      <TreeItem nodeId={key} label={label}>
                                      </TreeItem>
                                    );
                                  })}
                                </TreeItem>
                              );
                            })}
                          </TreeItem>
                        );
                      })}
                    </TreeItem>
                  );
                })}
              </TreeView>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{marginTop: 10, maxHeight: 'calc(100vh - 280px)', overflowY: 'auto'}}>
              {selectedDealers.map(dealer => {
                let addressElements = [];
                if (dealer.address_line_1) addressElements.push(dealer.address_line_1);
                if (dealer.address_line_2) addressElements.push(dealer.address_line_2);
                if (dealer.city) addressElements.push(dealer.city);
                if (dealer.state) addressElements.push(dealer.state);
                if (dealer.zip) addressElements.push(dealer.zip);
                if (dealer.country) addressElements.push(dealer.country);
                let address = addressElements.join(', ');
                let hasIssue = (dealer.id && dealerIdsWithIssue.includes(dealer.id));
                let startIcon = hasIssue ? <ErrorOutlineIcon fontSize="small" color="secondary" /> : null;
                return (
                  <div key={`dealer-${dealer.id}`}>
                    <Button
                      component={RouterLink}
                      to={`${appConfig.homepage}dealers/territory/${dealer.id}/`}
                      size="medium"
                      style={{textTransform: 'none', padding: 0}}
                    >
                      <span>{startIcon} {dealer.title}</span>
                      <span style={{color: '#888', fontSize: 11, marginLeft: 4}}>&mdash; {address}</span>
                    </Button>
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </Paper>


      <Switch>
          <Route exact path={`${path}:dealerId/`}>
            <DealerLocationDetailPanel
              onUpdated={(dealerLocation) => {
                loadReport();
              }}
              onDeleted={(dealerLocation) => {
                loadReport();
              }}
            />
          </Route>
      </Switch>
    </div>
  );
}


function DealerLocationDetailPanel(props: {
  onUpdated: (dealerLocation: DealerLocation) => void;
  onDeleted: (dealerLocation: DealerLocation) => void;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [dealerLocation, setDealerLocation] = useState(undefined as DealerLocation|undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [backUrl, setBackUrl] = useState('/dealers/territory/');

  const { path, url } = useRouteMatch();
  const { dealerId } = useParams() as any;
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('back')) setBackUrl(urlParams.get('back') as string);
  }, []);

  const loadDealerLocation = async (id: string) => {
    if (!id) return;
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`admin-dealer-locations/${id}/`);
      if ((data as any).id) {
        setDealerLocation(data as DealerLocation);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadDealerLocation(dealerId);

    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    }, 300);

  }, [dealerId]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
        aria-labelledby="selected-dealer-location-title"
        aria-describedby="selected-dealer-location-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.dialogTitle}>
              {dealerLocation?.title}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setNavigateBack(true);
              }, 300);
            }} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{backgroundColor: '#f5f5f5'}}>
          {!!dealerLocation &&
            <DealerLocationDetail
              dealerLocation={dealerLocation}
              onUpdated={dealerLocation => {
                setDealerLocation(dealerLocation);
                props.onUpdated(dealerLocation);
                if (dealerLocation.id) loadDealerLocation(dealerLocation.id);
              }}
              onDeleted={dealerLocation => {
                props.onDeleted(dealerLocation);
                setNavigateBack(true);
              }}
            />}
          {!dealerLocation &&
            <div>
              <LoadingView />
            </div>
          }
        </DialogContent>
        </>
      </Dialog>

      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}



function LoadingView() {
  return (
    <div style={{position: 'relative', padding: 10}}>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
    </div>
  );
}
