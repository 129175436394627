import React, {
  useState,
  useEffect
} from 'react';

import { Form, Field } from "react-final-form";

import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  MenuItem,
  ListSubheader,
  FormControl,
  InputLabel,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import {
  TransitionProps
} from '@material-ui/core/transitions';

import {
  APIError,
  BaseAPI,
} from '../../../data/BaseAPI';

import {
  User,
} from '../../../types';

import UserForm from '../UserForm';

const useStyles = makeStyles({
  root: {
    
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserSelect(props: {
  id: string;
  label: string;
  variant: "filled" | "outlined" | "standard" | undefined;
  name: string;
  value: string;
  allowEmpty?: boolean;
  allowCreate?: boolean;
  emptyLabel?: string;
  onChange: (event: any) => void;
  error?: boolean | undefined;
  errorMessage?: string | undefined;
  className?: string;
}) {
  const classes = useStyles();
  
  const [showEditUserForm, setShowEditUserForm] = useState(false);
  const [allUsers, setAllUsers] = useState(((window as any)._cached && (window as any)._cached['all_dealers'] ? (window as any)._cached['all_dealers']: []) as User[]);

  const loadAllUsers = async () => {

    const api = new BaseAPI();
    try {
      const data = await api.get('all-users-paginated/?page_size=1000');
      if (data && (data as any).results instanceof Array) {
        setAllUsers((data as any).results as User[]);

        if (!(window as any)._cached) (window as any)._cached = {} as any;
        if (!(window as any)._cached['all_users']) (window as any)._cached['all_users'] = (data as any).results;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(allUsers.length === 0) loadAllUsers();
  }, []);

  return (
    <div className={`${props.className}`}>
      <FormControl variant="outlined" fullWidth error={props.error}>
        <InputLabel>{props.label}</InputLabel>
        <Select
          id={props.id}
          label={props.label}
          variant={props.variant}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          error={props.error}
        >
          {!!props.allowEmpty && 
          <MenuItem value="">{props.emptyLabel ? props.emptyLabel : '----'}</MenuItem>}
          <ListSubheader>Active Users</ListSubheader>
          {allUsers.filter(user => user.is_active).map((user, i) => (
            <MenuItem key={`user-menu-item-${i}`} value={user.id}>{user.first_name} {user.last_name} - {user.email}</MenuItem>
          ))}
          <ListSubheader>Inactive Users</ListSubheader>
          {allUsers.filter(user => !user.is_active).map((user, i) => (
            <MenuItem key={`user-menu-item-inactive-${i}`} value={user.id}>{user.first_name} {user.last_name} - {user.email}</MenuItem>
          ))}
        </Select>
        {(props.error && props.errorMessage) &&
          <Typography style={{color: 'red'}}>{props.errorMessage}</Typography>
        }
      </FormControl>
      <div className="mt-2">
        <Button
          size="small"
          color="primary"
          startIcon={<AddIcon />}
          onClick={(e) => {
            setShowEditUserForm(true);
          }}
        >
          Create a new user
        </Button>
      </div>


      <Dialog
        open={showEditUserForm}
        onClose={() => {
          setShowEditUserForm(false);
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <UserForm
            user={null}
            onSave={(user) => {
              setShowEditUserForm(false);
              props.onChange({target: {value: user.id}});
              loadAllUsers();
            }}
            onCancel={() => {
              setShowEditUserForm(false);
            }}
          />
        </DialogContent>
      </Dialog>

    </div>
  );
}