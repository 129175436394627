import React, {
useState,
useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
BrowserRouter as Router,
Switch,
Route,
Redirect,
useRouteMatch,
useParams,
Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
Typography,
Button,
Checkbox,
Radio,
RadioGroup,
FormControlLabel,
ListSubheader,
CircularProgress,
Chip,
Grid,
FormControl,
TextField,
InputLabel,
Select,
MenuItem,
Collapse,
Snackbar,
IconButton,
Dialog,
DialogContent,
DialogTitle,
DialogActions,
DialogContentText,
Table,
TableBody,
TableCell,
TableContainer,
TableHead,
TableRow,
Paper,
Card,
CardActions,
CardContent,
InputAdornment,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {
APIError,
BaseAPI,
} from '../../../data/BaseAPI';

import {
BoatEditor,
BoatEditorPartGroup,
BoatEditorPart,
BoatEditorPartVariant,
BoatEditorPartOption,
PartPicture,
SimplePartRule,
} from '../../../types';

import PartVariantEditorElement from './PartVariantEditorElement';

import localization from '../../../utils/localizations';
import DateTimeView from '../../singles/DateTimeView';
import PartPictures from '../../collections/PartPictures';

const useStyles = makeStyles({
root: {

},
partContainer: {
    marginRight: -12,
    marginLeft: -12,
    paddingTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
    '&:hover': {
    backgroundColor: '#f9f9f9'
    }
},
partFieldsContainer: {

},
fieldContainer: {
    marginBottom: 20,
    '& .MuiInputBase-root ': {
      backgroundColor: '#fff'
    },
    '& >label': {
      top: -6,
      left: 14,
      zIndex: 9999,
    }
},
partVariantParentContainer: {
    paddingLeft: 12,
    paddingRight: 12,
    borderLeft: 'solid 5px #4caf50',
},
partAdvancedContainer: {
  marginTop: 12,
  marginBottom: 24,
},
linkedPartsContainer: {
  border: 'solid 1px rgba(0, 0, 0, 0.23)',
  padding: 6,
  borderRadius: 4,
  backgroundColor: '#fff',
},
compactContainer: {
  borderTop: 'solid 1px #ddd',
  marginRight: -12,
  marginLeft: -12,
  '&:hover': {
  backgroundColor: '#f9f9f9'
  }
},
compactContainerButton: {
  display: 'block',
  width: '100%',
  padding: 12,
  textAlign: 'left',
}
});
  

export default function PartEditorElement(props: {
  group: BoatEditorPartGroup;
  groups: BoatEditorPartGroup[];
  boat: BoatEditor;
  part: BoatEditorPart;
  parts: BoatEditorPart[];
  linkedParts: BoatEditorPart[];
  partVariants: BoatEditorPartVariant[];
  disabled?: boolean;
  showAdvancedOptions?: boolean;
  disableVariants?: boolean;
  isEngine?: boolean;
  onPartUpdated: (part: BoatEditorPart) => void;
  onLinkedPartUpdated: (part: BoatEditorPart) => void;
  onPartVariantsUpdated: (part: BoatEditorPartVariant[]) => void;
  onPartRemoved: (part: BoatEditorPart) => void;
  onLinkedPartRemoved: (part: BoatEditorPart) => void;
  onPartVariantRemoved: (part: BoatEditorPartVariant) => void;
  onBoatUpdated: (part: BoatEditor) => void;
  onPartPictureRemoved: (picture: PartPicture) => void;
  expanded?: boolean;
}) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(props.expanded ? true : false);
  const [part, setPart] = useState<BoatEditorPart>(props.part);
  const [showAddPartVariantForm, setShowAddPartVariantForm] = useState(false);
  const [addPartVariantError, setAddPartVariantError] = useState('');
  const [addPartVariant, setAddPartVariant] = useState<BoatEditorPartVariant|undefined>(undefined);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(props.showAdvancedOptions ? true : false);

  const [isLoading, setIsLoading] = useState(false);
  const [showAddPartNumberForm, setShowAddPartNumberForm] = useState(false);
  const [addPartNumber, setAddPartNumber] = useState('');
  const [addPartNumberType, setAddPartNumberType] = useState('option');
  const [addPartNumberError, setAddPartNumberError] = useState('');
  const [addPartTarget, setAddPartTarget] = useState('');
  const [addPartTargetVariant, setAddPartTargetVariant] = useState<BoatEditorPartVariant|undefined>(undefined);
  const [showAddPartForm, setShowAddPartForm] = useState(false);
  const [addPartError, setAddPartError] = useState('');
  const [addPart, setAddPart] = useState<BoatEditorPart|undefined>(undefined);
  const [addPartVariants, setAddPartVariants] = useState<BoatEditorPartVariant[]>([]);

  const [includedPartPictures, setIncludedPartPictures] = useState<PartPicture[]>([]);
  const [removedPartPictures, setRemovedPartPictures] = useState<PartPicture[]>([]);

  const getPart = async (partNumber: string): Promise<BoatEditorPart|null|undefined> => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    let url = `boats/editor/parts/`;
    let kwargs: any = {
      page_size: 20,
      part_number: partNumber
    };

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        const results = data.results as BoatEditorPart[];
        setIsLoading(false);
        if (results.length > 0) {
          let part = results[0];
          loadAddPartVariants(part.id);
          return part;
        }
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
    let groupIds = [];
    if (group.id > 0) groupIds.push(group.id);
    return {
      "id": 0,
      "local_id": Date.now(),
      "part_number": partNumber,
      "fb_part_number": "",
      "description": "",
      "csv_description": "",
      "price": "",
      "price_msrp": "",
      "price_msrp_deal": "",
      "quantity": 1,
      "boats": [
          boat.id,
      ],
      "part_type": addPartNumberType,
      "must_includes": [],
      "must_includes_options": [],
      "can_includes": [],
      "must_excludes": [],
      "unselectable_unless": [],
      "hidden_parts": [],
      "groups": groupIds,
      "extra_information": "",
      "variants": [],
      "order": 0,
      "csv_order": null,
      "variants_ordering": "price_low_high",
      "override_group_behavior": "",
      "active": true,
      "admin_only": false,
      "pictures": [],
    }
  }

  const loadAddPartVariants = async (partId: number, page?: number) => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    let url = `boats/editor/part-variants/`;
    let kwargs: any = {
      page_size: 100,
      part: partId,
    };
    if (page) kwargs.page = page;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        if (page && (page > 0)) {
          const results = data.results as BoatEditorPartVariant[];
          setAddPartVariants(results);
        }
        else {
          setAddPartVariants(data.results as BoatEditorPartVariant[]);
        }

        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const onAddPart = async (partNumber: string) => {
    let part = await getPart(partNumber);
    if (part) {
      setAddPart(part);
      setShowAddPartNumberForm(false);
      setShowAddPartForm(true);
      console.log('part', part);
    }
  }

  const loadPartPicture = async (partPictureId: string) => {
    const api = new BaseAPI();
    
    let url = `boats/editor/part-pictures/${partPictureId}/`;
    try {
      const data: any = await api.get(url);
      if (data.id) {
        return data as PartPicture;
      }
    } catch (error) {
      console.error(error);
    }

    return undefined;
  };

  const loadPartPictures = async (ids: string[]) => {
    let picturePromises = ids.map(pid => loadPartPicture(pid));

    let pictures = await Promise.all(picturePromises);
    return pictures.filter(p => p !== undefined) as PartPicture[];
  }

  const loadAllPartPictures = async (part: BoatEditorPart) => {
    let boatPartPictures = await loadPartPictures(part.pictures);
    setIncludedPartPictures(boatPartPictures);
  }

  useEffect(() => {
    setPart(props.part);
    loadAllPartPictures(props.part);
  }, [props.part]);

  const group = props.group;
  const groups = props.groups;
  const boat = props.boat;
  const parts = props.parts;
  const partVariants = React.useMemo(() => props.partVariants
    .filter(partVariant => partVariant.part === part.id)
    .sort((a, b) => {
      let priceA = a.price ? a.price : '0';
      let priceB = b.price ? b.price : '0';

      let nameA = a.name ? a.name : '0';
      let nameB = b.name ? b.name : '0';

      let orderA = a.order ? a.order : '0';
      let orderB = b.order ? b.order : '0';

      if (part.variants_ordering === 'price_low_high') {
        if (priceA > priceB) return 1;
        if (priceB > priceA) return -1;
        return 0;
      }

      if (part.variants_ordering === 'price_high_low') {
        if (priceA > priceB) return -1;
        if (priceB > priceA) return 1;
        return 0;
      }

      if (part.variants_ordering === 'name_asc') {
        if (nameA > nameB) return 1;
        if (nameB > nameA) return -1;
        return 0;
      }

      if (part.variants_ordering === 'name_desc') {
        if (nameA > nameB) return -1;
        if (nameB > nameA) return 1;
        return 0;
      }

      if (part.variants_ordering === 'custom') {
        if (orderA > orderB) return 1;
        if (orderB > orderA) return -1;
        return 0;
      }

      if (priceA > priceB) return 1;
      if (priceB > priceA) return -1;
      return 0;
    }), [props.partVariants, part.variants_ordering]);
  
  const flattenedGroups: BoatEditorPartGroup[] = [];
  groups.forEach(group => {
    flattenedGroups.push(group);
    if (group.children) group.children.forEach(cgroup => {
      flattenedGroups.push(cgroup);
    });
  });

  let isRecommended = React.useMemo(() => {
    if (part.id) return boat.recommended_parts.includes(part.id);
    else if (part.local_id) return boat.recommended_parts.includes(part.local_id);
    return false;
  }, [boat.recommended_parts, part, boat]);
  let isHidden = React.useMemo(() => {
    if (part.id) return boat.hidden_parts.includes(part.id);
    else if (part.local_id) return boat.hidden_parts.includes(part.local_id);
    return false;
  }, [boat.recommended_parts, part, boat]);
  let isRequired = React.useMemo(() => {
    if (part.id) return boat.required_parts.includes(part.id);
    else if (part.local_id) return boat.required_parts.includes(part.local_id);
    return false;
  }, [boat.required_parts, part, boat]);

  const addNewVariant = (part: BoatEditorPart) => {
    setAddPartVariant({
      "id": 0,
      "local_id": Date.now(),
      "part": part.id,
      "part_local": part.local_id ? part.local_id : undefined,
      "name": "",
      "description": "",
      "csv_description": "",
      "part_number": "",
      "price": null,
      "price_msrp": null,
      "price_msrp_deal": null,
      "quantity": 1,
      "boats": [],
      "parts": [],
      "active": true,
      "recommended": false,
      "admin_only": false,
      "order": 0,
      "pictures": [],
    });
    setShowAddPartVariantForm(true);
  }

  let uniquePartRules: number[] = [];
  let partRulesTarget: SimplePartRule[] = [];
  let partRulesTrigger: SimplePartRule[] = [];
  if (part.part_rules_target && part.part_rules_target.length > 0) {
    partRulesTarget = part.part_rules_target.filter(pr => pr.boat === boat.id);
    partRulesTarget.forEach(pr => {
      if (!uniquePartRules.includes(pr.id)) uniquePartRules.push(pr.id);
    })
  }
  if (part.part_rules_trigger && part.part_rules_trigger.length > 0) {
    partRulesTrigger = part.part_rules_trigger.filter(pr => pr.boat === boat.id);
    partRulesTrigger.forEach(pr => {
      if (!uniquePartRules.includes(pr.id)) uniquePartRules.push(pr.id);
    })
  }
  let totalPartRules = uniquePartRules.length;

  if (!expanded) {
    return (
      <div className={classes.compactContainer}>
        <button
          className={classes.compactContainerButton}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <div style={{
            display: 'flex',
            opacity: part.active ? 1 : 0.4
          }}>
            {!part.active &&
            <div style={{fontWeight: 'bold', marginRight: 12}}>inactive</div>}
            <div style={{marginRight: 12, color: '#555'}}>{part.part_number}</div> 
            <div style={{flexGrow: 1}}>
              <span>{part.description}</span>
              {(totalPartRules > 0) && <Chip size="small" label={`${totalPartRules} ${totalPartRules > 1 ? 'part rules' : 'part rule'}`} style={{marginTop: -6, marginLeft: 6}} />}
            </div>
            <div>
              {part.price ? formatNumber({prefix: 'US$'})(parseFloat(`${part.price}`)) : ''}
              <span style={{color: '#999'}}>{(part.quantity === 1) ? `` : ` x ${part.quantity}`}</span>
            </div>
          </div>
          <div style={{textAlign: 'right', color: '#777'}}>
              {partVariants.map((variant, i) => variant.name).join(', ')}
          </div>
        </button>
      </div>
    );
  }

  return (
    <>
    {!props.expanded && <div className={classes.compactContainer} style={{borderTop: 'solid 3px #ddd',}}>
      <button
        className={classes.compactContainerButton}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        
        <div style={{
            display: 'flex',
            opacity: part.active ? 1 : 0.4
          }}>
            {!part.active &&
            <div style={{fontWeight: 'bold', marginRight: 12}}>inactive</div>}
            <div style={{marginRight: 12, color: '#555'}}>{part.part_number}</div> 
            <div style={{flexGrow: 1}}>
              <span>{part.description}</span>
              {(totalPartRules > 0) && <Chip size="small" label={`${totalPartRules} ${totalPartRules > 1 ? 'part rules' : 'part rule'}`} style={{marginTop: -6, marginLeft: 6}} />}
            </div>
            <div>
              {part.price ? formatNumber({prefix: 'US$'})(parseFloat(`${part.price}`)) : ''}
              <span style={{color: '#999'}}>{(part.quantity === 1) ? `` : ` x ${part.quantity}`}</span>
            </div>
          </div>
      </button>
      {(partRulesTarget.length > 0) &&
        <Alert severity="info">
          <AlertTitle>The following part rules may affect this part:</AlertTitle>
          {partRulesTarget.map(pr => <Chip icon={<OpenInNewIcon />} size="small" color="primary" key={`pr1-${pr.id}`} label={pr.name} style={{margin: 3}} onClick={e => window.open(`/admin/seafox/partrule/${pr.id}/change/`, `_blank`)} />)}  
        </Alert>}
        {(partRulesTrigger.length > 0) &&
        <Alert severity="info">
          <AlertTitle>This part may trigger the following part rules:</AlertTitle>
          {partRulesTrigger.map(pr => <Chip icon={<OpenInNewIcon />} size="small" color="primary" key={`pr2-${pr.id}`} label={pr.name} style={{margin: 3}}  onClick={e => window.open(`/admin/seafox/partrule/${pr.id}/change/`, `_blank`)} />)}  
        </Alert>}
    </div>}
    <div className={classes.partContainer}>
      <div className={classes.partFieldsContainer}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl fullWidth className={classes.fieldContainer}>
                  <TextField
                    id="part_number"
                    label={'Part Number'}
                    variant="outlined"
                    name="part_number"
                    value={part.part_number}
                    required
                    size="small"
                    onChange={(e) => {
                      let p = Object.assign({}, part) as BoatEditorPart;
                      p.part_number = e.target.value;
                      setPart(p);
                    }}
                    onBlur={() => {
                      props.onPartUpdated(part);
                    }}
                    helperText=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                <FormControl fullWidth className={classes.fieldContainer}>
                  <TextField
                    id="description"
                    label={'Part Name'}
                    variant="outlined"
                    name="description"
                    value={part.description}
                    required
                    size="small"
                    onChange={(e) => {
                      let p = Object.assign({}, part) as BoatEditorPart;
                      p.description = e.target.value;
                      setPart(p);
                    }}
                    onBlur={() => {
                      props.onPartUpdated(part);
                    }}
                    helperText=""
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormControl fullWidth className={classes.fieldContainer}>
              <TextField
                id="csv_description"
                label={'CSV Description'}
                variant="outlined"
                name="csv_description"
                value={part.csv_description}
                required
                size="small"
                onChange={(e) => {
                  let p = Object.assign({}, part) as BoatEditorPart;
                  p.csv_description = e.target.value;
                  setPart(p);
                }}
                onBlur={() => {
                  props.onPartUpdated(part);
                }}
                helperText=""
              />
            </FormControl>
            <FormControl fullWidth className={classes.fieldContainer}>
              <TextField
                id="extra_information"
                label={'Description'}
                variant="outlined"
                name="extra_information"
                value={part.extra_information}
                size="small"
                onChange={(e) => {
                  let p = Object.assign({}, part) as BoatEditorPart;
                  p.extra_information = e.target.value;
                  setPart(p);
                }}
                onBlur={() => {
                  props.onPartUpdated(part);
                }}
                helperText=""
              />
            </FormControl>
            {props.isEngine &&
            <Grid item xs={12}>
              <div className={classes.linkedPartsContainer}>
                <div style={{color: '#555', fontSize: 13, marginBottom: 5}}>Engines</div>
                <div>
                  {part.must_includes.map((pid, i) => {
                    const parts = props.linkedParts.filter(p => {
                      if (p.id && p.id === pid) return true;
                      if (p.local_id && p.local_id === pid) return true;
                    });

                    let label = (parts.length > 0) ? `${parts[0].part_number} — ${parts[0].description}` : `part #${pid}`
                    if ((parts.length > 0) && (parts[0].part_type !== "engine")) return <></>;
                    return (
                      <Chip
                        key={`must_includes_item_${pid}`}
                        label={label}
                        clickable
                        onDelete={() => {
                          if (!window.confirm('remove this item?')) return;
                          let p = Object.assign({}, part) as BoatEditorPart;
                          p.must_includes = part.must_includes.filter(id => id !== pid);
                          p.hidden_parts = part.hidden_parts.filter(id => id !== pid);
                          setPart(p);
                          props.onPartUpdated(p);
                        }}
                        style={{marginTop: 6, marginRight: 6}}
                      />
                    );
                  })}
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setAddPartNumber('');
                      setAddPartNumberType('engine');
                      setAddPartTarget('must_includes');
                      setShowAddPartNumberForm(true);
                    }}
                  >Add Part</Button>
                </div>
              </div>
            </Grid>}
            <Grid container>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                  <Checkbox
                    checked={part.active}
                    onChange={(e) => {
                      let p = Object.assign({}, part) as BoatEditorPart;
                      p.active = e.target.checked;
                      setPart(p);
                      setTimeout(() => {
                        props.onPartUpdated(p);
                      }, 0);
                    }}
                    name="active"
                  />}
                  label="Active"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                  <Checkbox
                    checked={isRecommended}
                    onChange={(e) => {
                      let b = Object.assign({}, boat) as BoatEditor;
                      let recommended = e.target.checked;
                      if (!recommended) {
                        b.recommended_parts = boat.recommended_parts.filter(pid => {
                          if (part.id) return pid !== part.id;
                          else if (part.local_id) return pid !== part.local_id;
                        });
                      }
                      else {
                        if (part.id) b.recommended_parts.push(part.id);
                        else if (part.local_id) b.recommended_parts.push(part.local_id);
                      }
                      props.onBoatUpdated(b);
                    }}
                    name="is_recommended"
                  />}
                  label="Recommended"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                  <Checkbox
                    checked={isHidden}
                    onChange={(e) => {
                      let b = Object.assign({}, boat) as BoatEditor;
                      let hidden = e.target.checked;
                      if (!hidden) {
                        b.hidden_parts = boat.hidden_parts.filter(pid => {
                          if (part.id) return pid !== part.id;
                          else if (part.local_id) return pid !== part.local_id;
                        });
                      }
                      else {
                        if (part.id) b.hidden_parts.push(part.id);
                        else if (part.local_id) b.hidden_parts.push(part.local_id);
                      }
                      props.onBoatUpdated(b);
                    }}
                    name="is_hidden"
                  />}
                  label="Hidden"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                  <Checkbox
                    checked={isRequired}
                    onChange={(e) => {
                      let b = Object.assign({}, boat) as BoatEditor;
                      let required = e.target.checked;
                      if (!required) {
                        b.required_parts = boat.required_parts.filter(pid => {
                          if (part.id) return pid !== part.id;
                          else if (part.local_id) return pid !== part.local_id;
                        });
                      }
                      else {
                        if (part.id) b.required_parts.push(part.id);
                        else if (part.local_id) b.required_parts.push(part.local_id);
                      }
                      props.onBoatUpdated(b);
                    }}
                    name="is_required"
                  />}
                  label="Required"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                  <Checkbox
                    checked={part.admin_only}
                    onChange={(e) => {
                      let p = Object.assign({}, part) as BoatEditorPart;
                      p.admin_only = e.target.checked;
                      setPart(p);
                      setTimeout(() => {
                        props.onPartUpdated(p);
                      }, 0);
                    }}
                    name="admin_only"
                  />}
                  label="Admin Only"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth className={classes.fieldContainer}>
              <TextField
                id="price"
                label={'Price'}
                variant="outlined"
                name="price"
                value={part.price}
                type="number"
                size="small"
                onChange={(e) => {
                  let p = Object.assign({}, part) as BoatEditorPart;
                  p.price = e.target.value;
                  setPart(p);
                }}
                onBlur={() => {
                  props.onPartUpdated(part);
                }}
                helperText=""
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">Dealer</InputAdornment>,
                }}
              />
            </FormControl>
            <FormControl fullWidth className={classes.fieldContainer}>
              <TextField
                id="price_msrp"
                label={'MSRP Price'}
                variant="outlined"
                name="price_msrp"
                value={part.price_msrp}
                type="number"
                size="small"
                onChange={(e) => {
                  let p = Object.assign({}, part) as BoatEditorPart;
                  p.price_msrp = e.target.value;
                  setPart(p);
                }}
                onBlur={() => {
                  props.onPartUpdated(part);
                }}
                helperText=""
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">International</InputAdornment>,
                }}
              />
            </FormControl>
            <FormControl fullWidth className={classes.fieldContainer}>
              <TextField
                id="price_msrp_deal"
                label={'MSRP Deal Price'}
                variant="outlined"
                name="price_msrp_deal"
                value={part.price_msrp_deal}
                type="number"
                size="small"
                onChange={(e) => {
                  let p = Object.assign({}, part) as BoatEditorPart;
                  p.price_msrp_deal = e.target.value;
                  setPart(p);
                }}
                onBlur={() => {
                  props.onPartUpdated(part);
                }}
                helperText=""
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">US</InputAdornment>,
                }}
              />
            </FormControl>
            <div>
              {(!props.disableVariants && (partVariants.length === 0)) && 
              <Button
                variant="contained"
                size="small"
                style={{marginRight: 12}}
                onClick={() => {
                  addNewVariant(part);
                }}
              >Add Variant</Button>}
              <Button
                variant="contained"
                size="small"
                onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
              >{showAdvancedOptions ? 'Less Options...' : 'Advanced Options...'}</Button>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={{float: 'right'}}
                onClick={() => {
                  if (window.confirm(`remove ${part.part_number} ${part.description} ?`)) props.onPartRemoved(part);
                }}
              >Remove</Button>
            </div>
          </Grid>
        </Grid>
      </div>
      
      <Collapse in={showAdvancedOptions}>
        <div className={classes.partAdvancedContainer}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControl fullWidth className={classes.fieldContainer}>
                <InputLabel id="groups_label">Groups</InputLabel>
                <Select
                  id="groups"
                  labelId="groups_label"
                  label="Groups"
                  variant="outlined"
                  name="groups"
                  value={part.groups}
                  onChange={(e) => {
                    let p = Object.assign({}, part) as BoatEditorPart;
                    if (e.target.value) {
                      p.groups = [(e.target.value as number)];
                    }
                    else {
                      p.groups = [];
                    }
                    setPart(p);
                    props.onPartUpdated(p);
                  }}
                  readOnly={props.disabled}
                >
                  {flattenedGroups.map((group) => {
                    return (
                      <MenuItem key={`group-item-${group.id}`} value={group.id ? group.id : ''}>{group.name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth className={classes.fieldContainer}>
                <InputLabel id="variant_ordering_label">Variant Ordering</InputLabel>
                <Select
                  id="variant_ordering"
                  labelId="variant_ordering_label"
                  label="Variant Ordering"
                  variant="outlined"
                  name="variant_ordering"
                  value={part.variants_ordering}
                  onChange={(e) => {
                    let p = Object.assign({}, part) as BoatEditorPart;
                    p.variants_ordering = e.target.value as string;
                    setPart(p);
                    props.onPartUpdated(p);
                  }}
                  readOnly={props.disabled}
                >
                  <MenuItem value="price_low_high">Price (Ascending)</MenuItem>
                  <MenuItem value="price_high_low">Price (Descending)</MenuItem>
                  <MenuItem value="name_asc">Name (Ascending)</MenuItem>
                  <MenuItem value="name_desc">Name (Descending)</MenuItem>
                  <MenuItem value="custom">Custom Ordering</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
            <FormControl fullWidth className={classes.fieldContainer}>
              <TextField
                id="quantity"
                label={'Quantity'}
                variant="outlined"
                name="quantity"
                value={part.quantity}
                type="number"
                onChange={(e) => {
                  let p = Object.assign({}, part) as BoatEditorPart;
                  p.quantity = e.target.value ? parseInt(e.target.value) : 1;
                  setPart(p);
                }}
                onBlur={() => {
                  props.onPartUpdated(part);
                }}
                helperText=""
                InputProps={{
                  startAdornment: <InputAdornment position="start">x</InputAdornment>,
                }}
              />
            </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.linkedPartsContainer}>
                <div style={{color: '#555', fontSize: 13, marginBottom: 5}}>Must Includes</div>
                {part.must_includes.map((pid, i) => {
                  const parts = props.linkedParts.filter(p => {
                    if (p.id && p.id === pid) return true;
                    if (p.local_id && p.local_id === pid) return true;
                  });
                  if ((parts.length > 0) && (parts[0].part_type === "engine")) return <></>;
                  let label = (parts.length > 0) ? `${parts[0].part_number} — ${parts[0].description}` : `part #${pid}`
                  return (
                    <Chip
                      key={`must_includes_item_${pid}`}
                      label={label}
                      clickable
                      onDelete={() => {
                        if (!window.confirm('remove this item?')) return;
                        let p = Object.assign({}, part) as BoatEditorPart;
                        p.must_includes = part.must_includes.filter(id => id !== pid);
                        p.hidden_parts = part.hidden_parts.filter(id => id !== pid);
                        setPart(p);
                        props.onPartUpdated(p);
                      }}
                      style={{marginTop: 6, marginRight: 6}}
                    />
                  );
                })}
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setAddPartNumber('');
                    setAddPartNumberType('option');
                    setAddPartTarget('must_includes');
                    setShowAddPartNumberForm(true);
                  }}
                >Add Part</Button>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.linkedPartsContainer}>
                <div style={{color: '#555', fontSize: 13, marginBottom: 5}}>Can Includes</div>
                {part.can_includes.map((pid, i) => {
                  const parts = props.linkedParts.filter(p => {
                    if (p.id && p.id === pid) return true;
                    if (p.local_id && p.local_id === pid) return true;
                  });
                  if ((parts.length > 0) && (parts[0].part_type === "engine")) return <></>;
                  let label = (parts.length > 0) ? `${parts[0].part_number} — ${parts[0].description}` : `part #${pid}`
                  return (
                    <Chip
                      key={`can_includes_item_${pid}`}
                      label={label}
                      clickable
                      onDelete={() => {
                        if (!window.confirm('remove this item?')) return;
                        let p = Object.assign({}, part) as BoatEditorPart;
                        p.can_includes = part.can_includes.filter(id => id !== pid);
                        setPart(p);
                        props.onPartUpdated(p);
                      }}
                      style={{marginTop: 6, marginRight: 6}}
                    />
                  );
                })}
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setAddPartNumber('');
                    setAddPartNumberType('option');
                    setAddPartTarget('can_includes');
                    setShowAddPartNumberForm(true);
                  }}
                >Add Part</Button>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.linkedPartsContainer}>
                <div style={{color: '#555', fontSize: 13, marginBottom: 5}}>Must Excludes</div>
                {part.must_excludes.map((pid, i) => {
                  const parts = props.linkedParts.filter(p => {
                    if (p.id && p.id === pid) return true;
                    if (p.local_id && p.local_id === pid) return true;
                  });
                  let label = (parts.length > 0) ? `${parts[0].part_number} — ${parts[0].description}` : `part #${pid}`
                  return (
                    <Chip
                      key={`must_excludes_item_${pid}`}
                      label={label}
                      clickable
                      onDelete={() => {
                        if (!window.confirm('remove this item?')) return;
                        let p = Object.assign({}, part) as BoatEditorPart;
                        p.must_excludes = part.must_excludes.filter(id => id !== pid);
                        setPart(p);
                        props.onPartUpdated(p);
                      }}
                      style={{marginTop: 6, marginRight: 6}}
                    />
                  );
                })}
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setAddPartNumber('');
                    setAddPartNumberType('option');
                    setAddPartTarget('must_excludes');
                    setShowAddPartNumberForm(true);
                  }}
                >Add Part</Button>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.linkedPartsContainer}>
                <div style={{color: '#555', fontSize: 13, marginBottom: 5}}>Unselectable Unless</div>
                {part.unselectable_unless.map((pid, i) => {
                  const parts = props.linkedParts.filter(p => {
                    if (p.id && p.id === pid) return true;
                    if (p.local_id && p.local_id === pid) return true;
                  });
                  let label = (parts.length > 0) ? `${parts[0].part_number} — ${parts[0].description}` : `part #${pid}`
                  return (
                    <Chip
                      key={`unselectable_unless_item_${pid}`}
                      label={label}
                      clickable
                      onDelete={() => {
                        if (!window.confirm('remove this item?')) return;
                        let p = Object.assign({}, part) as BoatEditorPart;
                        p.unselectable_unless = part.unselectable_unless.filter(id => id !== pid);
                        setPart(p);
                        props.onPartUpdated(p);
                      }}
                      style={{marginTop: 6, marginRight: 6}}
                    />
                  );
                })}
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setAddPartNumber('');
                    setAddPartNumberType('option');
                    setAddPartTarget('unselectable_unless');
                    setShowAddPartNumberForm(true);
                  }}
                >Add Part</Button>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.linkedPartsContainer}>
                <div style={{color: '#555', fontSize: 13, marginBottom: 5}}>Hidden Linked Parts</div>
                {part.hidden_parts.map((pid, i) => {
                  const parts = props.linkedParts.filter(p => {
                    if (p.id && p.id === pid) return true;
                    if (p.local_id && p.local_id === pid) return true;
                  });
                  let label = (parts.length > 0) ? `${parts[0].part_number} — ${parts[0].description}` : `part #${pid}`
                  return (
                    <Chip
                      key={`hidden_parts_item_${pid}`}
                      label={label}
                      clickable
                      onDelete={() => {
                        if (!window.confirm('remove this item?')) return;
                        let p = Object.assign({}, part) as BoatEditorPart;
                        p.hidden_parts = part.hidden_parts.filter(id => id !== pid);
                        setPart(p);
                        props.onPartUpdated(p);
                      }}
                      style={{marginTop: 6, marginRight: 6}}
                    />
                  );
                })}
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setAddPartNumber('');
                    setAddPartNumberType('option');
                    setAddPartTarget('hidden_parts');
                    setShowAddPartNumberForm(true);
                  }}
                >Add Part</Button>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PartPictures
                title="Pictures"
                pictures={includedPartPictures}
                onAdded={(picture: PartPicture) => {
                  let newIncludedPartPictures = [...includedPartPictures];
                  newIncludedPartPictures.push(picture);
                  setIncludedPartPictures(newIncludedPartPictures);

                  let p = Object.assign({}, part) as BoatEditorPart;
                  p.pictures.push(picture.id);
                  setPart(p);
                  props.onPartUpdated(p);
                }}
                onRemoved={(picture: PartPicture) => {
                  console.log('removed', picture);
                  let newIncludedPartPictures = includedPartPictures.filter(p => p.id !== picture.id);
                  setIncludedPartPictures(newIncludedPartPictures);

                  let newRemovedPartPictuces = [...removedPartPictures];
                  newRemovedPartPictuces.push(picture);
                  setRemovedPartPictures(newRemovedPartPictuces);

                  let p = Object.assign({}, part) as BoatEditorPart;
                  p.pictures = p.pictures.filter(pid => pid !== picture.id);
                  
                  if (!p.removed_pictures) p.removed_pictures = [];
                  p.removed_pictures.push(picture.id);
                  setPart(p);
                  props.onPartUpdated(p);
                  props.onPartPictureRemoved(picture);
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Collapse>

      {(!props.disableVariants && (partVariants.length > 0)) &&
      <Paper className={classes.partVariantParentContainer}>
        <Typography variant="h6" style={{paddingTop: 12, paddingBottom: 12}}>
          Variants
          <Button
            variant="contained"
            size="small"
            style={{float: 'right'}}
            onClick={() => {
              addNewVariant(part);
            }}
          >Add Variant</Button>
        </Typography>
        {partVariants.map((partVariant, i) => {
          return (
            <PartVariantEditorElement
              key={`part-${group.id}-${part.id}-${partVariant.id}-${i}`}
              part={part}
              partVariant={partVariant}
              group={group}
              parts={parts}
              linkedParts={props.linkedParts}
              partVariants={partVariants}
              boat={boat}
              disabled={props.disabled}
              onLinkedPartUpdated={props.onLinkedPartUpdated}
              onLinkedPartRemoved={props.onLinkedPartRemoved}
              onPartVariantsUpdated={props.onPartVariantsUpdated}
              onPartVariantRemoved={props.onPartVariantRemoved}
              onAddPartToVariant={(variant) => {
                setAddPartNumber('');
                setAddPartNumberType('option');
                setAddPartTarget('variant_part');
                setAddPartTargetVariant(variant);
                setShowAddPartNumberForm(true);
              }}
              onPartPictureRemoved={props.onPartPictureRemoved}
            />
          );
        })}
        {(!props.disableVariants && (partVariants.length > 0)) && <div style={{height: 56, paddingTop: 12, paddingBottom: 12}}>
          <Button
            variant="contained"
            size="small"
            style={{float: 'right'}}
            onClick={() => {
              addNewVariant(part);
            }}
            startIcon={<AddIcon />}
          >Add More Variant</Button>
        </div>}
      </Paper>}

      <Dialog
        open={showAddPartVariantForm}
        maxWidth='xl'
        fullWidth
        onClose={() => {
          setShowAddPartVariantForm(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Add New Part Variant</DialogTitle>
        <DialogContent>
          {addPartVariant && <PartVariantEditorElement
            part={part}
            partVariant={addPartVariant}
            group={group}
            parts={parts}
            linkedParts={props.linkedParts}
            partVariants={partVariants}
            boat={boat}
            disabled={props.disabled}
            onLinkedPartUpdated={props.onLinkedPartUpdated}
            onLinkedPartRemoved={props.onLinkedPartRemoved}
            onPartVariantsUpdated={(partVariants) => {
              setAddPartVariant(partVariants[0]);
            }}
            onPartVariantRemoved={(partVariant) => {
              setShowAddPartVariantForm(false);
            }}
            onPartPictureRemoved={props.onPartPictureRemoved}
          />}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={props.disabled}
            onClick={() => {
              if (addPartVariant) {
                props.onPartVariantsUpdated([addPartVariant]);
                setShowAddPartVariantForm(false);
              }
            }}
          >Add Part</Button>
          <Button
            variant="contained"
            onClick={() => {
              setShowAddPartVariantForm(false);
            }}
          >Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showAddPartNumberForm}
        maxWidth='xs'
        fullWidth
        onClose={() => {
          setShowAddPartNumberForm(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Add New Part</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onAddPart(addPartNumber);
            }}
          >
            <FormControl fullWidth error={!!addPartNumberError} className={classes.fieldContainer}>
              <TextField
                id="part_number"
                label={'Part Number'}
                variant="outlined"
                name="part_number"
                value={addPartNumber}
                disabled={isLoading}
                required
                autoFocus
                size="small"
                onChange={(e) => {
                  setAddPartNumber(e.target.value.trim().toUpperCase());
                }}
                error={!!addPartNumberError}
                helperText={addPartNumberError}
              />
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >Add Part</Button>
          </form>
        </DialogContent>
      </Dialog>

      
      <Dialog
        open={showAddPartForm}
        maxWidth='xl'
        fullWidth
        onClose={() => {
          setShowAddPartForm(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Add New Part</DialogTitle>
        <DialogContent>
          {addPart && <PartEditorElement
            key={`new-part-editor-element`}
            part={addPart}
            group={group}
            groups={props.groups}
            parts={parts}
            linkedParts={props.linkedParts}
            partVariants={addPartVariants}
            boat={boat}
            disabled={props.disabled}
            disableVariants
            onPartUpdated={(part) => setAddPart(part)}
            onLinkedPartUpdated={props.onLinkedPartUpdated}
            onPartVariantsUpdated={(partVariants) => {
              
            }}
            onPartRemoved={(part) => setShowAddPartForm(false)}
            onLinkedPartRemoved={props.onLinkedPartRemoved}
            onPartVariantRemoved={(partVariant) => {
              
            }}
            onBoatUpdated={(boat) => {

            }}
            onPartPictureRemoved={(picture) => props.onPartPictureRemoved(picture)}
            expanded
          />}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={() => {
              if (addPart && addPartTarget) {
                let pid = addPart.id ? addPart.id : addPart.local_id;

                if (pid && (addPartTarget === 'variant_part') && addPartTargetVariant && !addPartTargetVariant.parts.includes(pid)) {
                  let p = Object.assign({}, addPartTargetVariant) as BoatEditorPartVariant;
                  p.parts.push(pid);
                  props.onPartVariantsUpdated([p]);
                }

                let p = Object.assign({}, part) as BoatEditorPart;

                if (pid && (addPartTarget === 'must_includes') && (!part.must_includes.includes(pid))) {
                  p.must_includes.push(pid);
                }
                if (pid && (addPartTarget === 'can_includes') && (!part.can_includes.includes(pid))) {
                  p.can_includes.push(pid);
                }
                if (pid && (addPartTarget === 'must_excludes') && (!part.must_excludes.includes(pid))) {
                  p.must_excludes.push(pid);
                }
                if (pid && (addPartTarget === 'unselectable_unless') && (!part.unselectable_unless.includes(pid))) {
                  p.unselectable_unless.push(pid);
                }
                if (pid && ((addPartTarget === 'must_includes') || (addPartTarget === 'hidden_parts')) && (!part.hidden_parts.includes(pid))) {
                  p.hidden_parts.push(pid);
                }

                setPart(p);
                props.onPartUpdated(p);

                props.onLinkedPartUpdated(addPart);
                setShowAddPartForm(false);
              }
            }}
          >Add Part</Button>
          <Button
            variant="contained"
            onClick={() => {
              setShowAddPartForm(false);
            }}
          >Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
    </>
  );
}
