import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import {
  Skeleton
} from '@material-ui/lab';

import DateTimeView from '../singles/DateTimeView';
import OrderDetail from '../singles/OrderDetail';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import { useProfile } from '../../data/Profile';
import {
  Order
} from '../../types';
import localization from '../../utils/localizations';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LoadingView() {
  return (
    <div style={{position: 'relative', padding: 10}}>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
    </div>
  );
}
export default function OrderList(props: {className?: string}) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [orders, setOrders] = useState([] as Order[]);
  const [ordersMeta, setOrdersMeta] = useState({
    count: 0,
    page_size: 10,
    num_pages: 0,
    page: 0,
    next: 0
  });

  const { path, url } = useRouteMatch();
  const backUrl = `${url}../`;

  const loadOrders = async (page?: number) => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    let url = `thin-orders/`;
    if (page) {
      url = `${url}?page=${page}`;
    }
    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        if (page && (page > ordersMeta.page)) {
          const results = data.results as Order[];
          setOrders(Object.assign([], orders).concat(results));
        }
        else {
          setOrders(data.results as Order[]);
        }

        setIsLoading(false);
        setOrdersMeta({
          count: data.count,
          page_size: data.page_size,
          num_pages: data.num_pages,
          page: data.page,
          next: data.next
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadOrders();
  }, [])

  useEffect(() => {
    if (ordersMeta.next) {
      loadOrders(ordersMeta.next);
    }
  }, [ordersMeta.next]);

  if (isLoading && orders.length === 0) {
    return (
      <div className={`${classes.root} ${props.className}`}>
        <LoadingView />
      </div>
    );
  }


  return (
    <div className={`${classes.root} ${props.className}`}>
      <List className={classes.root}>
        {orders.map((order) => {
          let selectedBoats = '';
          order.items.forEach((item) => {
            if (item?.part?.part_type == 'boat')
            selectedBoats = `${item?.part?.description ? item?.part?.description : ''} ${selectedBoats}`
          })
          selectedBoats = `${order.boat_name} — ${selectedBoats}`;
          return (
            <ListItem
              key={`order-${order.id}`}
              role={undefined}
              dense
              button
              to={`${appConfig.homepage}orders/${order.id}/`}
              component={RouterLink}
            >
              <ListItemIcon>
                #{order.id}
              </ListItemIcon>
              <ListItemText primary={selectedBoats} secondary={<DateTimeView value={order.created_at} chip size="small" />} />
              <ListItemSecondaryAction>
                {profile?.role !== 'admin' && 
                <Typography>{formatNumber({prefix: 'US$'})(parseFloat(order.total_price))}</Typography>}
                {profile?.role === 'admin' && 
                <Typography>{order.dealer_name}</Typography>}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      {orders.length === 0 && 
      <List>
        <ListItem>
          <ListItemText primary="[Empty]" />
        </ListItem>
      </List>}
      {isLoading &&
      <div style={{textAlign: 'center'}}>
        <CircularProgress />
      </div>
      }
    </div>
  );
}