import React, {
  useState,
  useEffect
} from 'react';


import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Divider,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import formatNumber from 'format-number';
import bankersRounding from '../../../utils/bankersRounding';

import {
  BoatPartGroup
} from './parts/BoatPartGroup';

import {
  Boat,
  Part,
  PartGroup,
  GroupedPart,
  Dealer,
  PartVariant,
  PartRule,
  DealerDiscount,
} from '../../../types';

import {
  OrderLine,
  PartOrderLine,
  DiscountItem,
  FormValues
} from './OrderTypes';


const useStyles = makeStyles({
  root: {

  },
  partGroup: {
    padding: 20
  }
});


export function BoatConfigurator(props: {
  boat: Boat;
  dealer?: Dealer;
  orderLines: OrderLine[];
  setOrderLines: (orderLines: OrderLine[]) => void;
  showHiddenInfo?: boolean;
  className?: string;
}) {
  const classes = useStyles();

  const engineGroups = props.boat.grouped_parts.filter(gp => gp.group?.part_type === 'engine');
  const partGroups = props.boat.grouped_parts.filter(gp => gp.group?.part_type !== 'engine').filter(gp => !gp.hidden);
  const footerGroupNames = props.boat.ui_combined_footer_groups.map(g => g.group ? g.group.name : '');
  const footerGroups = props.boat.grouped_parts.filter(gp => footerGroupNames.includes(gp.group?.name ? gp.group.name : ''));

  return (
    <div className={props.className}>
      
      {engineGroups.map((gp, i) => (
        <BoatPartGroup
          key={`gp-engine-${i}`}
          groupedPart={gp}
          boat={props.boat}
          dealer={props.dealer}
          orderLines={props.orderLines}
          setOrderLines={props.setOrderLines}
          showHiddenInfo={props.showHiddenInfo}
          className={classes.partGroup}
        />
      ))}

      {partGroups.map((gp, i) => (
        <BoatPartGroup
          key={`gp-default-${i}`}
          groupedPart={gp}
          boat={props.boat}
          dealer={props.dealer}
          orderLines={props.orderLines}
          setOrderLines={props.setOrderLines}
          showHiddenInfo={props.showHiddenInfo}
          className={classes.partGroup}
        />
      ))}

      {footerGroups.map((gp, i) => (
        <BoatPartGroup
          key={`gp-footer-${i}`}
          groupedPart={gp}
          boat={props.boat}
          dealer={props.dealer}
          orderLines={props.orderLines}
          setOrderLines={props.setOrderLines}
          showHiddenInfo={props.showHiddenInfo}
          className={classes.partGroup}
          hideGroupLabel
          oneLiner
        />
      ))}

    </div>
  );
}
