import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  Modal,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import {
  APIError,
  BaseAPI,
} from '../../data/BaseAPI';

import {
  useProfile
} from '../../data/Profile';

import {
  BoatEditor,
  BoatEditorPartGroup,
  BoatEditorPart,
  BoatEditorPartVariant,
  BoatEditorPartOption,
  PartPicture,
  Session,
} from '../../types';

import GroupEditorElement from './boat-editor/GroupEditorElement';
import PartPreview from './boat-editor/PartPreview';

import localization from '../../utils/localizations';

import DateTimeView from '../singles/DateTimeView';
import PartPictures from '../collections/PartPictures';

const useStyles = makeStyles({
  root: {

  },
  groupContainer: {
    padding: 12,
    marginBottom: 24
  },
  partContainer: {
    borderTop: 'solid 1px #ddd',
    marginTop: 12,
    marginRight: -12,
    marginLeft: -12,
    paddingTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
    '&:hover': {
      backgroundColor: '#f9f9f9'
    }
  },
  partFieldsContainer: {

  },
  fieldContainer: {
    marginBottom: 20,
    '& .MuiInputBase-root ': {
      backgroundColor: '#fff'
    }
  },
  partVariantParentContainer: {
    paddingLeft: 12,
    paddingRight: 12,
    borderLeft: 'solid 5px #4caf50',
  },
  partVariantContainer: {
    borderTop: 'solid 1px #ddd',
    marginRight: -12,
    marginLeft: -12,
    padding: 12,
    '&:hover': {
      backgroundColor: '#f9f9f9'
    }
  },
  partVariantFieldsContainer: {

  },
  loadingContainer: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    border: 'solid 1px #ddd',
    borderRadius: 4,
    padding: 12,
    top: 9,
    marginTop: 'calc(50vh - 50px)',
    marginLeft: 'calc(50% - 200px)',
    left: 100,
    width: 200,
    height: 100,
    zIndex: 99999,
  },
  closeButton: {
    padding: 4,
  },
  form: {
    marginBottom: 24,
    padding: 12,
  }
});


export default function BoatEditorPartForm(props: {
  boat: BoatEditor;
  onSave: (boat: BoatEditor) => void;
  className?: string;
}) {
  const classes = useStyles();
  const [boat, setBoat] = useState(props.boat);
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  const [groupsPageSize, setGroupsPageSize] = useState(200);
  const [groups, setGroups] = useState<BoatEditorPartGroup[]>([]);
  const [groupsMeta, setGroupsMeta] = useState({
    count: 0,
    page_size: 10,
    num_pages: 0,
    page: 0,
    next: 0
  });

  const [partsPageSize, setPartsPageSize] = useState(200);
  const [parts, setParts] = useState<BoatEditorPart[]>([]);
  const [linkedParts, setLinkedParts] = useState<BoatEditorPart[]>([]);
  const [partsMeta, setPartsMeta] = useState({
    count: 0,
    page_size: 10,
    num_pages: 0,
    page: 0,
    next: 0
  });

  const [partVariantsPageSize, setPartVariantsPageSize] = useState(200);
  const [partVariants, setPartVariants] = useState<BoatEditorPartVariant[]>([]);
  const [partVariantIdsRemoved, setPartVariantIdsRemoved] = useState<number[]>([]);
  const [partVariantsMeta, setPartVariantsMeta] = useState({
    count: 0,
    page_size: 10,
    num_pages: 0,
    page: 0,
    next: 0
  });

  const [includedBoatPartPictures, setIncludedBoatPartPictures] = useState<PartPicture[]>([]);
  const [removedPartPictures, setRemovedPartPictures] = useState<PartPicture[]>([]);

  const saveBoat = async (boat: BoatEditor, parts: BoatEditorPart[], partVariants: BoatEditorPartVariant[], linkedParts: BoatEditorPart[]) => {
    const api = new BaseAPI();
    setShowErrorMessage('');
    setIsSaving(true);
    let success = false;
    let boatId: number|undefined = undefined;
    try {
      
      const [result, response, error] = await api.post({
        boat: boat,
        parts: parts,
        variants: partVariants,
        linked_parts: linkedParts,
        removed_variants: partVariantIdsRemoved,
        pictures: includedBoatPartPictures.map(picture => picture.id),
        removed_pictures: removedPartPictures.map(picture => picture.id),
      }, 'boats/editor/submit/');
      success = result.success;
      console.log('result', result);
      setIsSaving(false);
      
      if (success && result.boat) return result.boat as BoatEditor;
      else if (!success && result.errors) {
        setShowErrorMessage("Error submitting your order: " + result.errors.join(', '));
      }
      else if (!success) {
        setShowErrorMessage("Error submitting your order. Please try again. Contact us if the issue persist.");
      }
    
    } catch (e) {
      console.log('error:', e, (e as any).errorData);
      let errorMessage = "Error submitting your order. Please try again. Contact us if the issue persist."
      if ((e as any).errorData && (e as any).errorData.errors) {
        errorMessage = (e as any).errorData.errors.join(', ');
        errorMessage = "Error submitting your order: " + errorMessage;
      }
      setShowErrorMessage(errorMessage);
    }

    setIsSaving(false);
  };

  const onSubmitBoat = async (boat: BoatEditor, parts: BoatEditorPart[], partVariants: BoatEditorPartVariant[], linkedParts: BoatEditorPart[]) => {
    let savedBoat = await saveBoat(boat, parts, partVariants, linkedParts);
    if (savedBoat) {
      setBoat(savedBoat);
      props.onSave(savedBoat);
    }
  }
  
  const loadGroups = async (page?: number) => {
    const api = new BaseAPI();

    let url = `boats/editor/part-groups/`;
    let kwargs: any = {
      page_size: groupsPageSize,
    };
    if (page) kwargs.page = page;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        const results = data.results as BoatEditorPartGroup[];
        results.push({
          "id": 0,
          "name": "Other",
          "part_type": "option",
          "group_behavior": "normal",
          "items_ordering": "custom",
          "parent": null,
          "order": 9999,
          "csv_order": 9999,
          "active": true,
          "required": false,
          "children": []
        });
        setGroups(results);

        setGroupsMeta({
          count: data.count,
          page_size: data.page_size,
          num_pages: data.num_pages,
          page: data.page,
          next: data.next
        });
      }
    } catch (error) {
      console.error(error);
    }

  };


  const loadParts = async (boatId: number, page?: number) => {
    const api = new BaseAPI();
    
    let url = `boats/editor/parts/`;
    let kwargs: any = {
      page_size: partsPageSize,
      boat: boatId,
    };
    if (page) kwargs.page = page;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        const results = data.results as BoatEditorPart[];
        setParts(results);

        setPartsMeta({
          count: data.count,
          page_size: data.page_size,
          num_pages: data.num_pages,
          page: data.page,
          next: data.next
        });

        return results;
      }
    } catch (error) {
      console.error(error);
    }

    return [] as BoatEditorPart[];
  };


  const loadPart = async (partId: number) => {
    const api = new BaseAPI();
    
    let url = `boats/editor/parts/${partId}/`;
    try {
      const data: any = await api.get(url);
      if (data.id) {
        return data as BoatEditorPart;
      }
    } catch (error) {
      console.error(error);
    }

    return undefined;
  };


  const loadLinkedParts = async (parts: BoatEditorPart[], variants: BoatEditorPartVariant[]) => {
    const api = new BaseAPI();

    let linkedPartIds: number[] = [];

    parts.forEach(part => {
      part.must_includes.forEach(pid => {
        if (!linkedPartIds.includes(pid)) linkedPartIds.push(pid);
      });
      part.can_includes.forEach(pid => {
        if (!linkedPartIds.includes(pid)) linkedPartIds.push(pid);
      });
      part.must_excludes.forEach(pid => {
        if (!linkedPartIds.includes(pid)) linkedPartIds.push(pid);
      });
      part.unselectable_unless.forEach(pid => {
        if (!linkedPartIds.includes(pid)) linkedPartIds.push(pid);
      });
      part.hidden_parts.forEach(pid => {
        if (!linkedPartIds.includes(pid)) linkedPartIds.push(pid);
      });
      part.variants.map(variantId => {
        let vl = variants.filter(variant => variant.id === variantId);
        vl.forEach(variant => {
          variant.parts.forEach(pid => {
            if (!linkedPartIds.includes(pid)) linkedPartIds.push(pid);
          });
        });
      })
    })

    let linkedPartPromises = linkedPartIds.map(pid => loadPart(pid));

    let linkedParts = await Promise.all(linkedPartPromises);
    return linkedParts.filter(p => p !== undefined);

  };


  const loadPartVariants = async (boatId: number, page?: number) => {
    const api = new BaseAPI();

    let url = `boats/editor/part-variants/`;
    let kwargs: any = {
      page_size: partVariantsPageSize,
      boat: boatId,
    };
    if (page) kwargs.page = page;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        const results = data.results as BoatEditorPartVariant[];
        setPartVariants(results);

        setPartVariantsMeta({
          count: data.count,
          page_size: data.page_size,
          num_pages: data.num_pages,
          page: data.page,
          next: data.next
        });
        return results;
      }
    } catch (error) {
      console.error(error);
    }

    return [] as BoatEditorPartVariant[];
  };

  const updatePart = (newPart: BoatEditorPart) => {
    let added = false;
    let newParts = parts.map(part => {
      if (newPart.local_id && (newPart.local_id === part.local_id)) {
        added = true;
        return newPart;
      }
      else if (newPart.id && (newPart.id === part.id)) {
        added = true;
        return newPart;
      }
      return part;
    });
    if (!added) {
      newParts.push(newPart);
    }
    setParts(newParts);
  }

  const removePart = (newPart: BoatEditorPart) => {
    let newParts = parts.filter(part => {
      if (newPart.local_id && (newPart.local_id === part.local_id)) {
        return false;
      }
      else if (newPart.id && (newPart.id === part.id)) {
        return false;
      }
      return true;
    }).map(part => part);
    
    if (newPart.local_id) removePartVariantForPart(newPart.local_id);
    else removePartVariantForPart(newPart.id);
    setParts(newParts);

    let b = Object.assign({}, boat) as BoatEditor;
    b.recommended_parts = boat.recommended_parts.filter(pid => {
      if (newPart.id) return pid !== newPart.id;
      else if (newPart.local_id) return pid !== newPart.local_id;
    });
    
    b.required_parts = boat.required_parts.filter(pid => {
      if (newPart.id) return pid !== newPart.id;
      else if (newPart.local_id) return pid !== newPart.local_id;
    });
    
    setBoat(b);
  }

  const updateLinkedPart = (newPart: BoatEditorPart) => {
    let added = false;
    let newParts = linkedParts.map(part => {
      if (newPart.local_id && (newPart.local_id === part.local_id)) {
        added = true;
        return newPart;
      }
      else if (newPart.id && (newPart.id === part.id)) {
        added = true;
        return newPart;
      }
      return part;
    });
    if (!added) {
      newParts.push(newPart);
    }
    setLinkedParts(newParts);
  }

  const removeLinkedPart = (newPart: BoatEditorPart) => {
    let newParts = linkedParts.filter(part => {
      if (newPart.local_id && (newPart.local_id === part.local_id)) {
        return false;
      }
      else if (newPart.id && (newPart.id === part.id)) {
        return false;
      }
      return true;
    }).map(part => part);
    
    if (newPart.local_id) removePartVariantForPart(newPart.local_id);
    else removePartVariantForPart(newPart.id);
    setLinkedParts(newParts);
  }

  const updatePartVariant = (newPartVariant: BoatEditorPartVariant) => {
    let added = false;
    let newPartVariants = partVariants.map(partVariant => {
      if (newPartVariant.local_id && (newPartVariant.local_id === partVariant.local_id)) {
        added = true;
        return newPartVariant;
      }
      else if (newPartVariant.id && (newPartVariant.id === partVariant.id)) {
        added = true;
        return newPartVariant;
      }
      return partVariant;
    });
    if (!added) {
      newPartVariants.push(newPartVariant);
    }
    setPartVariants(newPartVariants);
  }

  const updatePartVariants = (newPartVariants: BoatEditorPartVariant[]) => {
    let addedList: BoatEditorPartVariant[] = [];
    let newList = partVariants.map(partVariant => {
      let matchedPartVariants = newPartVariants.filter(newPartVariant => {
        if (newPartVariant.local_id && (newPartVariant.local_id === partVariant.local_id)) {
          return true;
        }
        else if (newPartVariant.id && (newPartVariant.id === partVariant.id)) {
          return true;
        }
        return false;
      });
      if (matchedPartVariants.length > 0) {
        addedList.push(matchedPartVariants[0]);
        return matchedPartVariants[0];
      }
      return partVariant;
    });

    newPartVariants.forEach(newPartVariant => {
      if (!addedList.includes(newPartVariant)) newList.push(newPartVariant);
    });
    setPartVariants(newList);
  }

  const removePartVariant = (newPartVariant: BoatEditorPartVariant) => {
    let newPartVariantIdsRemoved = [...partVariantIdsRemoved];
    let newPartVariants = partVariants.filter(partVariant => {
      if (newPartVariant.local_id && (newPartVariant.local_id === partVariant.local_id)) {
        return false;
      }
      else if (newPartVariant.id && (newPartVariant.id === partVariant.id)) {
        newPartVariantIdsRemoved.push(newPartVariant.id);
        return false;
      }
      return true;
    }).map(partVariant => partVariant);
    setPartVariants(newPartVariants);
    setPartVariantIdsRemoved(newPartVariantIdsRemoved);
  }

  const removePartVariantForPart = (partId: number) => {
    let newPartVariantIdsRemoved = [...partVariantIdsRemoved];
    let newPartVariants = partVariants.filter(partVariant => {
      if (partVariant.part_local && (partVariant.part_local === partId)) {
        return false;
      }
      else if (partVariant.id && (partVariant.id === partId)) {
        newPartVariantIdsRemoved.push(partVariant.id);
        return false;
      }
      return true;
    }).map(partVariant => partVariant);
    setPartVariants(newPartVariants);
    setPartVariantIdsRemoved(newPartVariantIdsRemoved);
  }

  const loadPartPicture = async (partPictureId: string) => {
    const api = new BaseAPI();
    
    let url = `boats/editor/part-pictures/${partPictureId}/`;
    try {
      const data: any = await api.get(url);
      if (data.id) {
        return data as PartPicture;
      }
    } catch (error) {
      console.error(error);
    }

    return undefined;
  };

  const loadPartPictures = async (ids: string[]) => {
    let picturePromises = ids.map(pid => loadPartPicture(pid));

    let pictures = await Promise.all(picturePromises);
    return pictures.filter(p => p !== undefined) as PartPicture[];
  }

  const loadAllData = async(boat: BoatEditor) => {
    setIsLoading(true);
    const results = await Promise.all([loadGroups(), loadParts(boat.id), loadPartVariants(boat.id)]);
    let linkedParts = await loadLinkedParts(results[1], results[2]);
    setLinkedParts(linkedParts as BoatEditorPart[]);

    let boatPartPictures = await loadPartPictures(boat.pictures);
    setIncludedBoatPartPictures(boatPartPictures);
    setIsLoading(false);
  }

  useEffect(() => {
    setBoat(props.boat);
    loadAllData(props.boat);
  }, [props.boat]);

  if (!boat) return (<></>);
  return (
    <div className={`${classes.root} ${props.className ? props.className : ''}`}>
      <Paper>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setShowPreview(true);
          }}
          className={classes.form}
        >
          <Grid container spacing={2}>
            
            <Grid item xs={10}>
              <FormControl fullWidth className={classes.fieldContainer}>
                <TextField
                  id={'name'}
                  label={'Name'}
                  variant="outlined"
                  required
                  name="name"
                  value={boat.name}
                  onChange={(e) => {
                    let b = Object.assign({}, boat) as BoatEditor;
                    b.name = e.target.value;
                    setBoat(b);
                  }}
                />
              </FormControl>
            </Grid>
          
            <Grid item xs={2}>
              <FormControl fullWidth className={classes.fieldContainer}>
                <TextField
                  id={'sort_key'}
                  label={'Sort Key'}
                  variant="outlined"
                  name="sort_key"
                  value={boat.sort_key}
                  onChange={(e) => {
                    let b = Object.assign({}, boat) as BoatEditor;
                    b.sort_key = e.target.value;
                    setBoat(b);
                  }}
                />
              </FormControl>
            </Grid>
          
            <Grid item xs={12}>
              <FormControl fullWidth className={classes.fieldContainer}>
                <TextField
                  id={'product_url'}
                  label={'Product URL'}
                  variant="outlined"
                  name="product_url"
                  value={boat.product_url}
                  onChange={(e) => {
                    let b = Object.assign({}, boat) as BoatEditor;
                    b.product_url = e.target.value;
                    setBoat(b);
                  }}
                />
              </FormControl>
            </Grid>
          
            <Grid item xs={12}>
              <FormControl fullWidth className={classes.fieldContainer}>
                <TextField
                  id={'specs_pdf_url'}
                  label={'PDF Specs URL'}
                  variant="outlined"
                  name="specs_pdf_url"
                  value={boat.specs_pdf_url}
                  onChange={(e) => {
                    let b = Object.assign({}, boat) as BoatEditor;
                    b.specs_pdf_url = e.target.value;
                    setBoat(b);
                  }}
                />
              </FormControl>
            </Grid>
          
            <Grid item xs={12}>
              <FormControl fullWidth className={classes.fieldContainer}>
                <TextField
                  id={'standard_option'}
                  label={'Standard Options'}
                  variant="outlined"
                  name="standard_option"
                  value={boat.standard_option}
                  multiline
                  rowsMax={20}
                  onChange={(e) => {
                    let b = Object.assign({}, boat) as BoatEditor;
                    b.standard_option = e.target.value;
                    setBoat(b);
                  }}
                  helperText="Markdown formatting accepted"
                />
              </FormControl>
            </Grid>
          
            <Grid item xs={2}>
              <FormControlLabel
                control={<Checkbox
                  checked={boat.active}
                  onChange={(e) => {
                    let b = Object.assign({}, boat) as BoatEditor;
                    b.active = e.target.checked;
                    setBoat(b);
                  }}
                  name="active"
                />}
                label="Active"
              />
            </Grid>

            <Grid item xs={2}>
              <FormControlLabel
                control={<Checkbox
                  checked={boat.enabled_in_builder}
                  onChange={(e) => {
                    let b = Object.assign({}, boat) as BoatEditor;
                    b.enabled_in_builder = e.target.checked;
                    setBoat(b);
                  }}
                  name="enabled_in_builder"
                />}
                label="Enabled in Builder"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth className={classes.fieldContainer}>
                <TextField
                  id={'override_builder_url'}
                  label={'Override Builder URL'}
                  variant="outlined"
                  name="override_builder_url"
                  value={boat.override_builder_url}
                  onChange={(e) => {
                    let b = Object.assign({}, boat) as BoatEditor;
                    b.override_builder_url = e.target.value;
                    setBoat(b);
                  }}
                  helperText="If set, visitors will be redirected to this url when they click this boat in the builder ui."
                />
              </FormControl>
            </Grid>
          
            <Grid item xs={12}>
              <PartPictures
                title="Gallery"
                pictures={includedBoatPartPictures}
                onAdded={(picture: PartPicture) => {
                  let newIncludedBoatPartPictures = [...includedBoatPartPictures];
                  newIncludedBoatPartPictures.push(picture);
                  setIncludedBoatPartPictures(newIncludedBoatPartPictures);
                }}
                onRemoved={(picture: PartPicture) => {
                  console.log('removed', picture);
                  let newIncludedBoatPartPictures = includedBoatPartPictures.filter(p => p.id !== picture.id);
                  setIncludedBoatPartPictures(newIncludedBoatPartPictures);

                  let newRemovedPartPictuces = [...removedPartPictures];
                  newRemovedPartPictuces.push(picture);
                  setRemovedPartPictures(newRemovedPartPictuces);
                }}
              />
            </Grid>

          </Grid>

        </form>
      </Paper>

      {groups.map(group => {
        return (
          <GroupEditorElement
            key={`group-${group.id}`}
            group={group}
            groups={groups}
            boat={boat}
            parts={parts}
            linkedParts={linkedParts}
            partVariants={partVariants}
            disabled={isLoading || isSaving}
            onPartUpdated={(part) => {
              updatePart(part);
            }}
            onLinkedPartUpdated={(part) => {
              updateLinkedPart(part);
            }}
            onPartVariantsUpdated={(partVariants) => {
              updatePartVariants(partVariants);
            }}
            onPartRemoved={(part) => {
              removePart(part);
            }}
            onLinkedPartRemoved={(part) => {
              removeLinkedPart(part);
            }}
            onPartVariantRemoved={(partVariant) => {
              removePartVariant(partVariant);
            }}
            onBoatUpdated={boat => {
              setBoat(boat);
              console.log(boat)
            }}
            onPartPictureRemoved={(picture: PartPicture) => {
              console.log('removed', picture);
              let newIncludedBoatPartPictures = includedBoatPartPictures.filter(p => p.id !== picture.id);
              setIncludedBoatPartPictures(newIncludedBoatPartPictures);

              let newRemovedPartPictuces = [...removedPartPictures];
              newRemovedPartPictuces.push(picture);
              setRemovedPartPictures(newRemovedPartPictuces);
            }}
          />
        );
      })}

      <div style={{marginBottom: 24}}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            setShowPreview(true);
          }}
          startIcon={<SaveIcon />}
          disabled={isLoading||isSaving}
        >{isSaving ? 'Saving...' : 'Save'}</Button>
      </div>

      <Modal open={isLoading||isSaving} disableRestoreFocus>
        <div className={classes.loadingContainer}>
          <CircularProgress size={32} />
          <div style={{marginTop: 12}}>{isSaving ? `Saving...` : `Loading...`}</div>
        </div>
      </Modal>

      <Snackbar
        key={'error-snackbar-custom'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!showErrorMessage}
        onClose={() => setShowErrorMessage('')}
        onExited={() => setShowErrorMessage('')}
        message={showErrorMessage}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.closeButton}
              onClick={() => setShowErrorMessage('')}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />

      <Dialog
        open={showPreview}
        maxWidth='xl'
        fullWidth
        onClose={() => {
          setShowPreview(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Preview Your Changes</DialogTitle>
        <DialogContent>
          <PartPreview
            boat={boat}
            groups={groups}
            parts={parts}
            linkedParts={linkedParts}
            partVariants={partVariants}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={() => {
              setShowPreview(false);
              onSubmitBoat(boat, parts, partVariants, linkedParts);
            }}
          >Save</Button>
          <Button
            variant="contained"
            onClick={() => {
              setShowPreview(false);
            }}
          >Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
