import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Grid,
  Paper,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Toolbar,
  IconButton,
  Card,
  CardActions,
  CardContent,
  Tooltip,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import {
  TransitionProps
} from '@material-ui/core/transitions';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import {
  BaseAPI,
  APIError,
} from '../../data/BaseAPI';
import {
  useProfile
} from '../../data/Profile';
import {
  Dealer,
  DealerAddress,
  DealerUser,
  DealerLocation,
  DealerLocationTerritoryCountry,
  DealerLocationTerritoryState,
  DealerLocationTerritoryCounty,
} from '../../types';
import DealerChip from './DealerChip';

import localization from '../../utils/localizations';
import DealerLocationForm from '../forms/DealerLocationForm';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
  },
  partNumber: {
    marginRight: 10,
    minWidth: 100,
  },
  alignRight: {
    textAlign: 'right',
  },
  bolder: {
    fontWeight: 'bold'
  },
  noteContainer: {
    marginBottom: 20,
  },
  columnContainer: {
    padding: 20,
    paddingTop: 10,
    paddingBottom: 6,
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  closeButton: {
    padding: 4,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function DealerLocationDetail(props: {
  dealerLocation: DealerLocation;
  onUpdated: (dealerLocation: DealerLocation) => void;
  onDeleted: (dealerLocation: DealerLocation) => void;
  className?: string;
}) {
  const classes = useStyles();
  const [showEditForm, setShowEditForm] = useState(false);
  const [showEditTerritoryZipCodesForm, setShowEditTerritoryZipCodesForm] = useState(false);
  const [showEditTerritoryCountiesForm, setShowEditTerritoryCountiesForm] = useState(false);
  const [showEditTerritoryStatesForm, setShowEditTerritoryStatesForm] = useState(false);
  const [showEditTerritoryCountriesForm, setShowEditTerritoryCountriesForm] = useState(false);
  const [redirect, setRedirect] = useState('');
  const dealerLocation = props.dealerLocation;

  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();

  const deleteDealerLocation = async (dealerLocation: DealerLocation) => {
    const api = new BaseAPI();
    try {
      const [result, request] = await api.delete(`admin-dealer-locations/${dealerLocation.id}/`);
      props.onDeleted(dealerLocation);
    } catch (error) {
      console.error(error);
    }
  };

  const reloadCoordinate = async (id: string) => {
    const api = new BaseAPI();
    try {
      const [result, response, error] = await api.post({}, `admin-dealer-locations/${id}/reload_coordinate/`);
      if (result.success) {
        if (props.onUpdated) props.onUpdated(props.dealerLocation);
      }
      else {
        return result;
      }
    }
    catch (e) {
      console.log(e);
      if ((e as APIError).errorData) {
        return (e as APIError).errorData;
      }
    }
  }

  const deleteTerritoryOverrideZip = async (id: string) => {
    const api = new BaseAPI();
    try {
      const [result, response, error] = await api.post({"id": id}, `admin-dealer-locations/${id}/delete_territory_zip_code/`);
      if (result.success) {
        if (props.onUpdated) props.onUpdated(props.dealerLocation);
      }
      else {
        return result;
      }
    }
    catch (e) {
      console.log(e);
      if ((e as APIError).errorData) {
        return (e as APIError).errorData;
      }
    }
  }

  const deleteTerritoryOverrideCounty = async (id: string) => {
    const api = new BaseAPI();
    try {
      const [result, response, error] = await api.post({"id": id}, `admin-dealer-locations/${id}/delete_territory_county/`);
      if (result.success) {
        if (props.onUpdated) props.onUpdated(props.dealerLocation);
      }
      else {
        return result;
      }
    }
    catch (e) {
      console.log(e);
      if ((e as APIError).errorData) {
        return (e as APIError).errorData;
      }
    }
  }

  const deleteTerritoryOverrideState = async (id: string) => {
    const api = new BaseAPI();
    try {
      const [result, response, error] = await api.post({"id": id}, `admin-dealer-locations/${id}/delete_territory_state/`);
      if (result.success) {
        if (props.onUpdated) props.onUpdated(props.dealerLocation);
      }
      else {
        return result;
      }
    }
    catch (e) {
      console.log(e);
      if ((e as APIError).errorData) {
        return (e as APIError).errorData;
      }
    }
  }

  const deleteTerritoryOverrideCountry = async (id: string) => {
    const api = new BaseAPI();
    try {
      const [result, response, error] = await api.post({"id": id}, `admin-dealer-locations/${id}/delete_territory_country/`);
      if (result.success) {
        if (props.onUpdated) props.onUpdated(props.dealerLocation);
      }
      else {
        return result;
      }
    }
    catch (e) {
      console.log(e);
      if ((e as APIError).errorData) {
        return (e as APIError).errorData;
      }
    }
  }

  let isAdmin = false;
  if (profile && (profile.role === 'admin')) {
    isAdmin = true;
  }

  let canEdit = false;
  if (profile && (profile.role === 'admin')) {
    canEdit = true;
  }
  let canDelete = canEdit;

  return (
    <div className={`${classes.root} ${props.className}`}>

      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper className={classes.columnContainer}>
            <Typography variant="h6">Dealer Location <Chip color={props.dealerLocation.active ? 'primary' : 'secondary'} size="small" label={dealerLocation.active ? "active" : "inactive"} style={{marginRight: 6}} /></Typography>
            <Typography variant="body2">
              Title: {dealerLocation.title} <br />
              Dealer: <DealerChip id={dealerLocation.dealer} size="small" link /> <br />
              Address line 1: {dealerLocation.address_line_1 ? dealerLocation.address_line_1 : '-'} <br />
              Address line 2: {dealerLocation.address_line_2 ? dealerLocation.address_line_2 : '-'} <br />
              City: {dealerLocation.city ? dealerLocation.city : '-'} <br />
              State: {dealerLocation.state ? dealerLocation.state : '-'} <br />
              Country: {dealerLocation.country ? dealerLocation.country : '-'} <br />
              Zip: {dealerLocation.zip ? dealerLocation.zip : '-'} <br />
              Website: {dealerLocation.website ? <a href={`${dealerLocation.website}`}>{dealerLocation.website}</a>: '-'} <br />
              Phone 1: {dealerLocation.phone_1 ? <a href={`tel:${dealerLocation.phone_1}`}>{dealerLocation.phone_1}</a>: '-'} <br />
              Phone 2: {dealerLocation.phone_2 ? <a href={`tel:${dealerLocation.phone_2}`}>{dealerLocation.phone_2}</a>: '-'} <br />
              Phone 3: {dealerLocation.phone_3 ? <a href={`tel:${dealerLocation.phone_3}`}>{dealerLocation.phone_3}</a>: '-'} <br />
              Fax: {dealerLocation.fax ? <a href={`tel:${dealerLocation.fax}`}>{dealerLocation.fax}</a>: '-'} <br />
              Email: {dealerLocation.email ? <a href={`mailto:${dealerLocation.email}`}>{dealerLocation.email}</a>: '-'} <br />
              Public Note: {dealerLocation.public_note ? dealerLocation.public_note : '-'} <br />
              Admin Note: {dealerLocation.admin_note ? dealerLocation.admin_note : '-'} <br />
              Lat: {dealerLocation.latitude ? dealerLocation.latitude : '-'} <br />
              Lng: {dealerLocation.longitude ? dealerLocation.longitude : '-'} <br />
              {(dealerLocation.latitude || dealerLocation.longitude) &&
              <a href={`https://www.google.com/maps/search/?api=1&query=${dealerLocation.latitude}%2C${dealerLocation.longitude}`} target="_blank">View on Google Maps</a>}
            </Typography>

            <div style={{marginBottom: 10, marginTop: 20, display: 'flex', justifyContent: 'space-between'}}>
              {canEdit &&
              <div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (dealerLocation.id) reloadCoordinate(dealerLocation.id);
                  }}
                  style={{marginRight: 10}}
                  size="small"
                >Reload Coordinate</Button>
              </div>}
            </div>

            <div style={{marginBottom: 10, marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
              {canEdit &&
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowEditForm(true)}
                  style={{marginRight: 10}}
                  size="small"
                >Edit</Button>

                {canDelete &&
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (window.confirm('Remove this dealer location?')) {
                      deleteDealerLocation(props.dealerLocation);
                    }
                  }}
                  size="small"
                  style={{marginRight: 10}}
                >Delete</Button>}
              </div>}
            </div>

          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper className={classes.columnContainer} style={{height: '100%'}}>
            <Typography variant="h6">Territory (Zip Codes)</Typography>
            <List>
              {dealerLocation.territory_zip_codes && dealerLocation.territory_zip_codes.map((zip, i) => {
                let secondary = <div>{zip.place_long}</div>;
                if (zip.invalid) {
                  secondary = <div style={{color: 'red'}}>Invalid zip code</div>;
                }
                let secondaryContainer = (<>{secondary}</>);
                if (zip.conflicts && (zip.conflicts.length > 0)) {
                  let overlappingTerritories = zip.conflicts.map((c) => c.locator_title).join(', ');
                  secondaryContainer = (<>
                    {secondary}
                    <div style={{color: 'red'}}>Territory overlap: {overlappingTerritories}</div>
                  </>);
                }
                return (
                  <ListItem key={`zip-item-${i}`}>
                    <ListItemText
                      primary={zip.zip_code}
                      secondary={secondaryContainer}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          if (!window.confirm(`Delete zip code ${zip.zip_code}?`)) return;
                          if (zip.id) deleteTerritoryOverrideZip(zip.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
              {(!dealerLocation.territory_zip_codes || dealerLocation.territory_zip_codes.length === 0) &&
                <ListItem>
                  <ListItemText
                    secondary="empty"
                  />
                </ListItem>
              }
            </List>
            {canEdit &&
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowEditTerritoryZipCodesForm(true)}
                  style={{marginRight: 10}}
                  size="small"
                >Edit</Button>
            </div>}
            <Alert severity="info" style={{marginTop: 10}}>The dealer locator will always return this dealer location for zip codes listed here.</Alert>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper className={classes.columnContainer} style={{height: '100%'}}>
            <Typography variant="h6">Territory (Counties)</Typography>
            <List>
              {dealerLocation.territory_counties && dealerLocation.territory_counties.map((county, i) => {
                let secondary = <div>{county.description}</div>;
                let secondaryContainer = (<>{secondary}</>);
                if (county.conflicts && (county.conflicts.length > 0)) {
                  let overlappingTerritories = county.conflicts.map((c) => c.locator_title).join(', ');
                  secondaryContainer = (<>
                    {secondary}
                    <div style={{color: 'red'}}>Territory overlap: {overlappingTerritories}</div>
                  </>);
                }
                return (
                  <ListItem key={`county-item-${i}`}>
                    <ListItemText
                      primary={county.county}
                      secondary={secondaryContainer}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          if (!window.confirm(`Delete county ${county.county}?`)) return;
                          if (county.id) deleteTerritoryOverrideCounty(county.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
              {(!dealerLocation.territory_counties || dealerLocation.territory_counties.length === 0) &&
                <ListItem>
                  <ListItemText
                    secondary="empty"
                  />
                </ListItem>
              }
            </List>
            {canEdit &&
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowEditTerritoryCountiesForm(true)}
                  style={{marginRight: 10}}
                  size="small"
                >Add County</Button>
            </div>}
            <Alert severity="info" style={{marginTop: 10}}>The dealer locator will always return this dealer location for counties listed here.</Alert>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper className={classes.columnContainer} style={{height: '100%'}}>
            <Typography variant="h6">Countries and States</Typography>
            <List subheader={<ListSubheader>Countries</ListSubheader>}>
              {dealerLocation.territory_countries && dealerLocation.territory_countries.map((country, i) => {
              let secondary = <div>{country.country_code}</div>;
              let secondaryContainer = (<>{secondary}</>);
              if (country.conflicts && (country.conflicts.length > 0)) {
                let overlappingTerritories = country.conflicts.map((c) => c.locator_title).join(', ');
                secondaryContainer = (<>
                  {secondary}
                  <div style={{color: 'red'}}>Territory overlap: {overlappingTerritories}</div>
                </>);
                }
                return (
                  <ListItem key={`country-item-${i}`}>
                    <ListItemText
                      primary={country.country}
                      secondary={secondaryContainer}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          if (!window.confirm(`Delete country ${country.country}?`)) return;
                          if (country.id) deleteTerritoryOverrideCountry(country.id);
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
              {(!dealerLocation.territory_countries || dealerLocation.territory_countries.length === 0) &&
                <ListItem>
                  <ListItemText
                    secondary="empty"
                  />
                </ListItem>
              }
            </List>{canEdit &&
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowEditTerritoryCountriesForm(true)}
                  style={{marginRight: 10}}
                  size="small"
                >Add Country</Button>
            </div>}
            <hr style={{marginTop: 10}}/>
            <List subheader={<ListSubheader>States</ListSubheader>}>
              {dealerLocation.territory_states && dealerLocation.territory_states.map((state, i) => {
                let secondary = <div>{state.country}</div>;
                let secondaryContainer = (<>{secondary}</>);
                if (state.conflicts && (state.conflicts.length > 0)) {
                  let overlappingTerritories = state.conflicts.map((c) => c.locator_title).join(', ');
                  secondaryContainer = (<>
                    {secondary}
                    <div style={{color: 'red'}}>Territory overlap: {overlappingTerritories}</div>
                  </>);
                }
                return (
                  <ListItem key={`state-item-${i}`}>
                    <ListItemText
                      primary={state.state}
                      secondary={secondaryContainer}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          if (!window.confirm(`Delete state ${state.state}?`)) return;
                          if (state.id) deleteTerritoryOverrideState(state.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
              {(!dealerLocation.territory_states || dealerLocation.territory_states.length === 0) &&
                <ListItem>
                  <ListItemText
                    secondary="empty"
                  />
                </ListItem>
              }
            </List>
            {canEdit &&
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowEditTerritoryStatesForm(true)}
                  style={{marginRight: 10}}
                  size="small"
                >Add State</Button>
            </div>}
            <Alert severity="warning" style={{marginTop: 10}}>These options can prevent other dealers in the same state/country from appearing in the search result. Do not use it unless necessary.</Alert>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={(canEdit && showEditForm)}
        onClose={() => {
          setShowEditForm(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <>
        <DialogContent>
          <DialogContentText id="dealer-location-editor">
            <div className={`${classes.root} ${props.className}`}>
              <DealerLocationForm
                dealerLocation={props.dealerLocation}
                onSave={(dealerLocation) => {
                  setShowEditForm(false);
                  props.onUpdated(dealerLocation);
                }}
                onCancel={() => {
                  setShowEditForm(false);
                }}
              /> 
            </div>
          </DialogContentText>
        </DialogContent>
        </>
      </Dialog>

      <Dialog
        open={(canEdit && showEditTerritoryZipCodesForm)}
        onClose={() => {
          setShowEditTerritoryZipCodesForm(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <>
        <DialogContent>
          <DialogContentText id="dealer-location-territory-zip-codes-editor">
            <DealerTerritoryZipCodesForm
              dealerLocation={props.dealerLocation}
              onUpdated={() => {
                setShowEditTerritoryZipCodesForm(false)
                props.onUpdated(props.dealerLocation);
              }}
              onCancel={() => setShowEditTerritoryZipCodesForm(false)}
            />
          </DialogContentText>
        </DialogContent>
        </>
      </Dialog>

      <Dialog
        open={(canEdit && showEditTerritoryCountiesForm)}
        onClose={() => {
          setShowEditTerritoryCountiesForm(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <>
        <DialogContent>
          <DialogContentText id="dealer-location-territory-counties-editor">
            <DealerTerritoryCountiesForm
              dealerLocation={props.dealerLocation}
              onUpdated={() => {
                setShowEditTerritoryCountiesForm(false)
                props.onUpdated(props.dealerLocation);
              }}
              onCancel={() => setShowEditTerritoryCountiesForm(false)}
            />
          </DialogContentText>
        </DialogContent>
        </>
      </Dialog>

      <Dialog
        open={(canEdit && showEditTerritoryStatesForm)}
        onClose={() => {
          setShowEditTerritoryStatesForm(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <>
        <DialogContent>
          <DialogContentText id="dealer-location-territory-states-editor">
            <DealerTerritoryStatesForm
              dealerLocation={props.dealerLocation}
              onUpdated={() => {
                setShowEditTerritoryStatesForm(false)
                props.onUpdated(props.dealerLocation);
              }}
              onCancel={() => setShowEditTerritoryStatesForm(false)}
            />
          </DialogContentText>
        </DialogContent>
        </>
      </Dialog>

      <Dialog
        open={(canEdit && showEditTerritoryCountriesForm)}
        onClose={() => {
          setShowEditTerritoryCountriesForm(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <>
        <DialogContent>
          <DialogContentText id="dealer-location-territory-countries-editor">
            <DealerTerritoryCountriesForm
              dealerLocation={props.dealerLocation}
              onUpdated={() => {
                setShowEditTerritoryCountriesForm(false)
                props.onUpdated(props.dealerLocation);
              }}
              onCancel={() => setShowEditTerritoryCountriesForm(false)}
            />
          </DialogContentText>
        </DialogContent>
        </>
      </Dialog>

      {!!redirect && <Redirect to={redirect} />}
    </div>
  );
}


interface DealerTerritoryZipCodesFormValues {
  zipCodes: string;
}

function DealerTerritoryZipCodesForm(props: {
  dealerLocation: DealerLocation;
  onUpdated: () => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);

  const getRawZipCodes = (dealerLocation: DealerLocation) => {
    let rawZipCodes = '';

    if (dealerLocation.territory_zip_codes) {
      dealerLocation.territory_zip_codes.forEach((z) => {
        rawZipCodes = `${rawZipCodes}\n${z.zip_code}`;
      });
    }
    return rawZipCodes.trim();
  }
  
  const onSubmit = async (values: DealerTerritoryZipCodesFormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();
    const locationId = props.dealerLocation.id ? props.dealerLocation.id : '';
    let postData = {
      zipCodes: values.zipCodes,
    }

    console.log(postData);
    let url = `admin-dealer-locations/${locationId}/edit_territory_zip_code/`;
    try {
      const [result, response, error] = await api.post(postData, url);
      console.log('result', result);
      if (result.success) {
        props.onUpdated();
      }
    } catch (e) {
      console.log(e);
      setShowErrorSnackBar(true);
      if ((e as APIError).errorData) {
        setIsSaving(false);
        return (e as APIError).errorData;
      }
    }

    setIsSaving(false);
  }

  const onValidate = (values: DealerTerritoryZipCodesFormValues) => {
    const errors: any = {}

    if (!values.zipCodes) errors.content = "Required";
    
    return errors;
  }

  let dealerLocation = props.dealerLocation;

  return (
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        zipCodes: getRawZipCodes(dealerLocation),
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={props.className}>
          <Grid container spacing={1}>

            <Field name="zipCodes">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      autoFocus
                      id={props.input.name}
                      label={'Zip Codes'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      multiline
                      rows={8}
                    />
                    <small>Separates multiple zip codes with new lines, spaces or commas.</small>
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Grid item xs={12}>
              <Button 
                type="submit"
                disabled={isSaving}
                color="primary"
                variant="contained"
              >
                Update
              </Button>

              <Button onClick={() => props.onCancel()} color="primary">
                Cancel
              </Button>
            </Grid>
          </Grid>
      </form>
      )}
    />
  );
}


interface DealerTerritoryCountiesFormValues {
  country: string;
  state: string;
  county: string;
}


function DealerTerritoryCountiesForm(props: {
  dealerLocation: DealerLocation;
  onUpdated: () => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [initiated, setInitiated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [countries, setCountries] = useState<DealerLocationTerritoryCountry[]>([]);
  const [states, setStates] = useState<DealerLocationTerritoryState[]>([]);
  const [counties, setCounties] = useState<DealerLocationTerritoryCounty[]>([]);
  
  const findCounties = async (county: string, state: string, country: string) => {
    const api = new BaseAPI();
    try {
      const data = await api.get(`counties/?search=${county}&state=${state}&country=${country}`);
      if ((data as any).results instanceof Array) {
        let counties = (data as any).results as DealerLocationTerritoryCounty[];
        setCounties(counties);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadStates = async (country: string) => {
    const api = new BaseAPI();
    try {
      const data = await api.get(`states/?country=${country}`);
      if ((data as any) instanceof Array) {
        let states = (data as any) as DealerLocationTerritoryState[];
        setStates(states);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadCountries = async () => {
    const api = new BaseAPI();
    try {
      const data = await api.get(`countries/`);
      if ((data as any) instanceof Array) {
        let countries = (data as any) as DealerLocationTerritoryCountry[];
        setCountries(countries);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadCountries();
    loadStates('US');
  }, []);

  const onSubmitCounty = async (countyId: string) => {
    setIsLoading(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();
    const locationId = props.dealerLocation.id ? props.dealerLocation.id : '';
    let postData = {
      county: countyId,
    }

    console.log(postData);
    let url = `admin-dealer-locations/${locationId}/add_territory_county/`;
    try {
      const [result, response, error] = await api.post(postData, url);
      console.log('result', result);
      if (result.success) {
        props.onUpdated();
      }
    } catch (e) {
      console.log(e);
      setShowErrorSnackBar(true);
      if ((e as APIError).errorData) {
        setIsLoading(false);
        return (e as APIError).errorData;
      }
    }

    setIsLoading(false);
  }

  const onSubmit = async (values: DealerTerritoryCountiesFormValues) => {
    setIsLoading(true);
    setShowErrorSnackBar(false);
    setInitiated(true);
    const api = new BaseAPI();
    
    // props.onSave(values.serial_number);
    await findCounties(values.county ? values.county : '', values.state ? values.state : '', values.country ? values.country : '');
    setIsLoading(false);
  }

  const onValidate = (values: DealerTerritoryCountiesFormValues) => {
    const errors: any = {}

    if (!values.country) errors.country = "Required";

    return errors;
  }

  return (
    <div>
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        country: 'US',
        state: '',
        county: ''
      }}
      render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
        <form onSubmit={handleSubmit} className={props.className} style={{margin: 12}}>
          <Grid container>
            <Grid container spacing={3}>

              <Field name="country">
                {props => (
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Country</InputLabel>
                      <Select
                        id={props.input.name}
                        label={'Country'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={(event) => {
                          props.input.onChange(event);
                          loadStates((event.target.value) as string);
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      >
                        {countries.map((country, i) => (
                          <MenuItem key={`country-menu-item-${i}`} value={country.country_code}>{country.country}</MenuItem>
                        ))}
                      </Select>
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="state">
                {props => (
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>State</InputLabel>
                      <Select
                        id={props.input.name}
                        label={'State'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={(event) => {
                          props.input.onChange(event);
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      >
                        {states.map((state, i) => (
                          <MenuItem key={`state-menu-item-${i}`} value={state.state}>{state.state}</MenuItem>
                        ))}
                      </Select>
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="county">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        autoFocus
                        id={props.input.name}
                        label={'County'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            {(!pristine && (Object.keys(errors as any).length > 0)) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">Please make sure all required fields are correctly filled!</Alert>
              </div>
            </Grid>}

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isLoading}
              >Find</Button>
              {isLoading && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />
    {(!isLoading && !!initiated && counties.length === 0) && <div style={{margin: 12}}>
      <Alert
        severity="error"
      >No counties found with currently specified filter.</Alert>
    </div>}
    {(!isLoading && !!initiated && counties.length > 0) && <div style={{margin: 12}}>
    {(counties.length >= 100) && <Alert severity="info" style={{marginBottom: 6}}>Only the first 100 results are displayed.</Alert>}

    <TableContainer component={Paper}>
      <Table aria-label="county list">
        <TableHead>
          <TableRow>
            <TableCell>County Name</TableCell>
            <TableCell>Google Map Name</TableCell>
            <TableCell align="right">State</TableCell>
            <TableCell align="right">Country</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {counties.map((county, i) => {
            return (
              <TableRow key={`county-row-${i}`}>
                <TableCell component="th" scope="row">
                  {county.county}
                </TableCell>
                <TableCell>{county.google_map_name}</TableCell>
                <TableCell align="right">{county.state}</TableCell>
                <TableCell align="right">{county.country}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      // add county to dealer location
                      if (county.id) onSubmitCounty(county.id);
                    }}
                  >Select</Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    </div>}
    </div>
  );
}

interface DealerTerritoryStatesFormValues {
  country: string;
  state: string;
}

function DealerTerritoryStatesForm(props: {
  dealerLocation: DealerLocation;
  onUpdated: () => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [initiated, setInitiated] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [countries, setCountries] = useState<DealerLocationTerritoryCountry[]>([]);
  const [states, setStates] = useState<DealerLocationTerritoryState[]>([]);
  
  const loadStates = async (state: string, country: string) => {
    const api = new BaseAPI();
    try {
      const data = await api.get(`states/?country=${country}&search=${state}`);
      if ((data as any) instanceof Array) {
        let states = (data as any) as DealerLocationTerritoryState[];
        setStates(states);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadCountries = async () => {
    const api = new BaseAPI();
    try {
      const data = await api.get(`countries/`);
      if ((data as any) instanceof Array) {
        let countries = (data as any) as DealerLocationTerritoryCountry[];
        setCountries(countries);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadCountries();
    loadStates('', 'US');
  }, []);

  const onSubmitState = async (stateId: string) => {
    setIsLoading(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();
    const locationId = props.dealerLocation.id ? props.dealerLocation.id : '';
    let postData = {
      state: stateId,
    }

    console.log(postData);
    let url = `admin-dealer-locations/${locationId}/add_territory_state/`;
    try {
      const [result, response, error] = await api.post(postData, url);
      console.log('result', result);
      if (result.success) {
        props.onUpdated();
      }
    } catch (e) {
      console.log(e);
      setShowErrorSnackBar(true);
      if ((e as APIError).errorData) {
        setIsLoading(false);
        return (e as APIError).errorData;
      }
    }

    setIsLoading(false);
  }

  const onSubmit = async (values: DealerTerritoryStatesFormValues) => {
    setIsLoading(true);
    setShowErrorSnackBar(false);
    setInitiated(true);
    const api = new BaseAPI();
    
    // props.onSave(values.serial_number);
    await loadStates(values.state ? values.state : '', values.country ? values.country : '');
    setIsLoading(false);
  }

  const onValidate = (values: DealerTerritoryStatesFormValues) => {
    const errors: any = {}

    if (!values.country) errors.country = "Required";

    return errors;
  }

  return (
    <div>
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        country: 'US',
        state: ''
      }}
      render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
        <form onSubmit={handleSubmit} className={props.className} style={{margin: 12}}>
          <Grid container>
            <Grid container spacing={3}>

              <Field name="country">
                {props => (
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Country</InputLabel>
                      <Select
                        id={props.input.name}
                        label={'Country'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={(event) => {
                          props.input.onChange(event);
                          loadStates(values.state, (event.target.value) as string);
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      >
                        {countries.map((country, i) => (
                          <MenuItem key={`country-menu-item-${i}`} value={country.country_code}>{country.country}</MenuItem>
                        ))}
                      </Select>
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="state">
                {props => (
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        autoFocus
                        id={props.input.name}
                        label={'State'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            {(!pristine && (Object.keys(errors as any).length > 0)) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">Please make sure all required fields are correctly filled!</Alert>
              </div>
            </Grid>}

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isLoading}
              >Find</Button>
              {isLoading && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />
    {(!isLoading && !!initiated && states.length === 0) && <div style={{margin: 12}}>
      <Alert
        severity="error"
      >No states found with currently specified filter.</Alert>
    </div>}
    {(!isLoading && !!initiated && states.length > 0) && <div style={{margin: 12}}>

    <TableContainer component={Paper}>
      <Table aria-label="state list">
        <TableHead>
          <TableRow>
            <TableCell>State Name</TableCell>
            <TableCell align="right">Country</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {states.map((state, i) => {
            return (
              <TableRow key={`state-row-${i}`}>
                <TableCell component="th" scope="row">
                  {state.state}
                </TableCell>
                <TableCell align="right">{state.country}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      // add state to dealer location
                      if (state.id) onSubmitState(state.id);
                    }}
                  >Select</Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    </div>}
    </div>
  );
}


interface DealerTerritoryCountriesFormValues {
  country: string;
}

function DealerTerritoryCountriesForm(props: {
  dealerLocation: DealerLocation;
  onUpdated: () => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [initiated, setInitiated] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [countries, setCountries] = useState<DealerLocationTerritoryCountry[]>([]);
  
  const loadCountries = async (country: string) => {
    const api = new BaseAPI();
    try {
      const data = await api.get(`countries/?search=${country}`);
      if ((data as any) instanceof Array) {
        let countries = (data as any) as DealerLocationTerritoryCountry[];
        setCountries(countries);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadCountries('');
  }, []);

  const onSubmitCountry = async (countryId: string) => {
    setIsLoading(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();
    const locationId = props.dealerLocation.id ? props.dealerLocation.id : '';
    let postData = {
      country: countryId,
    }

    console.log(postData);
    let url = `admin-dealer-locations/${locationId}/add_territory_country/`;
    try {
      const [result, response, error] = await api.post(postData, url);
      console.log('result', result);
      if (result.success) {
        props.onUpdated();
      }
    } catch (e) {
      console.log(e);
      setShowErrorSnackBar(true);
      if ((e as APIError).errorData) {
        setIsLoading(false);
        return (e as APIError).errorData;
      }
    }

    setIsLoading(false);
  }

  const onSubmit = async (values: DealerTerritoryStatesFormValues) => {
    setIsLoading(true);
    setShowErrorSnackBar(false);
    setInitiated(true);
    const api = new BaseAPI();
    
    // props.onSave(values.serial_number);
    await loadCountries(values.country ? values.country : '');
    setIsLoading(false);
  }

  const onValidate = (values: DealerTerritoryStatesFormValues) => {
    const errors: any = {}

    return errors;
  }

  return (
    <div>
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        country: '',
      }}
      render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
        <form onSubmit={handleSubmit} className={props.className} style={{margin: 12}}>
          <Grid container>
            <Grid container spacing={3}>

              <Field name="country">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        autoFocus
                        id={props.input.name}
                        label={'Country'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            {(!pristine && (Object.keys(errors as any).length > 0)) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">Please make sure all required fields are correctly filled!</Alert>
              </div>
            </Grid>}

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isLoading}
              >Find</Button>
              {isLoading && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />
    {(!isLoading && !!initiated && countries.length === 0) && <div style={{margin: 12}}>
      <Alert
        severity="error"
      >No countries found with currently specified filter.</Alert>
    </div>}
    {(!isLoading && !!initiated && countries.length > 0) && <div style={{margin: 12}}>

    <TableContainer component={Paper}>
      <Table aria-label="country list">
        <TableHead>
          <TableRow>
            <TableCell>Country Name</TableCell>
            <TableCell align="right">Country Code</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {countries.map((country, i) => {
            return (
              <TableRow key={`country-row-${i}`}>
                <TableCell component="th" scope="row">
                  {country.country}
                </TableCell>
                <TableCell align="right">{country.country_code}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      // add country to dealer location
                      if (country.id) onSubmitCountry(country.id);
                    }}
                  >Select</Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    </div>}
    </div>
  );
}