import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import {
  BaseAPI,
  APIError,
} from '../../data/BaseAPI';
import {
  Order,
  OrderNote,
  PartGroup,
  BoatOrderItem,
  User,
  Session,
} from '../../types';

import DateTimeView from './DateTimeView';
import ProfileChip from './ProfileChip';
import localization from '../../utils/localizations';
import OrderNoteForm from '../forms/OrderNoteForm';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles({
  root: {
    
  },
  partNumber: {
    marginRight: 10,
    minWidth: 100,
  },
  alignRight: {
    textAlign: 'right',
  },
  bolder: {
    fontWeight: 'bold'
  },
  noteContainer: {
    marginBottom: 20,
  },
  shippingAddressContainer: {
    marginBottom: 20,
  }
});


interface GroupedOrderItem {
  group: PartGroup | null | undefined;
  items: BoatOrderItem[];
}

export default function OrderDetail(props: {
  order: Order;
  onChanged?: () => void;
  className?: string;
  }) {
  const classes = useStyles();

  const [showNoteEditor, setShowNoteEditor] = useState(false);
  const session: Session = (window as any)._cache.session;

  const groupedOrderItems: GroupedOrderItem[] = React.useMemo(() => {
    const groupedOrderItems: GroupedOrderItem[] = [];
    const groupedMap: any = {};
  
    props.order.items.forEach(item => {
      const part = item.part;
      if (!part) return;
      const group = part.groups.length > 0 ? part.groups[0] : undefined;

      const groupKey = group ? group.name : 'null';
      let groupedOrderItem: GroupedOrderItem = {
        group: group,
        items: []
      };

      if (groupedMap[groupKey]) {
        groupedOrderItem = groupedMap[groupKey] as GroupedOrderItem;
      }
      else {
        groupedMap[groupKey] = groupedOrderItem;
        groupedOrderItems.push(groupedOrderItem);
      }
      
      groupedOrderItem.items.push(item);
    });

    return groupedOrderItems;
  }, [props.order, props.order.items.length]);

  const renderLineItem = (item: BoatOrderItem, key: string) => {
    const part = item.part;
    if (!part) return null;
    return (
      <ListItem
        key={key}
        role={undefined}
        dense
        button
        onClick={() => {
          
        }}
      >
        <ListItemText primary={part.description} secondary={`${ item.selected_variant ? item.selected_variant?.name : ''} ${(item.quantity && item.quantity > 1) ? `x${item.quantity}` : ''}`} />
        <ListItemSecondaryAction>
          {(item.price && item.price !== '0.00' && item.part?.part_type === 'discount_p') &&
          <Typography>{formatNumber({suffix: '%'})(parseFloat(item.price))}</Typography>
          }
          {(item.price && item.price !== '0.00' && item.part?.part_type === 'discount_a') &&
          <Typography>{formatNumber({prefix: 'US$'})(parseFloat(item.price))}</Typography>
          }
          {(item.price && item.price !== '0.00' && (item.part?.part_type !== 'discount_p' && item.part?.part_type !== 'discount_a')) &&
          <Typography>{formatNumber({prefix: 'US$'})(parseFloat(item.price))}</Typography>
          }
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  const renderGroupedOrderLines = (groupedPartOrderLine: GroupedOrderItem) => {
    let groupName = groupedPartOrderLine?.group ? groupedPartOrderLine.group.name : 'Other';
    return (
      <div className="my-4">
        <div className="text-xs uppercase text-stone-500">{groupName}</div>
        <List>
          {groupedPartOrderLine.items.map((item, i) => {
            const part = item.part;
            if (!part) return null;
            return renderLineItem(item, `${groupName}-${i}`);
          })}
        </List>
      </div>
    )
  }

  return (
    <div className={`${classes.root} ${props.className}`}>
      <div className="my-4">
        <div className="text-xs uppercase text-stone-500">Boat</div>
        <List>
          <ListItem
          role={undefined}
          dense
          button
        >
          <ListItemText primary={props.order.boat_name} />
        </ListItem>
        </List>
      </div>
      <div className="my-4">
        <List className={classes.root}>
          {groupedOrderItems.map((groupedItem, i) => {
            return renderGroupedOrderLines(groupedItem);
          })}
        </List>
      </div>
      {(props.order.dealer_discount_items.length > 0) &&
      <div className="my-4">
        <div className="text-xs uppercase text-stone-500">Dealer Discount</div>
        <List className={classes.root}>
          {props.order.dealer_discount_items.map((item, i) => (
            <ListItem
            key={`discount-${i}`}
            role={undefined}
            dense
            button
            onClick={() => {
              
            }}
          >
            <ListItemText primary={item.name} />
            <ListItemSecondaryAction>
              {(item.total_discount) &&
              <Typography>{formatNumber({prefix: 'US$'})(parseFloat(`-${item.total_discount}`))}</Typography>
              }
            </ListItemSecondaryAction>
          </ListItem>
          ))}
        </List>
      </div>
      }
      <div className="text-right mb-4">
        <Typography className={classes.bolder}>Total:</Typography>
        <Typography>{formatNumber({prefix: 'US$'})(parseFloat(`${props.order.total_without_discount}`))}</Typography>
      </div>
      {(props.order.total_discount > 0) &&
      <div className="text-right mb-4">
        <Typography className={classes.bolder}>Discount:</Typography>
        <Typography>{formatNumber({prefix: 'US$'})(parseFloat(`${props.order.total_discount}`))}</Typography>
      </div>}
      {(props.order.total_discount > 0) &&
      <div className="text-right mb-4">
        <Typography className={classes.bolder}>Grand Total:</Typography>
        <Typography>{formatNumber({prefix: 'US$'})(parseFloat(props.order.total_price))}</Typography>
      </div>}

      {!!props.order.note &&
      <div className={classes.noteContainer}>
        <Typography variant="h6">Note</Typography>
        <Typography>
          {props.order.note}
        </Typography>
      </div>}

      <div className={classes.shippingAddressContainer}>
        <Typography variant="h6">Dealer</Typography>
        <Typography variant="body2">{props.order.dealer_name}</Typography>
      </div>
      <div className={classes.shippingAddressContainer}>
        <Typography variant="h6">Shipping Address</Typography>
        <Typography variant="body2">{props.order.shipping_name}</Typography>
        <Typography variant="body2">{props.order.address_line_1}</Typography>
        <Typography variant="body2">{props.order.address_line_2}</Typography>
        <Typography variant="body2">{props.order.city} {props.order.state} {props.order.zip}</Typography>
        <Typography variant="body2">{props.order.country}</Typography>
      </div>

      <div className={classes.shippingAddressContainer}>
        <Typography variant="h6">Order Information</Typography>
        <Typography variant="body2">Order Type: {props.order.order_type ? props.order.order_type : '-'}</Typography>
        {props.order.order_type === 'sold' &&<Typography variant="body2">Customer Name: {props.order.customer_name ? props.order.customer_name : '-'}</Typography>}
        <Typography variant="body2">Submitter Name: {props.order.submitter_name ? props.order.submitter_name : '-'}</Typography>
        <Typography variant="body2">Submitted by: {props.order.created_by_name ? props.order.created_by_name : '-'}</Typography>
        <Typography variant="body2">Submitted at: {props.order?.created_at && <DateTimeView value={props.order.created_at} />}</Typography>
      </div>
      {(session.user?.role === 'admin') && <div className={classes.shippingAddressContainer}>
        <Typography variant="h6">CSV</Typography>
        <Typography variant="body2"><Button size="small" href={`/private/fishbowl-csv/${props.order.id}/`} target="_blank">fishbowl-order-{props.order.id}.csv</Button></Typography>
      </div>}

      <div>
        {(props.order.notes && props.order.notes.length > 0) && <Typography variant="h6">Additional Notes</Typography>}
        {props.order.notes && props.order.notes.map((note, i) => (
        <OrderNoteDetail
          orderId={props.order.id}
          note={note}
          editable={note.created_by && (note.created_by?.id === session?.user?.id)}
          isAdmin={session?.user?.role === 'admin'}
          onChanged={() => {
            if (props.onChanged) props.onChanged();
          }}
          key={`note-detail-${i}`}
        />
        ))}
      </div>
      {(session?.user?.role === 'admin') &&
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowNoteEditor(true)}
        >Add Note</Button>
      </div>}


      <Dialog
        open={showNoteEditor}
        onClose={() => {
          setShowNoteEditor(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <>
        <DialogContent>
          <DialogContentText id="selected-order-note-editor">
            <OrderNoteForm
              orderId={props.order.id}
              note={undefined}
              showAdminOnlyOption={session?.user?.role === 'admin'}
              onSave={() => {
                if (props.onChanged) props.onChanged();
                setShowNoteEditor(false);
              }}
              onDelete={() => {
                if (props.onChanged) props.onChanged();
                setShowNoteEditor(false);
              }}
              onCancel={() => {
                if (props.onChanged) props.onChanged();
                setShowNoteEditor(false);
              }}
            />
          </DialogContentText>
        </DialogContent>
        </>
      </Dialog>
    </div>
  );
}

function OrderNoteDetail(props: {
  orderId: string;
  note: OrderNote;
  editable?: boolean;
  isAdmin?: boolean;
  onChanged?: () => void;
}) {

  const [edit, setEdit] = useState(false);

  const deleteNote = async (orderId: string, note: OrderNote) => {
    const api = new BaseAPI();
    try {
      const [result, response, error] = await api.post({
        note: note.id,
      }, `orders/${orderId}/delete_note/`);
      if (result.success) {
        if (props.onChanged) props.onChanged();
      }
      else {
        return result;
      }
    }
    catch (e) {
      console.log(e);
      if ((e as APIError).errorData) {
        return (e as APIError).errorData;
      }
    }
  }


  return (
    <Card style={{marginBottom: 10}}>
      <CardContent>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 6
        }}>
          <ProfileChip profile={props.note.created_by} />
          <div>
            <Typography color="textSecondary" style={{fontSize: 10, textAlign: 'right'}}>
              Posted: <DateTimeView value={props.note.created_at} />
            </Typography>
            {props.note.edited && <Typography color="textSecondary" style={{fontSize: 10, textAlign: 'right'}}>
              Edited: <DateTimeView value={props.note.updated_at} /> by {props.note.updated_by?.first_name} {props.note.updated_by?.last_name}
            </Typography>}
            {props.note.admin_only && <Typography color="textSecondary" style={{fontSize: 10, textAlign: 'right'}}>
              Visibility: <span style={{color: '#ffb74d'}}>admin only</span>
            </Typography>}
          </div>
        </div>
        <Typography variant="body2" component="p" style={{whiteSpace: 'pre-line'}}>
          {props.note.content}
        </Typography>
      </CardContent>
      {props.editable &&
      <CardActions>
        <Button
          size="small"
          onClick={() => setEdit(true)}
        >Edit</Button>
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            if (window.confirm('Delete this note?')) {
              deleteNote(props.orderId, props.note);
            }
          }}
        >Delete</Button>
      </CardActions>}

      <Dialog
        open={edit}
        onClose={() => {
          setEdit(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <>
        <DialogContent>
          <DialogContentText id="selected-order-note-editor">
            <OrderNoteForm
              orderId={props.orderId}
              note={props.note}
              showAdminOnlyOption={props.isAdmin}
              onSave={() => {
                if (props.onChanged) props.onChanged();
                setEdit(false);
              }}
              onDelete={() => {
                if (props.onChanged) props.onChanged();
                setEdit(false);
              }}
              onCancel={() => {
                if (props.onChanged) props.onChanged();
                setEdit(false);
              }}
            />
          </DialogContentText>
        </DialogContent>
        </>
      </Dialog>
    </Card>);
}