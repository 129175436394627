import React, {
  useState,
  useEffect
} from 'react';

import { Form, Field } from "react-final-form";

import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';

import { makeStyles } from '@material-ui/core/styles';

import CountrySelect from '../fields/CountrySelect';
import StateSelect from '../fields/StateSelect';

import {
  Boat,
  Part,
  PartGroup,
  GroupedPart,
  Dealer,
  PartVariant,
  PartRule,
  DealerDiscount,
} from '../../../types';

import {
  OrderLine,
  PartOrderLine,
  DiscountItem,
  FormValues
} from './OrderTypes';


const useStyles = makeStyles({
  root: {
    padding: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  noteContainer: {
    marginBottom: 20,
  },
});


export function CustomerDataForm(props: {
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
  onValidate: (values: FormValues) => any;
  onDealerSelected: (dealer: Dealer|undefined, note: string) => void;
  initialValues: FormValues;
  saving?: boolean;
  dealers: Dealer[];
  disabled?: boolean;
  disabledMessage?: string;
}) {
  const classes = useStyles();
  const dealers = props.dealers;
  const onDealerSelected = props.onDealerSelected;

  return (

    <Form
      onSubmit={props.onSubmit}
      validate={props.onValidate}
      initialValues={props.initialValues}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid container>
            <Field name="note">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Note'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      multiline
                      rows={4}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="customer_number">
              {props => (
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <InputLabel>Dealer</InputLabel>
                    <Select
                      id={props.input.name}
                      label={'Dealer'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={(e) => {
                        if (e.target.value) {
                          let dealer = dealers.find(dealer => dealer.customer_number == e.target.value);
                          if (dealer) {
                            form.change('shipping_name', dealer.customer_name);
                            if (dealer.addresses.length > 0) {
                              let address = dealer.addresses[0];
                              form.change('address_line_1', address.address_line_1);
                              form.change('address_line_2', address.address_line_2);
                              form.change('city', address.city);
                              form.change('country', address.country);
                              form.change('state', address.state);
                              form.change('zip', address.zip);
                            }
                            else {
                              form.change('address_line_1', '');
                              form.change('address_line_2', '');
                              form.change('city', '');
                              form.change('country', 'United States');
                              form.change('state', '');
                              form.change('zip', '');
                            }
                          }
                          onDealerSelected(dealer, values.note);
                        }
                        else {
                          onDealerSelected(undefined, values.note);
                        }
                        props.input.onChange(e);
                      }}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                    >
                      {dealers.filter(dealer => dealer.active).map((dealer, i) => (
                        <MenuItem key={`dealer-menu-item-${i}`} value={dealer.customer_number}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
                      ))}
                    </Select>
                    {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                      <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                    }
                  </FormControl>
                </Grid>
              )}
            </Field>

            {dealers.find(user => user.customer_number == values.customer_number) &&
            <div key={`customer-address-${values.customer_number}`} style={{marginTop: 10, marginBottom: 40}}>
              <div style={{display: 'flex'}}>
            {dealers.find(user => user.customer_number == values.customer_number)?.addresses.map((address, i) => (
              <Card key={`address-selector-${i}`} style={{marginRight: 20}}>
                <CardContent>
                  <Typography variant="body2">
                    <span>{address.address_line_1}</span><br/>
                    <span>{address.address_line_2}</span><br/>
                    <span>{address.city}</span> <span>{address.state}</span> <span>{address.zip}</span><br/>
                    <span>{address.country}</span>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={() => {
                    form.change('address_line_1', address.address_line_1);
                    form.change('address_line_2', address.address_line_2);
                    form.change('city', address.city);
                    form.change('country', address.country);
                    form.change('state', address.state);
                    form.change('zip', address.zip);
                  }}>Use</Button>
                </CardActions>
              </Card>
            ))}
            </div>
            </div>}

            <Field name="order_type">
              {props => (
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="outlined" fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <RadioGroup
                      aria-label="Order Type"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                    >
                      <FormControlLabel value={'stock'} control={<Radio />} label={'Stock'} />
                      <FormControlLabel value={'sold'} control={<Radio />} label={'Sold'} />
                    </RadioGroup>
                    {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                      <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                    }
                  </FormControl>
                </Grid>
              )}
            </Field>

            {values.order_type === 'sold' && 
            <Field name="customer_name">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Customer Name'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>}

            <Field name="shipping_name">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Dealer Name'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="address_line_1">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Address Line 1'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="address_line_2">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Address Line 2'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Grid container spacing={3}>
              <Field name="city">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'City'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="country">
                {props => (
                  <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Country</InputLabel>
                      <CountrySelect
                        id={props.input.name}
                        label={'Country'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      />
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="state">
                {props => (
                  <Grid item xs={3}>
                    {values.country === 'United States' &&
                    <FormControl variant="outlined" fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>State</InputLabel>
                      <StateSelect
                        id={props.input.name}
                        label={'State'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      />
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                      }
                    </FormControl>}
                    {values.country !== 'United States' &&
                    <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'State'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                    }
                  </Grid>
                )}
              </Field>

              <Field name="zip">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Zip / Postal Code'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            <Field name="is_draft" type="checkbox">
              {props => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={values.is_draft} onChange={props.input.onChange} name={props.input.name} />}
                    label={
                      <div>
                        <div>Quote</div>
                        <div style={{fontSize: 11, lineHeight: '11px', color: '#888'}}>Save this order as a quote. Quotes can be reviewed and edited before it's finalized. Uncheck this option to submit as an order.</div>
                      </div>
                    }
                    style={{marginBottom: 24}}
                  />
                </Grid>
              )}
            </Field>

            {props.disabledMessage &&
              <Grid item xs={12}>
                <Alert severity="error" style={{marginBottom: 24}}>{props.disabledMessage}</Alert>
              </Grid>
            }

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={props.saving || props.disabled}
              >{values.is_draft ? 'Save Quote' : 'Submit Order'}</Button>
              {props.saving && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

        </form>
      )}
    />
  );
}