import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  Skeleton
} from '@material-ui/lab';

import CloseIcon from '@material-ui/icons/Close';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import OrderList from '../collections/OrderList';
import DateTimeView from '../singles/DateTimeView';
import OrderDetail from '../singles/OrderDetail';

import {
  BaseAPI,
} from '../../data/BaseAPI';

import {
  Order,
  User,
  DealerPermission
} from '../../types';
import localization from '../../utils/localizations';
import { useProfile } from '../../data/Profile';



const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  marginVertical: {
    marginTop: 20,
    marginBottom: 20,
  },
  breadcrumbs: {
    margin: 10,
  },
  returnButtonAbs: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  aboutContainer: {
    padding: 20,
  },
  mainTitle: {
    marginBottom: 20,
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function OrderDetailPanel() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [order, setOrder] = useState(undefined as Order|undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { path, url } = useRouteMatch();
  const { orderId } = useParams() as any;
  const backUrl = `/orders/`;

  const loadOrder = async (id: number) => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`orders/${id}/`);
      if ((data as any).id) {
        setOrder(data as Order);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadOrder(orderId);

    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    }, 300);

  }, [orderId]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
        aria-labelledby="selected-order-title"
        aria-describedby="selected-order-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.dialogTitle}>
              Order #{orderId}
            </Typography>
            <Typography style={{marginRight: 20}}>
              {order?.created_at && <DateTimeView value={order.created_at} />}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setNavigateBack(true);
              }, 300);
            }} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText id="selected-order-description">
            {!!order && <OrderDetail order={order} />}
            {!order &&
              <div>
                <LoadingView />
              </div>
            }
          </DialogContentText>
        </DialogContent>
        </>
      </Dialog>

      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}


function LoadingView() {
  return (
    <div style={{position: 'relative', padding: 10}}>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
    </div>
  );
}

function OrderPanel(props: {}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [navigateBack, setNavigateBack] = useState(false);
  

  const { path, url } = useRouteMatch();
  const backUrl = `/`;

  useEffect(() => {
    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    },300);

  }, []);

  const goBack = () => {
    setOpen(false);
    setTimeout(() => {
      setNavigateBack(true);
    }, 300);
  };

  const canAccessBoatOrderingPortal = (permissions?: DealerPermission[], role?: string) => {
    let hasPermission = false;
    if (role === 'admin') hasPermission = true;
    if (permissions) {
      permissions.forEach(permission => {
        if (permission.admin || permission.order) hasPermission = true;
      });
    }
    return hasPermission;
  }

  if (!canAccessBoatOrderingPortal(profile.permissions, profile.role)) {
    return (<>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={goBack}
      >
        <Typography variant="h2" style={{marginLeft: 14, marginTop: 14}}>Loading...</Typography>
        <div style={{height: 60}}></div>
      </Drawer>
      {navigateBack && <Redirect to={backUrl} />}
    </>);
  }

  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={goBack}
      >
        <Grid container>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={8} className={classes.aboutContainer}>
            <Typography variant="h2" className={classes.mainTitle}>
              {profile?.role === 'admin' ? 'All Orders' : 'Your Orders'}
            </Typography>

            <OrderList className={classes.marginVertical} />
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>

        <IconButton
          color="inherit"
          onClick={goBack}
          className={classes.returnButtonAbs}
        >
          <CloseOutlinedIcon />
        </IconButton>

        <Switch>
          <Route exact path={`${path}:orderId/`}>
            <OrderDetailPanel />
          </Route>
        </Switch>

      </Drawer>
      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}

export default OrderPanel;