import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import {
  BaseAPI,
  APIError,
} from '../../data/BaseAPI';
import {
  OrderDraft,
  OrderNote,
  PartGroup,
  BoatOrderItem,
  User,
  Session,
} from '../../types';

import DateTimeView from './DateTimeView';
import ProfileChip from './ProfileChip';
import localization from '../../utils/localizations';
import OrderNoteForm from '../forms/OrderNoteForm';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles({
  root: {
    
  },
  partNumber: {
    marginRight: 10,
    minWidth: 100,
  },
  alignRight: {
    textAlign: 'right',
  },
  bolder: {
    fontWeight: 'bold'
  },
  noteContainer: {
    marginBottom: 20,
  },
  shippingAddressContainer: {
    marginBottom: 20,
  }
});


interface GroupedOrderItem {
  group: PartGroup | null | undefined;
  items: BoatOrderItem[];
}


export default function OrderDraftDetail(props: {
  order: OrderDraft;
  onChanged?: (order: OrderDraft) => void;
  onDeleted?: (order: OrderDraft) => void;
  className?: string;
  }) {
  const classes = useStyles();

  const session: Session = (window as any)._cache.session;

  const deleteOrderDraft = async (draftId: string) => {
    const api = new BaseAPI();
    let success = false;
    const [result, response, error] = await api.post({
      id: draftId
    }, `order-drafts/${draftId}/delete/`);
    console.log('result', result);

    if (result.success) {
      if (props.onDeleted) props.onDeleted(props.order);
    }
  };

  const groupedOrderItems: GroupedOrderItem[] = React.useMemo(() => {
    const groupedOrderItems: GroupedOrderItem[] = [];
    const groupedMap: any = {};
  
    props.order.items.forEach(item => {
      const part = item.part;
      if (!part) return;
      const group = part.groups.length > 0 ? part.groups[0] : undefined;

      const groupKey = group ? group.name : 'null';
      let groupedOrderItem: GroupedOrderItem = {
        group: group,
        items: []
      };

      if (groupedMap[groupKey]) {
        groupedOrderItem = groupedMap[groupKey] as GroupedOrderItem;
      }
      else {
        groupedMap[groupKey] = groupedOrderItem;
        groupedOrderItems.push(groupedOrderItem);
      }
      
      groupedOrderItem.items.push(item);
    });

    return groupedOrderItems;
  }, [props.order, props.order.items.length]);

  const renderLineItem = (item: BoatOrderItem, key: string) => {
    const part = item.part;
    if (!part) return null;
    return (
      <ListItem
        key={key}
        role={undefined}
        dense
        button
        onClick={() => {
          
        }}
      >
        <ListItemText primary={part.description} secondary={`${ item.selected_variant ? item.selected_variant?.name : ''} ${(item.quantity && item.quantity > 1) ? `x${item.quantity}` : ''}`} />
        <ListItemSecondaryAction>
          {(item.price && item.price !== '0.00' && item.part?.part_type === 'discount_p') &&
          <Typography>{formatNumber({suffix: '%'})(parseFloat(item.price))}</Typography>
          }
          {(item.price && item.price !== '0.00' && item.part?.part_type === 'discount_a') &&
          <Typography>{formatNumber({prefix: 'US$'})(parseFloat(item.price))}</Typography>
          }
          {(item.price && item.price !== '0.00' && (item.part?.part_type !== 'discount_p' && item.part?.part_type !== 'discount_a')) &&
          <Typography>{formatNumber({prefix: 'US$'})(parseFloat(item.price))}</Typography>
          }
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  const renderGroupedOrderLines = (groupedPartOrderLine: GroupedOrderItem) => {
    let groupName = groupedPartOrderLine?.group ? groupedPartOrderLine.group.name : 'Other';
    return (
      <div className="my-4">
        <div className="text-xs uppercase text-stone-500">{groupName}</div>
        <List>
          {groupedPartOrderLine.items.map((item, i) => {
            const part = item.part;
            if (!part) return null;
            return renderLineItem(item, `${groupName}-${i}`);
          })}
        </List>
      </div>
    )
  }

  return (
    <div className={`${classes.root} ${props.className}`}>
      <div className="my-4">
        <div className="text-xs uppercase text-stone-500">Boat</div>
        <List>
          <ListItem
          role={undefined}
          dense
          button
        >
          <ListItemText primary={props.order.boat_name} />
        </ListItem>
        </List>
      </div>
      <div className="my-4">
        <List className={classes.root}>
          {groupedOrderItems.map((groupedItem, i) => {
            return renderGroupedOrderLines(groupedItem);
          })}
        </List>
      </div>
      <div className="text-right mb-4">
        <Typography className={classes.bolder}>Total:</Typography>
        <Typography>{formatNumber({prefix: 'US$'})(parseFloat(`${props.order.total_price}`))}</Typography>
      </div>

      {!!props.order.note &&
      <div className={classes.noteContainer}>
        <Typography variant="h6">Note</Typography>
        <Typography>
          {props.order.note}
        </Typography>
      </div>}

      <div className={classes.shippingAddressContainer}>
        <Typography variant="h6">Dealer</Typography>
        <Typography variant="body2">{props.order.dealer_name}</Typography>
      </div>

      <div className={classes.shippingAddressContainer}>
        <Typography variant="h6">Quote Information</Typography>
        <Typography variant="body2">Order Type: {props.order.order_type ? props.order.order_type : '-'}</Typography>
        {props.order.order_type === 'sold' &&<Typography variant="body2">Customer Name: {props.order.customer_name ? props.order.customer_name : '-'}</Typography>}
        <Typography variant="body2">Submitted by: {props.order.created_by_name ? props.order.created_by_name : '-'}</Typography>
        <Typography variant="body2">Submitted at: {props.order?.created_at && <DateTimeView value={props.order.created_at} />}</Typography>
      </div>
      <div>
        <Button
          component={RouterLink}
          to={`${appConfig.homepage}order-form/${props.order.boat_id}/?quote=${props.order.id}`}
          variant="contained"
          color="primary"
          style={{color: '#ffffff'}}
        >Edit</Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            if (window.confirm('Delete this quote?')) deleteOrderDraft(props.order.id);
          }}
          style={{marginLeft: 12}}
        >Delete</Button>
      </div>
    </div>
  );
}
