import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Divider,
  Typography,
  ListSubheader,
} from '@material-ui/core';
import {
  Alert,
  AlertTitle,
} from '@material-ui/lab/';

import localization from '../../utils/localizations';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import {
  SiteInfo,
} from '../../types';

const useStyles = makeStyles((theme) => ({
  mainBody: {
    padding: 10,
  },
  alert: {
    marginBottom: 10,
    marginTop: 10,
  },
  panelTitle: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
 

function SiteWideMessage() {
  const classes = useStyles();
  const [infos, setInfos] = useState([] as SiteInfo[]);
  const [isLoading, setIsloading] = useState(false);

  const loadInfos = async () => {
    const api = new BaseAPI();
    setIsloading(true);
    try {
      const infos = await api.get('infos/');
      setInfos(infos as SiteInfo[]);
    } catch (error) {
      console.error(error);
    }

    setIsloading(false);
  };
  useEffect(() => {
    loadInfos();
  }, []);

  if (infos.length == 0) return (<></>);

  return (
  <Paper className={classes.mainBody}>
    <ListSubheader style={{paddingLeft: 8, lineHeight: '24px'}}>{localization.importantInformation}</ListSubheader>

    {infos.map((info, i) => {
      return (
        <Alert key={`site-message-${i}`} severity={info.level} className={classes.alert}>
          {(Boolean(info.title) && Boolean(info.message)) && <AlertTitle>{info.title}</AlertTitle>}
          <span dangerouslySetInnerHTML={{__html: (info.message ? info.message : info.title)}} />
        </Alert>
      );
    })}
  </Paper>);
}

export default SiteWideMessage;