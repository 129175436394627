import React, {
  useState,
  useEffect
} from 'react';


import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Divider,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import formatNumber from 'format-number';
import bankersRounding from '../../../utils/bankersRounding';

import {
  Boat,
  Part,
  PartGroup,
  GroupedPart,
  Dealer,
  PartVariant,
  PartRule,
  DealerDiscount,
} from '../../../types';

import {
  OrderLine,
  PartOrderLine,
  DiscountItem,
  FormValues
} from './OrderTypes';


const useStyles = makeStyles({
  alignRight: {
    textAlign: 'right',
  },
  noteContainer: {
    marginBottom: 20,
  },
  footerGroup: {
    padding: 0,
  },
  textAlignRight: {
    textAlign: 'right',
  },
  fieldLabel: {
    color: '#777',
  },
  metaContainer: {
    marginBottom: 10,
  },
  smallText: {
    fontSize: 12,
  },
  shippingAddressContainer: {
    marginBottom: 20,
  },
  bolder: {
    fontWeight: 'bold'
  },
});


interface GroupedPartOrderLine {
  group: PartGroup | null | undefined;
  partOrderLines: PartOrderLine[];
}

export function OrderSummary(props: {
  formValues: FormValues;
  boat: Boat;
  orderLines: OrderLine[];
  totalPrice: string;
  totalPriceWithoutDiscount: string;
  discounts: DiscountItem[];
  className?: string;
}) {
  const classes = useStyles();

  const groupedPartOrderLines: GroupedPartOrderLine[] = React.useMemo(() => {
    const groupedPartOrderLines: GroupedPartOrderLine[] = [];
    const groupedMap: any = {};
  
    props.boat.grouped_parts.forEach(groupedPart => {
      const groupKey = groupedPart.group ? groupedPart.group.name : 'null';
      groupedPart.parts.forEach((part) => {
        props.orderLines.forEach((orderLine) => {
          if ((orderLine.id === part.id) && (orderLine.quantity > 0)) {
            let groupedPartOrderLine: GroupedPartOrderLine = {
              group: groupedPart.group,
              partOrderLines: []
            };

            if (groupedMap[groupKey]) {
              groupedPartOrderLine = groupedMap[groupKey] as GroupedPartOrderLine;
            }
            else {
              groupedMap[groupKey] = groupedPartOrderLine;
              groupedPartOrderLines.push(groupedPartOrderLine);
            }
            
            groupedPartOrderLine.partOrderLines.push({part, orderLine});
          };
        });
      });
      if (groupedPart.children) {
        groupedPart.children.forEach(groupedPart => {
          groupedPart.parts.forEach((part) => {
            props.orderLines.forEach((orderLine) => {
              if ((orderLine.id === part.id) && (orderLine.quantity > 0)) {
                let groupedPartOrderLine: GroupedPartOrderLine = {
                  group: groupedPart.group,
                  partOrderLines: []
                };
    
                if (groupedMap[groupKey]) {
                  groupedPartOrderLine = groupedMap[groupKey] as GroupedPartOrderLine;
                }
                else {
                  groupedMap[groupKey] = groupedPartOrderLine;
                  groupedPartOrderLines.push(groupedPartOrderLine);
                }
                
                groupedPartOrderLine.partOrderLines.push({part, orderLine});
              };
            });
          });

          if (groupedPart.children) {
            groupedPart.children.forEach(groupedPart => {
              groupedPart.parts.forEach((part) => {
                props.orderLines.forEach((orderLine) => {
                  if ((orderLine.id === part.id) && (orderLine.quantity > 0)) {
                    let groupedPartOrderLine: GroupedPartOrderLine = {
                      group: groupedPart.group,
                      partOrderLines: []
                    };
        
                    if (groupedMap[groupKey]) {
                      groupedPartOrderLine = groupedMap[groupKey] as GroupedPartOrderLine;
                    }
                    else {
                      groupedMap[groupKey] = groupedPartOrderLine;
                      groupedPartOrderLines.push(groupedPartOrderLine);
                    }
                    
                    groupedPartOrderLine.partOrderLines.push({part, orderLine});
                  };
                });
              });
            });
          }

        });
      }
    });

    return groupedPartOrderLines;
  }, [props.boat, props.orderLines]);

  const renderLineItem = (item: PartOrderLine, key: string) => {
    const part = item.part;
    if (!part) return null;
    return (
      <ListItem
        key={key}
        role={undefined}
        dense
        button
        onClick={() => {
          
        }}
      >
        <ListItemText primary={part.description} secondary={`${ item.orderLine?.variant ? item.orderLine?.variant?.name : ''} ${(item.orderLine?.quantity && item.orderLine.quantity > 1) ? `x${item.orderLine?.quantity}` : ''}`} />
        <ListItemSecondaryAction>
          {(item.orderLine?.price && item.orderLine.price !== '0.00' && item.orderLine.type === 'discount_p') &&
          <Typography>{formatNumber({suffix: '%'})(parseFloat(part.price))}</Typography>
          }
          {(item.orderLine?.price && item.orderLine.price !== '0.00' && item.orderLine.type === 'discount_a') &&
          <Typography>{formatNumber({prefix: 'US$'})(parseFloat(part.price))}</Typography>
          }
          {(item.orderLine?.price && item.orderLine.price !== '0.00' && (item.orderLine.type !== 'discount_p' && item.orderLine.type !== 'discount_a')) &&
          <Typography>{formatNumber({prefix: 'US$'})(parseFloat(part.price))}</Typography>
          }
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  const renderGroupedOrderLines = (groupedPartOrderLine: GroupedPartOrderLine) => {
    let groupName = groupedPartOrderLine?.group ? groupedPartOrderLine.group.name : '';
    return (
      <div className="my-4">
        <div className="text-xs uppercase text-stone-500">{groupName}</div>
        <List>
          {groupedPartOrderLine.partOrderLines.map((item, i) => {
            const part = item.part;
            if (!part) return null;
            return renderLineItem(item, `${groupName}-${i}`);
          })}
        </List>
      </div>
    )
  }

  const footerGroups = props.boat.ui_combined_footer_groups.map(g => g.group ? g.group.name : '');
  return (
    <div className={props.className}>
      <Grid container className={classes.metaContainer}>
        <Grid item xs={12}>
          <Grid container spacing={1} className={classes.smallText}>
            <Grid item xs={3} className={classes.textAlignRight}>
              <div className={classes.fieldLabel}>Dealer Name</div>
            </Grid>
            <Grid item xs={9}>
              <div>{props.formValues.shipping_name}</div>
            </Grid>
            <Grid item xs={3} className={classes.textAlignRight}>
              <div className={classes.fieldLabel}>Boat Model</div>
            </Grid>
            <Grid item xs={9}>
              <div>{props.boat.name}</div>
            </Grid>
            <Grid item xs={3} className={classes.textAlignRight}>
              <div className={classes.fieldLabel}>Order Type</div>
            </Grid>
            <Grid item xs={9}>
              <div>{props.formValues.order_type}</div>
            </Grid>
            {props.formValues.order_type === 'sold' && <>
              <Grid item xs={3} className={classes.textAlignRight}>
                <div className={classes.fieldLabel}>Customer Name</div>
              </Grid>
              <Grid item xs={9}>
                <div>{props.formValues.customer_name}</div>
              </Grid>
            </>}
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <div className="my-4">
      {groupedPartOrderLines.map(gp => {
        let isIncluded = false;
        if (gp.group && gp.group.part_type === 'engine') isIncluded = true;
        if (isIncluded) {
          return renderGroupedOrderLines(gp);
        }
      })}

      {groupedPartOrderLines.map(gp => {
        let isIncluded = true;
        if (gp.group && gp.group.part_type === 'engine') isIncluded = false;
        if (gp.group && footerGroups.includes(gp.group.name)) isIncluded = false;
        if (isIncluded) {
          return renderGroupedOrderLines(gp);
        }
      })}

      {groupedPartOrderLines.map(gp => {
        let isIncluded = false;
        if (gp.group && footerGroups.includes(gp.group.name)) isIncluded = true;
        if (isIncluded) {
          return renderGroupedOrderLines(gp);
        }
      })}
      </div>

      <div className={classes.alignRight}>
        <Typography>Total: <span className={classes.bolder}>{props.totalPriceWithoutDiscount}</span></Typography>
      </div>

      {(props.discounts.length > 0) &&
      <div className="pt-[16px]">
        {props.discounts.map((discount, i) => (
          <Typography key={`discount-${i}`} className="text-right" variant="body2">Dealer Discount: {discount.name} — <b>{formatNumber({prefix: 'US$'})(parseFloat(`${discount.total}`))} discount</b></Typography>
        ))}
      </div>}

      {(props.discounts.length > 0) &&
      <div className="pt-[16px]">
        <Typography variant="body1" className="text-right">
        Grand Total: <b>{props.totalPrice}</b>
        </Typography>
      </div>}
      {!!props.formValues.note &&
      <div className={classes.noteContainer}>
        <Typography variant="h6">Note</Typography>
        <Typography>
          {props.formValues.note}
        </Typography>
      </div>}

      <div className={classes.shippingAddressContainer}>
        <Typography variant="h6">Shipping Address</Typography>
        <Typography variant="body2">{props.formValues.shipping_name}</Typography>
        <Typography variant="body2">{props.formValues.address_line_1}</Typography>
        <Typography variant="body2">{props.formValues.address_line_2}</Typography>
        <Typography variant="body2">{props.formValues.city} {props.formValues.state} {props.formValues.zip}</Typography>
      </div>
    </div>
  );
}
