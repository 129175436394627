import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonBase,
  Typography,
  withStyles,
} from '@material-ui/core';

import localization from '../../utils/localizations';


const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#142b4d'
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 10,
    textTransform: 'uppercase',
    marginTop: 4,
    opacity: .8
  }
}));

interface ComponentProps {
  icon?: React.ReactNode;
  title: string;
  onClick?: () => void, className?: string;
  to?: string;
  href?: string;
  target?: string;
  component?: any;
}

function BigPanelButton(props: ComponentProps) {
  const classes = useStyles();

  return (
    <ButtonBase
      focusRipple
      onClick={props.onClick}
      href={props.href}
      target={props.target}
      to={props.to}
      component={props.component}
      className={`${classes.root} ${props.className}`}
    >
      <div className={`${classes.contentContainer} hover:text-sky-700`}>
        {props.icon && <div>{props.icon}</div>}
        <Typography className={classes.buttonText}>{props.title}</Typography>
      </div>
    </ButtonBase>
  );
}

export default BigPanelButton;