import { observable, keys } from "mobx"

import {
  Session
} from '../types';

const session: Session = observable.object({success: false, version: '', csrftoken: ''});

function setSession(newSession: Session) {
  if (session.success !== newSession.success) session.success = newSession.success;
  if (session.csrftoken !== newSession.csrftoken) session.csrftoken = newSession.csrftoken;
  if (session.user !== newSession.user) session.user = newSession.user;
  if (session.version !== newSession.version) session.version = newSession.version;
  return session;
}

function getSession() {
  return session;
}

export {
  setSession,
  getSession
}