import React, {
  useState,
  useEffect
} from 'react';

import { Form, Field } from "react-final-form";

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import {
  BaseAPI,
} from '../../data/BaseAPI';

import {
  DealerUser,
  User,
} from '../../types';

import UserSelect from './fields/UserSelect';

const useStyles = makeStyles({
  root: {
    margin: 10,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  closeButton: {
    padding: 4,
  },
});


interface FormValues {
  user: string;
  active: boolean;
  admin: boolean;
  order: boolean;
  warranty: boolean;
  receive_admin_notification_mails: boolean;
  receive_orders_notification_mails: boolean;
  receive_warranty_notification_mails: boolean;
}


export default function DealerUserForm(props: {
  dealerUser?: DealerUser;
  dealerId: string;
  className?: string;
  onCancel: () => void;
  onSaved: (dealerUser: DealerUser) => void;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);

  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);

  const onSubmit = async (values: FormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    // try {
    //   const address = await BaseAPI.createAddress(values);
    //   props.onSaved(address);
    // } catch (e) {
    //   setShowErrorSnackBar(true);
    // }

    const api = new BaseAPI();
    
    let success = false;
    let dealerUserId: number|undefined = undefined;
    let dealerUser: DealerUser|undefined = undefined;

    if (!props.dealerUser) {
      try {
        const [result, response, error] = await api.post({
          user: values.user,
          dealer: props.dealerId,
          active: values.active,
          admin: values.admin,
          order: values.order,
          warranty: values.warranty,
          receive_admin_notification_mails: values.receive_admin_notification_mails,
          receive_orders_notification_mails: values.receive_orders_notification_mails,
          receive_warranty_notification_mails: values.receive_warranty_notification_mails,
        }, 'dealer-users-raw-paginated/');
        dealerUserId = result.id;
        dealerUser = result as DealerUser;
        console.log('result', result);
        
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
      }
      setIsSaving(false);

      if (dealerUserId && dealerUser) {
        props.onSaved(dealerUser);
      }
    }
    else {
      try {
        const [result, response, error] = await api.put({
          id: props.dealerUser.id,
          user: props.dealerUser.user.id,
          dealer: props.dealerId,
          active: values.active,
          admin: values.admin,
          order: values.order,
          warranty: values.warranty,
          receive_admin_notification_mails: values.receive_admin_notification_mails,
          receive_orders_notification_mails: values.receive_orders_notification_mails,
          receive_warranty_notification_mails: values.receive_warranty_notification_mails,
        }, `dealer-users-raw-paginated/${props.dealerUser.id}/`);
        dealerUserId = result.id;
        dealerUser = result as DealerUser;
        console.log('result', result);
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
      }
      setIsSaving(false);

      if (dealerUserId && dealerUser) {
        props.onSaved(dealerUser);
      }
    }

    setIsSaving(false);
  }

  const onValidate = (values: FormValues) => {
    const errors: any = {}

    if (!values.user) errors.user = 'Required'

    return errors;
  }


  const dealerUser = props.dealerUser;

  return (
    <div className={`${classes.root} ${props.className}`}>

    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        user: dealerUser ? dealerUser.user.id : '',
        active: dealerUser ? dealerUser.active : true,
        admin: dealerUser ? dealerUser.admin : false,
        order: dealerUser ? dealerUser.order : false,
        warranty: dealerUser ? dealerUser.warranty : false,
        receive_admin_notification_mails: dealerUser ? dealerUser.receive_admin_notification_mails : false,
        receive_orders_notification_mails: dealerUser ? dealerUser.receive_orders_notification_mails : false,
        receive_warranty_notification_mails: dealerUser ? dealerUser.receive_warranty_notification_mails : false,
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid container>
            
            <Field name="user">
              {props => (
                <Grid item xs={12}>
                  <UserSelect
                    id={props.input.name}
                    label={'User'}
                    variant="outlined"
                    allowCreate
                    name={props.input.name}
                    value={props.input.value}
                    onChange={props.input.onChange}
                    error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                    className={classes.fieldContainer}
                    errorMessage={props.meta.error || props.meta.submitError}
                  />
                </Grid>
              )}
            </Field>

            <Field name="active">
              {props => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox
                                checked={props.input.value}
                                onChange={(event, checked) => {
                                  props.input.onChange(event);
                                }}
                                name={props.input.name}
                              />}
                    label="Active"
                  />
                </Grid>
              )}
            </Field>

          </Grid>
          
          <Grid container>
            <Grid item xs={12}>
              <div className="pt-4 font-bold">Dealer Administration</div>
            </Grid>

            <Field name="admin">
              {props => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox
                                checked={props.input.value}
                                onChange={(event, checked) => {
                                  props.input.onChange(event);
                                }}
                                name={props.input.name}
                              />}
                    label="Full access to all dealer's functionality"
                  />
                </Grid>
              )}
            </Field>

            <Field name="receive_admin_notification_mails">
              {props => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox
                                checked={props.input.value}
                                onChange={(event, checked) => {
                                  props.input.onChange(event);
                                }}
                                name={props.input.name}
                              />}
                    label="Receive dealer's administrative notification emails"
                  />
                </Grid>
              )}
            </Field>

          </Grid>
          
          <Grid container>
            <Grid item xs={12}>
            <div className="pt-4 font-bold">Order</div>
            </Grid>

            <Field name="order">
              {props => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox
                                checked={props.input.value}
                                onChange={(event, checked) => {
                                  props.input.onChange(event);
                                }}
                                name={props.input.name}
                              />}
                    label="Access to ordering portal"
                  />
                </Grid>
              )}
            </Field>

            <Field name="receive_orders_notification_mails">
              {props => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox
                                checked={props.input.value}
                                onChange={(event, checked) => {
                                  props.input.onChange(event);
                                }}
                                name={props.input.name}
                              />}
                    label="Receive order notification emails"
                  />
                </Grid>
              )}
            </Field>

          </Grid>
          
          <Grid container>
            <Grid item xs={12}>
            <div className="pt-4 font-bold">Warranty</div>
            </Grid>

            <Field name="warranty">
              {props => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox
                                checked={props.input.value}
                                onChange={(event, checked) => {
                                  props.input.onChange(event);
                                }}
                                name={props.input.name}
                              />}
                    label="Access to warranty portal"
                  />
                </Grid>
              )}
            </Field>

            <Field name="receive_warranty_notification_mails">
              {props => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox
                                checked={props.input.value}
                                onChange={(event, checked) => {
                                  props.input.onChange(event);
                                }}
                                name={props.input.name}
                              />}
                    label="Receive warranty notification emails"
                  />
                </Grid>
              )}
            </Field>
          </Grid>

          <Grid container className="mt-3">
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isSaving}
              >Save</Button>
              {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />
    </div>
  );
}