import React, {
  useState,
  useEffect
} from 'react';

import { Form, Field } from "react-final-form";

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    left: -99999,
  }
});

export default function FilePicker(props: {
  label: any;
  multiple?: boolean;
  accept?: string;
  onChange: (files: File[]) => void;
}) {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <label>
        <div>{props.label}</div>
        <input
          className={classes.fileInput}
          type="file"
          accept={props.accept}
          multiple={props.multiple}
          onChange={(event) => {
            if (!event.target.value) return;
            if (!event.target.files?.length) return;
            const files: File[] = [];
            for (let index = 0; index < event.target.files.length; index++) {
              files.push(event.target.files[index]);
              
            }
            props.onChange(files);
          }}
        />
      </label>
    </div>
  );
}