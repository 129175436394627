import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Grid,
  Paper,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Toolbar,
  IconButton,
  Card,
  CardActions,
  CardContent,
  Tooltip,
} from '@material-ui/core';

import {
  TransitionProps
} from '@material-ui/core/transitions';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import {
  useProfile
} from '../../data/Profile';
import {
  Dealer,
  DealerAddress,
  DealerUser,
} from '../../types';

import localization from '../../utils/localizations';
import DealerUserCollection from '../collections/DealerUserCollection';
import DealerForm from '../forms/DealerForm';
import DealerLaborRateForm from '../forms/DealerLaborRateForm';
import DealerAddressForm from '../forms/DealerAddressForm';
import DealerUserForm from '../forms/DealerUserForm';
import DateTimeView from './DateTimeView';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
  },
  partNumber: {
    marginRight: 10,
    minWidth: 100,
  },
  alignRight: {
    textAlign: 'right',
  },
  bolder: {
    fontWeight: 'bold'
  },
  noteContainer: {
    marginBottom: 20,
  },
  shippingAddressContainer: {
    marginBottom: 20,
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function UserDetail(props: {
  dealer: Dealer;
  onUpdated: (dealer: Dealer) => void;
  onDeleted: (dealer: Dealer) => void;
  className?: string;
}) {
  const classes = useStyles();
  const [showEditForm, setShowEditForm] = useState(false);
  const [showEditLaborRateForm, setShowEditLaborRateForm] = useState(false);
  const [editAddress, setEditAddress] = useState<DealerAddress|undefined>(undefined);
  const [editDealerUser, setEditDealerUser] = useState<DealerUser|undefined>(undefined);
  const [showCreateDealerUserForm, setShowCreateDealerUserForm] = useState(false);
  const [dealerUserCounter, setDealerUserCounter] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null|HTMLButtonElement>(null);
  const [redirect, setRedirect] = useState('');
  const dealer = props.dealer;

  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();

  const deleteDealer = async (dealer: Dealer) => {
    const api = new BaseAPI();
    try {
      const [result, request] = await api.delete(`all-dealers-paginated/${dealer.id}/`);
      props.onDeleted(dealer);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAddress = async (address: DealerAddress) => {
    const api = new BaseAPI();
    try {
      const [result, request] = await api.delete(`dealer-addresses-paginated/${address.id}/`);
      props.onUpdated(dealer);
    } catch (error) {
      console.error(error);
    }
  };

  const sendInvite = async (dealer: Dealer) => {
    const api = new BaseAPI();
    try {
      const [result, request] = await api.post({}, `all-dealers-paginated/${dealer.id}/send_invite/`);
      window.alert('Invite sent!');
    } catch (error) {
      console.error(error);
    }
  }

  let isAdmin = false;
  if (profile && (profile.role === 'admin')) {
    isAdmin = true;
  }

  let canEdit = false;
  if (profile && (profile.role === 'admin')) {
    canEdit = true;
  }
  let canDelete = canEdit;

  if (canEdit && showEditForm) {
    return (
      <div className={`${classes.root} ${props.className}`}>
        <DealerForm
          dealer={props.dealer}
          onSave={(dealer) => {
            setShowEditForm(false);
            props.onUpdated(dealer);
          }}
          onCancel={() => {
            setShowEditForm(false);
          }}
        /> 
      </div>
    )
  }

  return (
    <div className={`${classes.root} ${props.className}`}>

      <Grid container spacing={3} component={Paper} style={{margin: 0, marginBottom: 12, width: '100%'}}>
        {(dealer.customer_number != '0') &&
        <Grid item xs={12}>
          <div className={classes.shippingAddressContainer}>
            <Typography variant="h6">Dealer <Chip color={props.dealer.active ? 'primary' : 'secondary'} size="small" label={dealer.active ? "active" : "inactive"} style={{marginRight: 6}} /></Typography>
            <Typography variant="body2">
              Dealer Name: {dealer.customer_name} <br />
              Dealer #: {dealer.customer_number.padStart(5, '0')} <br />
              Email: {dealer.email ? <a href={`mailto:${dealer.email}`}>{dealer.email}</a>: '-'} <br />
              Labor Rate: ${dealer.labor_rate}/hour <br />
              Date Joined: {dealer.date_joined && <DateTimeView value={dealer.date_joined} dateOnly />} <br />
              Last Login: {dealer.last_login && <DateTimeView value={dealer.last_login} />} <br />
            </Typography>

            <div style={{marginBottom: 20, marginTop: 20, display: 'flex', justifyContent: 'space-between'}}>
              {canEdit &&
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowEditForm(true)}
                  style={{marginRight: 10}}
                  size="small"
                >Edit</Button>

                <Button
                  variant="contained"
                  onClick={() => setShowEditLaborRateForm(true)}
                  style={{marginRight: 10}}
                  size="small"
                >Change Labor Rate</Button>

                {canDelete &&
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (window.confirm('Remove this dealer?')) {
                      deleteDealer(props.dealer);
                    }
                  }}
                  size="small"
                  style={{marginRight: 10}}
                >Delete</Button>}
              </div>}
            </div>

          </div>
        </Grid>}
      </Grid>

      {(dealer.addresses.length > 0) && 
      <div className="mb-4">
        {dealer.addresses.map((dealerAddress, i) => (
          <Card className="inline-block mr-2 mb-2 min-w-[180px]" variant="outlined" key={`address-${i}`}>
            <CardContent className="min-h-[181px]">
              <Typography className="" color="textSecondary" gutterBottom>
                Address #{i+1}
              </Typography>
              <Typography variant="body2">
                Email: {dealerAddress.email ? <a href={`mailto:${dealerAddress.email}`}>{dealerAddress.email}</a>: '-'} <br />
                Phone: {dealerAddress.phone ? <a href={`tel:${dealerAddress.phone}`}>{dealerAddress.phone}</a>: '-'} <br />
              </Typography>
              <Typography variant="body2">{dealerAddress.address_line_1}</Typography>
              <Typography variant="body2">{dealerAddress.address_line_2}</Typography>
              <Typography variant="body2">{dealerAddress.city} {dealerAddress.state} {dealerAddress.zip}</Typography>
              <Typography variant="body2">{dealerAddress.country}</Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton aria-label="edit" onClick={() => setEditAddress(dealerAddress)}>
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                style={{marginLeft: 'auto'}}
                onClick={() => {
                  if (window.confirm('Remove this address?')) {
                    deleteAddress(dealerAddress);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Card>
        ))}
        
        <div className="inline-block MuiCard-root h-[254px] pt-[90px]">
          <Tooltip title="add new address">
            <IconButton
              aria-label="add"
              onClick={() => {
                setEditAddress({
                  address_line_1: '',
                  address_line_2: '',
                  city: '',
                  country: 'United States',
                  state: '',
                  zip: '',
                  phone: '',
                  email: '',
                })
              }}
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </div>
      </div>}
  
      {(dealer.addresses.length === 0) && 
      <div className="mb-4">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setEditAddress({
              address_line_1: '',
              address_line_2: '',
              city: '',
              country: 'United States',
              state: '',
              zip: '',
              phone: '',
              email: '',
            });
          }}
          style={{marginRight: 10}}
          size="small"
        >Add new address</Button>
      </div>}
      
      <DealerUserCollection
        key={`dealer-users-${dealerUserCounter}`}
        dealerId={dealer.id}
        backUrl={window.location.pathname}
        hideDealer
      />

      <div style={{marginBottom: 20, marginTop: 20, display: 'flex', justifyContent: 'space-between'}}>
        {canEdit &&
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowCreateDealerUserForm(true);
            }}
            style={{marginRight: 10}}
            size="small"
          >Add User</Button>
        </div>}
        {canEdit &&
        <div>
          <Button
            variant="contained"
            onClick={() => {
              if (window.confirm('Send invite email to all users from this dealer?')) {
                sendInvite(dealer);
              }
            }}
            style={{marginRight: 10}}
            size="small"
          >Send Invite to All Users</Button>
        </div>}
      </div>


      <Dialog
        open={!!editAddress}
        onClose={() => {
          setEditAddress(undefined);
        }}
        TransitionComponent={Transition}
      >
        <>
        <DialogContent>
          <DealerAddressForm
            address={editAddress}
            dealerId={dealer.id}
            onSaved={(address) => {
              props.onUpdated(dealer);
              setEditAddress(undefined);
            }}
            onCancel={() => {
              setEditAddress(undefined);
            }}
          />
        </DialogContent>
        </>
      </Dialog>

      <Dialog
        open={showEditLaborRateForm}
        onClose={() => {
          setShowEditLaborRateForm(false);
        }}
        TransitionComponent={Transition}
      >
        <>
        <DialogContent>
          <DealerLaborRateForm
            dealer={props.dealer}
            onSave={(dealer) => {
              setShowEditLaborRateForm(false);
              props.onUpdated(dealer);
            }}
            onCancel={() => {
              setShowEditLaborRateForm(false);
            }}
          /> 
        </DialogContent>
        </>
      </Dialog>

      <Dialog
        open={!!showCreateDealerUserForm}
        onClose={() => {
          setEditDealerUser(undefined);
          setShowCreateDealerUserForm(false);
        }}
        TransitionComponent={Transition}
      >
        <>
        <DialogContent>
          <DealerUserForm
            dealerUser={editDealerUser}
            dealerId={dealer.id}
            onSaved={(dealerUser) => {
              props.onUpdated(dealer);
              setDealerUserCounter(dealerUserCounter+1);
              setEditDealerUser(undefined);
              setShowCreateDealerUserForm(false);
            }}
            onCancel={() => {
              setEditDealerUser(undefined);
              setShowCreateDealerUserForm(false);
            }}
          />
        </DialogContent>
        </>
      </Dialog>


      {!!redirect && <Redirect to={redirect} />}
    </div>
  );
}