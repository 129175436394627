import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  LinearProgress,
  ListSubheader,
  ButtonGroup,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  CircularProgress,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Toolbar,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton,
  Alert
} from '@material-ui/lab';
import PreviousIcon from '@material-ui/icons/ArrowBack';
import NextIcon from '@material-ui/icons/ArrowForward';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import { useProfile } from '../../data/Profile';
import DateTimeView from '../singles/DateTimeView';
import {
  DealerUser
} from '../../types';
import SortButton from './components/SortButton';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  container: {
    paddingBottom: 10,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  tableNav: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paginationControl: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function DealerUserCollection(props: {
  className?: string;
  dealerId?: string;
  userId?: string;
  hideUser?: boolean;
  hideDealer?: boolean;
  backUrl?: string;
}) {
  const classes = useStyles();

  const [pageSize, setPageSize] = useState(100);
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [isLoading, setIsLoading] = useState(false);
  const [sortKey, setSortKey] = useState<string|undefined>(undefined);
  const [dealerUsers, setDealerUsers] = useState([] as DealerUser[]);
  const [dealerUsersMeta, setDealerUsersMeta] = useState({
    count: 0,
    page_size: 10,
    num_pages: 0,
    page: 0,
    next: 0
  });

  const { path, url } = useRouteMatch();
  const backUrl = `${url}../`;

  const loadDealerUsers = async (page?: number, dealerId?: string, userId?: string, ordering?: string) => {
    if (isLoading) return;
    if (!dealerId && !userId) return;

    const api = new BaseAPI();
    setIsLoading(true);
    let url = `dealer-users-paginated/`;
    let kwargs: any = {
      page_size: pageSize,
    };
    if (page) kwargs.page = page;
    if (dealerId) kwargs.dealer = dealerId;
    if (userId) kwargs.user = userId;
    else if (sortKey) kwargs.ordering = sortKey;

    url = `${url}?${new URLSearchParams(kwargs).toString()}`;

    try {
      const data: any = await api.get(url);
      if (data.results instanceof Array) {
        if (page && (page > dealerUsersMeta.page)) {
          const results = data.results as DealerUser[];
          setDealerUsers(results);
        }
        else {
          setDealerUsers(data.results as DealerUser[]);
        }

        setIsLoading(false);
        setDealerUsersMeta({
          count: data.count,
          page_size: data.page_size,
          num_pages: data.num_pages,
          page: data.page,
          next: data.next
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadDealerUsers(undefined, props.dealerId, props.userId, sortKey);
  }, [sortKey, pageSize, props.dealerId, props.userId]);

  let paginations = Array.from({length: dealerUsersMeta.num_pages}, (_, i) => i + 1);
  if (paginations.length > 10) {
    const starts = paginations.slice(0, 3);
    const ends = paginations.slice(paginations.length - 4, paginations.length - 1);
    let middles: number[] = [];
    if (starts.length && ends.length && dealerUsersMeta.page > 1) {
      if (!starts.includes(dealerUsersMeta.page - 1) && !ends.includes(dealerUsersMeta.page - 1)) middles.push(dealerUsersMeta.page - 1);
      if (!starts.includes(dealerUsersMeta.page) && !ends.includes(dealerUsersMeta.page)) middles.push(dealerUsersMeta.page);
      if (!starts.includes(dealerUsersMeta.page + 1) && !ends.includes(dealerUsersMeta.page + 1)) middles.push(dealerUsersMeta.page + 1);
    }
    const newPaginations = [...starts, ...middles, ...ends];
    paginations = newPaginations;
  }
  let totalColumns = 10;
  if (props.hideDealer) totalColumns -= 1;
  if (props.hideUser) totalColumns -= 2;

  return (
    <div className={classes.root}>
      <TableContainer component={Paper} className={classes.container}>
        <Table size="small">
          <TableHead>
            {isLoading && 
            <TableRow>
              <TableCell colSpan={totalColumns} style={{padding: 0}}>
                <LinearProgress id="dealer-users-list-loading" style={{width: '100%'}} />
              </TableCell>
            </TableRow>}
            <TableRow>
              {!props.hideDealer &&
              <TableCell>
                <SortButton
                  sortKey='dealer__customer_name'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                >Dealer</SortButton>
              </TableCell>}
              {!props.hideUser &&
              <TableCell>
                <SortButton
                  sortKey='user__first_name'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                >User</SortButton>
              </TableCell>}
              {!props.hideUser &&
              <TableCell align="right">
                <SortButton
                  sortKey='user__email'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >Email</SortButton>
              </TableCell>}
              <TableCell align="right">
                <SortButton
                  sortKey='active'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >Active</SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey='admin'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >Dealer Administration</SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey='order'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >Order</SortButton>
              </TableCell>
              <TableCell align="right">
                <SortButton
                  sortKey='warranty'
                  currentSortKey={sortKey}
                  onSort={(sortKey) => {
                    setSortKey(sortKey);
                  }}
                  disabled={isLoading}
                  style={{textAlign: 'right'}}
                >Warranty</SortButton>
              </TableCell>
              <TableCell align="right">
                Date Joined
              </TableCell>
              <TableCell align="right">
                Last Login
              </TableCell>
              <TableCell align="right" className="min-w-[150px]"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dealerUsers.map((dealerUser, i) => {
              return (
                <DealerUserRow
                  key={`dealer-user-item-${dealerUser.id}`}
                  dealerUser={dealerUser}
                  hideDealer={props.hideDealer}
                  hideUser={props.hideUser}
                  backUrl={props.backUrl}
                  onReload={() => {
                    loadDealerUsers(dealerUsersMeta.page, props.dealerId, props.userId);
                  }}
                />
              );
            })}
            {(dealerUsers.length === 0) &&
              <TableRow>
                <TableCell colSpan={totalColumns}>{isLoading ? 'Loading...' : 'No data yet.'}</TableCell>
              </TableRow>
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={1}>
                {isLoading && <CircularProgress size={20} />}
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={8}
                count={dealerUsersMeta.count}
                rowsPerPage={dealerUsersMeta.page_size}
                page={dealerUsersMeta.page-1}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={(event: any, newPage: number) => {
                  if (!isLoading) loadDealerUsers(newPage + 1, props.dealerId, props.userId);
                }}
                onChangeRowsPerPage={(event: any) => {
                  if (!isLoading) setPageSize(parseInt(event.target.value, 10));
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}


interface FormValues {
  active: boolean;
  admin: boolean;
  order: boolean;
  warranty: boolean;
  receive_admin_notification_mails: boolean;
  receive_orders_notification_mails: boolean;
  receive_warranty_notification_mails: boolean;
}

function DealerUserRow(props: {
  dealerUser: DealerUser;
  hideUser?: boolean;
  hideDealer?: boolean;
  backUrl?: string;
  onReload: () => void;
}) {
  const classes = useStyles();
  const dealerUser = props.dealerUser;
  const [anchorEl, setAnchorEl] = useState<null|HTMLButtonElement>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [values, setValues] = useState({
    active: dealerUser.active,
    admin: dealerUser.admin,
    order: dealerUser.order,
    warranty: dealerUser.warranty,
    receive_admin_notification_mails: dealerUser.receive_admin_notification_mails,
    receive_orders_notification_mails: dealerUser.receive_orders_notification_mails,
    receive_warranty_notification_mails: dealerUser.receive_warranty_notification_mails,
  })

  let dealerUrl = `${appConfig.homepage}dealers/${dealerUser.dealer.id}/`;
  let userUrl = `${appConfig.homepage}users/${dealerUser.user.id}/`;
  if (props.backUrl) {
    dealerUrl = `${dealerUrl}?back=${props.backUrl}`;
    userUrl = `${userUrl}?back=${props.backUrl}`;
  }


  const onSave = async () => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();
    let data = Object.assign({}, values);
    try {
      const [result, request] = await api.patch(data, `dealer-users-paginated/${dealerUser.id}/`);
      props.onReload();
      setIsDirty(false);
      setIsSaving(false);
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  };

  const onDelete = async () => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();
    try {
      const [result, request] = await api.delete(`dealer-users-paginated/${dealerUser.id}/`);
      props.onReload();
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  };

  const onReset = () => {
    setValues({
      active: dealerUser.active,
      admin: dealerUser.admin,
      order: dealerUser.order,
      warranty: dealerUser.warranty,
      receive_admin_notification_mails: dealerUser.receive_admin_notification_mails,
      receive_orders_notification_mails: dealerUser.receive_orders_notification_mails,
      receive_warranty_notification_mails: dealerUser.receive_warranty_notification_mails,
    });
    setIsDirty(false);
  };

  return (
    <TableRow>
      {!props.hideDealer &&
      <TableCell>
        <Button component={RouterLink} to={dealerUrl} size="medium" style={{textTransform: 'none', padding: 0}}>{dealerUser.dealer.customer_name}</Button>
      </TableCell>}
      {!props.hideUser && <TableCell>
        <Button component={RouterLink} to={userUrl} size="medium" style={{textTransform: 'none', padding: 0}}>{dealerUser.user.first_name} {dealerUser.user.last_name}</Button>
      </TableCell>}
      {!props.hideUser && <TableCell align="right">
        <Button component={RouterLink} to={userUrl} size="medium" style={{textTransform: 'none', padding: 0}}>{dealerUser.user.email}</Button>
      </TableCell>}
      <TableCell align="right">
        <Tooltip title="is active user">
          <Checkbox
            checked={values.active}
            onChange={(event, checked) => {
              const newValue = Object.assign({}, values);
              newValue.active = checked;
              setValues(newValue);
              setIsDirty(true);
            }}
            name="active"
          />
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        <Tooltip title="has full access to all dealer functionality">
          <Checkbox
            checked={values.admin}
            name="admin"
            onChange={(event, checked) => {
              const newValue = Object.assign({}, values);
              newValue.admin = checked;
              setValues(newValue);
              setIsDirty(true);
            }}
          />
        </Tooltip>
        <Tooltip title="receive dealer administration notification emails">
          <Checkbox
            checked={values.receive_admin_notification_mails}
            name="receive_admin_notification_mails"
            onChange={(event, checked) => {
              const newValue = Object.assign({}, values);
              newValue.receive_admin_notification_mails = checked;
              setValues(newValue);
              setIsDirty(true);
            }}
          />
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        <Tooltip title="has access to ordering portal">
          <Checkbox
            checked={values.order}
            name="order"
            onChange={(event, checked) => {
              const newValue = Object.assign({}, values);
              newValue.order = checked;
              setValues(newValue);
              setIsDirty(true);
            }}
          />
        </Tooltip>
        <Tooltip title="receive order notification emails">
          <Checkbox
            checked={values.receive_orders_notification_mails}
            name="receive_orders_notification_mails"
            onChange={(event, checked) => {
              const newValue = Object.assign({}, values);
              newValue.receive_orders_notification_mails = checked;
              setValues(newValue);
              setIsDirty(true);
            }}
          />
        </Tooltip>
      </TableCell>
      <TableCell align="right">
        <Tooltip title="has access to warranty portal">
          <Checkbox
            checked={values.warranty}
            name="warranty"
            onChange={(event, checked) => {
              const newValue = Object.assign({}, values);
              newValue.warranty = checked;
              setValues(newValue);
              setIsDirty(true);
            }}
          />
        </Tooltip>
        <Tooltip title="receive warranty notification emails">
          <Checkbox
            checked={values.receive_warranty_notification_mails}
            name="receive_warranty_notification_mails"
            onChange={(event, checked) => {
              const newValue = Object.assign({}, values);
              newValue.receive_warranty_notification_mails = checked;
              setValues(newValue);
              setIsDirty(true);
            }}
          />
        </Tooltip>
      </TableCell>
      <TableCell align="right">{props.dealerUser.date_joined && <DateTimeView value={props.dealerUser.date_joined} />}</TableCell>
      <TableCell align="right">{props.dealerUser.last_login && <DateTimeView value={props.dealerUser.last_login} />}</TableCell>
      {isSaving &&
      <TableCell align="right">
        <CircularProgress size={20} />
      </TableCell>}
      {!isSaving &&
      <TableCell align="right">
        {isDirty &&
        <Tooltip title="Save">
          <IconButton aria-label="save" style={{marginLeft: 'auto'}} onClick={() => onSave()}>
            <DoneIcon />
          </IconButton>
        </Tooltip>}
        {isDirty &&
        <Tooltip title="Cancel">
          <IconButton aria-label="cancel" style={{marginLeft: 'auto'}} onClick={() => onReset()}>
            <CancelIcon />
          </IconButton>
        </Tooltip>}
        {!isDirty &&
        <Tooltip title="Remove">
          <IconButton
            aria-label="delete"
            style={{marginLeft: 'auto'}}
            onClick={() => {
              if (window.confirm('Remove this user?')) {
                onDelete();
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>}
      </TableCell>}
    </TableRow>
  );
}


function TablePaginationActions(props: {
  count: number;
  onChangePage?: (event: any, newPage: number) => void;
  page: number;
  rowsPerPage: number;
}) {
  const classes = useStyles();
  const { count, page, rowsPerPage } = props;

  const onChangePage = (event: any, newPage: number) => {
    if (props.onChangePage) props.onChangePage(event, newPage);
  }

  let numPages = Math.ceil(props.count / props.rowsPerPage);
  let paginations = Array.from({length: numPages}, (_, i) => i + 1);
  if (paginations.length > 10) {
    let page = props.page + 1;
    const starts = paginations.slice(0, 3);
    const ends = paginations.slice(paginations.length - 3, paginations.length );
    let middles: number[] = [];
    if (starts.length && ends.length && page > 1) {
      if (!starts.includes(page - 1) && !ends.includes(page - 1)) middles.push(page - 1);
      if (!starts.includes(page) && !ends.includes(page)) middles.push(page);
      if (((page + 1) < numPages) && !starts.includes(page + 1) && !ends.includes(page + 1)) middles.push(page + 1);
    }
    const newPaginations = [...starts, ...middles, ...ends];
    paginations = newPaginations;
  }

  return (
    <div className={classes.paginationControl}>
      <IconButton
        onClick={(event) => {
          onChangePage(event, 0);
        }}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={(event) => {
          onChangePage(event, page - 1);
        }}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {paginations.map(i => (
        <Button
          key={`pagination-button-${i}`}
          disabled={(i - 1) === (props.page)}
          onClick={() => {
            onChangePage(undefined, i - 1);
          }}
        >{i}</Button>
      ))}
      <IconButton
        onClick={(event) => {
          onChangePage(event, page + 1);
        }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={(event) => {
          onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}
