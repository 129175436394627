import React, {
  useState,
  useEffect
} from 'react';


import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Radio,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Grid,
  Divider,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import formatNumber from 'format-number';
import bankersRounding from '../../../../utils/bankersRounding';

import {
  isPartRequired,
  getVariants,
  toggleOrderLine,
  replaceOrderLine,
  addOrderLine,
  removeOrderLine,
} from './utils';

import {
  Boat,
  Part,
  PartGroup,
  GroupedPart,
  Dealer,
  PartVariant,
  PartRule,
  DealerDiscount,
} from '../../../../types';

import {
  OrderLine,
  PartOrderLine,
  DiscountItem,
  FormValues
} from '../OrderTypes';


const useStyles = makeStyles({
  root: {
    padding: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  totalContainer: {
    padding: `20px 36px`,
    textAlign: 'right',
  },
  boatTitle: {
    marginBottom: 20,
  },
  noteContainer: {
    marginBottom: 20,
  },
  closeButton: {
    padding: 4,
  },
  listItem: {
    paddingRight: 112,
  },
  allowNewline: {
    whiteSpace: "pre-line",
  },
  negativeTopMargin: {
    marginTop: -19,
    paddingTop: 0,
  },
  flexDirectionRowReverse: {
    flexDirection: 'row-reverse',
  },
  footerGroup: {
    padding: 0,
  },
  textAlignRight: {
    textAlign: 'right',
  },
  fieldLabel: {
    color: '#777',
  },
  metaContainer: {
    marginBottom: 10,
  },
  smallText: {
    fontSize: 12,
  },
  shippingAddressContainer: {
    marginBottom: 20,
  },
  bolder: {
    fontWeight: 'bold'
  },
});



export function BoatPartItem(props: {
  part: Part;
  groupedPart: GroupedPart;
  boat: Boat;
  dealer?: Dealer;
  orderLines: OrderLine[];
  setOrderLines: (orderLines: OrderLine[]) => void;
  showHiddenInfo?: boolean;
  oneLiner?: boolean;
  className?: string;
}) {
  const classes = useStyles();

  const [isRequired, setIsRequired] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [partVariants, setPartVariants] = useState<PartVariant[]>([]);
  const [currentPrice, setCurrentPrice] = useState(props.part.price);
  const [selectedQuantity, setSelectedQuantity] = useState(props.part.quantity);
  const [selectedVariant, setSelectedVariant] = useState<PartVariant|undefined>(undefined);
  const [orderLine, setOrderLine] = useState<OrderLine>({
    id: props.part.id,
    part_number: props.part.part_number,
    description: props.part.description,
    type: props.part.part_type,
    quantity: selectedQuantity,
    variant: selectedVariant,
    note: '',
    price: currentPrice,
  });

  useEffect(() => {
    setIsRequired(isPartRequired(props.part, props.boat));
    const partVariants = getVariants(props.part, props.boat, props.orderLines);
    setPartVariants(partVariants);
    partVariants.forEach((variant) => {
      if (variant.recommended) {
        setSelectedVariant(variant);
        setCurrentPrice(variant.price && variant.price !== 'None' ? variant.price : props.part.price);
        setSelectedQuantity(variant.quantity);
        setOrderLine({
          id: props.part.id,
          part_number: props.part.part_number,
          description: props.part.description,
          type: props.part.part_type,
          quantity: variant.quantity,
          variant: variant,
          note: '',
          price: variant.price && variant.price !== 'None' ? variant.price : props.part.price,
        })
      }
    });
  }, [props.part, props.boat]);

  useEffect(() => {
    const partVariants = getVariants(props.part, props.boat, props.orderLines);
    setPartVariants(partVariants);
    setIsSelected(props.orderLines.filter(ol => ol.id === orderLine.id).length > 0);

    props.orderLines.filter(ol => ol.id === orderLine.id).forEach((orderLine) => {

      if (!orderLine.variant || !partVariants.includes(orderLine.variant)) {
        let partVariants = getVariants(props.part, props.boat, props.orderLines);
        let currentPrice = props.part.price;
        let selectedQuantity = props.part.quantity;
        let selectedVariant = partVariants.length > 0 ? partVariants[0] : undefined;
        partVariants.forEach((variant) => {
          if (variant.recommended) {
            selectedVariant = variant;
            currentPrice = variant.price && variant.price !== 'None' ? variant.price : props.part.price;
          }
        });
        if (selectedVariant) selectedQuantity = selectedVariant.quantity;
        let newOrderLine = {
          id: props.part.id,
          part_number: props.part.part_number,
          description: props.part.description,
          type: props.part.part_type,
          quantity: selectedQuantity,
          variant: selectedVariant,
          note: '',
          price: currentPrice,
        };
        setOrderLine(newOrderLine);
        if (orderLine.variant?.id !== selectedVariant?.id) {
          props.setOrderLines(replaceOrderLine(newOrderLine, props.orderLines, part, props.groupedPart, props.boat));
        }
      }
    });

    props.orderLines.filter(ol => ol.id === orderLine.id).forEach((o) => {
      if (o.variant !== orderLine.variant) {
        setOrderLine(o);
      }
    })

  }, [props.part, props.boat, props.orderLines.map(orderLine => orderLine.id).join(',')]);


  const part = props.part;

  let disabled = false;
  let disabledParent = disabled;

  let partPrice = part.price;
  if (orderLine.price !== part.price) {
    partPrice = orderLine.price;
  }

  if (isSelected && isRequired) disabledParent = true;

  const canWeDeselectThisPart = (part: Part, variant: PartVariant|null) => {
    if (part.groups.filter(group => group.required).length > 0) return false;

    return true;
  }

  return (
    <>
      {!props.oneLiner &&
      <ListItem
        role={undefined}
        dense
        button
        className={classes.listItem}
        onClick={() => {
          if (disabled) return;
          if (isSelected && !canWeDeselectThisPart(part, null)) return;
          props.setOrderLines(toggleOrderLine(orderLine, props.orderLines, part, props.groupedPart, props.boat));
        }}
      >
        <ListItemIcon>
          {props.groupedPart.group?.group_behavior === 'exclusive' ? (
            <Radio
              edge="start"
              checked={isSelected}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': `list-item-${part.id}` }}
              disabled={disabled || disabledParent}
            />
          ) : (
            <Checkbox
              edge="start"
              checked={isSelected}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': `list-item-${part.id}` }}
              disabled={disabled || disabledParent}
            />
          )}
        </ListItemIcon>
        <ListItemText
          id={`list-item-${part.id}`}
          primary={<PartDescription part={part} isRequired={isRequired} showHiddenInfo={props.showHiddenInfo} />}
          secondary={<PartExtraInfo part={part} orderLines={props.orderLines} showHiddenInfo={props.showHiddenInfo} />} />
        <ListItemSecondaryAction>
          <Typography><b>{(partVariants.length > 0) && isSelected ? '' : formatNumber({prefix: 'US$'})(parseFloat(partPrice))}</b></Typography>
        </ListItemSecondaryAction>
      </ListItem>}
      {partVariants.length > 0 &&
      <Collapse in={isSelected}>
        <ListItem
          role={undefined}
          dense
          className={props.oneLiner ? classes.flexDirectionRowReverse : ''}
          style={{paddingRight: 0}}
        >
          {!props.oneLiner && <ListItemIcon></ListItemIcon>}
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="variant"
              name={`variant_${part.id}`}
              key={`variant_${part.id}-${orderLine.variant?.id}`}
              value={orderLine.variant?.id}
              onChange={e => {
                if (disabled) return;

                const newOrderLine = Object.assign(orderLine, {});
                let selectedVariantId = (e.target as HTMLInputElement).value;
                let selectedVariant: PartVariant|undefined = undefined;
                let selectedVariantQuantity = part.quantity;
                part.variants.forEach(variant => {
                  if (variant.id == selectedVariantId) {
                    selectedVariant = variant;
                    selectedVariantQuantity = variant.quantity;
                  }
                })
                if (selectedVariant && (selectedVariant as PartVariant).price && ((selectedVariant as PartVariant).price !== 'None')) {
                  newOrderLine.price = (selectedVariant as PartVariant).price;
                }
                else {
                  newOrderLine.price = part.price;
                }
                newOrderLine.variant = selectedVariant;
                newOrderLine.quantity = selectedVariantQuantity;
                setOrderLine(newOrderLine);
                props.setOrderLines(replaceOrderLine(newOrderLine, props.orderLines, part, props.groupedPart, props.boat));
              }}
            >
              {partVariants.map((variant, i) => {
                let name = variant.name;
                let price = part.price;
                let priceStr = '';
                if (variant.price && (variant.price !== 'None')) {
                  price = variant.price;
                  priceStr = `${formatNumber({prefix: 'US$'})(parseFloat(variant.price))}`;
                }
                else {
                  priceStr = `${formatNumber({prefix: 'US$'})(parseFloat(part.price))}`;
                }

                if (part.part_type == 'discount_p') {
                  priceStr = `${price}% discount`;
                }
                if (part.part_type == 'discount_a') {
                  priceStr = `${formatNumber({prefix: 'US$'})(parseFloat(price))} discount`;
                }

                if (variant.quantity > 1) {
                  priceStr = `${priceStr} x${variant.quantity}`
                }
                if (props.oneLiner && (orderLine.variant?.id != variant.id)) return;

                if (props.oneLiner && !name.toLowerCase().includes(part.description ? part.description.toLowerCase() : '')) {
                  name = `${part.description}: ${name}`;
                }

                let nameEl = (<span>{name}{variant.admin_only ? " (admin only)" : ""}</span>);
                if (props.showHiddenInfo && variant.part_number) {
                  nameEl = (<span><a href={`/admin/seafox/part/${part.id}/change/`} target="_blank" style={{color: '#888', 'textDecoration': 'none'}} onClick={(e) => e.stopPropagation()}>{variant.part_number} — </a>{name}{variant.admin_only ? " (admin only)" : ""}</span>);
                }

                let radioEl = generateRadioElement(variant.name);

                return (
                  <FormControlLabel
                    key={`variant-selector-${part.id}-${i}`}
                    value={variant.id} control={props.oneLiner ? <></> : radioEl}
                    label={<div>
                      <Typography variant='body2' style={variant.admin_only ? {color: '#f50057'} : {}}>{nameEl} — <b>{priceStr}</b></Typography>
                      {variant.description && <span className="MuiTypography-body2 MuiTypography-colorTextSecondary">{variant.description}</span>}
                    </div>}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </ListItem>
      </Collapse>
      }
    </>
  );
}


function generateRadioElement(name: string) {
  const colors: any = {
    "White Pearl": {
      background: '#ffffff',
      text: '#555',
    },
    "White": {
      background: '#ffffff',
      text: '#555',
    },
    "Marsh Green": {
      background: 'rgb(181,202,144)',
      text: '#fff'
    },
    "Bahama Blue": {
      background: 'rgb(197,225,235)',
      text: '#fff'
    },
    "Artic Blue": {
      background: 'rgb(155,208,236)',
      text: '#fff'
    },
    "Arctic Blue": {
      background: 'rgb(155,208,236)',
      text: '#fff'
    },
    "Channel Blue": {
      background: 'rgb(104,117,144)',
      text: '#fff'
    },
    "Navy": {
      background: 'rgb(0,0,80)',
      text: '#fff'
    },
    "Light Grey": {
      background: 'rgb(200,200,200)',
      text: '#fff'
    },
    "Light Gray": {
      background: 'rgb(200,200,200)',
      text: '#fff'
    },
    "Silver Shark": {
      background: 'rgb(198,198,198)',
      text: '#fff'
    },
    "Silver Shark Gray": {
      background: 'rgb(198,198,198)',
      text: '#fff'
    },
    "Bull Shark": {
      background: 'rgb(150,165,178)',
      text: '#fff'
    },
    "Bull Shark Gray": {
      background: 'rgb(150,165,178)',
      text: '#fff'
    },
    "Grey": {
      background: 'rgb(150,150,150)',
      text: '#fff'
    },
    "Jetty Grey": {
      background: 'rgb(55,55,55)',
      text: '#fff'
    },
    "Jetty Gray": {
      background: 'rgb(55,55,55)',
      text: '#fff'
    },
    "Black": {
      background: 'rgb(0,0,0)',
      text: '#fff'
    }
  };

  if (colors[name.trim()]) {
    return (<RadioColor backgroundColor={colors[name.trim()].background} textColor={colors[name.trim()].text} />);
  }
  return (<Radio />);
}


function RadioColor(props: {
  backgroundColor: string;
  textColor: string;
}) {

  return (
    <div style={{
      backgroundColor: props.backgroundColor,
      borderRadius: 3,
      marginRight: 4,
      marginBottom: 4,
      boxShadow: '0px 0px 2px #aaa'
    }}>
      <Radio color="secondary" {...(props as any)} style={{color: props.textColor}} />
    </div>
  );
}


function PartDescription(props: {
  part: Part;
  showHiddenInfo?: boolean;
  isRequired: boolean;
}) {
  const part = props.part;

  let partNumber = part.part_number;
  part.must_includes.forEach(extraPart => {
    let hiddenPart = false;
    part.hidden_parts.forEach(hp => {
      if (hp.id === extraPart.id) hiddenPart = true;
    });
    if (hiddenPart) return;

    let extraPartQuantity = 1;
    part.must_includes_options.forEach(option => {
      if (option.part === extraPart.id) {
        extraPartQuantity = option.minimum_quantity;
      }
    });

    for (let i = 0; i < extraPartQuantity; i++) {
      partNumber = `${partNumber}/${extraPart.part_number}`;
    }
  })

  let description = part.description;
  if (part.quantity > 1) {
    description = `${description} x${part.quantity}`
  }

  let partDescriptionTextElement = (<span style={part.admin_only ? {color: '#f50057'} : {}}>{part.admin_only ? `${description} (admin only)` : description}</span>);

  if (props.showHiddenInfo) {
    let includedParts = (<></>);
    if (part.must_includes.length > 0) {
      includedParts = (<div style={{color: '#888', }}>Included parts: {part.must_includes.map((pp) => (<a href={`/admin/seafox/part/${pp.id}/change/`} target="_blank"  style={{color: '#888', textDecoration: 'none', marginRight: 6}} onClick={(e) => e.stopPropagation()}>{pp.part_number}</a>))}</div>);
    }
    let excludedParts = (<></>);
    if (part.must_excludes.length > 0) {
      excludedParts = (<div style={{color: '#888', }}>Excluded parts:
        {part.must_excludes.map((pp) => (<a href={`/admin/seafox/part/${pp.id}/change/`} target="_blank"  style={{color: '#888', textDecoration: 'none', marginRight: 6}} onClick={(e) => e.stopPropagation()}>{pp.part_number}</a>))}
      </div>);
    }
    let requiredParts = (<></>);
    if (part.unselectable_unless.length > 0) {
      requiredParts = (<div style={{color: '#888', }}>Required parts: {part.unselectable_unless.map((pp) => (<a href={`/admin/seafox/part/${pp.id}/change/`} target="_blank"  style={{color: '#888', textDecoration: 'none', marginRight: 6}} onClick={(e) => e.stopPropagation()}>{pp.part_number}</a>))}</div>);
    }
    partDescriptionTextElement = (
      <span style={part.admin_only ? {color: '#f50057'} : {}}>
        <a href={`/admin/seafox/part/${part.id}/change/`} target="_blank"  style={{color: '#888', textDecoration: 'none'}} onClick={(e) => e.stopPropagation()}>{partNumber} — </a>
        <span>{part.admin_only ? `${description} (admin only)` : description}</span>
        {includedParts}
        {excludedParts}
        {requiredParts}
      </span>);
  }

  return partDescriptionTextElement;
}


function PartExtraInfo(props: {
  part: Part;
  orderLines: OrderLine[];
  showHiddenInfo?: boolean;
}) {
  const classes = useStyles();
  const part = props.part;
  
  let extraInformation = part.extra_information;

  let disabled = false;
  let requiredPartsTexts: string[] = [];
  part.unselectable_unless.forEach((p) => {
    requiredPartsTexts.push(`${p.description}`);
    let d = true;
    props.orderLines.forEach((o) => {
      if (o.id === p.id) d = false;
    });
    if (d) disabled = true;
  });

  if ((requiredPartsTexts.length > 0) && props.showHiddenInfo) {
    let extra = `requires ${requiredPartsTexts.join(', ')}`;
    extraInformation = `${extra}\n${extraInformation}`;
  }


  return <span className={classes.allowNewline}>{extraInformation}</span>;
}