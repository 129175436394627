import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Grid,
  Paper,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Toolbar,
  IconButton,
  Card,
  CardActions,
  CardContent,
  Tooltip,
} from '@material-ui/core';

import {
  TransitionProps
} from '@material-ui/core/transitions';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import {
  useProfile
} from '../../data/Profile';
import {
  BoatEditor,
} from '../../types';

import localization from '../../utils/localizations';
import DealerUserCollection from '../collections/DealerUserCollection';
import BoatEditorForm from '../forms/BoatEditorForm';
import BoatEditorPartForm from '../forms/BoatEditorPartForm';
import DealerAddressForm from '../forms/DealerAddressForm';
import DealerUserForm from '../forms/DealerUserForm';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
  },
  partNumber: {
    marginRight: 10,
    minWidth: 100,
  },
  alignRight: {
    textAlign: 'right',
  },
  bolder: {
    fontWeight: 'bold'
  },
  noteContainer: {
    marginBottom: 20,
  },
  shippingAddressContainer: {
    marginBottom: 20,
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function BoatEditorDetail(props: {
  boat: BoatEditor;
  onUpdated: (boat: BoatEditor) => void;
  onDeleted: (boat: BoatEditor) => void;
  className?: string;
}) {
  const classes = useStyles();
  const [showEditForm, setShowEditForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null|HTMLButtonElement>(null);
  const [redirect, setRedirect] = useState('');
  const boat = props.boat;

  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();

  const deleteBoat = async (boat: BoatEditor) => {
    const api = new BaseAPI();
    try {
      const [result, request] = await api.delete(`boats/editor/${boat.id}/`);
      props.onDeleted(boat);
    } catch (error) {
      console.error(error);
    }
  };

  let isAdmin = false;
  if (profile && (profile.role === 'admin')) {
    isAdmin = true;
  }

  let canEdit = false;
  if (profile && (profile.role === 'admin')) {
    canEdit = true;
  }
  let canDelete = canEdit;

  if (!canEdit) {
    return (
      <div className={`${classes.root} ${props.className}`}>
        
      </div>
    )
  }

  return (
    <div className={`${classes.root} ${props.className}`}>

      <BoatEditorPartForm
        boat={props.boat}
        onSave={(boat) => {
          props.onUpdated(boat);
        }}
      />

      {!!redirect && <Redirect to={redirect} />}
    </div>
  );
}