import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  IconButton,
  Paper,
  TextField,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import ReactMarkdown from 'react-markdown';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import { useProfile } from '../../data/Profile';
import {
  User,
  Dealer
} from '../../types';
import SortButton from './components/SortButton';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    
  },
}));


export default function UserCollection(props: {
  content?: string;
  className?: string;
}) {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [results, setResults] = useState<string[]>([]);

  React.useEffect(() => {
    if (props.content) {
      const searchTerms: string[] = search.toLowerCase().split(' ').map((term) => term.trim()).filter((term) => !!term);
      const results: string[] = [];
      props.content.split("\n").forEach((line) => {
        if (!line.trim()) return;

        if (searchTerms.length === 0) {
          results.push(line);
          return;
        }

        let included = true;
        let lineLowerCase = line.toLowerCase();
        searchTerms.forEach((search) => {
          if (!lineLowerCase.includes(search)) included = false;
        });
        if (included) results.push(line);
      });
      setSearchTerms(searchTerms);
      setResults(results);
    }
  }, [search])
  
  return (
    <div>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          name="search"
          label="Enter Keywords"
          variant="outlined"
          fullWidth
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </form>
      <List dense aria-label="Standard Items">
        {results.map((result, i) => (
          <ListItem key={`result-${i}`}>
            <ListItemText primary={<HighLightedResultItem content={result} highlighted={searchTerms} />} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}


function HighLightedResultItem(props: {
  content: string;
  highlighted: string[];
}) {
  let content = props.content;
  const words: string[] = content.split(' ').map((term) => term.trim()).filter((term) => !!term);

  props.highlighted.forEach((term) => {
    content = content.replaceAll(term, `**${term}**`);
  });
  content = content.replaceAll('****', "");
  return (<ReactMarkdown>{content}</ReactMarkdown>);
}