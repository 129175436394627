import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { Form, Field } from "react-final-form";

import formatNumber from 'format-number';
import bankersRounding from '../../../utils/bankersRounding';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Divider,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';

import {
  BaseAPI,
} from '../../../data/BaseAPI';

import {
  Boat,
  Part,
  PartGroup,
  GroupedPart,
  Dealer,
  PartVariant,
  PartRule,
  DealerDiscount,
} from '../../../types';

import {
  useProfile
} from '../../../data/Profile';

import localization from '../../../utils/localizations';
import CountrySelect from '../fields/CountrySelect';
import StateSelect from '../fields/StateSelect';


const useStyles = makeStyles({
  root: {
    padding: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  totalContainer: {
    padding: `20px 36px`,
    textAlign: 'right',
  },
  boatTitle: {
    marginBottom: 20,
  },
  noteContainer: {
    marginBottom: 20,
  },
  closeButton: {
    padding: 4,
  },
  listItem: {
    paddingRight: 112,
  },
  allowNewline: {
    whiteSpace: "pre-line",
  },
  negativeTopMargin: {
    marginTop: -19,
    paddingTop: 0,
  },
  flexDirectionRowReverse: {
    flexDirection: 'row-reverse',
  },
  footerGroup: {
    padding: 0,
  },
  textAlignRight: {
    textAlign: 'right',
  },
  fieldLabel: {
    color: '#777',
  },
  metaContainer: {
    marginBottom: 10,
  },
  smallText: {
    fontSize: 12,
  },
  shippingAddressContainer: {
    marginBottom: 20,
  },
  bolder: {
    fontWeight: 'bold'
  },
});

interface OrderLine {
  id: string;
  part_number: string;
  description?: string;
  type: string;
  quantity: number;
  variant?: PartVariant;
  note: string;
  price: string;
}

interface PartOrderLine {
  part: Part;
  orderLine?: OrderLine;
}

interface DiscountItem {
  name: string;
  total: string|number;
}

function OrderListItemGroup(props: {
  boat: Boat;
  groupedPart: GroupedPart;
  orderLines: OrderLine[];
  onChange: (orderLine: OrderLine, part: Part) => void;
  onBulkUpdate: (orderLines: OrderLine[]) => void;
  hideGroupLabel?: boolean;
  defaultGroupName?: string;
  showHiddenInfo?: boolean;
  oneLiner?: boolean;
  className?: string;
}) {
  const classes = useStyles();

  const partOrderLines: PartOrderLine[] = props.groupedPart.parts.map((part, i) => {
    let orderLine: OrderLine|undefined;
    props.orderLines.forEach((o) => {
      if (o.id === part.id) orderLine = o;
    });
    return {part, orderLine};
  });

  const removePartFromOrderLines = (oldOrderLines: OrderLine[], removedOrderLine: OrderLine, part: Part): OrderLine[] => {
    const orderLines = oldOrderLines.slice();

    const removedParts: OrderLine[] = [];
    orderLines.forEach((orderLine, i) => {
      if (orderLine.id === removedOrderLine.id) {
        removedParts.push(orderLine);
      }
    });

    part.must_includes.forEach(part => {
      orderLines.forEach((orderLine, i) => {
        if (orderLine.id === part.id) {
          removedParts.push(orderLine);
        }
      });
    });

    orderLines.forEach((orderLine, i) => {
      props.boat.available_parts.forEach(pp => {
        if (orderLine.id === pp.id) {
          pp.must_includes.forEach(mp => {
            if (mp.id === part.id) {
              if (pp.part_type == 'boat') {
                console.log('removal skipped because target is a boat', pp, part)
              }
              else {
                removedParts.push(orderLine);
                console.log('removing', pp, part)
              }
            }
          });
          pp.unselectable_unless.forEach(mp => {
            if (mp.id === part.id) {
              if (pp.part_type == 'boat') {
                console.log('removal skipped because target is a boat', pp, part)
              }
              else {
                removedParts.push(orderLine);
                console.log('removing', pp, part)
              }
            }
          });
        }
      });
    });

    removedParts.forEach(orderLine => {
      orderLines.splice(orderLines.indexOf(orderLine), 1);
    });

    // iterate over added parts that also exists in boat.available_parts
    // make sure their must included and must excluded is satisfied
    // starting with engine, then the rest

    let addedParts: OrderLine[] = [];
    let engineParts: OrderLine[] = [];
    let otherParts: OrderLine[] = [];

    orderLines.forEach((orderLine, i) => {
      if (orderLine.type === 'engine') {
        engineParts.push(orderLine);
      }
      else {
        if (props.boat.available_parts.find(pp => pp.id === orderLine.id)) {
          addedParts.push(orderLine);
        }
      }
    });

    let primaryParts: OrderLine[] = [...engineParts, ...addedParts];

    primaryParts.forEach((orderLine, i) => {
      let part = props.boat.available_parts.find(pp => pp.id === orderLine.id);
      if (!part) return;
      part.must_includes.forEach(part => {
        if (part.id === removedOrderLine.id) return;
        let partQuantity = 1;
        let partPrice = part.price;
        part.must_includes_options.forEach(option => {
          if (option.part === part.id) {
            console.log(option, part);
            partQuantity = option.minimum_quantity;
            if (option.price !== 'None') {
              partPrice = option.price;
            }
          }
        });
        otherParts.push({
          id: part.id,
          part_number: part.part_number,
          description: part.description,
          type: part.part_type,
          quantity: partQuantity,
          variant: part.variants.length > 0 ? part.variants[0] : undefined,
          note: '',
          price: partPrice,
        });
      });
    });

    primaryParts.forEach((orderLine, i) => {
      let part = props.boat.available_parts.find(pp => pp.id === orderLine.id);
      if (!part) return;
      part.must_excludes.forEach(part => {
        if (part.id === removedOrderLine.id) return;
        if (otherParts.find(op => op.id === part.id)) {
          otherParts.splice(otherParts.findIndex(op => op.id === part.id), 1);
        }
      });
    });

    otherParts.forEach((orderLine, i) => {
      if (orderLines.find(ol => ol.id === orderLine.id)) return;
      orderLines.push(orderLine);
    });

    return orderLines;
  }

  const onAdd = (orderLine: OrderLine, part: Part) => {
    let orderLines = props.orderLines.slice().filter(o => o.id !== orderLine.id);
    if (props.groupedPart.group?.group_behavior === 'exclusive') {
      // exclusive parts: remove other parts from the same category
      partOrderLines.forEach(({part, orderLine}, i) => {
        if (orderLine) {
          orderLines = removePartFromOrderLines(orderLines, orderLine, part);
        }
      });
    }
    else {
      if (part.override_group_behavior === 'exclusive') {
        partOrderLines.forEach(({part, orderLine}, i) => {
          if (orderLine && (part.override_group_behavior === 'exclusive')) {
            orderLines = removePartFromOrderLines(orderLines, orderLine, part);
          }
        }); 
      }
    }

    const newItems: OrderLine[] = [];

    part.must_includes.forEach(extraPart => {
      let extraPartQuantity = 1;
      let extraPartPrice = extraPart.price;
      part.must_includes_options.forEach(option => {
        if (option.part === extraPart.id) {
          console.log(option, extraPart);
          extraPartQuantity = option.minimum_quantity;
          if (option.price !== 'None') {
            extraPartPrice = option.price;
          }
        }
      });
      newItems.push({
        id: extraPart.id,
        part_number: extraPart.part_number,
        description: extraPart.description,
        type: extraPart.part_type,
        quantity: extraPartQuantity,
        variant: extraPart.variants.length > 0 ? extraPart.variants[0] : undefined,
        note: '',
        price: extraPartPrice,
      });
    });
    newItems.push(orderLine);

    const shouldBeOnFront = part.part_type === 'boat';

    newItems.forEach(orderLine => {
      if (shouldBeOnFront) {
        orderLines.unshift(orderLine);
      }
      else {
        orderLines.push(orderLine);
      }
    });

    part.must_excludes.forEach(part => {
      orderLines.forEach((orderLine, i) => {
        if (orderLine.id === part.id) {
          orderLines = removePartFromOrderLines(orderLines, orderLine, part);
        }
      });
    });
    orderLines.forEach((orderLine, i) => {
      props.boat.available_parts.forEach(p => {
        if (p.id === orderLine.id) {
          p.must_excludes.forEach(pp => {
            if (part.id === pp.id) {
              orderLines = removePartFromOrderLines(orderLines, orderLine, p);
            }
            orderLines.forEach((oo) => {
              if (oo.id === pp.id) {
                orderLines = removePartFromOrderLines(orderLines, oo, pp);
              }
            });
          });
        }
      })
    });

    props.onBulkUpdate(orderLines);
  }

  const onRemove = (orderLine: OrderLine, part: Part) => {
    const orderLines = removePartFromOrderLines(props.orderLines, orderLine, part);
    props.onBulkUpdate(orderLines);
  }

  const isPartRequired = (part: Part) => {
    let required = false;

    props.boat.required_parts.forEach((requiredPart) => {
      if (requiredPart.id === part.id) required = true;
    })

    return required;
  }

  let defaultGroupName = '';
  if (props.defaultGroupName) defaultGroupName = props.defaultGroupName;

  let hideGroupLabel = props.hideGroupLabel;
  if (props.oneLiner) hideGroupLabel = true;

  return (
    <List
      key={`grouped-list-${props.groupedPart.group?.id}`}
      className={`${classes.root} ${props.className ? props.className : ''}`}
      subheader={!hideGroupLabel ?
        <ListSubheader component="div">{props.groupedPart.group?.name ? props.groupedPart.group?.name : defaultGroupName}</ListSubheader>
        : undefined
      }
    >
      {partOrderLines.map(({part, orderLine}, i) => {
        return (
          <OrderListItem
            key={`order-list-item-${part.id}`}
            boat={props.boat}
            part={part}
            group={props.groupedPart.group}
            orderLine={orderLine}
            orderLines={props.orderLines}
            onAdd={onAdd}
            onChange={props.onChange}
            onRemove={onRemove}
            isRequired={isPartRequired(part)}
            showHiddenInfo={props.showHiddenInfo}
            oneLiner={props.oneLiner}
          />
        );
      })}
    </List>
  );
}

function OrderListItem(props: {
  boat: Boat;
  part: Part;
  group?: PartGroup|null;
  orderLine?: OrderLine;
  orderLines: OrderLine[];
  isRequired?: boolean;
  oneLiner?: boolean;
  showHiddenInfo?: boolean;
  onAdd: (orderLine: OrderLine, part: Part) => void;
  onChange: (orderLine: OrderLine, part: Part) => void;
  onRemove: (orderLine: OrderLine, part: Part) => void;
}) {
  const classes = useStyles();

  const part = props.part;
  const getVariants = (part: Part, boat: Boat, orderLines: OrderLine[]) => {
    return part.variants.filter(variant => {
      let boatFound = false;
      variant.boats.forEach((b) => {
        if (b.id == boat.id) boatFound = true;
      })
      if (variant.boats.length > 0) {
        if (boatFound) {
          if (variant.parts.length > 0) {
            let found = false;
            variant.parts.forEach((p) => {
              orderLines.forEach((orderLine) => {
                if (orderLine.id == p.id) {
                  found = true;
                }
              });
            });
            if (!found) return false;
          }
        }
        else {
          return false;
        }
      }
      return true;
    });
  };
  let partVariants = getVariants(part, props.boat, props.orderLines);
  let currentPrice = props.part.price;
  let selectedQuantity = props.part.quantity;
  let selectedVariant = partVariants.length > 0 ? partVariants[0] : undefined;
  partVariants.forEach((variant) => {
    if (variant.recommended) {
      selectedVariant = variant;
      currentPrice = variant.price && variant.price !== 'None' ? variant.price : props.part.price;
      selectedQuantity = variant.quantity;
    }
  });
  if (selectedVariant) selectedQuantity = selectedVariant.quantity;
  const [orderLine, setOrderLine] = useState({
    id: props.part.id,
    part_number: props.part.part_number,
    description: props.part.description,
    type: props.part.part_type,
    quantity: selectedQuantity,
    variant: selectedVariant,
    note: '',
    price: currentPrice,
  });

  const isSelected = !!props.orderLine;

  let partNumber = part.part_number;
  part.must_includes.forEach(extraPart => {
    let hiddenPart = false;
    part.hidden_parts.forEach(hp => {
      if (hp.id === extraPart.id) hiddenPart = true;
    });
    if (hiddenPart) return;

    let extraPartQuantity = 1;
    part.must_includes_options.forEach(option => {
      if (option.part === extraPart.id) {
        extraPartQuantity = option.minimum_quantity;
      }
    });

    for (let i = 0; i < extraPartQuantity; i++) {
      partNumber = `${partNumber}/${extraPart.part_number}`;
    }
  })

  let description = props.isRequired ? `${part.description} (REQUIRED)` : part.description;
  if (part.quantity > 1) {
    description = `${description} x${part.quantity}`
  }

  useEffect(() => {
    let partVariants = getVariants(props.part, props.boat, props.orderLines);
    if (!orderLine.variant || !partVariants.includes(orderLine.variant)) {
      let partVariants = getVariants(props.part, props.boat, props.orderLines);
      let currentPrice = props.part.price;
      let selectedQuantity = props.part.quantity;
      let selectedVariant = partVariants.length > 0 ? partVariants[0] : undefined;
      partVariants.forEach((variant) => {
        if (variant.recommended) {
          selectedVariant = variant;
          currentPrice = variant.price && variant.price !== 'None' ? variant.price : props.part.price;
        }
      });
      if (selectedVariant) selectedQuantity = selectedVariant.quantity;
      let newOrderLine = {
        id: props.part.id,
        part_number: props.part.part_number,
        description: props.part.description,
        type: props.part.part_type,
        quantity: selectedQuantity,
        variant: selectedVariant,
        note: '',
        price: currentPrice,
      };
      setOrderLine(newOrderLine);
      if (props.orderLine && props.orderLine.variant?.id !== selectedVariant?.id) {
        props.onAdd(newOrderLine, props.part);
      }
    }
  }, [props.orderLines]);

  let partPrice = part.price;
  if (props.orderLine && props.orderLine.price !== part.price) {
    partPrice = props.orderLine.price;
  }

  let partDescriptionTextElement = (<span style={part.admin_only ? {color: '#f50057'} : {}}>{part.admin_only ? `${description} (admin only)` : description}</span>);

  if (props.showHiddenInfo) {
    let includedParts = (<></>);
    if (part.must_includes.length > 0) {
      includedParts = (<div style={{color: '#888', }}>Included parts: {part.must_includes.map((pp) => (<a href={`/admin/seafox/part/${pp.id}/change/`} target="_blank"  style={{color: '#888', textDecoration: 'none', marginRight: 6}} onClick={(e) => e.stopPropagation()}>{pp.part_number}</a>))}</div>);
    }
    let excludedParts = (<></>);
    if (part.must_excludes.length > 0) {
      excludedParts = (<div style={{color: '#888', }}>Excluded parts:
        {part.must_excludes.map((pp) => (<a href={`/admin/seafox/part/${pp.id}/change/`} target="_blank"  style={{color: '#888', textDecoration: 'none', marginRight: 6}} onClick={(e) => e.stopPropagation()}>{pp.part_number}</a>))}
      </div>);
    }
    let requiredParts = (<></>);
    if (part.unselectable_unless.length > 0) {
      requiredParts = (<div style={{color: '#888', }}>Required parts: {part.unselectable_unless.map((pp) => (<a href={`/admin/seafox/part/${pp.id}/change/`} target="_blank"  style={{color: '#888', textDecoration: 'none', marginRight: 6}} onClick={(e) => e.stopPropagation()}>{pp.part_number}</a>))}</div>);
    }
    partDescriptionTextElement = (
      <span style={part.admin_only ? {color: '#f50057'} : {}}>
        <a href={`/admin/seafox/part/${part.id}/change/`} target="_blank"  style={{color: '#888', textDecoration: 'none'}} onClick={(e) => e.stopPropagation()}>{partNumber} — </a>
        <span>{part.admin_only ? `${description} (admin only)` : description}</span>
        {includedParts}
        {excludedParts}
        {requiredParts}
      </span>);
  }

  let extraInformation = part.extra_information;

  let disabled = false;
  let requiredPartsTexts: string[] = [];
  part.unselectable_unless.forEach((p) => {
    requiredPartsTexts.push(`${p.description}`);
    let d = true;
    props.orderLines.forEach((o) => {
      if (o.id === p.id) d = false;
    });
    if (d) disabled = true;
  });

  if (requiredPartsTexts.length > 0) {
    let extra = `requires ${requiredPartsTexts.join(', ')}`;
    extraInformation = `${extra}\n${extraInformation}`;
  }

  return (
    <>
      {!props.oneLiner &&
      <ListItem
        role={undefined}
        dense
        button
        className={classes.listItem}
        onClick={() => {
          if (disabled) return;
          if (props.orderLine) {
            props.onRemove(orderLine, props.part);
          }
          else {
            props.onAdd(orderLine, props.part);
          }
        }}
      >
        <ListItemIcon>
          {props.group?.group_behavior === 'exclusive' ? (
            <Radio
              edge="start"
              checked={isSelected}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': `list-item-${part.id}` }}
              disabled={disabled}
            />
          ) : (
            <Checkbox
              edge="start"
              checked={isSelected}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': `list-item-${part.id}` }}
              disabled={disabled}
            />
          )}
        </ListItemIcon>
        <ListItemText id={`list-item-${part.id}`} primary={partDescriptionTextElement} secondary={<span className={classes.allowNewline}>{extraInformation}</span>} />
        <ListItemSecondaryAction>
          <Typography><b>{(partVariants.length > 0) && isSelected ? '' : formatNumber({prefix: 'US$'})(parseFloat(partPrice))}</b></Typography>
        </ListItemSecondaryAction>
      </ListItem>}
      {partVariants.length > 0 &&
      <Collapse in={isSelected}>
        <ListItem
          role={undefined}
          dense
          className={props.oneLiner ? classes.flexDirectionRowReverse : ''}
        >
          {!props.oneLiner && <ListItemIcon></ListItemIcon>}
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="variant"
              name={`variant_${part.id}`}
              key={`variant_${part.id}-${orderLine.variant?.id}`}
              value={orderLine.variant?.id}
              onChange={e => {
                if (disabled) return;

                const newOrderLine = Object.assign(orderLine, {});
                let selectedVariantId = (e.target as HTMLInputElement).value;
                let selectedVariant: PartVariant|undefined = undefined;
                let selectedVariantQuantity = part.quantity;
                part.variants.forEach(variant => {
                  if (variant.id == selectedVariantId) {
                    selectedVariant = variant;
                    selectedVariantQuantity = variant.quantity;
                  }
                })
                if (selectedVariant && (selectedVariant as PartVariant).price && ((selectedVariant as PartVariant).price !== 'None')) {
                  newOrderLine.price = (selectedVariant as PartVariant).price;
                }
                else {
                  newOrderLine.price = part.price;
                }
                newOrderLine.variant = selectedVariant;
                newOrderLine.quantity = selectedVariantQuantity;
                setOrderLine(newOrderLine);
                props.onChange(newOrderLine, part);
              }}
            >
              {partVariants.map((variant, i) => {
                let name = variant.name;
                let price = part.price;
                let priceStr = '';
                if (variant.price && (variant.price !== 'None')) {
                  price = variant.price;
                  priceStr = `${formatNumber({prefix: 'US$'})(parseFloat(variant.price))}`;
                }
                else {
                  priceStr = `${formatNumber({prefix: 'US$'})(parseFloat(part.price))}`;
                }

                if (part.part_type == 'discount_p') {
                  priceStr = `${price}% discount`;
                }
                if (part.part_type == 'discount_a') {
                  priceStr = `${formatNumber({prefix: 'US$'})(parseFloat(price))} discount`;
                }

                if (variant.quantity > 1) {
                  priceStr = `${priceStr} x${variant.quantity}`
                }
                if (props.oneLiner && (orderLine.variant?.id != variant.id)) return;

                if (props.oneLiner && !name.toLowerCase().includes(part.description ? part.description.toLowerCase() : '')) {
                  name = `${part.description}: ${name}`;
                }

                let nameEl = (<span>{name}{variant.admin_only ? " (admin only)" : ""}</span>);
                if (props.showHiddenInfo && variant.part_number) {
                  nameEl = (<span><a href={`/admin/seafox/part/${part.id}/change/`} target="_blank" style={{color: '#888', 'textDecoration': 'none'}} onClick={(e) => e.stopPropagation()}>{variant.part_number} — </a>{name}{variant.admin_only ? " (admin only)" : ""}</span>);
                }

                return (<FormControlLabel key={`variant-selector-${part.id}-${i}`} value={variant.id} control={props.oneLiner ? <></> : <Radio />} label={<Typography variant='body2' style={variant.admin_only ? {color: '#f50057'} : {}}>{nameEl} — <b>{priceStr}</b></Typography>} />);
              })}
            </RadioGroup>
          </FormControl>
        </ListItem>
      </Collapse>
      }
    </>
  );
}

function BoatLineItems(props: {
  boat: Boat;
  hideGroupLabel?: boolean;
  includedPartType?: string|undefined;
  excludedPartType?: string|undefined;
  excludedGroups?: string[];
  includedGroups?: string[];
  orderLines: OrderLine[];
  setOrderLines: (orderLines: OrderLine[]) => void;
  oneLiner?: boolean;
  showHiddenInfo?: boolean;
  className?: string;
}) {
  const classes = useStyles();

  const renderChildren = (groupedPart: GroupedPart): any => {
    return (
      <>
        <OrderListItemGroup
          key={`group-children-${groupedPart.group?.id}`}
          boat={props.boat}
          groupedPart={groupedPart}
          orderLines={props.orderLines}
          hideGroupLabel={true}
          defaultGroupName="Other"
          oneLiner={props.oneLiner}
          className={classes.negativeTopMargin}
          onChange={(orderLine: OrderLine, part: Part) => {
            const newOrderLines = props.orderLines.slice();
            props.orderLines.forEach((o, i) => {
              if (o.id === orderLine.id) {
                newOrderLines[i] = orderLine
              }
            });
            props.setOrderLines(newOrderLines);
            console.log('onChange', props.orderLines);
          }}
          onBulkUpdate={(orderLines: OrderLine[]) => {
            props.setOrderLines(orderLines);
            console.log('bulkUpdate', orderLines);
          }}
          showHiddenInfo={props.showHiddenInfo}
        />
        {!!groupedPart.children && groupedPart.children.map((gp, i) => {
          return renderChildren(gp);
        })}
      </>
    );
  }

  return (
    <>
    {props.boat.grouped_parts.map((groupedPart, i) => {
      let show = true;
      if (props.includedPartType && groupedPart.group?.part_type !== props.includedPartType) show = false;
      if (props.excludedPartType && groupedPart.group?.part_type === props.excludedPartType) show = false;
      if (props.excludedGroups && props.excludedGroups.find(group => group === groupedPart.group?.name)) show = false;
      if (props.includedGroups) {
        if (props.includedGroups.find(group => group === groupedPart.group?.name)) {
          show = true;
        }
        else {
          show = false;
        }
      }
      
      const children: any = (
        <>
        {!!groupedPart.children && groupedPart.children.map((gp, i) => {
          return renderChildren(gp);
        })}
        </>
      );
      if (show) {
        return (
          <>
          <OrderListItemGroup
            key={`group-${groupedPart.group?.id}`}
            boat={props.boat}
            groupedPart={groupedPart}
            orderLines={props.orderLines}
            hideGroupLabel={props.hideGroupLabel}
            defaultGroupName="Other"
            oneLiner={props.oneLiner}
            className={props.className}
            onChange={(orderLine: OrderLine, part: Part) => {
              const newOrderLines = props.orderLines.slice();
              props.orderLines.forEach((o, i) => {
                if (o.id === orderLine.id) {
                  newOrderLines[i] = orderLine
                }
              });
              props.setOrderLines(newOrderLines);
              console.log('onChange', props.orderLines);
            }}
            onBulkUpdate={(orderLines: OrderLine[]) => {
              props.setOrderLines(orderLines);
              console.log('bulkUpdate', orderLines);
            }}
            showHiddenInfo={props.showHiddenInfo}
          />
          {children}
          </>
        )
      }
      else return;
    })}
    </>
  );
}

interface FormValues {
  customer_number: string;
  note: string;
  order_type: string;
  customer_name: string;
  shipping_name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  country: string;
  state: string;
  zip: string;
}

export default function OrderBoatForm(props: {
  boat: Boat;
  className?: string;
  onCancel: () => void;
  onOrderCompleted: (orderId: number) => void;
  showHiddenInfo?: boolean;  
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [confirmSubmission, setConfirmSubmission] = useState(undefined as undefined|FormValues);
  const [confirmSubmissionWithName, setConfirmSubmissionWithName] = useState(false);
  const [submitterName, setSubmitterName] = useState('');
  const [submitterNameError, setSubmitterNameError] = useState('');
  const [orderSubmitted, setOrderSubmitted] = useState(false);
  const [newOrderId, setNewOrderId] = useState(undefined as number|undefined);

  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState('');

  const [total, setTotal] = useState(0);
  const [totalWithoutDiscount, setTotalWithoutDiscount] = useState(0);
  const [orderLines, setOrderLines] = useState([] as OrderLine[])
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [allDealers, setAllDealers] = useState([] as Dealer[]);
  const [dealerDiscounts, setDealerDiscounts] = useState([] as DealerDiscount[]);
  const [selectedDealer, setSelectedDealer] = useState(undefined as Dealer|undefined)


  const loadAllDealers = async () => {
    const api = new BaseAPI();
    try {
      const data = await api.get('all-dealers/');
      if (data instanceof Array) {
        const dealers = data as Dealer[];
        setAllDealers(data as Dealer[]);
        if (dealers.length == 1) setSelectedDealer(dealers[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadDealerDiscounts = async () => {
    const api = new BaseAPI();
    try {
      const data = await api.get('dealer-discounts/');
      if (data instanceof Array) {
        const dealerDiscounts = data as DealerDiscount[];
        setDealerDiscounts(dealerDiscounts)
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadAllDealers();
    loadDealerDiscounts();
  }, []);

  useEffect(() => {
    setTotal(computeTotal(orderLines, selectedDealer));
    setTotalWithoutDiscount(computeTotalWithoutDiscounts(orderLines));
  }, [selectedDealer, orderLines.map(orderLine => `${orderLine.id}`).join(',')]);


  const boat = props.boat;

  
  const submitOrder = async (values: FormValues, submitterName: string) => {
    const api = new BaseAPI();
    setIsSaving(true);
    let success = false;
    let orderId: number|undefined = undefined;
    try {
      const [result, response, error] = await api.post({
        boat: boat.id,
        parts: orderLines,
        note: values.note,
        customer_number: values.customer_number,
        order_type: values.order_type,
        customer_name: values.order_type === 'stock' ? '' : values.customer_name,
        shipping_name: values.shipping_name,
        address_line_1: values.address_line_1,
        address_line_2: values.address_line_2,
        city: values.city,
        state: values.state,
        country: values.country,
        zip: values.zip,
        submitter_name: submitterName,
      }, 'orders/submit/');
      success = result.success;
      orderId = result.id;
      console.log('result', result)
    } catch (e) {
      console.log('error:', e, (e as any).errorData);
      let errorMessage = "Error submitting your order. Please try again. Contact us if the issue persist."
      if ((e as any).errorData && (e as any).errorData.errors) {
        errorMessage = (e as any).errorData.errors.join(', ');
        errorMessage = "Error submitting your order: " + errorMessage;
      }
      setShowErrorMessage(errorMessage);
      setConfirmSubmission(undefined);
    }
    setIsSaving(false);

    if (success && orderId) {
      setOrderSubmitted(true);
      setNewOrderId(orderId);
    }
  };

  const onSubmit = async (values: FormValues) => {
    setConfirmSubmission(values);
  };

  const onSubmitConfirmed = (submitterName: string) => {
    if (confirmSubmission) return submitOrder(confirmSubmission, submitterName);
  }

  let standardOptionText = boat.standard_option;
  let standardOptionTextExtra = '';

  const computeTotal = (orderLines: OrderLine[], dealer: Dealer|undefined): number => {
    let total = 0;
    let discount = 0;
    let boatOrderLine = orderLines.find(o => o.type === 'boat');
    orderLines.forEach(orderLine => {
      if (orderLine.type === 'boat' || orderLine.type === 'option') {
        total += parseFloat(orderLine.price) * orderLine.quantity;
      }
      else if (boatOrderLine && orderLine.type === 'discount_p') {
        discount += bankersRounding((parseFloat(orderLine.price) / 100) * parseFloat(boatOrderLine.price) * orderLine.quantity, 2);
      }
      else if (boatOrderLine && orderLine.type === 'discount_a') {
        let d = parseFloat(boatOrderLine.price) - parseFloat(orderLine.price);
        if (d < 0) discount += parseFloat(boatOrderLine.price) * orderLine.quantity;
        else discount += parseFloat(orderLine.price) * orderLine.quantity;
      }
    });

    if (dealer) {
      const applicableDiscounts = getApplicableDiscounts(dealer);
      applicableDiscounts.forEach(dd => discount += parseFloat(`${dd.total}`));
    }
    return total - discount;
  }

  const computeTotalWithoutDiscounts = (orderLines: OrderLine[]) => {
    let total = 0;
    orderLines.forEach(orderLine => {
      const price = parseFloat(`${orderLine.price}`);
      if (price > 0) total += price * orderLine.quantity;
    });

    return total;
  }

  const onValidate = (values: FormValues) => {
    const errors: any = {}

    if (!values.shipping_name) errors.shipping_name = "Required";
    if (!values.address_line_1) errors.address_line_1 = "Required";
    if (!values.city) errors.city = "Required";
    if (!values.zip) errors.zip = "Required";

    return errors;
  }

  const includeRequiredParts = (orderLines: OrderLine[]) => {
    props.boat.required_parts.forEach((requiredPart) => {
      let alreadyIncluded = false;
      orderLines.forEach((orderLine) => {
        if (orderLine.id === requiredPart.id) alreadyIncluded = true;
      });
      if (!alreadyIncluded) {
        let price = requiredPart.price;
        let selectedVariant = requiredPart.variants.length > 0 ? requiredPart.variants[0] : undefined;
        getVariants(requiredPart, boat, orderLines).forEach((variant) => {
          if (variant.recommended) {
            selectedVariant = variant;
            price = variant.price && variant.price !== 'None' ? variant.price : requiredPart.price;
          }
        });

        orderLines.push({
          id: requiredPart.id,
          part_number: requiredPart.part_number,
          description: requiredPart.description,
          type: requiredPart.part_type,
          quantity: 1,
          variant: selectedVariant,
          note: '',
          price: price,
        });

        requiredPart.must_includes.forEach(extraPart => {
          let extraPartQuantity = 1;
          let extraPartPrice = extraPart.price;
          requiredPart.must_includes_options.forEach(option => {
            if (option.part === extraPart.id) {
              console.log(option, extraPart);
              extraPartQuantity = option.minimum_quantity;
              if (option.price !== 'None') {
                extraPartPrice = option.price;
              }
            }
          });
          let alreadyIncluded = false;
          orderLines.forEach((orderLine) => {
            if (orderLine.id === extraPart.id) alreadyIncluded = true;
          });
          if (alreadyIncluded) return;
          orderLines.push({
            id: extraPart.id,
            part_number: extraPart.part_number,
            description: extraPart.description,
            type: extraPart.part_type,
            quantity: extraPartQuantity,
            variant: extraPart.variants.length > 0 ? extraPart.variants[0] : undefined,
            note: '',
            price: extraPartPrice,
          });
        });
      }
    });
    return orderLines;
  };
  const getVariants = (part: Part, boat: Boat, orderLines: OrderLine[]) => {
    return part.variants.filter(variant => {
      let boatFound = false;
      variant.boats.forEach((b) => {
        if (b.id == boat.id) boatFound = true;
      })
      if (variant.boats.length > 0) {
        if (boatFound) {
          if (variant.parts.length > 0) {
            let found = false;
            variant.parts.forEach((p) => {
              orderLines.forEach((orderLine) => {
                if (orderLine.id == p.id) {
                  found = true;
                }
              });
            });
            if (!found) return false;
          }
        }
        else {
          return false;
        }
      }
      return true;
    });
  };
  const includeRecommendedParts = (orderLines: OrderLine[]) => {
    props.boat.recommended_parts.forEach((recommendedPart) => {
      let alreadyIncluded = false;
      orderLines.forEach((orderLine) => {
        if (orderLine.id === recommendedPart.id) alreadyIncluded = true;
      });
      if (!alreadyIncluded) {
        let price = recommendedPart.price;
        let selectedVariant = recommendedPart.variants.length > 0 ? recommendedPart.variants[0] : undefined;
        recommendedPart.variants.forEach((variant) => {
          if (variant.recommended) {
            selectedVariant = variant;
            price = variant.price && variant.price !== 'None' ? variant.price : recommendedPart.price;
          }
        });

        orderLines.push({
          id: recommendedPart.id,
          part_number: recommendedPart.part_number,
          description: recommendedPart.description,
          type: recommendedPart.part_type,
          quantity: 1,
          variant: selectedVariant,
          note: '',
          price: price,
        });

        recommendedPart.must_includes.forEach(extraPart => {
          let extraPartQuantity = 1;
          let extraPartPrice = extraPart.price;
          recommendedPart.must_includes_options.forEach(option => {
            if (option.part === extraPart.id) {
              extraPartQuantity = option.minimum_quantity;
              if (option.price !== 'None') {
                extraPartPrice = option.price;
              }
            }
          });
          let alreadyIncluded = false;
          orderLines.forEach((orderLine) => {
            if (orderLine.id === extraPart.id) alreadyIncluded = true;
          });
          if (alreadyIncluded) return;
          orderLines.push({
            id: extraPart.id,
            part_number: extraPart.part_number,
            description: extraPart.description,
            type: extraPart.part_type,
            quantity: extraPartQuantity,
            variant: extraPart.variants.length > 0 ? extraPart.variants[0] : undefined,
            note: '',
            price: extraPartPrice,
          });
        });
      }
    });
    return orderLines;
  };

  const applyPartRulesToOrderLines = (orderLines: OrderLine[], partRules: PartRule[]) => {
    const selectedParts = orderLines.map(orderLine => orderLine.id);
    const matchedWithAnyRule: any = {};
    const removedOrderLines: OrderLine[] = [];

    orderLines.forEach(orderLine => {
      partRules.forEach(partRule => {
        if (partRule.target.id === orderLine.id) {
          let isMatches = partRule.matches.length > 0;
          if (partRule.match_mode == 'and') {
            partRule.matches.forEach(part => {
              if (!selectedParts.includes(part.id)) isMatches = false;
            });
          }
          else if (partRule.match_mode == 'or') {
            isMatches = false;
            partRule.matches.forEach(part => {
              if (selectedParts.includes(part.id)) isMatches = true;
            });
          }
          if (isMatches) {
            matchedWithAnyRule[partRule.target.id] = true;
            if (partRule.target_field === 'quantity') {
              let defaultQuantity = partRule.target_value_parsed as number;
              if (orderLine.quantity != defaultQuantity) orderLine.quantity = defaultQuantity;
            }
            if (partRule.target_field === 'price') {
              let price = partRule.target_value_parsed as string;
              orderLine.price = price;
            }
            if (partRule.target_field === 'unselectable') {
              removedOrderLines.push(orderLine);
            }
          }
          else if (!matchedWithAnyRule[partRule.target.id]) {
            if (partRule.target_field === 'quantity') {
              let defaultQuantity = partRule.target.quantity;
              if (orderLine.variant && partRule.target.quantity != orderLine.variant.quantity) defaultQuantity = orderLine.variant.quantity;
              if (orderLine.quantity != defaultQuantity) orderLine.quantity = defaultQuantity;
            }
            if (partRule.target_field === 'price') {
              let price = partRule.target.price;
              orderLine.price = price;
            }
          }
        }
        else {

        }
      });
    });

    return orderLines.filter((o) => {
      let retained = true;
      removedOrderLines.forEach((oo) => {
        if (oo.id === o.id) {
          retained = false;
        }
      });
      return retained;
    });
  };


  const computeDiscount = (discount: DealerDiscount, amount: string|number) => {
    if (discount.discount_type == 'fixed') return discount.discount_amount;
    if (discount.discount_type == 'percentage') return bankersRounding(parseFloat(`${amount}`) * (parseFloat(`${discount.discount_amount}`) / 100.0))
    return 0
  }

  const getApplicableDiscounts = (dealer: Dealer) => {
    const discounts = [] as DiscountItem[];
    dealerDiscounts.forEach(dealerDiscount => {
      if (dealerDiscount.excluded_dealers.includes(dealer.id)) return;
      if (!dealerDiscount.applied_to_all_dealers) return;

      if (dealerDiscount.discount_target === 'boat_only') {
        orderLines.forEach(orderLine => {
          if (orderLine.type === 'boat') {
            let totalPrice = parseFloat(`${orderLine.price}`) * orderLine.quantity;
            discounts.push({
              name: dealerDiscount.discount_name,
              total: computeDiscount(dealerDiscount, totalPrice)
            });
          }
        })
      }
      if (dealerDiscount.discount_target === 'total_amount') {
        discounts.push({
          name: dealerDiscount.discount_name,
          total: computeDiscount(dealerDiscount, totalWithoutDiscount)
        });
      }
      if (dealerDiscount.discount_target === 'parts_only') {
        orderLines.forEach(orderLine => {
          if (orderLine.type === 'option') {
            let excluded = false;

            if (!dealerDiscount.parts.includes(orderLine.id)) excluded = true;

            if ((dealerDiscount.parts.length == 0) && (dealerDiscount.part_groups.length > 0)) {
              let partGroupIncluded = false;
              // TODO: part group matching
            }

            if (dealerDiscount.excluded_parts.includes(orderLine.id)) excluded = true;
            if (dealerDiscount.excluded_parts.length > 0) {
              let partGroupIncluded = false;
              // TODO: part group exclusion matching
              if (partGroupIncluded) excluded = true
            }

            if (excluded) return;

            let totalPrice = parseFloat(`${orderLine.price}`) * orderLine.quantity;

            discounts.push({
              name: dealerDiscount.discount_name,
              total: computeDiscount(dealerDiscount, totalPrice)
            });
          }
        })
      }
    });

    return discounts;
  }

  const applicableDiscounts = selectedDealer ? getApplicableDiscounts(selectedDealer) : [];
  let totalDiscount = 0;
  applicableDiscounts.forEach(discount => totalDiscount += parseFloat(`${discount.total}`));

  useEffect(() => {
    let o = orderLines.slice();
    o = includeRequiredParts(o);
    o = includeRecommendedParts(o);
    o = applyPartRulesToOrderLines(o, props.boat.part_rules);
    setOrderLines(o);
    setTotal(computeTotal(o, selectedDealer));
    setTotalWithoutDiscount(computeTotalWithoutDiscounts(o));
    console.log('initial selection:', o);
  }, []);

  const excludedGroups = props.boat.grouped_parts.filter(g => g.group ? g.hidden : false).map(g => g.group ? g.group.name : '');
  excludedGroups.push('Painted Motor');

  const footerGroups = props.boat.ui_combined_footer_groups.map(g => g.group ? g.group.name : '');
  const selectedDealerAddress = (selectedDealer && selectedDealer.addresses.length > 0) ? selectedDealer.addresses[0] : undefined;
  return (
    <>
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.boatTitle}>{boat.name}</Typography>
          <Typography variant="h6">Options</Typography>
        </Grid>

        <Grid item xs={12}>
          <BoatLineItems
            boat={props.boat}
            orderLines={orderLines}
            setOrderLines={(orderLines) => {
              orderLines = includeRequiredParts(orderLines);
              orderLines = applyPartRulesToOrderLines(orderLines, props.boat.part_rules);
              setOrderLines(orderLines);
              setTotal(computeTotal(orderLines, selectedDealer));
              setTotalWithoutDiscount(computeTotalWithoutDiscounts(orderLines));
            }}
            includedPartType="engine"
            excludedPartType={undefined}
            showHiddenInfo={props.showHiddenInfo}
          />
        </Grid>

        <Grid item xs={12}>
          <BoatLineItems
            boat={props.boat}
            orderLines={orderLines}
            setOrderLines={(orderLines) => {
              orderLines = includeRequiredParts(orderLines);
              orderLines = applyPartRulesToOrderLines(orderLines, props.boat.part_rules);
              setOrderLines(orderLines);
              setTotal(computeTotal(orderLines, selectedDealer));
              setTotalWithoutDiscount(computeTotalWithoutDiscounts(orderLines));
            }}
            hideGroupLabel={false}
            includedPartType={undefined}
            excludedPartType="engine"
            excludedGroups={excludedGroups}
            showHiddenInfo={props.showHiddenInfo}
          />
        </Grid>

        <Grid item xs={12}>
          <BoatLineItems
            boat={props.boat}
            orderLines={orderLines}
            setOrderLines={(orderLines) => {
              orderLines = includeRequiredParts(orderLines);
              orderLines = applyPartRulesToOrderLines(orderLines, props.boat.part_rules);
              setOrderLines(orderLines);
              setTotal(computeTotal(orderLines, selectedDealer));
              setTotalWithoutDiscount(computeTotalWithoutDiscounts(orderLines));
            }}
            hideGroupLabel={false}
            includedPartType={undefined}
            excludedPartType="engine"
            includedGroups={footerGroups}
            className={classes.footerGroup}
            showHiddenInfo={props.showHiddenInfo}
            oneLiner
          />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.totalContainer}>
            <Typography variant="body1">
              Total: <b>{formatNumber({prefix: 'US$'})(bankersRounding(totalWithoutDiscount, 2))}</b>
            </Typography>
          </div>
        </Grid>

        {(!selectedDealer && allDealers.length > 0) &&
        <Grid item xs={12}>
          <div className="pt-[20px] pr-[36px]">
            <Typography className="text-right text-gray-500" variant="body2">Please select a dealer to see any applicable discount</Typography>
          </div>
        </Grid>}

        {(applicableDiscounts.length > 0) &&
        <Grid item xs={12}>
          <div className="pt-[20px] pr-[36px]">
            {applicableDiscounts.map((discount, i) => (
              <Typography key={`discount-${i}`} className="text-right" variant="body2">Dealer Discount: {discount.name} — <b>{formatNumber({prefix: 'US$'})(parseFloat(`${discount.total}`))} discount</b></Typography>
            ))}
          </div>
        </Grid>}

        {(totalDiscount > 0) &&
        <Grid item xs={12}>
          <div className={classes.totalContainer}>
            <Typography variant="body1">
              Grand Total: <b>{formatNumber({prefix: 'US$'})(bankersRounding(total, 2))}</b>
            </Typography>
          </div>
        </Grid>}
      </Grid>
    </div>

    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        note: '',
        customer_number: selectedDealer ? selectedDealer.customer_number : '',
        order_type: 'stock',
        customer_name: '',
        shipping_name: selectedDealer ? selectedDealer.customer_name : '',
        address_line_1: selectedDealerAddress ? selectedDealerAddress.address_line_1 : '',
        address_line_2: selectedDealerAddress ? selectedDealerAddress.address_line_2 : '',
        city: selectedDealerAddress ? selectedDealerAddress.city : '',
        country: selectedDealerAddress ? selectedDealerAddress.country : 'United States',
        state: selectedDealerAddress ? selectedDealerAddress.state : '',
        zip: selectedDealerAddress ? selectedDealerAddress.zip : ''
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid container>
            <Field name="note">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Note'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      multiline
                      rows={4}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="customer_number">
              {props => (
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <InputLabel>Dealer *</InputLabel>
                    <Select
                      id={props.input.name}
                      label={'Dealer *'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={(e) => {
                        if (e.target.value) {
                          let dealer = allDealers.find(dealer => dealer.customer_number == e.target.value);
                          if (dealer) {
                            form.change('shipping_name', dealer.customer_name);
                            if (dealer.addresses.length > 0) {
                              let address = dealer.addresses[0];
                              form.change('address_line_1', address.address_line_1);
                              form.change('address_line_2', address.address_line_2);
                              form.change('city', address.city);
                              form.change('country', address.country);
                              form.change('state', address.state);
                              form.change('zip', address.zip);
                            }
                            else {
                              form.change('address_line_1', '');
                              form.change('address_line_2', '');
                              form.change('city', '');
                              form.change('country', 'United States');
                              form.change('state', '');
                              form.change('zip', '');
                            }
                          }
                          setSelectedDealer(dealer);
                        }
                        else {
                          setSelectedDealer(undefined);
                        }
                        props.input.onChange(e);
                      }}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                    >
                      {allDealers.filter(dealer => dealer.active).map((dealer, i) => (
                        <MenuItem key={`dealer-menu-item-${i}`} value={dealer.customer_number}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
                      ))}
                    </Select>
                    {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                      <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                    }
                  </FormControl>
                </Grid>
              )}
            </Field>

            {allDealers.find(user => user.customer_number == values.customer_number) &&
            <div key={`customer-address-${values.customer_number}`} style={{marginTop: 10, marginBottom: 40}}>
              <div style={{display: 'flex'}}>
            {allDealers.find(user => user.customer_number == values.customer_number)?.addresses.map((address, i) => (
              <Card key={`address-selector-${i}`} style={{marginRight: 20}}>
                <CardContent>
                  <Typography variant="body2">
                    <span>{address.address_line_1}</span><br/>
                    <span>{address.address_line_2}</span><br/>
                    <span>{address.city}</span> <span>{address.state}</span> <span>{address.zip}</span><br/>
                    <span>{address.country}</span>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={() => {
                    form.change('address_line_1', address.address_line_1);
                    form.change('address_line_2', address.address_line_2);
                    form.change('city', address.city);
                    form.change('country', address.country);
                    form.change('state', address.state);
                    form.change('zip', address.zip);
                  }}>Use</Button>
                </CardActions>
              </Card>
            ))}
            </div>
            </div>}

            <Field name="order_type">
              {props => (
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="outlined" fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <RadioGroup
                      aria-label="Order Type"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                    >
                      <FormControlLabel value={'stock'} control={<Radio />} label={'Stock'} />
                      <FormControlLabel value={'sold'} control={<Radio />} label={'Sold'} />
                    </RadioGroup>
                    {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                      <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                    }
                  </FormControl>
                </Grid>
              )}
            </Field>

            {values.order_type === 'sold' && 
            <Field name="customer_name">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Customer Name'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>}

            <Field name="shipping_name">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Dealer Name'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="address_line_1">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Address Line 1'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="address_line_2">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Address Line 2'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Grid container spacing={3}>
              <Field name="city">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'City'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="country">
                {props => (
                  <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Country</InputLabel>
                      <CountrySelect
                        id={props.input.name}
                        label={'Country'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      />
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="state">
                {props => (
                  <Grid item xs={3}>
                    {values.country === 'United States' &&
                    <FormControl variant="outlined" fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>State</InputLabel>
                      <StateSelect
                        id={props.input.name}
                        label={'State'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      />
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                      }
                    </FormControl>}
                    {values.country !== 'United States' &&
                    <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'State'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                    }
                  </Grid>
                )}
              </Field>

              <Field name="zip">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.noteContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Zip / Postal Code'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isSaving || !total}
              >Submit</Button>
              {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error submitting your order. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

          <Snackbar
            key={'error-snackbar-custom'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={!!showErrorMessage}
            onClose={() => setShowErrorMessage('')}
            onExited={() => setShowErrorMessage('')}
            message={showErrorMessage}
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorMessage('')}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

          <Dialog
            open={orderSubmitted}
            onClose={() => {
              if (newOrderId) props.onOrderCompleted(newOrderId);
            }}
            aria-labelledby="order-completed-title"
            aria-describedby="order-completed-description"
          >
            <DialogTitle id="order-completed-title">{"Order Submitted"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="order-completed-description">
                Your order has been successfully submitted!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                  if (newOrderId) props.onOrderCompleted(newOrderId);
                }}
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          
          <Dialog
            open={!!confirmSubmission}
            onClose={() => setConfirmSubmission(undefined)}
            scroll='paper'
            aria-labelledby="confirm-order"
          >
            <DialogTitle id="confirm-order">Confirm Order</DialogTitle>
            <DialogContent dividers>
              <Alert severity="info">Please confirm the order details below, initial, and submit. Once submitted, any changes that need to be made must go through Sea Fox’s Sales Department.</Alert>
              <div className="mt-4">
              {confirmSubmission &&
              <OrderSummary
                formValues={confirmSubmission}
                boat={boat}
                orderLines={orderLines}
                totalPrice={formatNumber({prefix: 'US$'})(bankersRounding(total, 2))}
                totalPriceWithoutDiscount={formatNumber({prefix: 'US$'})(bankersRounding(totalWithoutDiscount, 2))}
                discounts={applicableDiscounts}
              />}
              </div>
            </DialogContent>
            <DialogActions style={{justifyContent: 'space-between'}}>
              <span>
              <Button
                onClick={() => {
                  setConfirmSubmissionWithName(true);
                  setSubmitterNameError('');
                }}
                variant="contained"
                color="primary"
                startIcon={<SendIcon />}
                disabled={isSaving || !total}
              >
                Submit Order ({formatNumber({prefix: 'US$'})(total)})
              </Button>
              {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
              </span>
              <Button onClick={() => setConfirmSubmission(undefined)} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={confirmSubmissionWithName}
            onClose={() => {
              setConfirmSubmissionWithName(false)
            }}
            aria-labelledby="order-confirmation-enter-submitter-name-title"
            aria-describedby="order-confirmation-enter-submitter-name-description"
          >
            <DialogTitle id="order-confirmation-enter-submitter-name-title">Enter Your Name To Confirm Order</DialogTitle>
            <DialogContent>
              <DialogContentText id="order-confirmation-enter-submitter-name-description">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <TextField
                    id="submitter-name"
                    label="Your Name"
                    variant="outlined"
                    name="submitter-name"
                    value={submitterName}
                    onChange={(e) => setSubmitterName(e.target.value)}
                    error={submitterNameError ? true: false}
                    helperText={submitterNameError}
                  />
                </form>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{justifyContent: 'space-between'}}>
              <span>
              <Button
                onClick={() => {
                  if (!submitterName) setSubmitterNameError('Please enter your initial!');
                  else {
                    setConfirmSubmissionWithName(false);
                    onSubmitConfirmed(submitterName);
                  }
                }}
                variant="contained"
                color="primary"
                disabled={isSaving || !total}
              >
                Submit
              </Button>
              {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
              </span>
              <Button onClick={() => setConfirmSubmissionWithName(false)} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          
        </form>
      )}
    />
    </>
  );
}

interface GroupedPartOrderLine {
  group: PartGroup | null | undefined;
  partOrderLines: PartOrderLine[];
}

function OrderSummary(props: {
  formValues: FormValues;
  boat: Boat;
  orderLines: OrderLine[];
  totalPrice: string;
  totalPriceWithoutDiscount: string;
  discounts: DiscountItem[];
  className?: string;
}) {
  const classes = useStyles();

  const groupedPartOrderLines: GroupedPartOrderLine[] = React.useMemo(() => {
    const groupedPartOrderLines: GroupedPartOrderLine[] = [];
    const groupedMap: any = {};
  
    props.boat.grouped_parts.forEach(groupedPart => {
      const groupKey = groupedPart.group ? groupedPart.group.name : 'null';
      groupedPart.parts.forEach((part) => {
        props.orderLines.forEach((orderLine) => {
          if ((orderLine.id === part.id) && (orderLine.quantity > 0)) {
            let groupedPartOrderLine: GroupedPartOrderLine = {
              group: groupedPart.group,
              partOrderLines: []
            };

            if (groupedMap[groupKey]) {
              groupedPartOrderLine = groupedMap[groupKey] as GroupedPartOrderLine;
            }
            else {
              groupedMap[groupKey] = groupedPartOrderLine;
              groupedPartOrderLines.push(groupedPartOrderLine);
            }
            
            groupedPartOrderLine.partOrderLines.push({part, orderLine});
          };
        });
      });
      if (groupedPart.children) {
        groupedPart.children.forEach(groupedPart => {
          groupedPart.parts.forEach((part) => {
            props.orderLines.forEach((orderLine) => {
              if ((orderLine.id === part.id) && (orderLine.quantity > 0)) {
                let groupedPartOrderLine: GroupedPartOrderLine = {
                  group: groupedPart.group,
                  partOrderLines: []
                };
    
                if (groupedMap[groupKey]) {
                  groupedPartOrderLine = groupedMap[groupKey] as GroupedPartOrderLine;
                }
                else {
                  groupedMap[groupKey] = groupedPartOrderLine;
                  groupedPartOrderLines.push(groupedPartOrderLine);
                }
                
                groupedPartOrderLine.partOrderLines.push({part, orderLine});
              };
            });
          });
        });
      }
    });

    return groupedPartOrderLines;
  }, [props.boat, props.orderLines]);

  const renderLineItem = (item: PartOrderLine, key: string) => {
    const part = item.part;
    if (!part) return null;
    return (
      <ListItem
        key={key}
        role={undefined}
        dense
        button
        onClick={() => {
          
        }}
      >
        <ListItemText primary={part.description} secondary={`${ item.orderLine?.variant ? item.orderLine?.variant?.name : ''} ${(item.orderLine?.quantity && item.orderLine.quantity > 1) ? `x${item.orderLine?.quantity}` : ''}`} />
        <ListItemSecondaryAction>
          {(item.orderLine?.price && item.orderLine.price !== '0.00' && item.orderLine.type === 'discount_p') &&
          <Typography>{formatNumber({suffix: '%'})(parseFloat(part.price))}</Typography>
          }
          {(item.orderLine?.price && item.orderLine.price !== '0.00' && item.orderLine.type === 'discount_a') &&
          <Typography>{formatNumber({prefix: 'US$'})(parseFloat(part.price))}</Typography>
          }
          {(item.orderLine?.price && item.orderLine.price !== '0.00' && (item.orderLine.type !== 'discount_p' && item.orderLine.type !== 'discount_a')) &&
          <Typography>{formatNumber({prefix: 'US$'})(parseFloat(part.price))}</Typography>
          }
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  const renderGroupedOrderLines = (groupedPartOrderLine: GroupedPartOrderLine) => {
    let groupName = groupedPartOrderLine?.group ? groupedPartOrderLine.group.name : '';
    return (
      <div className="my-4">
        <div className="text-xs uppercase text-stone-500">{groupName}</div>
        <List>
          {groupedPartOrderLine.partOrderLines.map((item, i) => {
            const part = item.part;
            if (!part) return null;
            return renderLineItem(item, `${groupName}-${i}`);
          })}
        </List>
      </div>
    )
  }

  const footerGroups = props.boat.ui_combined_footer_groups.map(g => g.group ? g.group.name : '');
  return (
    <div className={props.className}>
      <Grid container className={classes.metaContainer}>
        <Grid item xs={12}>
          <Grid container spacing={1} className={classes.smallText}>
            <Grid item xs={3} className={classes.textAlignRight}>
              <div className={classes.fieldLabel}>Dealer Name</div>
            </Grid>
            <Grid item xs={9}>
              <div>{props.formValues.shipping_name}</div>
            </Grid>
            <Grid item xs={3} className={classes.textAlignRight}>
              <div className={classes.fieldLabel}>Boat Model</div>
            </Grid>
            <Grid item xs={9}>
              <div>{props.boat.name}</div>
            </Grid>
            <Grid item xs={3} className={classes.textAlignRight}>
              <div className={classes.fieldLabel}>Order Type</div>
            </Grid>
            <Grid item xs={9}>
              <div>{props.formValues.order_type}</div>
            </Grid>
            {props.formValues.order_type === 'sold' && <>
              <Grid item xs={3} className={classes.textAlignRight}>
                <div className={classes.fieldLabel}>Customer Name</div>
              </Grid>
              <Grid item xs={9}>
                <div>{props.formValues.customer_name}</div>
              </Grid>
            </>}
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <div className="my-4">
      {groupedPartOrderLines.map(gp => {
        let isIncluded = false;
        if (gp.group && gp.group.part_type === 'engine') isIncluded = true;
        if (isIncluded) {
          return renderGroupedOrderLines(gp);
        }
      })}

      {groupedPartOrderLines.map(gp => {
        let isIncluded = true;
        if (gp.group && gp.group.part_type === 'engine') isIncluded = false;
        if (gp.group && footerGroups.includes(gp.group.name)) isIncluded = false;
        if (isIncluded) {
          return renderGroupedOrderLines(gp);
        }
      })}

      {groupedPartOrderLines.map(gp => {
        let isIncluded = false;
        if (gp.group && footerGroups.includes(gp.group.name)) isIncluded = true;
        if (isIncluded) {
          return renderGroupedOrderLines(gp);
        }
      })}
      </div>

      <div className={classes.alignRight}>
        <Typography>Total: <span className={classes.bolder}>{props.totalPriceWithoutDiscount}</span></Typography>
      </div>

      {(props.discounts.length > 0) &&
      <div className="pt-[16px]">
        {props.discounts.map((discount, i) => (
          <Typography key={`discount-${i}`} className="text-right" variant="body2">Dealer Discount: {discount.name} — <b>{formatNumber({prefix: 'US$'})(parseFloat(`${discount.total}`))} discount</b></Typography>
        ))}
      </div>}

      {(props.discounts.length > 0) &&
      <div className="pt-[16px]">
        <Typography variant="body1" className="text-right">
        Grand Total: <b>{props.totalPrice}</b>
        </Typography>
      </div>}
      {!!props.formValues.note &&
      <div className={classes.noteContainer}>
        <Typography variant="h6">Note</Typography>
        <Typography>
          {props.formValues.note}
        </Typography>
      </div>}

      <div className={classes.shippingAddressContainer}>
        <Typography variant="h6">Shipping Address</Typography>
        <Typography variant="body2">{props.formValues.shipping_name}</Typography>
        <Typography variant="body2">{props.formValues.address_line_1}</Typography>
        <Typography variant="body2">{props.formValues.address_line_2}</Typography>
        <Typography variant="body2">{props.formValues.city} {props.formValues.state} {props.formValues.zip}</Typography>
      </div>
    </div>
  );
}
