import React, {
  useState,
  useEffect
} from 'react';

import {
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import WarrantyIcon from '@material-ui/icons/Beenhere';
import FileIcon from '@material-ui/icons/Cloud';
import StoreIcon from '@material-ui/icons/Store';
import PeopleIcon from '@material-ui/icons/People';
import InfoIcon from '@material-ui/icons/Info';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import localization from '../utils/localizations';
import {
  DateTimeView,
  StatusChip,
  SiteWideMessage,
  IssueReportPanel,
  BigPanelButton,
  UserProfile,
} from '../views';

import {
  SessionContext
} from '../data/Session';

import {
  BaseAPI,
} from '../data/BaseAPI';
import {
  Boat,
  DealerPermission,
} from '../types';
import processBoatsData from '../utils/processBoatsData';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '15%',
  },
  mainMenu: {
    marginBottom: 40,
    padding: 0,
  },
  centerItem: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 30,
    marginRight: 30
  },
  profileDialogContent: {
    padding: 0,
  }
}));

function HomePage() {
  const classes = useStyles();
  const [isBoatsLoading, setIsBoatsLoading] = useState(false);
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const [boatList, setBoatList] = useState(((window as any)._cached && (window as any)._cached['boats'] ? (window as any)._cached['boats']: []) as Boat[]);

  const canAccessBoatOrderingPortal = (permissions?: DealerPermission[], role?: string) => {
    let hasPermission = false;
    if (role === 'admin') hasPermission = true;
    if (permissions) {
      permissions.forEach(permission => {
        if (permission.admin || permission.order) hasPermission = true;
      });
    }
    return hasPermission;
  }

  const canAccessWarrantyPortal = (permissions?: DealerPermission[], role?: string) => {
    let hasPermission = false;
    if (role === 'admin') hasPermission = true;
    if (permissions) {
      permissions.forEach(permission => {
        if (permission.admin || permission.warranty) hasPermission = true;
      });
    }
    return hasPermission;
  }

  const canAccessFilesPortal = (permissions?: DealerPermission[], role?: string) => {
    if (role === 'admin') return true;
    let hasPermission = false;
    if (permissions) {
      permissions.forEach(permission => {
        if (permission.files) hasPermission = true;
      });
    }
    return hasPermission;
  }

  const profileDialog = (
    <Dialog
        open={showProfileDialog}
        onClose={() => setShowProfileDialog(false)}
        aria-labelledby="profile-home-dialog-title"
        aria-describedby="profile-home-dialog-description"
      >
      <DialogContent className={classes.profileDialogContent}>
        <UserProfile />
      </DialogContent>
    </Dialog>
  );


  return (
    <SessionContext.Consumer>
      {({session}) => (
        <div className={classes.root}>
          <div className="">
            <div
              className={`${classes.mainMenu} mx-4 md:mx-0 bg-gradient-to-r from-white/[.5] via-white/75 to-white/[.5]`}
            >
              <div className="text-center md:flex md:justify-center">
                {canAccessBoatOrderingPortal(session?.user?.permissions, session?.user?.role) && <div className={classes.centerItem}>
                  <BigPanelButton
                    icon={<AddIcon style={{ fontSize: 40 }} />}
                    title={localization.createNewOrder}
                    to={`${appConfig.homepage}order-form/`}
                    component={RouterLink}
                  />
                </div>}
                {canAccessBoatOrderingPortal(session?.user?.permissions, session?.user?.role) && <div className={classes.centerItem}>
                  <BigPanelButton
                    icon={<FormatListBulletedIcon style={{ fontSize: 40 }} />}
                    title={localization.submittedOrders}
                    to={`${appConfig.homepage}orders/`}
                    component={RouterLink}
                  />
                </div>}
                {canAccessWarrantyPortal(session?.user?.permissions, session?.user?.role) && <div className={classes.centerItem}>
                  <BigPanelButton
                    icon={<WarrantyIcon style={{ fontSize: 40 }} />}
                    title={localization.warranty}
                    to={`${appConfig.homepage}warranty/customers/`}
                    component={RouterLink}
                  />
                </div>}
                {canAccessFilesPortal(session?.user?.permissions, session?.user?.role) && <div className={classes.centerItem}>
                  <BigPanelButton
                    icon={<FileIcon style={{ fontSize: 40 }} />}
                    title="Marketing Files"
                    to={`${appConfig.homepage}marketing-files/`}
                    component={RouterLink}
                  />
                </div>}
                {(session?.user?.role === 'admin') &&
                <div className={classes.centerItem}>
                  <BigPanelButton
                    icon={<StoreIcon style={{ fontSize: 40 }} />}
                    title={localization.dealers}
                    to={`${appConfig.homepage}dealers/list/`}
                    component={RouterLink}
                  />
                </div>}
                {(session?.user?.role === 'admin') &&
                <div className={classes.centerItem}>
                  <BigPanelButton
                    icon={<PeopleIcon style={{ fontSize: 40 }} />}
                    title={localization.users}
                    to={`${appConfig.homepage}users/`}
                    component={RouterLink}
                  />
                </div>}
                <div className={classes.centerItem}>
                  <BigPanelButton
                    icon={<AccountBoxIcon style={{ fontSize: 40 }} />}
                    title="Account"
                    onClick={() => {
                      setShowProfileDialog(true);
                    }}
                  />
                </div>
                <div className={classes.centerItem}>
                  <BigPanelButton
                    icon={<ShoppingCartIcon style={{ fontSize: 40 }} />}
                    title="Dealer Store"
                    href="https://seafoxboatsdealerstore.itemorder.com/shop/home/"
                    target="_blank"
                  />
                </div>
                <div className={classes.centerItem}>
                  <BigPanelButton
                    icon={<ShoppingBasketIcon style={{ fontSize: 40 }} />}
                    title="Apparel"
                    href="https://seafoxboats.online/"
                    target="_blank"
                  />
                </div>
              </div>
            </div>

            <SiteWideMessage />
          </div>

          {profileDialog}
        </div>
      )}
    </SessionContext.Consumer>
  );
}

export default HomePage;