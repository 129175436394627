import React, {
  useState,
  useEffect
} from 'react';

import {
  Link as RouterLink
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  ButtonBase,
  IconButton,
  Typography,
  withStyles,
  Breadcrumbs,
  Link,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import WarrantyIcon from '@material-ui/icons/Beenhere';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import StoreIcon from '@material-ui/icons/Store';
import PeopleIcon from '@material-ui/icons/People';
import InfoIcon from '@material-ui/icons/Info';
import FileIcon from '@material-ui/icons/Cloud';

import {
  Session,
  DealerPermission
} from '../../types';

import {
  SessionContext
} from '../../data/Session';

import localization from '../../utils/localizations';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingLeft: 0,
    marginBottom: theme.spacing(2),
    backgroundColor: 'rgba(0,0,0,.444)',
    color: 'rgba(255,255,255,.777)',
    textTransform: 'uppercase',
  },
  breadcrumbs: {
    color: 'rgba(255,255,255,.777)',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  inherit: {
    fontSize: 'inherit',
  },
  mainTitle: {
    marginLeft: theme.spacing(1.5),
  },
  breadcrumbsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbsMenuButton: {
    
  },
  menuList: {
    minWidth: 250,
  },
  menuDrawer: {

  }
}));

interface Page {
  title: string;
  link: string;
}

interface ComponentProps {
  session?: Session;
  title: string;
  breadcrumbs: Page[]
}

function Header(props: ComponentProps) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);

  const canAccessBoatOrderingPortal = (permissions?: DealerPermission[], role?: string) => {
    let hasPermission = false;
    if (role === 'admin') hasPermission = true;
    if (permissions) {
      permissions.forEach(permission => {
        if (permission.admin || permission.order) hasPermission = true;
      });
    }
    return hasPermission;
  }

  const canAccessWarrantyPortal = (permissions?: DealerPermission[], role?: string) => {
    let hasPermission = false;
    if (role === 'admin') hasPermission = true;
    if (permissions) {
      permissions.forEach(permission => {
        if (permission.admin || permission.warranty) hasPermission = true;
      });
    }
    return hasPermission;
  }

  const canAccessFilesPortal = (permissions?: DealerPermission[], role?: string) => {
    let hasPermission = false;
    if (permissions) {
      permissions.forEach(permission => {
        if (permission.files) hasPermission = true;
      });
    }
    return hasPermission;
  }

  return (
    <SessionContext.Consumer>
      {({session}) => (
        <div className={classes.root}>
          <Typography variant="h4" className={classes.mainTitle}>{props.title}</Typography>
          {(props.breadcrumbs.length > 0) &&
          <div className={classes.breadcrumbsContainer}>
            <div className={classes.breadcrumbsMenuButton}>
              <IconButton
                aria-label="Menu"
                color="inherit"
                component="span"
                onClick={(event: any) => {
                  setMenuOpen(true);
                }}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
              <Link color="inherit" to={`${appConfig.homepage}`} component={RouterLink}>
                {localization.home}
              </Link>
              {props.breadcrumbs.map((value, index) => {

                if (index === (props.breadcrumbs.length - 1)) {
                  return (
                    <Typography color="inherit" key={`breadcrumb-item-${index}`} className={classes.inherit}>
                      {value.title}
                    </Typography>
                  );
                }

                return (
                  <Link color="inherit" to={value.link} key={`breadcrumb-item-${index}`} component={RouterLink}>
                    {value.title}
                  </Link>
                );
              })}
            </Breadcrumbs>
          </div>
          }
          <Drawer anchor="left" open={menuOpen} onClose={() => setMenuOpen(false)} className={classes.menuDrawer}>
            <div
              role="presentation"
              onClick={() => setMenuOpen(false)}
              onKeyDown={() => setMenuOpen(false)}
            >
              <List className={classes.menuList}>
                <ListItem
                  button
                  to={`${appConfig.homepage}`}
                  component={RouterLink}
                >
                <ListItemIcon><HomeIcon color="inherit"/></ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
              </List>
              <Divider />
              <List className={classes.menuList}>
                {canAccessBoatOrderingPortal(session?.user?.permissions, session?.user?.role) &&
                <ListItem
                  button
                  to={`${appConfig.homepage}order-form/`}
                  component={RouterLink}
                >
                  <ListItemIcon><AddIcon color="inherit" /></ListItemIcon>
                  <ListItemText primary="Create New Order" />
                </ListItem>}
                
                {canAccessBoatOrderingPortal(session?.user?.permissions, session?.user?.role) &&
                <ListItem
                  button
                  to={`${appConfig.homepage}orders/`}
                  component={RouterLink}
                >
                  <ListItemIcon><FormatListBulletedIcon color="inherit" /></ListItemIcon>
                  <ListItemText primary="Orders" />
                </ListItem>}

                {canAccessWarrantyPortal(session?.user?.permissions, session?.user?.role) &&
                <ListItem
                  button
                  to={`${appConfig.homepage}warranty/customers/`}
                  component={RouterLink}
                >
                  <ListItemIcon><WarrantyIcon color="inherit" /></ListItemIcon>
                  <ListItemText primary="Warranty" />
                </ListItem>}
                {canAccessFilesPortal(session?.user?.permissions, session?.user?.role) &&
                <ListItem
                  button
                  to={`${appConfig.homepage}marketing-files/`}
                  component={RouterLink}
                >
                  <ListItemIcon><FileIcon color="inherit" /></ListItemIcon>
                  <ListItemText primary="Marketing Files" />
                </ListItem>}
                
                {(session?.user?.role === 'admin') &&
                <ListItem
                  button
                  to={`${appConfig.homepage}dealers/list/`}
                  component={RouterLink}
                >
                  <ListItemIcon><StoreIcon color="inherit" /></ListItemIcon>
                  <ListItemText primary="Dealers" />
                </ListItem>
                }
                {(session?.user?.role === 'admin') &&
                <ListItem
                  button
                  to={`${appConfig.homepage}users/`}
                  component={RouterLink}
                >
                  <ListItemIcon><PeopleIcon color="inherit" /></ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
                }
              </List>
              <Divider />
              <List className={classes.menuList}>
                <ListItem
                  button
                  to={`${appConfig.homepage}about/`}
                  component={RouterLink}
                >
                <ListItemIcon><InfoIcon color="inherit" /></ListItemIcon>
                  <ListItemText primary="About" />
                </ListItem>
              </List>
            </div>
          </Drawer>
        </div>
      )}
    </SessionContext.Consumer>
  );
}

export {
  Header
};