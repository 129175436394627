import { Boat, Part, GroupedPart } from "../types";

function isFree(boat: Boat, part: Part) {
  let free = false;
  if (boat.free_parts.find(p => p.id === part.id)) {
    free = true;
  }
  return free;
}

function makeFree(part: Part) {
  part.price = '0.00';
  part.variants.forEach(variant => {
    variant.price = '0.00';
  });
}

function processBoatsData(boats: Boat[]) {
  boats.forEach(boat => {
    boat.available_parts.forEach(part => {
      if (isFree(boat, part)) {
        makeFree(part);
      }
    });
    boat.free_parts.forEach(part => {
      if (isFree(boat, part)) {
        makeFree(part);
      }
    });
    boat.recommended_parts.forEach(part => {
      if (isFree(boat, part)) {
        makeFree(part);
      }
    });
    boat.required_parts.forEach(part => {
      if (isFree(boat, part)) {
        makeFree(part);
      }
    });
    boat.grouped_parts.forEach(group => {
      group.parts.forEach(part => {
        if (isFree(boat, part)) {
          makeFree(part);
        }
      });
    });

    const pids: any = {};
    const processGroup = (group: GroupedPart) => {
      group.parts.forEach(part => {
        if (!pids[part.id]) {
          pids[part.id] = part.id;
          boat.available_parts.push(part);
        }
      });
      if (group.children) {
        group.children.forEach(child => {
          processGroup(child);
        });
      }
    };
    
    boat.grouped_parts.forEach(group => {
      processGroup(group);
    });

  });
  return boats;
}

export default processBoatsData;