import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Avatar,
  Button,
  Typography,
  LinearProgress,
  Collapse,
  Grid,
  FormControl,
  FormHelperText,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Divider,
  ButtonBase,
  ListSubheader,
} from '@material-ui/core';
import {
  Skeleton
} from '@material-ui/lab';

import { grey } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import ImageIcon from '@material-ui/icons/Image';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Dropzone from 'react-dropzone'

import localization from '../../utils/localizations';
import bgImage from '../../img/profile-background-small.jpg';
import {
  useProfile
} from '../../data/Profile';

import {
  BaseAPI,
} from '../../data/BaseAPI';

import {
  Address,
} from '../../types';
import AddressForm from '../forms/AddressForm';


const useStyles = makeStyles((theme) => ({
  mainBody: {
    position: 'relative'
  },
  profileBackground: {
    width: '100%'
  },
  profileAvatarContainer: {
    position: 'relative',
    height: 150,
    overflow: 'hidden',
  },
  avatarCenteringContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  avatar: {
    width: 90,
    height: 90,
    color: theme.palette.getContrastText(grey[300]),
    backgroundColor: grey[300],
  },
  avatarButtonContainer: {
    borderRadius: 100,
  },
  userInfoContainer: {
    padding: 20,
  },
  marginBottom: {
    marginBottom: 16,
  },
  customerName: {
    fontSize: '1.4rem',
    textAlign: 'center',
  },
  customerNumber: {
    letterSpacing: '.1rem',
    color: 'grey',
    textAlign: 'center',
  },
  center: {
    textAlign: 'center',
  },
  customerAddress: {
    marginTop: 20,
    textAlign: 'center',
  },
  profileLoadingBar: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  userFaculty: {
    fontSize: '.75rem', 
    textAlign: 'center',
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
  },
  form: {
    marginTop: 20,
  },
  uploadContainer: {
    padding: 10,
    marginBottom: 20,
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    marginBottom: 10,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
}));
 

function UserProfile(props: {onClose?: () => void}) {
  const classes = useStyles();
  const [profile, profileLoading, updateProfile, updateProfilePicture, loadProfile] = useProfile();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [errors, setErrors] = useState({} as any);
  const [showAvatarForm, setShowAvatarForm] = useState(false);
  const [selectedAvatarUrl, setSelectedAvatarUrl] = useState('');
  const [selectedFile, setSelectedFile] = useState({} as any);
  const [selectedAddress, setSelectedAddress] = useState<Address|undefined>(undefined);
  const [showAddressForm, setShowAddressForm] = useState(false);

  const onShowEditForm = (e: any) => {
    e.preventDefault();
    setFirstName(profile.first_name);
    setLastName(profile.last_name);
    setShowEditProfile(true);
  }

  useEffect(() => {
    setSelectedAvatarUrl(profile.avatar);
  }, [profile]);

  const onSubmitForm = async (e: any) => {
    e.preventDefault();
    if (profileLoading) return;

    let data: any = {
      first_name: firstName,
      last_name: lastName
    };

    if (currentPassword) data.current_password = currentPassword;
    if (password) data.password = password;
    if (password2) data.password_2 = password2;

    const [success, result] = await updateProfile(data);

    if (success) {
      setShowEditProfile(false);
      setErrors({})
      setCurrentPassword('')
      setPassword('')
      setPassword2('')
    }
    else if (result && result.errors) {
      setErrors(result.errors);
    }
  }

  const hideAndClearAvatarForm = async () => {
    setShowAvatarForm(false);
    setSelectedFile({});
    setShowAvatarForm(false);
  }

  const updateAvatar = async () => {
    if (profileLoading) return;
    if (!selectedFile.name) return;

    const [success, result] = await updateProfilePicture(selectedFile);

    if (success) {
      hideAndClearAvatarForm();
    }
    else if (result && result.errors) {
      setErrors(result.errors);
    }
  }

  const getErrorMessage = (messages: string[]) => {
    if (!messages) return '';

    let message = '';
    messages.forEach((m: string) => {
      message = `${message} ${m}`;
    })

    return message;
  }

  const isEditing = showEditProfile || showAddressForm;

  return (
  <div className={classes.mainBody}>
    <div className={classes.profileAvatarContainer}>
      <div className={classes.avatarCenteringContainer}>
        <ButtonBase className={classes.avatarButtonContainer} onClick={() => setShowAvatarForm(!showAvatarForm)}>
          <Avatar className={classes.avatar} src={selectedAvatarUrl} />
        </ButtonBase>
      </div>
      <img src={bgImage} alt="" className={classes.profileBackground} />
    </div>


    <Collapse
      in={showAvatarForm}
    >
      <Dropzone
        accept=".jpeg,.jpg,.png"
        onDrop={(acceptedFiles: any[]) => {
          if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];

            const reader = new FileReader()
            reader.onload = () => {
              const base64Url = reader.result;
              if (base64Url) {
                setSelectedAvatarUrl(base64Url as string);
              }
            };

            reader.readAsDataURL(file)
            setSelectedFile(file);
          }
        }}
      >
        {({getRootProps, getInputProps}) => (
          <section className={classes.uploadContainer}>
            <div className={classes.dropzone} {...getRootProps()}>
              <input {...getInputProps()} />
              <CloudUploadIcon fontSize="large" />
              <Typography className={classes.textAlignCenter}>Choose a file or drag here</Typography>
            </div>
            <div>
            {selectedFile.name &&
              <div className={classes.textAlignRight}>
                <Chip
                  avatar={<Avatar alt="" src={selectedAvatarUrl} />}
                  label={selectedFile.name ? selectedFile.name : ''}
                  className={classes.marginBottom}
                  onDelete={() => {
                    setSelectedFile({});
                    setSelectedAvatarUrl(profile.avatar);
                  }}
                />
              </div>
            }
            </div>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={profileLoading}
                onClick={(e) => {
                  e.preventDefault();
                  updateAvatar();
                }}
              >{localization.save}</Button>
              <Button
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  hideAndClearAvatarForm();
                  setSelectedAvatarUrl(profile.avatar);
                }}
              >{localization.cancel}</Button>
            </div>
          </section>
        )}
      </Dropzone>
    </Collapse>


    <Collapse
      in={!isEditing}
      {...(!isEditing ? { timeout: 300 } : {})}
    >
      <div className={classes.userInfoContainer}>
        <div className={classes.marginBottom}>
          {profileLoading ? (
            <>
            <Skeleton variant="rect" width={'50%'} height={20} className={classes.profileLoadingBar} style={{marginBottom: 10}} />
            <Skeleton variant="rect" width={'50%'} height={20} className={classes.profileLoadingBar} style={{marginBottom: 10}} />
            <Skeleton variant="rect" width={'50%'} height={20} className={classes.profileLoadingBar} style={{marginBottom: 10}} />
            <Skeleton variant="rect" width={'50%'} height={20} className={classes.profileLoadingBar} style={{marginBottom: 10}} />
            </>
          ) : (
            <>
            <Typography variant="h5" className={classes.center}>{profile.first_name} {profile.last_name}</Typography>
            <Typography className={classes.center}>{profile.email}</Typography>
            <div style={{marginTop: 12, marginBottom: 12}}>
            {profile.dealers.map((dealer) => (
              <Typography key={`d-${dealer.id}`} className={classes.center}>{dealer.customer_name}</Typography>
            ))}
            </div>
            <div className={classes.buttons}>
              <Button
                size="small"
                onClick={onShowEditForm}
              >{localization.editProfile}</Button>
            </div>
            
            </>
          )}
        </div>
      </div>
    </Collapse>


    <Collapse
      in={showEditProfile}
      {...(showEditProfile ? { timeout: 300 } : {})}
    >
      <div className={classes.userInfoContainer}>
        <form onSubmit={onSubmitForm} className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="first_name"
                  label="First Name"
                  variant="outlined"
                  value={firstName}
                  onChange={(event: any) => setFirstName(event.target.value.substring(0, 150))}
                  disabled={profileLoading}
                  error={Boolean(errors.customer_name)}
                  helperText={getErrorMessage(errors.customer_name)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <TextField
                  id="last_name"
                  label="Last Name"
                  variant="outlined"
                  value={lastName}
                  onChange={(event: any) => setLastName(event.target.value.substring(0, 150))}
                  disabled={profileLoading}
                  error={Boolean(errors.customer_name)}
                  helperText={getErrorMessage(errors.customer_name)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider />
              </Grid>

            <Grid item xs={12} sm={12}>
              <Typography>{localization.changePassword}</Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  id="current_password"
                  label={localization.currentPassword}
                  variant="outlined"
                  value={currentPassword}
                  onChange={(event: any) => setCurrentPassword(event.target.value)}
                  disabled={profileLoading}
                  type="password"
                  autoComplete="current-password"
                  error={Boolean(errors.current_password)}
                  helperText={getErrorMessage(errors.current_password)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  id="password"
                  label={localization.newPassword}
                  variant="outlined"
                  value={password}
                  onChange={(event: any) => setPassword(event.target.value)}
                  disabled={profileLoading}
                  type="password"
                  error={Boolean(errors.password)}
                  helperText={getErrorMessage(errors.password)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  id="password_2"
                  label={localization.newPasswordRepeat}
                  variant="outlined"
                  value={password2}
                  onChange={(event: any) => setPassword2(event.target.value)}
                  disabled={profileLoading}
                  type="password"
                  error={Boolean(errors.password_2)}
                  helperText={getErrorMessage(errors.password_2)}
                />
              </FormControl>
            </Grid>

            {Boolean(errors.non_field_errors) &&
              <Grid item xs={12} sm={12}>
                <Alert severity="error">{getErrorMessage(errors.non_field_errors)}</Alert>
              </Grid>
            }

            <Grid item xs={12} sm={12}>
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  disabled={profileLoading}
                >{localization.save}</Button>
                <Button
                  size="small"
                  onClick={(e) => {
                    e.preventDefault()
                    setShowEditProfile(false);
                  }}
                >{localization.cancel}</Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </Collapse>


    <Collapse
      in={showAddressForm}
      {...(showAddressForm ? { timeout: 300 } : {})}
    >
      <AddressForm
        address={selectedAddress}
        userId={profile?.id}
        className={classes.form}
        onSaved={async (address) => {
          await loadProfile();
          setSelectedAddress(undefined);
          setShowAddressForm(false);
        }}
        onCancel={() => {
          setSelectedAddress(undefined);
          setShowAddressForm(false);
        }}
      />
    </Collapse>

  </div>
  );
}

export default UserProfile;