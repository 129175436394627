import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Card,
  CardActions,
  CardContent,
  Paper,
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PrintIcon from '@material-ui/icons/Print';
import FileIcon from '@material-ui/icons/Description';

import {
  BaseAPI,
} from '../../data/BaseAPI';
import {
  useProfile
} from '../../data/Profile';
import {
  WarrantyClaim,
  User,
  Session
} from '../../types';

import WarrantyClaimForm from '../forms/WarrantyClaimForm';
import ClaimAttachments from '../collections/ClaimAttachments';
import localization from '../../utils/localizations';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f5f5f5',
  },
  partNumber: {
    marginRight: 10,
    minWidth: 100,
  },
  alignRight: {
    textAlign: 'right',
  },
  bolder: {
    fontWeight: 'bold'
  },
  noteContainer: {
    marginBottom: 20,
  },
  shippingAddressContainer: {
    marginBottom: 20,
  },
  downloadButton: {
    marginRight: 6,
  },
  lineItem: {
    minWidth: '90%',
    marginBottom: 12,
  },
  lineItemTitle: {
    fontSize: 14
  }
});

export default function WarrantyClaimDetail(props: {
  claim: WarrantyClaim;
  onUpdated?: (warrantyClaim: WarrantyClaim) => void;
  onDeleted?: (warrantyClaim: WarrantyClaim) => void;
  className?: string;
}) {
  const classes = useStyles();
  const [showEditForm, setShowEditForm] = useState(false);
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();

  const deleteClaim = async (claim: WarrantyClaim) => {
    const api = new BaseAPI();
    try {
      const [result, request] = await api.delete(`warranty-claims/${claim.id}/`);
      if (props.onDeleted) props.onDeleted(claim);
    } catch (error) {
      console.error(error);
    }
  }

  if (showEditForm) {
    return (
      <div className={`${classes.root} ${props.className}`}>
        <WarrantyClaimForm
          claim={props.claim}
          dealerId=""
          onSave={(claim) => {
            setShowEditForm(false);
            if (props.onUpdated) props.onUpdated(claim);
          }}
          onCancel={() => {
            setShowEditForm(false);
          }}
        />
      </div>
    )
  }

  let totalLaborHours = 0;
  if (props.claim.labors.length > 0) {
    totalLaborHours = props.claim.labors.map(item => {
      const hours = parseFloat(item.labor_hours);
      if (isNaN(hours)) return 0;
      return hours;
    }).reduce((previousValue, currentValue, currentIndex, array) => previousValue + currentValue);
  }

  let totalLaborCost = 0;
  if (props.claim.dealer?.labor_rate) {
    let laborRate = props.claim.dealer.labor_rate;
    if (props.claim.approved_labor_hourly_rate) laborRate = props.claim.approved_labor_hourly_rate;
    totalLaborCost = totalLaborHours * laborRate;
  }
  if (props.claim.approved_labors_total_cost) totalLaborCost = props.claim.approved_labors_total_cost;

  let totalPartCost = 0;
  if (props.claim.parts.length > 0) {
    totalPartCost = props.claim.parts.map(item => {
      const cost = parseFloat(item.total_cost);
      if (isNaN(cost)) return 0;
      return cost;
    }).reduce((previousValue, currentValue, currentIndex, array) => previousValue + currentValue);
  }
  if (props.claim.approved_parts_total_cost) totalPartCost = props.claim.approved_parts_total_cost;

  let totalSubletCost = 0;
  if (props.claim.sublets.length > 0) {
    totalSubletCost = props.claim.sublets.map(item => {
      const cost = parseFloat(item.cost);
      if (isNaN(cost)) return 0;
      return cost;
    }).reduce((previousValue, currentValue, currentIndex, array) => previousValue + currentValue);
  }
  if (props.claim.approved_sublets_total_cost) totalSubletCost = props.claim.approved_sublets_total_cost;

  let totalCost = totalLaborCost + totalPartCost + totalSubletCost;
  if (props.claim.approved_total_cost) totalCost = props.claim.approved_total_cost;

  let showComputedTotal = false;

  if (profile.role === 'admin') {
    showComputedTotal = true;
  }
  else {
    if (props.claim.status === 'approved') {
      showComputedTotal = true;
    }
    if (props.claim.status === 'paid') {
      showComputedTotal = true;
    }
  }

  if (props.claim.date_submitted && (moment(props.claim.date_submitted) < moment('2022-09-05'))) {
    showComputedTotal = false;
  }

  let canEdit = false;
  if (profile && (profile.role === 'admin')) {
    canEdit = true;
  }
  if (profile && (profile.role !== 'admin') && (props.claim.status === 'wip') && profile.dealers) {
    profile.dealers.forEach((dealer) => {
      if (dealer.id === props.claim.dealer?.id) canEdit = true;
    })
  }

  if (!props.onUpdated) canEdit = false;

  return (
    <div className={`${classes.root} ${props.className}`}>
      
      <Grid container spacing={3} component={Paper} style={{margin: 0, marginBottom: 12, width: '100%'}}>
        <Grid item xs={4}>
          <div className={classes.shippingAddressContainer}>
            <Typography variant="h6">Warranty Claim Information</Typography>
            <Typography variant="body2">
              Name: <RouterLink to={`/warranty/customers/${props.claim.customer?.id}/`}>{props.claim.owner_contact}</RouterLink> <br />
              Email: {props.claim.owner_email ? <a href={`mailto:${props.claim.owner_email}`}>{props.claim.owner_email}</a>: '-'} <br />
              Phone: {props.claim.owner_phone ? <a href={`tel:${props.claim.owner_phone}`}>{props.claim.owner_phone}</a>: '-'} <br />
              Status: <strong>{props.claim.status_text}</strong> <br />
              Date Submitted: {props.claim.date_submitted ? moment(props.claim.date_submitted).format('MMMM Do YYYY') : '-'}<br />
              Date of Failure: {props.claim.date_of_failure ? moment(props.claim.date_of_failure).format('MMMM Do YYYY') : '-'}<br />
              Invoice #: {props.claim.invoice_number ? props.claim.invoice_number : '-'} <br />
              Check #: {props.claim.reimb_acct ? props.claim.reimb_acct : '-'} <br />
              Date of Check: {props.claim.date_of_check ? moment(props.claim.date_of_check).format('MMMM Do YYYY') : '-'}
            </Typography>
            {!!props.claim.denial_reason &&
            <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
              Denial Reason: {props.claim.denial_reason}
            </Typography>}
          </div>
          <div className={classes.shippingAddressContainer}>
            <Typography variant="h6">Dealer Address</Typography>
            <Typography variant="body2">{props.claim.dealer_contact}</Typography>
            <Typography variant="body2"><a href={`mailto:${props.claim.dealer_email}`}>{props.claim.dealer_email}</a></Typography>
            <Typography variant="body2">{props.claim.dealer_address}</Typography>
            <Typography variant="body2">{props.claim.dealer_city} {props.claim.dealer_state} {props.claim.dealer_zip}</Typography>
            <Typography variant="body2">{props.claim.dealer_country}</Typography>
          </div>
          <div className={classes.shippingAddressContainer}>
            <Typography variant="h6">Owner Address</Typography>
            <Typography variant="body2">{props.claim.owner_contact}</Typography>
            <Typography variant="body2"><a href={`mailto:${props.claim.owner_email}`}>{props.claim.owner_email}</a></Typography>
            <Typography variant="body2">{props.claim.owner_address}</Typography>
            <Typography variant="body2">{props.claim.owner_city} {props.claim.owner_state} {props.claim.owner_zip}</Typography>
            <Typography variant="body2">{props.claim.owner_country}</Typography>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.shippingAddressContainer}>
            <Typography variant="h6">Warranty Registration Information</Typography>
            <Typography variant="body2">
              Name: {props.claim.owner_contact} <br />
              Email: {props.claim.owner_email ? <a href={`mailto:${props.claim.owner_email}`}>{props.claim.owner_email}</a>: '-'} <br />
              Phone: {props.claim.owner_phone ? <a href={`tel:${props.claim.owner_phone}`}>{props.claim.owner_phone}</a>: '-'} <br />
              {props.claim.customer?.stock ? <span>Stock <br /></span> : ''}
              {props.claim.customer?.first_owner ? <span>First Owner <br /></span> : ''}
              {props.claim.customer?.second_owner ? <span>Second Owner <br /></span> : ''}
              {props.claim.customer?.rental_fleet ? <span>Rental Fleet <br /></span> : ''}
              {props.claim.customer?.servicing_dealer ? <span>Servicing Dealer <br /></span> : ''}
            </Typography>
          </div>
          <div className={classes.shippingAddressContainer}>
            <Typography variant="body1">Address</Typography>
            <Typography variant="body2">{props.claim.owner_address}</Typography>
            <Typography variant="body2">{props.claim.owner_city} {props.claim.owner_state} {props.claim.owner_zip}</Typography>
            <Typography variant="body2">{props.claim.owner_country}</Typography>
          </div>
          {!!props.claim.customer?.boat &&
          <div className={classes.noteContainer}>
            <Typography variant="h6">Boat</Typography>
            <Typography variant="body2">
              Model: {props.claim.customer.boat.model} <br />
              Serial / HIN: {props.claim.customer.boat.serial_number} <br />
              Engine #1 Serial: {props.claim.customer.boat.engine_serial} <br />
              {props.claim.customer.boat.engine_serial_2 && <div>Engine #2 Serial: {props.claim.customer.boat.engine_serial_2}</div>}
              {props.claim.customer.boat.engine_serial_3 && <div>Engine #3 Serial: {props.claim.customer.boat.engine_serial_3}</div>}
              Purchase Date: {moment(props.claim.customer.boat.purchase_date).format('MMMM Do YYYY')} <br />
              Registration Date: {moment(props.claim.customer.boat.registration_date).format('MMMM Do YYYY')} <br />
              Warranty Transfer: {props.claim.customer.boat.warranty_transfer ? 'Yes' : 'No'}
            </Typography>
          </div>}
          {!props.claim.customer?.boat &&
          <div className={classes.noteContainer}>
            <Typography variant="h6">Boat</Typography>
            <Typography variant="body2">
              Serial / HIN: {props.claim.serial_number} <br />
              Engine Serial: {props.claim.engine_serial} <br />
            </Typography>
          </div>}
        </Grid>
        <Grid item xs={4}>
          <div className={classes.shippingAddressContainer}>
            <Typography variant="h6">Dealer</Typography>
            <Typography variant="body2">
              Dealer Name: {props.claim.dealer.customer_name} <br />
              Dealer #: {props.claim.dealer.customer_number.padStart(5, '0')} <br />
              Email: {props.claim.dealer.email ? <a href={`mailto:${props.claim.dealer.email}`}>{props.claim.dealer.email}</a>: '-'} <br />
            </Typography>
          </div>
          {props.claim.dealer.addresses.map((dealerAddress, i) => (
            <div className={classes.shippingAddressContainer} key={`address-${i}`}>
              <Typography variant="body1">Address #{i+1}</Typography>
              <Typography variant="body2">
                Email: {dealerAddress.email ? <a href={`mailto:${dealerAddress.email}`}>{dealerAddress.email}</a>: '-'} <br />
                Phone: {dealerAddress.phone ? <a href={`tel:${dealerAddress.phone}`}>{dealerAddress.phone}</a>: '-'} <br />
              </Typography>
              <Typography variant="body2">{dealerAddress.address_line_1}</Typography>
              <Typography variant="body2">{dealerAddress.address_line_2}</Typography>
              <Typography variant="body2">{dealerAddress.city} {dealerAddress.state} {dealerAddress.zip}</Typography>
              <Typography variant="body2">{dealerAddress.country}</Typography>
            </div>
          ))}
        </Grid>
      </Grid>
      
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Paper style={{padding: 12}}>
            <div className={classes.noteContainer}>
              {!!props.claim.dealer_comments &&
              <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
                Dealer Comments: <br />{props.claim.dealer_comments ? props.claim.dealer_comments : '-'}
              </Typography>}

              {!!props.claim.seafox_comments &&
              <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
                Seafox Comments: <br />{props.claim.seafox_comments ? props.claim.seafox_comments : '-'}
              </Typography>}
            </div>
            
            {showComputedTotal &&
            <div className={classes.noteContainer}>
              {!!totalLaborHours && 
              <Typography variant="body2">
                Total Labor Hours: {totalLaborHours.toFixed(2)} {totalLaborHours > 1 ? 'hours' : 'hour'}
              </Typography>}
              {!!totalLaborCost && 
              <Typography variant="body2">
                Total Labor Cost: ${totalLaborCost.toFixed(2)}
              </Typography>}
              {!!totalPartCost && 
              <Typography variant="body2">
                Total Part Cost: ${totalPartCost.toFixed(2)}
              </Typography>}
              {!!totalSubletCost && 
              <Typography variant="body2">
                Total Sublet Cost: ${totalSubletCost.toFixed(2)}
              </Typography>}
              {!!totalCost && 
              <Typography variant="body2">
                Total Cost: ${totalCost.toFixed(2)}
              </Typography>}
              </div>}

            {(profile.role === 'admin') &&
            <div className={classes.shippingAddressContainer}>
              {!!props.claim.created_by && <Typography variant="body2">Created By: {props.claim.created_by.first_name} {props.claim.created_by.last_name} on {moment(props.claim.created_at).format('MMMM Do YYYY hh:mm:ss')}</Typography>}
              {!!props.claim.updated_by && <Typography variant="body2">Updated By: {props.claim.updated_by.first_name} {props.claim.updated_by.last_name} on {moment(props.claim.updated_at).format('MMMM Do YYYY hh:mm:ss')}</Typography>}
            </div>}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          {(props.claim.warranty_cards.length > 0) &&
          <ClaimAttachments
            title="Warranty Card"
            attachments={props.claim.warranty_cards}
            readOnly
            style={{marginBottom: 12}}
          />}
          {(props.claim.attachments.length > 0) &&
          <ClaimAttachments
            title="Attachments"
            attachments={props.claim.attachments}
            readOnly
          />}
        </Grid>
      </Grid>


      <div style={{marginBottom: 20, marginTop: 20, display: 'flex', justifyContent: 'space-between'}}>
        {canEdit &&
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowEditForm(true)}
            style={{marginRight: 10}}
          >Edit</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (window.confirm('Remove this claim?')) {
                deleteClaim(props.claim);
              }
            }}
            style={{marginRight: 10}}
          >Delete</Button>
        </div>}
        <div>
          <Button
            variant="contained"
            target="_blank"
            href={`/api/v1/warranty-claims/${props.claim.id}/print`}
            startIcon={<PrintIcon />}
            style={{marginRight: 10}}
          >Print</Button>
          <Button
            variant="contained"
            target="_blank"
            href={`/api/v1/warranty-claims/${props.claim.id}/pdf`}
            startIcon={<FileIcon />}
            style={{marginRight: 10}}
          >Download PDF</Button>
          <Button
            variant="contained"
            target="_blank"
            href={`/api/v1/warranty-claims-pending-download/?id=${props.claim.id}`}
            startIcon={<CloudDownloadIcon />}
            style={{marginRight: 10}}
          >Download CSV</Button>
        </div>
      </div>


      <div className={classes.noteContainer}>
        {props.claim.labors.map((labor, i) => {
          return (
            <Card key={`labor-entry-${i}`} className={classes.lineItem} variant="outlined">
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <CardContent>
                    <Typography className={classes.lineItemTitle} color="textSecondary" gutterBottom>
                      #{i+1} &mdash; Line Type: Labor
                    </Typography>
                    <Typography variant="body2">
                      Labor Category: {labor.labor_category?.name} <br />
                      Job Description: {labor.job_description?.name} <br />
                      Flat Rate Code: {labor.job_description?.flat_rate_code} <br />
                      Labor Hours: {labor.labor_hours ? parseFloat(labor.labor_hours).toFixed(2) : '0.00'} <br />
                    </Typography>
                    <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
                      Comments: {labor.comments}
                    </Typography>
                  </CardContent>
                </Grid>
                <Grid item xs={4}>
                  <ClaimAttachments
                    title="Attachments"
                    attachments={labor.attachments}
                    readOnly
                  />
                </Grid>
              </Grid>
            </Card>
          );
        })}
        {props.claim.parts.map((part, i) => {
          let unitCost = parseFloat(part.unit_cost);
          if (isNaN(unitCost)) unitCost = 0;
          return (
            <Card key={`part-item-${i}`} className={classes.lineItem} variant="outlined">
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <CardContent>
                    <Typography className={classes.lineItemTitle} color="textSecondary" gutterBottom>
                      #{i+1+props.claim.labors.length} &mdash; Line Type: Parts
                    </Typography>
                    <Typography variant="body2">
                      Ship / Credit: {part.type} <br />
                      Quantity: {part.quantity} <br />
                      Unit Cost: {unitCost.toFixed(2)} <br />
                      Total Cost: {(unitCost * part.quantity).toFixed(2)} <br />
                      Part Number: {part.part_number} <br />
                      Description: {part.description}
                    </Typography>
                    <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
                      Comments: {part.comments}
                    </Typography>
                  </CardContent>
                </Grid>
                <Grid item xs={4}>
                  <ClaimAttachments
                    title="Attachments"
                    attachments={part.attachments}
                    readOnly
                  />
                </Grid>
              </Grid>
            </Card>
          );
        })}

        {props.claim.sublets.map((sublet, i) => {
          let cost = parseFloat(sublet.cost);
          if (isNaN(cost)) cost = 0;
          return (
            <Card key={`sublet-item-${i}`} className={classes.lineItem} variant="outlined">
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <CardContent>
                    <Typography className={classes.lineItemTitle} color="textSecondary" gutterBottom>
                      #{i+1+props.claim.labors.length+props.claim.parts.length} &mdash; Line Type: Sublet
                    </Typography>
                    <Typography variant="body2">
                      Description: {sublet.description} <br />
                      Company Name: {sublet.company_name} <br />
                      Invoice #: {sublet.invoice_number} <br />
                      Cost: {sublet.cost} <br />
                    </Typography>
                    <Typography variant="body2" style={{whiteSpace: 'pre-line'}}>
                      Comments: {sublet.comments}
                    </Typography>
                  </CardContent>
                </Grid>
                <Grid item xs={4}>
                  <ClaimAttachments
                    title="Attachments"
                    attachments={sublet.attachments}
                    readOnly
                  />
                </Grid>
              </Grid>
            </Card>
          );
        })}
      </div>

    </div>
  );
}