import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import {
  APIError,
  BaseAPI,
} from '../../data/BaseAPI';

import {
  useProfile
} from '../../data/Profile';

import {
  Dealer,
  DealerLocation,
  Session,
} from '../../types';

import localization from '../../utils/localizations';

import DateTimeView from '../singles/DateTimeView';

import CountrySelect from './fields/CountrySelect';
import StateSelect from './fields/StateSelect';
import ClaimAttachments from '../collections/ClaimAttachments';
import HinField from './fields/HinField';

const useStyles = makeStyles({
  root: {
    padding: 10,
    borderRadius: 4
  },
  form: {

  },
  fieldContainer: {
    marginBottom: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  closeButton: {
    padding: 4,
  },
  bgWhite: {
    backgroundColor: '#ffffff',
  }
});


interface FormValues {
  dealer: string;
  title: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  email: string;
  phone_1: string;
  phone_2: string;
  phone_3: string;
  fax: string;
  website: string;
  public_note: string;
  admin_note: string;
  latitude?: number;
  longitude?: number;
  active: boolean;
}

export default function DealerLocationForm(props: {
  dealerLocation: DealerLocation|null|undefined;
  onSave: (dealerLocation: DealerLocation) => void;
  onSaveRedirect?: (dealerLocation: DealerLocation) => string;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [allDealers, setAllDealers] = useState(((window as any)._cached && (window as any)._cached['all_dealers'] ? (window as any)._cached['all_dealers']: []) as Dealer[]);
  
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();

  const loadAllDealers = async () => {

    const api = new BaseAPI();
    try {
      const data = await api.get('all-dealers/');
      if (data instanceof Array) {
        setAllDealers(data as Dealer[]);

        if (!(window as any)._cached) (window as any)._cached = {} as any;
        if (!(window as any)._cached['all_dealers']) (window as any)._cached['all_dealers'] = data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (allDealers.length === 0) loadAllDealers();
  }, []);

  const onSubmit = async (values: FormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();
    
    let success = false;
    let id: number|undefined = undefined;
    let dealerLocation: DealerLocation|undefined = undefined;

    if (!props.dealerLocation) {
      try {
        const [result, response, error] = await api.post({
          dealer: values.dealer,
          title: values.title,
          address_line_1: values.address_line_1,
          address_line_2: values.address_line_2,
          city: values.city,
          state: values.state,
          country: values.country,
          zip: values.zip,
          email: values.email,
          phone_1: values.phone_1,
          phone_2: values.phone_2,
          phone_3: values.phone_3,
          fax: values.fax,
          website: values.website,
          public_note: values.public_note,
          admin_note: values.admin_note,
          latitude: values.latitude,
          longitude: values.longitude,
          active: values.active,
        }, 'admin-dealer-locations/');
        id = result.id;
        dealerLocation = result as DealerLocation;
        console.log('result', result);
        
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
        if ((e as APIError).errorData) {
          setIsSaving(false);
          return (e as APIError).errorData;
        }
      }
      setIsSaving(false);

      if (id && dealerLocation) {
        if (props.onSaveRedirect) {
          setRedirect(props.onSaveRedirect(dealerLocation));
        }
        props.onSave(dealerLocation);
      }
    }
    else {
      try {
        const [result, response, error] = await api.patch({
          dealer: values.dealer,
          title: values.title,
          address_line_1: values.address_line_1,
          address_line_2: values.address_line_2,
          city: values.city,
          state: values.state,
          country: values.country,
          zip: values.zip,
          email: values.email,
          phone_1: values.phone_1,
          phone_2: values.phone_2,
          phone_3: values.phone_3,
          fax: values.fax,
          website: values.website,
          public_note: values.public_note,
          admin_note: values.admin_note,
          latitude: values.latitude,
          longitude: values.longitude,
          active: values.active,
        }, `admin-dealer-locations/${props.dealerLocation.id}/`);
        id = result.id;
        dealerLocation = result as DealerLocation;
        console.log('result', result);
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
        if ((e as APIError).errorData) {
          setIsSaving(false);
          return (e as APIError).errorData;
        }
      }
      setIsSaving(false);

      if (id && dealerLocation) {
        if (props.onSaveRedirect) {
          setRedirect(props.onSaveRedirect(dealerLocation));
        }
        props.onSave(dealerLocation);
      }
    }

    setIsSaving(false);
  }

  const onValidate = (values: FormValues) => {
    const errors: any = {}

    if (!values.dealer) errors.dealer = 'Required';
    if (!values.title) errors.title = "Required";
    if (!values.city) errors.city = "Required";
    if (!values.address_line_1) errors.address_line_1 = "Required";
    if (!values.zip) errors.zip = "Required";
    if (!values.country) errors.country = "Required";

    return errors;
  }

  const dealerLocation = props.dealerLocation;

  return (
    <div className={`${classes.root} ${classes.bgWhite} ${props.className ? props.className : ''}`}>
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        dealer: dealerLocation ? dealerLocation.dealer : '',
        title: dealerLocation ? dealerLocation.title : '',
        address_line_1: dealerLocation ? dealerLocation.address_line_1 : '',
        address_line_2: dealerLocation ? dealerLocation.address_line_2 : '',
        city: dealerLocation ? dealerLocation.city : '',
        state: dealerLocation ? dealerLocation.state : '',
        country: dealerLocation ? dealerLocation.country : 'United States',
        zip: dealerLocation ? dealerLocation.zip : '',
        email: dealerLocation ? dealerLocation.email : '',
        phone_1: dealerLocation ? dealerLocation.phone_1 : '',
        phone_2: dealerLocation ? dealerLocation.phone_2 : '',
        phone_3: dealerLocation ? dealerLocation.phone_3 : '',
        fax: dealerLocation ? dealerLocation.fax : '',
        public_note: dealerLocation ? dealerLocation.public_note : '',
        admin_note: dealerLocation ? dealerLocation.admin_note : '',
        latitude: dealerLocation ? dealerLocation.latitude : '',
        longitude: dealerLocation ? dealerLocation.longitude : '',
        active: dealerLocation ? dealerLocation.active : true,
      }}
      render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container>
            <Field name="dealer">
              {props => (
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <InputLabel>Dealer</InputLabel>
                    <Select
                      id={props.input.name}
                      label={'Dealer'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                    >
                      <ListSubheader>Active Dealers</ListSubheader>
                      {allDealers.filter(dealer => dealer.active).map((dealer, i) => (
                        <MenuItem key={`dealer-menu-item-${i}`} value={dealer.id}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
                      ))}
                      <ListSubheader>Inactive Dealers</ListSubheader>
                      {allDealers.filter(dealer => !dealer.active).map((dealer, i) => (
                        <MenuItem key={`dealer-menu-item-inactive-${i}`} value={dealer.id}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
                      ))}
                    </Select>
                    {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                      <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                    }
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="title">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Title'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>
          
            <Grid container spacing={3}>
              <Field name="phone_1">
                {props => (
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Phone 1'}
                        variant="outlined"
                        type="tel"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        InputProps={{
                          readOnly: profile.role !== 'admin',
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
              <Field name="phone_2">
                {props => (
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Phone 2'}
                        variant="outlined"
                        type="tel"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        InputProps={{
                          readOnly: profile.role !== 'admin',
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
              <Field name="phone_3">
                {props => (
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Phone 3'}
                        variant="outlined"
                        type="tel"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        InputProps={{
                          readOnly: profile.role !== 'admin',
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            <Field name="fax">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Fax'}
                      variant="outlined"
                      type="tel"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      InputProps={{
                        readOnly: profile.role !== 'admin',
                      }}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="email">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Email'}
                      variant="outlined"
                      type="email"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="website">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Website'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>
          
            <Field name="address_line_1">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Address Line 1'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="address_line_2">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Address Line 2'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Grid container spacing={3}>
              <Field name="city">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'City'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="country">
                {props => (
                  <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Country</InputLabel>
                      <CountrySelect
                        id={props.input.name}
                        label={'Country'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      />
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="state">
                {props => (
                  <Grid item xs={3}>
                    {values.country === 'United States' &&
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>State</InputLabel>
                      <StateSelect
                        id={props.input.name}
                        label={'State'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      />
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                      }
                    </FormControl>}
                    {values.country !== 'United States' &&
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'State'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                    }
                  </Grid>
                )}
              </Field>

              <Field name="zip">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Zip / Postal Code'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            <Field name="public_note">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Public Note'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      multiline
                      rows={4}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="admin_note">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Admin Note'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      multiline
                      rows={4}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Grid container spacing={3}>
              <Field name="latitude">
                {props => (
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Latitude'}
                        variant="outlined"
                        type="tel"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        InputProps={{
                          readOnly: profile.role !== 'admin',
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
              <Field name="longitude">
                {props => (
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Longitude'}
                        variant="outlined"
                        type="tel"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        InputProps={{
                          readOnly: profile.role !== 'admin',
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>
            
            <Field name="active">
              {props => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={props.input.value} onChange={props.input.onChange} name={props.input.name} />}
                    label="Active Dealer"
                  />
                </Grid>
              )}
            </Field>

            {((errors as any)[FORM_ERROR]) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">{(errors as any)[FORM_ERROR]}</Alert>
              </div>
            </Grid>}

            {(!pristine && (Object.keys(errors as any).length > 0)) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">Please make sure all required fields are correctly filled!</Alert>
              </div>
            </Grid>}

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isSaving}
              >Save</Button>
              {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />

      {!!redirect && <Redirect to={redirect} />}
    </div>
  );
}

