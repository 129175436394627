import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  CircularProgress,
  Slide,
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton
} from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import localization from '../utils/localizations';
import {
  Header
} from '../views/panels/Header';

import {
  SessionContext
} from '../data/Session';

import {
  BaseAPI,
} from '../data/BaseAPI';

import { useProfile } from '../data/Profile';

import {
  User,
  DealerPermission
} from '../types';

import UserCollection from '../views/collections/UserCollection';
import UserDetail from '../views/singles/UserDetail';
import UserForm from '../views/forms/UserForm';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    position: 'relative',
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    marginBottom: 10,
    padding: 10
  },
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function LoadingView() {
  return (
    <div style={{position: 'relative', padding: 10}}>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
    </div>
  );
}


export default function FilesPage() {
  const classes = useStyles();
  
  const [open, setOpen] = useState(false);
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [navigateBack, setNavigateBack] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeCounter, setIframeCounter] = useState(1)
  const [filesUrl, setFilesUrl] = useState('/files/seafox-initialize.php')
  
  const iframeRef = React.useRef<null|HTMLIFrameElement>(null);

  const { path, url } = useRouteMatch();
  const backUrl = `/`;

  useEffect(() => {
    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    },300);

  }, []);

  const goBack = () => {
    setOpen(false);
    setTimeout(() => {
      setNavigateBack(true);
    }, 300);
  };

  const canAccessFilesPortal = (permissions?: DealerPermission[], role?: string) => {
    if (role === 'admin') return true;
    let hasPermission = false;
    if (permissions) {
      permissions.forEach(permission => {
        if (permission.files) hasPermission = true;
      });
    }
    return hasPermission;
  }

  if (!canAccessFilesPortal(profile.permissions, profile.role)) {
    return (<>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={goBack}
      >
        <Typography variant="h2" style={{marginLeft: 14, marginTop: 14}}>Loading...</Typography>
        <div style={{height: 60}}></div>
      </Drawer>
      {navigateBack && <Redirect to={backUrl} />}
    </>);
  }

  return (
    <SessionContext.Consumer>
      {({session}) => (
        <>
          <Header
            session={session}
            title="Marketing Files"
            breadcrumbs={[
              {
                title: "Marketing Files",
                link: `${appConfig.homepage}marketing-files/`
              }
            ]}
          />
          <div className={classes.root}>
            {!iframeLoaded &&
              <CircularProgress
                size={20}
                style={{
                  marginLeft: 10,
                  position: 'absolute',
                  top: -54,
                  right: 24,
                  color: '#fff'
                }}
              />}

            {!iframeLoaded && <Alert severity="info" style={{marginBottom: 12}}>Loading...</Alert>}

            <iframe
              id={`iframe_${iframeCounter}`}
              ref={iframeRef}
              onLoad={(e) => {
                console.log('>', (e.target as any)?.contentWindow?.location?.pathname);
                if ((e.target as any)?.contentWindow?.location?.pathname === '/files/index.php_login/oidc') {
                  console.log('reloading iframe...');
                  setIframeCounter(Math.random()*1000000);
                  setFilesUrl(`/files/apps/files?_sf=${iframeCounter}`);
                }
                if ((e.target as any)?.contentWindow?.location?.pathname === '/files/apps/files') {
                  console.log('loading completed');
                  setIframeLoaded(true);
                }
                
              }}
              onLoadStart={() => setIframeLoaded(false)}
              src={filesUrl}
              style={{
                width: "100%",
                height: "calc(100vh - 135px)",
              }}
            >Loading...</iframe>

          </div>
        </>
      )}
    </SessionContext.Consumer>
  );
}


