import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import {
  APIError,
  BaseAPI,
} from '../../data/BaseAPI';

import {
  useProfile
} from '../../data/Profile';

import {
  Dealer,
  Session,
} from '../../types';

import localization from '../../utils/localizations';

import DateTimeView from '../singles/DateTimeView';

import CountrySelect from './fields/CountrySelect';
import StateSelect from './fields/StateSelect';
import ClaimAttachments from '../collections/ClaimAttachments';
import HinField from './fields/HinField';

const useStyles = makeStyles({
  root: {
    padding: 10,
    borderRadius: 4
  },
  form: {

  },
  fieldContainer: {
    marginBottom: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  closeButton: {
    padding: 4,
  },
  bgWhite: {
    backgroundColor: '#ffffff',
  }
});


interface FormValues {
  customer_name: string;
  customer_number: string;
  email: string;
  active: boolean;
}

export default function DealerForm(props: {
  dealer: Dealer|null|undefined;
  onSave: (dealer: Dealer) => void;
  onSaveRedirect?: (dealer: Dealer) => string;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [redirect, setRedirect] = useState('');
  
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();

  const onSubmit = async (values: FormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();
    
    let success = false;
    let id: number|undefined = undefined;
    let dealer: Dealer|undefined = undefined;

    if (!props.dealer) {
      try {
        const [result, response, error] = await api.post({
          customer_name: values.customer_name,
          customer_number: values.customer_number,
          email: values.email,
          active: values.active
        }, 'all-dealers-paginated/');
        id = result.id;
        dealer = result as Dealer;
        console.log('result', result);
        
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
        if ((e as APIError).errorData) {
          setIsSaving(false);
          return (e as APIError).errorData;
        }
      }
      setIsSaving(false);

      if (id && dealer) {
        if (props.onSaveRedirect) {
          setRedirect(props.onSaveRedirect(dealer));
        }
        props.onSave(dealer);
      }
    }
    else {
      try {
        const [result, response, error] = await api.patch({
          customer_name: values.customer_name ? values.customer_name : '',
          customer_number: values.customer_number ? values.customer_number : '',
          email: values.email ? values.email : '',
          active: values.active
        }, `all-dealers-paginated/${props.dealer.id}/`);
        id = result.id;
        dealer = result as Dealer;
        console.log('result', result);
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
        if ((e as APIError).errorData) {
          setIsSaving(false);
          return (e as APIError).errorData;
        }
      }
      setIsSaving(false);

      if (id && dealer) {
        if (props.onSaveRedirect) {
          setRedirect(props.onSaveRedirect(dealer));
        }
        props.onSave(dealer);
      }
    }

    setIsSaving(false);
  }

  const onValidate = (values: FormValues) => {
    const errors: any = {}

    if (!values.customer_name) errors.customer_name = "Required";
    if (!values.email) errors.email = "Required";

    return errors;
  }


  const dealer = props.dealer;

  return (
    <div className={`${classes.root} ${classes.bgWhite} ${props.className ? props.className : ''}`}>
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        customer_name: dealer ? dealer.customer_name : '',
        customer_number: dealer ? dealer.customer_number : '',
        email: dealer ? dealer.email : '',
        active: dealer ? dealer.active : true,
      }}
      render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container>
            
            <Field name="customer_name">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Dealer Name'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>
          
            <Field name="customer_number">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Dealer Number'}
                      placeholder="Dealer number is required to enable warranty submission feature"
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="email">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Email'}
                      variant="outlined"
                      type="email"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="active">
              {props => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={props.input.value} onChange={props.input.onChange} name={props.input.name} />}
                    label="Active Dealer"
                  />
                </Grid>
              )}
            </Field>

            {((errors as any)[FORM_ERROR]) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">{(errors as any)[FORM_ERROR]}</Alert>
              </div>
            </Grid>}

            {(!pristine && (Object.keys(errors as any).length > 0)) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">Please make sure all required fields are correctly filled!</Alert>
              </div>
            </Grid>}

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isSaving}
              >Save</Button>
              {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />

      {!!redirect && <Redirect to={redirect} />}
    </div>
  );
}

