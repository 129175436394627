import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Toolbar,
} from '@material-ui/core';

import {
  TransitionProps
} from '@material-ui/core/transitions';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import ReactMarkdown from 'react-markdown'

import {
  BaseAPI,
} from '../../data/BaseAPI';
import {
  Boat
} from '../../types';

import SearchableStandardItemsList from '../collections/SearchableStandardItemsList';
import localization from '../../utils/localizations';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: 20,
  },
  title: {
    marginBottom: 20,
  },
  list: {

  },
  listContent: {
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em'
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function BoatStandardItem(props: {boat: Boat; className?: string;}) {
  const classes = useStyles();
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  return (
    <div className={`${classes.root} ${props.className}`}>
      <Typography variant="h6" className={classes.title}>
        Standard Features
      </Typography>
      <div style={{position: 'absolute', top: 15, right: 20}}>
        <IconButton edge="end" color="inherit" onClick={() => {
          setShowSearchDialog(true);
        }} aria-label="close">
          <SearchIcon />
        </IconButton>
      </div>
      <div className={classes.list}>
        <div className={classes.listContent}>
          <ReactMarkdown className='CustomMarkdown'>{`${props.boat.standard_option}`}</ReactMarkdown>
        </div>
      </div>


      <Dialog
          open={showSearchDialog}
          onClose={() => {
            setShowSearchDialog(false);
          }}
          maxWidth="sm"
          fullWidth
          TransitionComponent={Transition}
        >
          <>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.dialogTitle}>
                Search Standard Items
              </Typography>
              <IconButton edge="start" color="inherit" onClick={() => {
                setShowSearchDialog(false);
              }} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <SearchableStandardItemsList
              content={props.boat.standard_option}
            />
          </DialogContent>
          </>
        </Dialog>

    </div>
  );
}