import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  CircularProgress
} from '@material-ui/core';

import localization from '../../utils/localizations';
import loginBg from '../../img/login-bg.jpg';
import logo from '../../img/new/seafox-signature-bw.png';


const useStyles = makeStyles((theme) => ({
  mainBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${loginBg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  mainBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backdropFilter: 'blur(9px)',
  },
  loadingContainer: {
    padding: 10,
    width: 300,
    textAlign: 'center',
    backgroundColor: 'rgba(255,255,255,.777)',
    backdropFilter: 'blur(9px)',
  },
  appLogo: {},
  block: {
    display: 'block',
  },
  loadingText: {
    margin: 20,
  },
  loadingCircleContainer: {
    margin: 10,
    textAlign: 'center'
  }
}));
 

function SiteLoadingComponent() {
  const classes = useStyles();
  return (
    <div className={classes.mainBg}>
      <div className={classes.mainBody}>
        <Paper className={classes.loadingContainer}>
          <img src={logo}  alt="" className={classes.appLogo} />
          <div className={classes.loadingCircleContainer}>
            <CircularProgress disableShrink />
          </div>
          <Typography variant="body1" className={classes.loadingText}>Loading...</Typography>
        </Paper>
      </div>
    </div>
  );
}

export default SiteLoadingComponent;