import React, {
  useState,
  useEffect
} from 'react';

import { Form, Field } from "react-final-form";

import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  MenuItem,
  ListSubheader,
} from '@material-ui/core';

import {
  APIError,
  BaseAPI,
} from '../../../data/BaseAPI';

import {
  Boat,
} from '../../../types';

const useStyles = makeStyles({
  root: {
    
  },
});

export default function BoatSelect(props: {
  id: string;
  label: string;
  variant: "filled" | "outlined" | "standard" | undefined;
  name: string;
  value: string;
  allowEmpty?: boolean;
  emptyLabel?: string;
  onChange: (event: any) => void;
  error?: boolean | undefined
}) {
  const classes = useStyles();
  
  const [allBoats, setAllBoats] = useState(((window as any)._cached && (window as any)._cached['all_boats'] ? (window as any)._cached['all_boats']: []) as Boat[]);


  const loadBoats = async () => {
    const api = new BaseAPI();
    try {
      const data = await api.get(`thin-boats/?all_boats=1`);
      if (data instanceof Array) {
        // let boats = processBoatsData(data as Boat[]);
        let boats = (data as Boat[]).sort((a, b) => (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)));
        setAllBoats(boats);
        if (!(window as any)._cached) (window as any)._cached = {} as any;
        if (!(window as any)._cached['all_boats']) (window as any)._cached['all_boats'] = boats;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (allBoats.length === 0) loadBoats();
  }, []);

  return (
    <Select
      id={props.id}
      label={props.label}
      variant={props.variant}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      error={props.error}
    >
      {!!props.allowEmpty && 
      <MenuItem value="">{props.emptyLabel ? props.emptyLabel : '----'}</MenuItem>}
      <ListSubheader>Active Boats</ListSubheader>
      {allBoats.filter(boat => boat.active).map((boat, i) => (
        <MenuItem key={`boat-menu-item-${i}`} value={boat.name}>{boat.name}</MenuItem>
      ))}
      <ListSubheader>Discontinued Boats</ListSubheader>
      {allBoats.filter(boat => !boat.active).map((boat, i) => (
        <MenuItem key={`boat-menu-item-inactive-${i}`} value={boat.name}>{boat.name}</MenuItem>
      ))}
    </Select>
  );
}