import React, {
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import {
  APIError,
  BaseAPI,
} from '../../data/BaseAPI';

import {
  useProfile
} from '../../data/Profile';

import {
  Customer,
  Dealer,
  Session,
  Boat,
  Order,
  ClaimAttachment
} from '../../types';

import {
  isSamePhoneNumber,
  isSameEmail,
  isSameAddress,
  isSimilarAddress
} from '../../utils/formUtils';

import DateTimeView from '../singles/DateTimeView';

import CountrySelect from './fields/CountrySelect';
import StateSelect from './fields/StateSelect';
import BoatSelect from './fields/BoatSelect';
import ClaimAttachments from '../collections/ClaimAttachments';
import HinField from './fields/HinField';

const useStyles = makeStyles({
  root: {
    padding: 10,
    borderRadius: 4
  },
  form: {

  },
  fieldContainer: {
    marginBottom: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  closeButton: {
    padding: 4,
  },
  bgWhite: {
    backgroundColor: '#ffffff',
  }
});


interface FormValues {
  serial_number: string;
  engine_serial: string;
  engine_serial_2: string;
  engine_serial_3: string;
  model: string;
  purchase_date: string;
  registration_date: string;
  first_name: string;
  last_name: string;
  dealer: string;
  phone: string;
  email: string;
  address_line_1: string;
  city: string;
  country: string;
  state: string;
  zip: string;
  dealer_name: string;
  dealer_phone: string;
  dealer_email: string;
  dealer_address: string;
  dealer_city: string;
  dealer_country: string;
  dealer_state: string;
  dealer_zip: string;
  warranty_transfer: boolean;
  stock: boolean;
  first_owner: boolean;
  rental_fleet: boolean;
  second_owner: boolean;
  servicing_dealer: boolean;
}

export default function CustomerForm(props: {
  customer: Customer|null|undefined;
  dealerId: string;
  onSave: (customer: Customer) => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [showSerialNumberPicker, setShowSerialNumberPicker] = useState(false);
  const [currentForm, setCurrentForm] = useState<any>(undefined);
  const [currentSerialNumber, setCurrentSerialNumber] = useState<string>('');
  const [attachments, setAttachments] = useState<ClaimAttachment[]>([]);
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);

  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [selectedDealer, setSelectedDealer] = useState(null as Dealer|null);
  const [allDealers, setALlDealers] = useState(((window as any)._cached && (window as any)._cached['all_dealers'] ? (window as any)._cached['all_dealers']: []) as Dealer[]);

  const loadAllDealers = async () => {

    const api = new BaseAPI();
    try {
      const data = await api.get('all-dealers/');
      if (data instanceof Array) {
        setALlDealers(data as Dealer[]);

        if (!(window as any)._cached) (window as any)._cached = {} as any;
        if (!(window as any)._cached['all_dealers']) (window as any)._cached['all_dealers'] = data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(allDealers.length === 0) loadAllDealers();
  }, []);

  useEffect(() => {
    if (props.customer?.dealer?.id) {
      setSelectedDealer(props.customer.dealer);
    }
  }, [props.customer?.dealer?.id]);

  useEffect(() => {
    if (props.customer?.attachments) {
      setAttachments(props.customer.attachments);
    }
  }, [props.customer?.attachments?.length]);

  const onSubmit = async (values: FormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();
    
    let success = false;
    let id: number|undefined = undefined;
    let customer: Customer|undefined = undefined;

    if (!props.customer) {
      try {
        const [result, response, error] = await api.post({
          dealer: values.dealer,
          serial_number: values.serial_number,
          engine_serial: values.engine_serial,
          engine_serial_2: values.engine_serial_2,
          engine_serial_3: values.engine_serial_3,
          model: values.model,
          purchase_date: values.purchase_date,
          registration_date: values.registration_date,
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email,
          address_line_1: values.address_line_1,
          city: values.city,
          state: values.state,
          country: values.country,
          zip: values.zip,
          dealer_name: values.dealer_name,
          dealer_phone: values.dealer_phone,
          dealer_email: values.dealer_email,
          dealer_address: values.dealer_address,
          dealer_city: values.dealer_city,
          dealer_state: values.dealer_state,
          dealer_country: values.dealer_country,
          dealer_zip: values.dealer_zip,
          warranty_transfer: values.warranty_transfer,
          stock: values.stock,
          first_owner: values.first_owner,
          second_owner: values.second_owner,
          rental_fleet: values.rental_fleet,
          servicing_dealer: values.servicing_dealer,
          attachment_ids: attachments.map(attachment => attachment.id),
          removed_attachment_ids: removedAttachments,
        }, 'warranty-customers/create_customer/');
        id = result.id;
        customer = result as Customer;
        console.log('result', result);
        
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
        if ((e as APIError).errorData) {
          setIsSaving(false);
          return (e as APIError).errorData;
        }
      }
      setIsSaving(false);

      if (id && customer) {
        props.onSave(customer);
      }
    }
    else {
      try {
        const [result, response, error] = await api.post({
          id: props.customer.id,
          dealer: values.dealer,
          serial_number: values.serial_number,
          engine_serial: values.engine_serial,
          engine_serial_2: values.engine_serial_2,
          engine_serial_3: values.engine_serial_3,
          model: values.model,
          purchase_date: values.purchase_date,
          registration_date: values.registration_date,
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email,
          address_line_1: values.address_line_1,
          city: values.city,
          state: values.state,
          country: values.country,
          zip: values.zip,
          dealer_name: values.dealer_name,
          dealer_phone: values.dealer_phone,
          dealer_email: values.dealer_email,
          dealer_address: values.dealer_address,
          dealer_city: values.dealer_city,
          dealer_state: values.dealer_state,
          dealer_country: values.dealer_country,
          dealer_zip: values.dealer_zip,
          warranty_transfer: values.warranty_transfer,
          stock: values.stock,
          first_owner: values.first_owner,
          second_owner: values.second_owner,
          rental_fleet: values.rental_fleet,
          servicing_dealer: values.servicing_dealer,
          attachment_ids: attachments.map(attachment => attachment.id),
          removed_attachment_ids: removedAttachments,
        }, `warranty-customers/${props.customer.id}/edit_customer/`);
        id = result.id;
        customer = result as Customer;
        console.log('result', result);
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
        if ((e as APIError).errorData) {
          setIsSaving(false);
          return (e as APIError).errorData;
        }
      }
      setIsSaving(false);

      if (id && customer) {
        props.onSave(customer);
      }
    }

    setIsSaving(false);
  }

  const onValidate = (values: FormValues) => {
    const errors: any = {}

    if (values.warranty_transfer || values.first_owner || values.second_owner) {
      // if admin should be allowed to save without attachment, add && (profile.role != 'admin')
      if ((attachments.length === 0) && (!props.customer)) errors[FORM_ERROR] = "Please upload a warranty registration attachment!";
      if ((attachments.length === 0) && (props.customer?.stock)) errors[FORM_ERROR] = "Please upload a warranty registration attachment!";
    }

    if (!values.dealer) errors.dealer = "Required";
    if (!values.serial_number) errors.serial_number = "Required";
    if (values.serial_number && values.serial_number.includes('_')) errors.serial_number = "Required";
    if (!values.engine_serial) errors.engine_serial = "Required";
    if (!values.model) errors.model = "Required";
    if (!values.purchase_date) errors.purchase_date = "Required";
    if (!values.registration_date) errors.registration_date = "Required";
    
    if (!values.stock) {
      if (!values.first_name) errors.first_name = "Required";
      if (!values.last_name) errors.last_name = "Required";
      if (!values.email) errors.email = "Required";
      if (!values.phone) errors.phone = "Required";
      if (!values.address_line_1) errors.address_line_1 = "Required";
      if (!values.city) errors.city = "Required";
      if (!values.state) errors.state = "Required";
      if (!values.zip) errors.zip = "Required";
      if (!values.country) errors.country = "Required";

      if (profile.role != 'admin') {
        if (values.phone && !errors.phone && isSamePhoneNumber(values.phone, values.dealer_phone)) {
          errors.phone = "Please use the customer's phone number instead of dealer's phone number";
        }
        if (values.email && !errors.email && isSameEmail(values.email, values.dealer_email)) {
          errors.email = "Please use the customer's email address instead of dealer's email address";
        }
        if (
          values.address_line_1 &&
          !errors.address_line_1 &&
          isSameAddress(
            `${values.address_line_1} ${values.city ? values.city : ''} ${values.state ? values.state : ''} ${values.zip ? values.zip : ''} ${values.country ? values.country : ''}`, 
            `${values.dealer_address} ${values.dealer_city ? values.dealer_city : ''} ${values.dealer_state ? values.dealer_state : ''} ${values.dealer_zip ? values.dealer_zip : ''} ${values.dealer_country ? values.dealer_country : ''}`
          )) {
          errors.address_line_1 = "Please use the customer's address instead of dealer's address";
        }
      }
    }

    if (!values.dealer_name) errors.dealer_name = "Required";
    if (!values.dealer_email) errors.dealer_email = "Required";
    if (!values.dealer_phone) errors.dealer_phone = "Required";
    if (!values.dealer_address) errors.dealer_address = "Required";
    if (!values.dealer_city) errors.dealer_city = "Required";
    if (!values.dealer_state) errors.dealer_state = "Required";
    if (!values.dealer_zip) errors.dealer_zip = "Required";
    if (!values.dealer_country) errors.dealer_country = "Required";

    if (!values.first_owner && !values.second_owner && !values.stock) {
      errors.stock = "Please select first owner or second owner status, or mark this boat as Stock";
    }

    return errors;
  }


  const customer = props.customer;
  let showStockCheckbox = profile.role === 'admin';
  if ((profile.role !== 'admin') && (customer?.stock)) {
    showStockCheckbox = true;
  }
  if ((profile.role !== 'admin') && !customer) {
    showStockCheckbox = true;
  }

  return (
    <div className={`${classes.root} ${classes.bgWhite} ${props.className ? props.className : ''}`}>
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        model: customer?.boat ? customer.boat.model : '',
        serial_number: customer?.boat ? customer.boat.serial_number : '',
        engine_serial: customer?.boat ? customer.boat.engine_serial : '',
        engine_serial_2: customer?.boat ? customer.boat.engine_serial_2 : '',
        engine_serial_3: customer?.boat ? customer.boat.engine_serial_3 : '',
        purchase_date: customer?.boat ? customer.boat.purchase_date : moment().format('YYYY-MM-DD'),
        registration_date: customer?.boat ? customer.boat.registration_date : moment().format('YYYY-MM-DD'),
        dealer: (customer && customer.dealer) ? customer.dealer.id : props.dealerId,
        first_name: customer ? customer.first_name : '',
        last_name: customer ? customer.last_name : '',
        email: customer ? customer.email : '',
        phone: customer ? customer.phone : '',
        address_line_1: customer ? customer.address_line_1 : '',
        city: customer ? customer.city : '',
        state: customer ? customer.state : '',
        zip: customer ? customer.zip : '',
        country: customer ? customer.country : 'United States',
        dealer_name: customer ? customer.dealer_name : '',
        dealer_email: customer ? customer.dealer_email : '',
        dealer_phone: customer ? customer.dealer_phone : '',
        dealer_address: customer ? customer.dealer_address : '',
        dealer_city: customer ? customer.dealer_city : '',
        dealer_state: customer ? customer.dealer_state : '',
        dealer_zip: customer ? customer.dealer_zip : '',
        dealer_country: customer ? customer.dealer_country : 'United States',
        warranty_transfer: customer?.boat ? customer.boat.warranty_transfer : false,
        stock: customer ? customer.stock : false,
        first_owner: customer ? customer.first_owner : true,
        second_owner: customer ? customer.second_owner : false,
        rental_fleet: customer ? customer.rental_fleet : false,
        servicing_dealer: customer ? customer.servicing_dealer : false,
      }}
      render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container>
            
            <Grid container spacing={3}>
              <Field name="dealer">
                {props => (
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Dealer</InputLabel>
                      <Select
                        id={props.input.name}
                        label={'Dealer'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={(event) => {
                          props.input.onChange(event);
                          allDealers.filter((dealer) => {
                            if (dealer.id == event.target.value) {
                              setSelectedDealer(dealer);
                              if (dealer.addresses.length > 0) {
                                const address = dealer.addresses[0];
                                form.change('dealer_name', dealer.customer_name);
                                if (address.email) {
                                  form.change('dealer_email', address.email);
                                }
                                else {
                                  form.change('dealer_email', dealer.email);
                                }

                                form.change('dealer_phone', address.phone);
                                form.change('dealer_address', address.address_line_1);
                                form.change('dealer_city', address.city);
                                form.change('dealer_country', address.country);
                                form.change('dealer_state', address.state);
                                form.change('dealer_zip', address.zip);
                              }
                            }
                          })
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      >
                        <ListSubheader>Active Dealers</ListSubheader>
                        {allDealers.filter(dealer => dealer.active).map((dealer, i) => (
                          <MenuItem key={`dealer-menu-item-${i}`} value={dealer.id}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
                        ))}
                        <ListSubheader>Inactive Dealers</ListSubheader>
                        {allDealers.filter(dealer => !dealer.active).map((dealer, i) => (
                          <MenuItem key={`dealer-menu-item-inactive-${i}`} value={dealer.id}>{dealer.customer_name} {dealer.customer_number ? `(${dealer.customer_number.padStart(5, '0')})` : ''}</MenuItem>
                        ))}
                      </Select>
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="serial_number">
                {props => (
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Serial Number / HIN'}
                        placeholder="LYGAB123C456"
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        onClick={() => {
                          setCurrentForm(form);
                          setCurrentSerialNumber(props.input.value);
                          setShowSerialNumberPicker(true);
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            <Grid container spacing={3}>
              <Field name="model">
                {props => (
                  <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Boat Model</InputLabel>
                      <BoatSelect
                        id={props.input.name}
                        label={'Boat Model'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        allowEmpty
                        emptyLabel="All Boats"
                        onChange={(event) => {
                          props.input.onChange(event);
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      />
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="engine_serial">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Engine #1 Serial Number'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="engine_serial_2">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Engine #2 Serial Number'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="engine_serial_3">
                {props => (
                  <Grid item xs={3}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Engine #3 Serial Number'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            <Grid container spacing={3}>

              <Field name="purchase_date">
                {props => (
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Purchase Date'}
                        variant="outlined"
                        type="date"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="registration_date">
                {props => (
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Registration Date'}
                        variant="outlined"
                        type="date"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            <Grid container spacing={3} style={{marginBottom: 12}}>
            {(profile.role === 'admin') &&
              <Field name="warranty_transfer">
                {props => (
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox checked={props.input.value} onChange={props.input.onChange} name={props.input.name} />}
                      label="Warranty Transfer"
                    />
                  </Grid>
                )}
              </Field>}

            {showStockCheckbox &&
              <Field name="stock">
                {props => (
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox
                                  checked={props.input.value}
                                  onChange={(event, checked) => {
                                    props.input.onChange(event);
                                    form.change('first_owner', !checked);
                                    form.change('second_owner', false);
                                  }}
                                  name={props.input.name}
                                />}
                      label="Stock"
                    />
                  </Grid>
                )}
              </Field>}

            
              <Field name="first_owner">
                {props => (
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox
                                  checked={props.input.value}
                                  onChange={(event, checked) => {
                                    props.input.onChange(event);
                                    form.change('stock', false);
                                    form.change('second_owner', !checked);
                                  }}
                                  name={props.input.name}
                                />}
                      label="First Owner"
                    />
                  </Grid>
                )}
              </Field>

            {(profile.role === 'admin') &&
              <Field name="second_owner">
                {props => (
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox
                                  checked={props.input.value}
                                  onChange={(event, checked) => {
                                    props.input.onChange(event);
                                    form.change('stock', false);
                                    form.change('first_owner', !checked);
                                  }}
                                  name={props.input.name}
                                />}
                      label="Second Owner"
                    />
                  </Grid>
                )}
              </Field>}

              <Field name="rental_fleet">
                {props => (
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox
                                  checked={props.input.value}
                                  onChange={(event, checked) => {
                                    props.input.onChange(event);
                                  }}
                                  name={props.input.name}
                                />}
                      label="Rental Fleet"
                    />
                  </Grid>
                )}
              </Field>

              <Field name="servicing_dealer">
                {props => (
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox
                                  checked={props.input.value}
                                  onChange={(event, checked) => {
                                    props.input.onChange(event);
                                  }}
                                  name={props.input.name}
                                />}
                      label="Servicing Dealer"
                    />
                  </Grid>
                )}
              </Field>
            </Grid>

            
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant='h5' style={{paddingBottom: 12}}>Customer Information</Typography>
              </Grid>
                
              <Grid container spacing={3}>
                <Field name="first_name">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'First Name'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={((props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined) || "If there are two names, please enter both of them (e.g. Jim & Jan)"}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="last_name">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Last Name'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="email">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Email'}
                          variant="outlined"
                          type="email"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="phone">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Phone'}
                          variant="outlined"
                          type="tel"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
              </Grid>

              <Field name="address_line_1">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Address'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                      {!(props.meta.error || props.meta.submitError) &&
                        values.address_line_1 &&
                          (isSimilarAddress(
                          `${values.address_line_1} ${values.city ? values.city : ''} ${values.state ? values.state : ''} ${values.zip ? values.zip : ''} ${values.country ? values.country : ''}`, 
                          `${values.dealer_address} ${values.dealer_city ? values.dealer_city : ''} ${values.dealer_state ? values.dealer_state : ''} ${values.dealer_zip ? values.dealer_zip : ''} ${values.dealer_country ? values.dealer_country : ''}`
                        )) &&
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled" id="address_line_1-helper-text">This address is very similar with the dealer's address. Please make sure to use the customer's address instead of dealer's address.</p>}
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Grid container spacing={3}>
                <Field name="city">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'City'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="country">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <InputLabel>Country</InputLabel>
                        <CountrySelect
                          id={props.input.name}
                          label={'Country'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        />
                        {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                          <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                        }
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="state">
                  {props => (
                    <Grid item xs={3}>
                      {values.country === 'United States' &&
                      <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <InputLabel>State</InputLabel>
                        <StateSelect
                          id={props.input.name}
                          label={'State'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        />
                        {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                          <Typography style={{color: 'red'}}>{props.meta.error || props.meta.submitError}</Typography>
                        }
                      </FormControl>}
                      {values.country !== 'United States' &&
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'State'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                      }
                    </Grid>
                  )}
                </Field>

                <Field name="zip">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Zip / Postal Code'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant='h5' style={{paddingBottom: 12}}>Dealer Information</Typography>
              </Grid>
              <Grid item xs={12}>
                {(!!selectedDealer && selectedDealer.addresses.length > 0) &&
                <div key={`customer-address-${values.dealer}`} style={{marginTop: 10, marginBottom: 40}}>
                  <div style={{display: 'flex'}}>
                {selectedDealer.addresses.map((address, i) => (
                  <Card key={`address-selector-${i}`} style={{marginRight: 20}}>
                    <CardContent>
                      <Typography variant="body2">
                        <span>{address.address_line_1}</span><br/>
                        <span>{address.city}</span> <span>{address.state}</span> <span>{address.zip}</span><br/>
                        <span>{address.country}</span>
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" onClick={() => {
                        form.change('dealer_name', selectedDealer.customer_name);
                        if (address.email) {
                          form.change('dealer_email', address.email);
                        }
                        else {
                          form.change('dealer_email', selectedDealer.email);
                        }

                        form.change('dealer_phone', address.phone);
                        form.change('dealer_address', address.address_line_1);
                        form.change('dealer_city', address.city);
                        form.change('dealer_country', address.country);
                        form.change('dealer_state', address.state);
                        form.change('dealer_zip', address.zip);
                      }}>Use</Button>
                    </CardActions>
                  </Card>
                ))}
                </div>
                </div>}

              </Grid>
              <Grid container spacing={3}>
                <Field name="dealer_name">
                  {props => (
                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Dealer Name'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="dealer_email">
                  {props => (
                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Email'}
                          variant="outlined"
                          type="email"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
                <Field name="dealer_phone">
                  {props => (
                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Phone'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 20)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
              </Grid>
              
              <Field name="dealer_address">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Address'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Grid container spacing={3}>
                <Field name="dealer_city">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'City'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="dealer_country">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <InputLabel>Country</InputLabel>
                        <CountrySelect
                          id={props.input.name}
                          label={'Country'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        />
                        {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                          <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                        }
                      </FormControl>
                    </Grid>
                  )}
                </Field>

                <Field name="dealer_state">
                  {props => (
                    <Grid item xs={3}>
                      {values.dealer_country === 'United States' &&
                      <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <InputLabel>State</InputLabel>
                        <StateSelect
                          id={props.input.name}
                          label={'State'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        />
                        {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                          <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                        }
                      </FormControl>}
                      {values.dealer_country !== 'United States' &&
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'State'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                      }
                    </Grid>
                  )}
                </Field>

                <Field name="dealer_zip">
                  {props => (
                    <Grid item xs={3}>
                      <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                        <TextField
                          id={props.input.name}
                          label={'Zip / Postal Code'}
                          variant="outlined"
                          name={props.input.name}
                          value={props.input.value.substring(0, 150)}
                          onChange={props.input.onChange}
                          error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                          helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Field>
              </Grid>
            </Grid>

            {((errors as any)[FORM_ERROR]) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">{(errors as any)[FORM_ERROR]}</Alert>
              </div>
            </Grid>}

            <Grid item xs={12} style={{marginTop: 12, marginBottom: 12}}>
              <ClaimAttachments
                title="Attachments"
                baseUrl="warranty-customers-attachments/"
                attachments={attachments}
                onAdded={(attachment) => {
                  const newAttachments = Object.assign([], attachments);
                  newAttachments.push(attachment);
                  setAttachments(newAttachments);
                }}
                onRemoved={(attachment) => {
                  const newRemovedAttachments = Object.assign([], removedAttachments);
                  newRemovedAttachments.push(attachment.id);
                  setRemovedAttachments(newRemovedAttachments);
                }}
              />
            </Grid>
            
            {(!pristine && (Object.keys(errors as any).length > 0)) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">Please make sure all required fields are correctly filled!</Alert>
              </div>
            </Grid>}

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isSaving}
              >Save</Button>
              {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />

    <Dialog
      open={showSerialNumberPicker}
      maxWidth='md'
      fullWidth
      onClose={() => {
        setShowSerialNumberPicker(false);
      }}
    >
      <DialogTitle id="form-dialog-title">Find Orders</DialogTitle>
      <FindOrdersBySerialNumberForm
        serialNumber={currentSerialNumber}
        onSave={(found) => {
          if (currentForm) {
            currentForm.change('serial_number', found.serial_number);
            if (found.model) currentForm.change('model', found.model);
            if (found.engine_serial) currentForm.change('engine_serial', found.engine_serial);
            if (found.engine_serial_2) currentForm.change('engine_serial_2', found.engine_serial_2);
            if (found.engine_serial_3) currentForm.change('engine_serial_3', found.engine_serial_3);
            if (found.purchase_date) currentForm.change('purchase_date', moment(found.purchase_date).format('YYYY-MM-DD'));
          }
          setShowSerialNumberPicker(false);
        }}
        onCancel={() => {
          setShowSerialNumberPicker(false);
        }}
      />
    </Dialog>
    </div>
  );
}


function validateHin(hin: string) {
  hin = hin.trim().toUpperCase();
  if (!hin.match(/[L][Y][G][A-Z][A-Z][0-9][0-9][0-9][A-Z][0-9][0-9][0-9]/g)) return false;
  return true;
}

interface FindOrdersBySerialNumberFormValues {
  serial_number: string;
}

interface FoundOrder {
  serial_number: string;
  order_id?: string;
  model?: string;
  engine_serial?: string;
  engine_serial_2?: string;
  engine_serial_3?: string;
  purchase_date?: string;
}

function FindOrdersBySerialNumberForm(props: {
  serialNumber: string;
  onSave: (found: FoundOrder) => void;
  onCancel: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [initiated, setInitiated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [serialNumber, setSerialNumber] = useState('');
  
  const findOrders = async (serialNumber: string) => {
    const api = new BaseAPI();
    try {
      const data = await api.get(`orders/?serial_number=${serialNumber}`);
      if ((data as any).results instanceof Array) {
        let orders = (data as any).results as Order[];
        setOrders(orders);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (values: FindOrdersBySerialNumberFormValues) => {
    setIsLoading(true);
    setShowErrorSnackBar(false);
    setInitiated(true);
    setSerialNumber(values.serial_number ? values.serial_number.toUpperCase() : '');
    const api = new BaseAPI();
    
    // props.onSave(values.serial_number);
    await findOrders(values.serial_number ? values.serial_number.toUpperCase() : '');
    setIsLoading(false);
  }

  const onValidate = (values: FindOrdersBySerialNumberFormValues) => {
    const errors: any = {}

    if (!values.serial_number) errors.serial_number = "Required";
    if (values.serial_number && values.serial_number.includes('_')) errors.serial_number = "Required";
    
    if (values.serial_number && !validateHin(values.serial_number)) {
      errors.serial_number = 'HIN should always start with LYG, two letters, 3 numbers, 1 letter and 3 numbers. Example: LYGAB123C456'
    }

    return errors;
  }

  return (
    <div>
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        serial_number: props.serialNumber,
      }}
      render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
        <form onSubmit={handleSubmit} className={classes.root} style={{margin: 12}}>
          <Grid container>
            <Grid container spacing={3}>
              <Field name="serial_number">
                {props => (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <HinField
                        id={props.input.name}
                        label={'Serial Number / HIN'}
                        placeholder="LYGAB123C456"
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>

            {(!pristine && (Object.keys(errors as any).length > 0)) &&
            <Grid item xs={12}>
              <div style={{marginTop: 10, marginBottom: 10}}>
                <Alert severity="error">Please make sure all required fields are correctly filled!</Alert>
              </div>
            </Grid>}

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isLoading}
              >Find</Button>
              {isLoading && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />
    {(!isLoading && !!initiated && orders.length === 0) && <div style={{margin: 12}}>
      <Alert
        severity="error"
        action={
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              props.onSave({
                serial_number: serialNumber
              })
            }}
          >
            Use This Serial Number Anyway
          </Button>
        }
      >No order found with serial number / HIN {serialNumber}.</Alert>
    </div>}
    {(!isLoading && !!initiated && orders.length > 0) && <div style={{margin: 12}}>
    <TableContainer component={Paper}>
      <Table aria-label="orders list">
        <TableHead>
          <TableRow>
            <TableCell>Order #</TableCell>
            <TableCell align="right">Boat</TableCell>
            <TableCell align="right">Dealer</TableCell>
            <TableCell align="right">Purchase Date</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => {
            let selectedBoats = '';
            order.items.forEach((item) => {
              if (item?.part?.part_type == 'boat')
              selectedBoats = `${item?.part?.description ? item?.part?.description : ''} ${selectedBoats}`
            })
            selectedBoats = `${order.boat_name} — ${selectedBoats}`;
            return (
              <TableRow key={`order-row-${order.id}`}>
                <TableCell component="th" scope="row">
                  {order.id}
                </TableCell>
                <TableCell align="right">{selectedBoats}</TableCell>
                <TableCell align="right">{order.customer_name}</TableCell>
                <TableCell align="right"><DateTimeView value={order.created_at} chip size="small" /></TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      let data = {
                        serial_number: order.serial_number,
                        engine_serial: order.engine_serial,
                        model: order.boat_name,
                        purchase_date: order.created_at,
                      };
                      if (order.engine_serial_2) (data as any).engine_serial_2 = order.engine_serial_2;
                      if (order.engine_serial_3) (data as any).engine_serial_3 = order.engine_serial_3;
                      props.onSave(order)
                    }}
                  >Select</Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    </div>}
    </div>
  );
}