import React, {
  useState,
  useEffect
} from 'react';

import {
  APIError,
  BaseAPI,
} from './BaseAPI';

import {
  Profile
} from '../types';

class ProfileAPI extends BaseAPI {
  path = 'users/profile/'
}

let profileAPI: ProfileAPI;
function getAPI() {
  if (!profileAPI) profileAPI = new ProfileAPI();
  return profileAPI;
}

let cachedProfile: Profile|undefined = undefined;

function useProfile(path=''): [Profile, boolean, ((data: any) => Promise<any[]>), ((image: any) => Promise<any[]>), (() => Promise<any>)] {
  const [profile, setProfile] = useState(cachedProfile ? cachedProfile : {} as Profile);
  const [loading, setLoading] = useState(true);

  const loadProfile = async () => {
    setLoading(true);
    const profileAPI = getAPI();
    try {
      const data = await profileAPI.get(path);
      cachedProfile = data as Profile;
      setProfile(data as Profile);
    }
    catch (error) {
    }

    setLoading(false);
  }

  const updateProfile = async (data: any) => {
    let success = false;
    setLoading(true);
    const profileAPI = getAPI();
    try {
      const [result, response, error] = await profileAPI.post(data, path);
      if (result && result.success) {
        if (data.password) {
          // changing password will log user out
          // reload so user can re-login
          window.location.reload();
          return [success, result];
        }

        const profile = await profileAPI.get(path);
        cachedProfile = profile as Profile;
        setProfile(profile as Profile);
        success = true;
      }
      setLoading(false);
      return [success, result];
    }
    catch (error) {
      success = false;
      setLoading(false);
      return [success, (error as APIError).errorData];
    }
  };

  const updateProfilePicture = async (image: any) => {
    let success = false;
    setLoading(true);
    let formData = new FormData();
    formData.append('picture', image);
    formData.append('first_name', profile.first_name);
    formData.append('last_name', profile.last_name);

    const profileAPI = getAPI();
    const [result, response, error] = await profileAPI.post(formData, path);
    if (result && result.success) {
      const profile = await profileAPI.get(path);
      cachedProfile = profile as Profile;
      setProfile(profile as Profile);
      success = true;
    }
    setLoading(false);
    return [success, result];
  };

  useEffect(() => {
    loadProfile();
  }, []);

  return [
    profile,
    loading,
    updateProfile,
    updateProfilePicture,
    loadProfile,
  ];
}

export {
  getAPI,
  useProfile,
};
