
import getCookie from './Cookie';
import {
  getSession,
  setSession,
} from './SessionUtils';

const appConfig = (window as any).APP_CONFIG;


class APIError extends Error {
  status: number;
  errorData: any;
  constructor(message?: string, statusCode?: number, errorData?: any) {
    super(message)
    if (statusCode) {
      this.status = statusCode;
    }
    else {
      this.status = 0;
    }

    if (errorData) {
      this.errorData = errorData;
    }
    else {
      this.errorData = {}
    }
  }
}

class BaseAPI {
  apiEndpoint = appConfig.apiEndpoint;
  path = '';
  controller: AbortController|null = null;
  xhrController: XMLHttpRequest|null = null;

  get csrfToken() {
    let csrfToken = (window as any).csrftoken;
    if (!csrfToken) {
      csrfToken = getCookie('csrftoken');
    }
    return csrfToken;
  }

  get baseUrl() {
    return `${this.apiEndpoint}${this.path}`;
  }

  abort() {
    if (this.controller) {
      this.controller.abort();
      this.controller = null;
    }
    
    if (this.xhrController) {
      this.xhrController.abort();
      this.xhrController = null;
    }
  }

  async doFetch(params?: any): Promise<object[]> {
    let url = new URL(this.baseUrl);

    if (params) {
      (url.search as any) = new URLSearchParams(params);
    }

    // console.log('fetch', params, url.toString());
    if (window.AbortController) {
      this.controller = new AbortController();
      this.xhrController = null;
    }

    let raw;
    let response = await fetch(url.toString(), {
      credentials: 'include',
      signal: this.controller ? this.controller.signal : null,
    });

    if ((response.status >= 300) && (response.status < 600)) {
      if (response.status == 403) {
        const session = getSession();
        session.success = false;
      }
      throw new APIError(response.statusText, response.status, null);
    }

    raw = await response.json();
    this.isFetching = false;

    // console.log(raw);
    return raw;
  }

  isFetching: boolean = false;
  fetchPromise?: Promise<object[]>;

  fetch(params?: any): Promise<object[]> {
    return this.doFetch(params);
  }


  pendingGet: any = {}

  async get(path: string=''): Promise<object> {
    // if multiple get() run at the same time
    // no need to open multiple connections to the server
    if (this.pendingGet[path]) {
      let p = this.pendingGet[path];
      let value = await p;
      return value;
    }
    else {
      this.pendingGet[path] = this.rawGet(path);
      try {
        let value = await this.pendingGet[path];
        delete this.pendingGet[path];
        return value;
      } catch (error) {
        delete this.pendingGet[path];
        throw error;
      }
    }
  }

  async rawGet(path: string): Promise<object> {
    let raw;
    let url = `${this.baseUrl}${path}`;

    if (window.AbortController) {
      this.controller = new AbortController();
      this.xhrController = null;
    }

    try {
      let fetchPromise = fetch(url, {
        credentials: 'include',
        signal: this.controller ? this.controller.signal : null,
      });

      let response = await fetchPromise;   

      if ((response.status >= 300) && (response.status < 600)) {
        if (response.status == 403) {
          const session = getSession();
          session.success = false;
        }
        throw new APIError(response.statusText, response.status, null);
      }

      raw = await response.json();
    }
    catch (error) {
      throw new APIError((error as Error).message, (error as any).status ? (error as any).status : 1200, null);
    }

    return raw;
  }

  async post(data: any, path="") {
    let raw: any;
    const asFormData = data instanceof FormData;

    if (window.AbortController) {
      this.controller = new AbortController();
      this.xhrController = null;
    }

    let url = `${this.baseUrl}${path}`;
    let method = 'POST';

    let headers: any = {
      "X-CSRFToken": this.csrfToken,
    };

    if (!asFormData) {
      headers['Content-Type'] = 'application/json';
    }

    let response = undefined;
    let error = undefined;

    try {
      response = await fetch(url, {
        credentials: 'include',
        method: method,
        body: asFormData ? data : JSON.stringify(data),
        headers: headers,
        signal: this.controller ? this.controller.signal : null,
      });
      if ((response.status >= 300) && (response.status < 600)) {
        if (response.status == 403) {
          const session = getSession();
          session.success = false;
        }
        if (response.status == 400) {
          try {
            raw = await response.json();
          }
          catch (jsonParsingError) {
            raw = {success: false, errors: {non_field_errors: [`${jsonParsingError}`]}}
          }
        }
        throw new APIError(response.statusText, response.status, raw ? raw : null);
      }
    }
    catch (error) {
      throw new APIError((error as any).message, (error as any).status ? (error as any).status : 1200, (error as any).errorData ? (error as any).errorData : null);
    }

    try {
      raw = await response.json();
    }
    catch (jsonParsingError) {
      console.log(jsonParsingError)
      raw = {success: false, errors: {non_field_errors: [`${jsonParsingError}`]}}
    }

    return [raw, response, error];
  }

  async put(data: any, path="") {
    let raw: any;
    const asFormData = data instanceof FormData;

    if (window.AbortController) {
      this.controller = new AbortController();
      this.xhrController = null;
    }

    let url = `${this.baseUrl}${path}`;
    let method = 'PUT';

    let headers: any = {
      "X-CSRFToken": this.csrfToken,
    };

    if (!asFormData) {
      headers['Content-Type'] = 'application/json';
    }

    let response = undefined;
    let error = undefined;

    try {
      response = await fetch(url, {
        credentials: 'include',
        method: method,
        body: asFormData ? data : JSON.stringify(data),
        headers: headers,
        signal: this.controller ? this.controller.signal : null,
      });
      if ((response.status >= 300) && (response.status < 600)) {
        if (response.status == 403) {
          const session = getSession();
          session.success = false;
        }
        try {
          raw = await response.json();
        }
        catch (jsonParsingError) {
          
        }
        throw new APIError(response.statusText, response.status, raw ? raw : null);
      }
    }
    catch (error) {
      throw new APIError((error as Error).message, (error as any).status ? (error as any).status : 1200, (error as any).errorData ? (error as any).errorData : null);
    }

    try {
      raw = await response.json();
    }
    catch (jsonParsingError) {
      console.log(jsonParsingError)
      raw = {success: false, errors: {non_field_errors: [`${jsonParsingError}`]}}
    }

    return [raw, response, error];
  }

  async patch(data: any, path="") {
    let raw: any;
    const asFormData = data instanceof FormData;

    if (window.AbortController) {
      this.controller = new AbortController();
      this.xhrController = null;
    }

    let url = `${this.baseUrl}${path}`;
    let method = 'PATCH';

    let headers: any = {
      "X-CSRFToken": this.csrfToken,
    };

    if (!asFormData) {
      headers['Content-Type'] = 'application/json';
    }

    let response = undefined;
    let error = undefined;

    try {
      response = await fetch(url, {
        credentials: 'include',
        method: method,
        body: asFormData ? data : JSON.stringify(data),
        headers: headers,
        signal: this.controller ? this.controller.signal : null,
      });
      if ((response.status >= 300) && (response.status < 600)) {
        if (response.status == 403) {
          const session = getSession();
          session.success = false;
        }
        try {
          raw = await response.json();
        }
        catch (jsonParsingError) {
          
        }
        throw new APIError(response.statusText, response.status, raw ? raw : null);
      }
    }
    catch (error) {
      throw new APIError((error as Error).message, (error as any).status ? (error as any).status : 1200, (error as any).errorData ? (error as any).errorData : null);
    }

    try {
      raw = await response.json();
    }
    catch (jsonParsingError) {
      console.log(jsonParsingError)
      raw = {success: false, errors: {non_field_errors: [`${jsonParsingError}`]}}
    }

    return [raw, response, error];
  }

  async delete(path="") {
    let raw: any;

    if (window.AbortController) {
      this.controller = new AbortController();
      this.xhrController = null;
    }

    let url = `${this.baseUrl}${path}`;
    let method = 'POST';

    let headers: any = {
      "X-CSRFToken": this.csrfToken,
    };

    let response = undefined;
    let error = undefined;

    try {
      response = await fetch(url, {
        credentials: 'include',
        method: 'DELETE',
        headers: headers,
        signal: this.controller ? this.controller.signal : null,
      });
      if ((response.status >= 300) && (response.status < 600)) {
        if (response.status == 403) {
          const session = getSession();
          session.success = false;
        }
        throw new APIError(response.statusText, response.status, null);
      }
    }
    catch (error) {
      throw new APIError((error as Error).message, (error as any).status ? (error as any).status : 1200, null);
    }

    try {
      raw = await response.json();
    }
    catch (jsonParsingError) {
      console.log(jsonParsingError)
      raw = {success: false, errors: {non_field_errors: [`${jsonParsingError}`]}}
    }

    return [raw, response, error];
  }

  toFormData(data: any) {
    let formData = new FormData();
    for ( var key in data ) {
      if (data[key] === undefined) continue;
      if (data[key] === null) continue;
      if (data[key] === "") continue;
      if (data[key] === "null") continue;
      if (Array.isArray(data[key])) {
        let dataArray = (data[key] as any[]);
        dataArray.forEach((content) => {
          formData.append(key, content);
        });
        continue;
      }

      formData.append(key, data[key]);
    }

    return formData;
  }

  /**
   * Performs HTTP POST via XMLHttpRequest, with upload progress callback
   */
   async upload(
    data: any,
    path="",
    xhrCallback:(((xhr: XMLHttpRequest) => void)|null)=null,
    progressCallback:(((loaded: number, total: number) => void)|null)=null,
  ) {
  let raw: any;

  if (window.AbortController) {
    this.controller = null;
  }

  const asFormData = data instanceof FormData;

  let url = `${this.baseUrl}${path}`;
  let request: XMLHttpRequest|undefined = undefined;

  const xhrFetch = () => {
    return new Promise((resolve, reject) => {
      request = new XMLHttpRequest();
      this.xhrController = request;
      if (xhrCallback) xhrCallback(request);

      request.onreadystatechange = function() {
        if (!request) return;
        if (request.readyState === 4) {
          const response = request.response;
          if ((request.status >= 300) && (request.status < 600)) {
            if (request.status == 403) {
              const session = getSession();
              session.success = false;
            }
            reject(new APIError(request.statusText, request.status, null));
          }
          try {
            resolve(JSON.parse(response));
          } catch (error) {
            reject(new APIError((error as any).message, 1200, null));
          }
        }
      }

      request.upload.addEventListener('progress', (event) => {
        if (progressCallback) {
          progressCallback(event.loaded, event.total);
        }
      });

      request.addEventListener('error', (event) => {
        if (!request) return new APIError('Connection error', 1200, null);
        reject(new APIError(request.statusText, 1200, null));
      });

      request.addEventListener('abort', (event) => {
        if (!request) return new APIError('Connection aborted', 1200, null);
        reject(new APIError(request.statusText, 1200, null));
      });

      request.open('POST', url);

      request.setRequestHeader('X-CSRFToken', this.csrfToken)
      if (!asFormData) {
        request.setRequestHeader('Content-Type', 'application/json')
      }

      request.send(data);
    });
  }

  raw = await xhrFetch();
  return [raw, request];
}

}

export {
  BaseAPI,
  APIError,
}