import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Slide,
  Snackbar,
  Typography
} from '@material-ui/core';
import {
  Alert,
} from '@material-ui/lab';

import localization from '../../utils/localizations';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  container: {
    display: 'flex',
    alignItem: 'center',
  },
  progress: {
    color: '#ffffff',
    marginRight: 10
  },
  loadingText: {
    color: '#ffffff',
  }
}));


function GlobalLoadingIndicator() {
  const classes = useStyles();
  const isLoading = false; // TODO: migrate to mobx global state
  return (
    <Snackbar
      open={isLoading}
      TransitionComponent={Slide}
      message={
        <div className={classes.container}>
          <CircularProgress size={24} className={classes.progress} />
          <Typography variant="body1" className={classes.loadingText}>Loading...</Typography>
        </div>
      }
      key={'test-loading'}
    />
  );
}

export default GlobalLoadingIndicator;