import React, {
  useState,
  useEffect
} from 'react';

import { Form, Field } from "react-final-form";

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import {
  BaseAPI,
} from '../../data/BaseAPI';

import {
  Address,
} from '../../types';

import CountrySelect from './fields/CountrySelect';
import StateSelect from './fields/StateSelect';

const useStyles = makeStyles({
  root: {
    margin: 10,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  closeButton: {
    padding: 4,
  },
});


interface FormValues {
  address_line_1: string;
  address_line_2: string;
  city: string;
  country: string;
  state: string;
  zip: string;
}


export default function AddressForm(props: {
  address?: Address;
  userId?: string;
  className?: string;
  onCancel: () => void;
  onSaved: (address: Address) => void;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);

  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);

  const onSubmit = async (values: FormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    // try {
    //   const address = await BaseAPI.createAddress(values);
    //   props.onSaved(address);
    // } catch (e) {
    //   setShowErrorSnackBar(true);
    // }

    const api = new BaseAPI();
    
    let success = false;
    let addressId: number|undefined = undefined;
    let address: Address|undefined = undefined;

    if (!props.address) {
      try {
        const [result, response, error] = await api.post({
          user: props.userId,
          address_line_1: values.address_line_1,
          address_line_2: values.address_line_2,
          city: values.city,
          state: values.state,
          country: values.country,
          zip: values.zip,
        }, 'addresses/');
        addressId = result.id;
        address = result as Address;
        console.log('result', result);
        
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
      }
      setIsSaving(false);

      if (addressId && address) {
        props.onSaved(address);
      }
    }
    else {
      try {
        const [result, response, error] = await api.put({
          id: props.address.id,
          user: props.address.user,
          address_line_1: values.address_line_1,
          address_line_2: values.address_line_2,
          city: values.city,
          state: values.state,
          country: values.country,
          zip: values.zip,
        }, `addresses/${props.address.id}/`);
        addressId = result.id;
        address = result as Address;
        console.log('result', result);
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
      }
      setIsSaving(false);

      if (addressId && address) {
        props.onSaved(address);
      }
    }

    setIsSaving(false);
  }

  const onValidate = (values: FormValues) => {
    const errors: any = {}

    // if (!values.shipping_name) errors.shipping_name = "Required";
    // if (!values.address_line_1) errors.address_line_1 = "Required";
    // if (!values.city) errors.city = "Required";
    // if (!values.state) errors.state = "Required";
    // if (!values.zip) errors.zip = "Required";

    return errors;
  }


  const address = props.address;

  return (
    <div className={`${classes.root} ${props.className}`}>

    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        address_line_1: address ? address.address_line_1 : '',
        address_line_2: address ? address.address_line_2 : '',
        city: address ? address.city : '',
        state: address ? address.state : '',
        zip: address ? address.zip : '',
        country: address ? address.country : '',
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid container>
            
          <Field name="address_line_1">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Address Line 1'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Field name="address_line_2">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Address Line 2'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value.substring(0, 150)}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Grid container spacing={3}>
              <Field name="city">
                {props => (
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'City'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="country">
                {props => (
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>Country</InputLabel>
                      <CountrySelect
                        id={props.input.name}
                        label={'Country'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      />
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                      }
                    </FormControl>
                  </Grid>
                )}
              </Field>

              <Field name="state">
                {props => (
                  <Grid item xs={6}>
                    {values.country === 'United States' &&
                    <FormControl variant="outlined" fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <InputLabel>State</InputLabel>
                      <StateSelect
                        id={props.input.name}
                        label={'State'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      />
                      {((props.meta.error || props.meta.submitError) && props.meta.touched) &&
                        <Typography style={{color: 'red'}}>{props.meta.error}</Typography>
                      }
                    </FormControl>}
                    {values.country !== 'United States' &&
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'State'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                    }
                  </Grid>
                )}
              </Field>

              <Field name="zip">
                {props => (
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                      <TextField
                        id={props.input.name}
                        label={'Zip / Postal Code'}
                        variant="outlined"
                        name={props.input.name}
                        value={props.input.value.substring(0, 150)}
                        onChange={props.input.onChange}
                        error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                        helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Field>
            </Grid>


            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                type="submit"
                disabled={isSaving}
              >Save</Button>
              {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <Button
                variant="contained"
                onClick={() => props.onCancel()}
              >Cancel</Button>
            </Grid>
          </Grid>

          <Snackbar
            key={'error-snackbar'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showErrorSnackbar}
            onClose={() => setShowErrorSnackBar(false)}
            onExited={() => setShowErrorSnackBar(false)}
            message="Error saving your data. Please try again. Contact us if the issue persist."
            action={
              <React.Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={() => setShowErrorSnackBar(false)}
                >
                  <CloseIcon />
                </IconButton>
              </React.Fragment>
            }
          />

        </form>
      )}
    />
    </div>
  );
}