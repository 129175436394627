import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Chip,
  Tooltip,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';

import {
  BaseAPI,
} from '../../data/BaseAPI';

import {
  Boat
} from '../../types';


const useStyles = makeStyles((theme) => ({
  root: {
  },
  appBar: {
    position: 'relative',
  },
  appBarTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PrintIframe(props: {
  boat: Boat;
  className?: string;
}) {
  const classes = useStyles();
  const [showPrintDialog, setShowPrintDialog] = useState(false);

  return (
    <div className={`${classes.root} ${props.className}`}>
      <Button
        color="primary"
        onClick={() => {
          setShowPrintDialog(true);
        }}
        variant="outlined"
        startIcon={<PrintIcon />}
      >
        Print Order Form
      </Button>
      <Dialog
        fullScreen
        open={showPrintDialog}
        TransitionComponent={Transition}
        onClose={() => {
          setShowPrintDialog(false);
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setShowPrintDialog(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.appBarTitle}>
              Print Form
            </Typography>
          </Toolbar>
        </AppBar>
        {showPrintDialog &&
        <iframe
          key={`print_order_form_${props.boat.id}`}
          id={`print_order_form_${props.boat.id}`}
          src={`/print/boat/${props.boat.id}/order/`}
          style={{ width: '100%', height: '100%' }}
          title={`Order Form: ${props.boat.name}`}
        />}
      </Dialog>
    </div>
  );
}

export default PrintIframe;