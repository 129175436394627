import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  CircularProgress,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton
} from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import localization from '../utils/localizations';
import {
  Header
} from '../views/panels/Header';

import {
  SessionContext
} from '../data/Session';

import {
  BaseAPI,
} from '../data/BaseAPI';

import { useProfile } from '../data/Profile';

import {
  Order,
  User,
  DealerPermission
} from '../types';

import OrderList from '../views/collections/OrderList';
import DateTimeView from '../views/singles/DateTimeView';
import OrderDetail from '../views/singles/OrderDetail';
import OrderCollection from '../views/collections/OrderCollection';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    position: 'relative',
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    marginBottom: 10,
    padding: 10
  },
  marginVertical: {
    marginTop: 20,
    marginBottom: 20,
  },
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function LoadingView() {
  return (
    <div style={{position: 'relative', padding: 10}}>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
    </div>
  );
}


export default function OrdersPage() {
  const classes = useStyles();
  
  const [open, setOpen] = useState(false);
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [navigateBack, setNavigateBack] = useState(false);

  const [showEditOrderForm, setShowEditOrderForm] = useState(false);
  const [editOrder, setEditOrder] = useState<Order|null>(null);
  const [editOrderCounter, setEditOrderCounter] = useState(0);

  const [toolsMenuAnchorEl, setToolsMenuAnchorEl] = useState<HTMLElement|null>(null);


  const { path, url } = useRouteMatch();
  const backUrl = `/`;

  useEffect(() => {
    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    },300);

  }, []);

  const goBack = () => {
    setOpen(false);
    setTimeout(() => {
      setNavigateBack(true);
    }, 300);
  };

  const canAccessBoatOrderingPortal = (permissions?: DealerPermission[], role?: string) => {
    let hasPermission = false;
    if (role === 'admin') hasPermission = true;
    if (permissions) {
      permissions.forEach(permission => {
        if (permission.admin || permission.order) hasPermission = true;
      });
    }
    return hasPermission;
  }

  if (!canAccessBoatOrderingPortal(profile.permissions, profile.role)) {
    return (<>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={goBack}
      >
        <Typography variant="h2" style={{marginLeft: 14, marginTop: 14}}>Loading...</Typography>
        <div style={{height: 60}}></div>
      </Drawer>
      {navigateBack && <Redirect to={backUrl} />}
    </>);
  }

  return (
    <SessionContext.Consumer>
      {({session}) => (
        <>
          <Header
            session={session}
            title={profile.role === 'admin' ? 'All Orders' : 'Your Orders'}
            breadcrumbs={[
              {
                title: "Orders",
                link: `${appConfig.homepage}orders/`
              }
            ]}
          />
          <div className={classes.root}>
            <Paper className={classes.toolbar}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  component={RouterLink}
                  to={`${appConfig.homepage}order-form/`}
                >New Order</Button>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`${appConfig.homepage}orders/quotes/`}
                  style={{marginLeft: 12}}
                >Quotes</Button>
                <Button
                  variant="contained"
                  aria-controls="order-tools-menu"
                  aria-haspopup="true"
                  onClick={(e) => {
                    setToolsMenuAnchorEl(e.currentTarget)
                  }}
                  endIcon={<ExpandMoreIcon />}
                  style={{float: 'right'}}
                >
                  Tools
                </Button>
                <Menu
                  id="order-tools-menu"
                  anchorEl={toolsMenuAnchorEl}
                  keepMounted
                  open={Boolean(toolsMenuAnchorEl)}
                  onClose={() => setToolsMenuAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      setToolsMenuAnchorEl(null);
                    }}
                    component={RouterLink}
                    to={`${appConfig.homepage}orders/tools/fb-invoice-to-np-csv/`}
                    >Transform FishBowl Invoce into NorthPoint CSV</MenuItem>
                </Menu>
              </div>
            </Paper>

            <OrderCollection key={`order-collections-${editOrderCounter}`} />
          </div>


          <Switch>
            <Route exact path={`${path}:orderId/`}>
              <OrderDetailPanel />
            </Route>
          </Switch>

        </>
      )}
    </SessionContext.Consumer>
  );
}


function OrderDetailPanel() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [order, setOrder] = useState(undefined as Order|undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { path, url } = useRouteMatch();
  const { orderId } = useParams() as any;
  const backUrl = `/orders/`;

  const loadOrder = async (id: number) => {
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`orders/${id}/`);
      if ((data as any).id) {
        setOrder(data as Order);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadOrder(orderId);

    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    }, 300);

  }, [orderId]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
        aria-labelledby="selected-order-title"
        aria-describedby="selected-order-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.dialogTitle}>
              Order #{orderId}
            </Typography>
            <Typography style={{marginRight: 20}}>
              {order?.created_at && <DateTimeView value={order.created_at} />}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setNavigateBack(true);
              }, 300);
            }} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText id="selected-order-description">
            {!!order &&
              <OrderDetail
                order={order}
                onChanged={() => {
                  loadOrder(orderId);
                }}
              />}
            {!order &&
              <div>
                <LoadingView />
              </div>
            }
          </DialogContentText>
        </DialogContent>
        </>
      </Dialog>

      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}
