import React, {
  useState,
  useEffect,
  useRef,
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListSubheader,
  CircularProgress,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';

import CustomerCommentAttachments from '../collections/CustomerCommentAttachments';

import {
  APIError,
  BaseAPI,
} from '../../data/BaseAPI';

import {
  useProfile
} from '../../data/Profile';

import {
  Customer,
  WarrantyClaim,
  User,
  Session,
  Boat,
  CustomerComment,
  LaborCategory,
  JobDescription,
  CustomerCommentAttachment
} from '../../types';

import localization from '../../utils/localizations';

import CountrySelect from './fields/CountrySelect';
import StateSelect from './fields/StateSelect';

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles({
  root: {
    margin: 10,
  },
  fieldContainer: {
    marginBottom: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  closeButton: {
    padding: 4,
  },
  downloadButton: {
    marginRight: 6,
  },
  lineItem: {
    minWidth: '90%',
    marginBottom: 12,
  },
  lineItemTitle: {
    fontSize: 14
  }
});


interface CustomerCommentFormValues {
  comments: string;
  show_to_dealer: boolean;
}

export default function CustomerCommentForm(props: {
  comment: CustomerComment|null|undefined;
  onSave: (comment: CustomerComment) => void;
  onCancel: () => void;
  onDelete?: () => void;
  className?: string;
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  
  const [attachments, setAttachments] = useState<CustomerCommentAttachment[]>([]);
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);

  useEffect(() => {
    if (props.comment) {
      setAttachments(props.comment.attachments);
      if ((props.comment as any).removed_attachment_ids) {
        setRemovedAttachments((props.comment as any).removed_attachment_ids as string[]);
      }
      else {
        setRemovedAttachments([]);
      }
    }
  }, [props.comment]);

  const deleteComment = async (commentId: string) => {
    if (!window.confirm('Delete this comment?')) return;
    const api = new BaseAPI();
    try {
      const [result, request] = await api.delete(`warranty-customer-comments/${commentId}/`);
    } catch (error) {
      console.error(error);
    }
    if (props.onDelete) props.onDelete();
  }

  const onSubmit = async (values: CustomerCommentFormValues) => {
    setIsSaving(true);
    setShowErrorSnackBar(false);
    const api = new BaseAPI();
    if (props.comment) {
      const comment = Object.assign(props.comment, {
        comments: values.comments,
        show_to_dealer: values.show_to_dealer,
        attachments: Object.assign([], attachments),
        attachment_ids: attachments.map((attachment => attachment.id)),
        removed_attachment_ids: removedAttachments,
        updated_at: moment().format(),
      });
      console.log(comment);
      let url = `warranty-customer-comments/create_comment/`;
      if (props.comment.id) url = `warranty-customer-comments/${comment.id}/edit_comment/`;
      try {
        const [result, response, error] = await api.post(comment, url);
        console.log('result', result);
        if (result.id) {
          props.onSave(result as CustomerComment);
        }
      } catch (e) {
        console.log(e);
        setShowErrorSnackBar(true);
        if ((e as APIError).errorData) {
          setIsSaving(false);
          return (e as APIError).errorData;
        }
      }
    }

    setIsSaving(false);
  }

  const onValidate = (values: CustomerCommentFormValues) => {
    const errors: any = {}

    if (!values.comments) errors.comments = "Required";
    
    return errors;
  }

  let comment = props.comment;
  return (
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={{
        comments: comment ? comment.comments : '',
        show_to_dealer: comment ? comment.show_to_dealer : false,
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid container spacing={1}>

            <Field name="comments">
              {props => (
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.fieldContainer} error={props.meta.error && props.meta.touched ? !!props.meta.error : undefined}>
                    <TextField
                      id={props.input.name}
                      label={'Comment'}
                      variant="outlined"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={(props.meta.error || props.meta.submitError) && props.meta.touched ? true: false}
                      helperText={(props.meta.error || props.meta.submitError) && props.meta.touched ? props.meta.error || props.meta.submitError : undefined}
                      multiline
                      rows={4}
                    />
                  </FormControl>
                </Grid>
              )}
            </Field>

            <Grid item xs={12}>
              <CustomerCommentAttachments
                title="Attachments"
                attachments={attachments}
                onAdded={(attachment) => {
                  const newAttachments = Object.assign([], attachments);
                  newAttachments.push(attachment);
                  setAttachments(newAttachments);
                }}
                onRemoved={(attachment) => {
                  const index = attachments.findIndex(a => a.id === attachment.id)
                  const newAttachments = Object.assign([], attachments);
                  if (index >= 0) {
                    newAttachments.splice(index, 1);
                  }
                  setAttachments(newAttachments);

                  const newRemovedAttachments = Object.assign([], removedAttachments);
                  newRemovedAttachments.push(attachment.id);
                  setRemovedAttachments(newRemovedAttachments);
                }}
              />
            </Grid>

            <Field name="show_to_dealer">
              {props => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={props.input.value} onChange={props.input.onChange} name={props.input.name} />}
                    label="show this comment to dealers"
                  />
                </Grid>
              )}
            </Field>

            <Grid item xs={12}>
              <Button 
                type="submit"
                disabled={isSaving}
                color="primary"
                variant="contained"
              >
                {props.comment?.id ? "Update" : "Add"}
              </Button>
              {(!!props.comment?.id && props.onDelete) &&
              <Button
                onClick={() => {
                  if (props.comment?.id) deleteComment(props.comment.id);
                }}
                color="secondary"
                variant="contained"
                style={{
                  marginLeft: 12,
                  marginRight: 12
                }}
              >
                Delete
              </Button>}
              <Button onClick={() => props.onCancel()} color="primary">
                Cancel
              </Button>
            </Grid>
          </Grid>
      </form>
      )}
    />
  );
}
